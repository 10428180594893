import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import {
  COLUMN_TYPE,
  SpreadSheetConfig,
} from "@/common/providers/ColumnMapperProvider";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useProjectZonesStore } from "@/contractor/pages/home/project/store/projectZonesStore";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { useInvoiceImportExternalPO } from "../../../../providers/InvoiceImportExternalPoProvider";
import { InvoiceCreateReleaseFormValues } from "../InvoiceVerificationForm";

export const useInvoiceCreateReleaseSpreadsheetConfig =
  (): SpreadSheetConfig[] => {
    const intl = useIntl();
    const { requiredValidator } = useTableValidators();
    const { importedItems, importedPoItemized } = useInvoiceImportExternalPO();
    const { watch } = useFormContext<InvoiceCreateReleaseFormValues>();
    const projectId = watch("projectId");
    const { costCodes } = useProjectCostCodes(projectId);
    const { zones } = useProjectZonesStore(
      useShallow((state) => ({
        zones: state.zones,
      })),
    );

    const itemIsPartOfPo = useCallback(
      (id: string, columnType: COLUMN_TYPE) => {
        const importedItem = importedItems?.find((item) => item.id === id);
        if (importedItem) {
          switch (columnType) {
            case COLUMN_TYPE.Material:
              return importedItem.description
                ? intl.$t({ id: "DISABLED_ITEM_PART_OF_PO" })
                : "";
            case COLUMN_TYPE.UOM:
              return importedItem.UOM
                ? intl.$t({ id: "DISABLED_ITEM_PART_OF_PO" })
                : "";
            default:
              return "";
          }
        }

        return "";
      },
      [importedItems, intl],
    );

    if (!importedPoItemized) {
      return [
        {
          header: intl.$t({ id: "COST_CODE" }),
          columnId: "description",
          width: 100,
          columnType: COLUMN_TYPE.CostCode,
          options: costCodes.map((c) => c.formatted),
          metadata: {
            projectId: projectId,
          },
          readOnlyFn: (id) => itemIsPartOfPo(id, COLUMN_TYPE.Material),
        },
        {
          header: intl.$t({ id: "VALUE" }),
          columnId: "unitPrice",
          width: 60,
          columnType: COLUMN_TYPE.UnitPrice,
          validator: requiredValidator,
        },
      ];
    }

    return [
      {
        header: intl.$t({ id: "ITEM_NAME" }),
        columnId: "description",
        width: 100,
        columnType: COLUMN_TYPE.Material,
        readOnlyFn: (id) => itemIsPartOfPo(id, COLUMN_TYPE.Material),
      },
      {
        header: intl.$t({ id: "UOM" }),
        columnId: "UOM",
        width: 60,
        columnType: COLUMN_TYPE.UOM,
        options: costCodes.map((c) => c.formatted),
        metadata: {
          projectId: projectId,
        },
        readOnlyFn: (id) => itemIsPartOfPo(id, COLUMN_TYPE.UOM),
      },
      {
        header: intl.$t({ id: "QUANTITY" }),
        columnId: "quantityDecimal",
        width: 60,
        columnType: COLUMN_TYPE.Quantity,
      },
      {
        header: intl.$t({ id: "PRICE" }),
        columnId: "unitPrice",
        width: 60,
        columnType: COLUMN_TYPE.UnitPrice,
        validator: requiredValidator,
        disabledForLumpSum: true,
      },
      {
        header: intl.$t({ id: "EXT_PRICE" }),
        columnId: "extPrice",
        width: 60,
        columnType: COLUMN_TYPE.ExtPrice,
      },
      {
        header: intl.$t({ id: "COST_CODE" }),
        columnId: "costCode",
        width: 80,
        columnType: COLUMN_TYPE.CostCode,
        options: costCodes.map((c) => c.formatted),
        metadata: {
          projectId: projectId,
        },
      },
      {
        header: intl.$t({ id: "ZONE" }),
        columnId: "zone.name",
        width: 100,
        columnType: COLUMN_TYPE.Zone,
      },
      {
        header: intl.$t({ id: "NOTES" }),
        columnId: "instructions.text",
        width: 100,
        columnType: COLUMN_TYPE.Notes,
        hidden: zones?.length > 0,
      },
    ];
  };
