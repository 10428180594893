import { BackorderIcon } from "@/common/components/icons/BackorderIcon";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { formattedDate } from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import { routes } from "@/config/routes";
import { ReleasePartialFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";

const Container = tw.div`flex items-center w-[100%] justify-between`;
const Col = tw.div`grid`;
const Number = tw.span`truncate font-medium text-sm`;
const BackorderView = tw.div`flex flex-1 justify-center`;
const Description = tw.span`truncate text-[10px] font-normal text-gray-600 leading-[12px]`;

export const ReleaseName: FC<{ release: ReleasePartialFieldsFragment }> = ({
  release,
}) => {
  const navigate = useNavigate();
  return (
    <Container>
      <Col>
        <If isTrue={release?.buyout}>
          <LinkLike
            onClick={() => {
              navigate(
                generatePath(routes.buyout, { id: release?.buyout?.id }),
              );
            }}
          >
            <FormattedMessage
              id="BUYOUT"
              values={{ number: release?.buyout?.clientIdentifier }}
            />
          </LinkLike>
        </If>
        <Number>
          {!release.sequenceNumber ? (
            <FormattedMessage id="RELEASE_STATUS_DRAFT" />
          ) : (
            <FormattedMessage
              id="RELEASE_NAME"
              values={{ number: release.sequenceNumber }}
            />
          )}
        </Number>
        <If isTrue={release?.createdBy}>
          <Tooltip
            id="created-at"
            element={
              <Description>
                <FormattedMessage
                  id="BY_USERNAME"
                  values={{ username: getUserName(release.createdBy) }}
                />
              </Description>
            }
          >
            <FormattedMessage
              id="CREATED_AT"
              values={{
                date: formattedDate({ date: release.createdAt }),
              }}
            />
          </Tooltip>
        </If>
        <If isTrue={release?.description}>
          <Description>{release.description}</Description>
        </If>
      </Col>
      <BackorderView>
        <If isTrue={release.isBackorder}>
          <Tooltip id="backorder" element={<BackorderIcon />}>
            <If isTrue={release.backorderFrom.length === 1}>
              <FormattedMessage
                id="CONTAINS_BACKORDERED_ITEMS_FROM_ORDER"
                values={{
                  orderNumber: release.backorderFrom[0]?.sequenceNumber
                    ? `#${release.backorderFrom[0]?.sequenceNumber}`
                    : "",
                }}
              />
            </If>
            <If isTrue={release.backorderFrom.length > 1}>
              <FormattedMessage
                id="CONTAINS_BACKORDERED_ITEMS_FROM_ORDERS"
                values={{
                  orderNumbers: release.backorderFrom
                    .map((r) =>
                      r.sequenceNumber ? `#${r.sequenceNumber}` : "",
                    )
                    .join(", "),
                }}
              />
            </If>
          </Tooltip>
        </If>
      </BackorderView>
    </Container>
  );
};
