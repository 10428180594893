import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useVendors } from "@/contractor/pages/admin/vendors/hooks/useVendors";
import {
  AddToBuyoutItemInput,
  BuyoutsDocument,
  OrgPreferredVendorsFieldsFragment,
  UomsDocument,
  UpdateContractorBuyoutInput,
  UpdateContractorBuyoutItemInput,
  useCreateBuyoutMutation,
  useProjectPredictedPoNumberQuery,
} from "@/generated/graphql";
import { NoFunctionStringPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useBuyoutMutations } from "../../buyout/components/non-quoted/providers/useBuyoutMutations";
import { useContractorBuyout } from "../../buyout/providers/ContractorBuyoutProvider";
import { useQuoteDocument } from "../../common/quote-document/providers/QuoteDocumentProvider";
import { useProjectTags } from "../../project/providers/ProjectTagsProvider";
import { CreateBuyoutFromQuoteFormValues } from "../components/buyout-from-quote/create-buyout-from-quote/components/CreateBuyoutFromQuoteForm";

type AddToBuyoutExtendedItemInput = AddToBuyoutItemInput & {
  orgCatalogSkuName?: string;
  isAddMode?: boolean;
  notes?: string;
};

type ProviderContextType = {
  syncCreateBuyoutFromQuote: (options?: {
    submit: boolean;
  }) => Promise<string | boolean>;
  syncUpdateBuyoutFromQuote: () => Promise<string | boolean>;
  creating: boolean;
  updating: boolean;
  vendors: OrgPreferredVendorsFieldsFragment[];
  loadingVendors: boolean;
  predictedPoNumber?: string | null;
  loadingPredictedPoNumber: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  syncCreateBuyoutFromQuote: NoFunctionStringPromise,
  syncUpdateBuyoutFromQuote: NoFunctionStringPromise,
  creating: false,
  updating: false,
  vendors: [],
  loadingVendors: false,
  predictedPoNumber: "",
  loadingPredictedPoNumber: false,
});

export const BuyoutFromQuoteProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();
  const { setError } = useGlobalError();
  const { quoteDocument } = useQuoteDocument();
  const { materials } = useMaterials();
  const { buyout } = useContractorBuyout();
  const { defaultOrderType } = useOrderTypeOptions();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const {
    spreadsheetData,
    resetPreviousData,
    gotoInvalidRow,
    getRemovedRowIds,
    rowHasChanges,
  } = useColumnMapper();
  const {
    getCellValue,
    getRowUomCreatableValue,
    rowIsEmpty,
    addMissingMaterials,
    getCellWithAdditionalData,
    findMaterialByName,
    getNameParts,
    getCostCodeId,
    addMissingTags,
  } = useTableHelpers();
  const { setWarningAlert, setSuccessAlert } = useSnackbar();
  const { paginationArgs } = usePagination();
  const { vendors, loading: loadingVendors } = useVendors();
  const { tags } = useProjectTags();

  const { getValues, watch } =
    useFormContext<CreateBuyoutFromQuoteFormValues>();

  const projectId = watch("projectId");
  const poNumber = watch("poNumber");
  const { data: predictedPoNumberData, loading: loadingPredictedPoNumber } =
    useProjectPredictedPoNumberQuery({
      variables: {
        id: projectId,
      },
      skip: !projectId || !!poNumber,
      fetchPolicy: "no-cache",
    });

  const [saving, setSaving] = useState(false);
  const [createBuyout, { loading: creatingBuyout }] = useCreateBuyoutMutation();
  const syncCreateBuyoutFromQuote = async (options?: { submit: boolean }) => {
    if (spreadsheetData.every((row) => rowIsEmpty(row))) {
      setWarningAlert(
        <FormattedMessage id={`VALIDATION_ERROR_SHEETS_EMPTY_LIST`} />,
      );
      return false;
    }

    if (
      !validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.PositiveQuantity,
        COLUMN_TYPE.UOM,
      ]) ||
      !validateRowValues([
        COLUMN_TYPE.PositiveQuantity,
        COLUMN_TYPE.UnitPrice,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.CostCode,
      ])
    ) {
      gotoInvalidRow();
      return false;
    }

    const newItems: AddToBuyoutItemInput[] = [];

    setSaving(true);
    const newMaterials = (await addMissingMaterials()) || [];
    const newTags = (await addMissingTags(projectId)) || [];
    setSaving(false);

    spreadsheetData.forEach((row, index) => {
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.Material,
      );
      const material = findMaterialByName(rowMaterialText, [
        ...materials,
        ...newMaterials,
      ]);

      if (!material || rowIsEmpty(row)) {
        return false;
      }

      const uom = getRowUomCreatableValue(row);
      const quantityDecimal = getCellValue(
        row,
        COLUMN_TYPE.PositiveQuantity,
      ).toString();
      const unitPrice = Number(getCellValue(row, COLUMN_TYPE.UnitPrice));
      const notes = getCellValue(row, COLUMN_TYPE.Notes) || "";
      const tag = [...(tags || []), ...newTags].find(
        (t) => t?.name === getCellValue(row, COLUMN_TYPE.Tag),
      );

      newItems.push({
        description: getNameParts(rowMaterialText).namePart,
        projectItem: {
          estimateUom: uom,
          ...(isOrgCatalogSku(material.material) && {
            orgCatalogSkuId: material.material.id,
          }),
          ...(isProductSku(material.material) && {
            masterProductId: material.material.id,
          }),
          ...(isMasterSku(material.material) && {
            masterSkuId: material.material.id,
          }),
        },
        quantityDecimal,
        requestedUnitPrice: unitPrice ? String(unitPrice) : undefined,
        position: index,
        costCodeId: getCostCodeId(row),
        instructions: {
          text: notes,
        },
        tags: tag ? [tag.id] : [],
      });
    });

    if (newItems.length > 0) {
      try {
        const values = getValues();
        const { data, errors } = await createBuyout({
          variables: {
            input: {
              projectId: values.projectId ?? "",
              vendorLocationId: values.vendorId,
              releaseTypeId: values.releaseTypeId ?? defaultOrderType?.id,
              poNumber: values.poNumber || undefined,
              description: values.description || "",
              items: newItems,
              submit: options?.submit,
              taxRate:
                values.taxRate ||
                (values.clearCustomTaxAmount ||
                values.customTaxAmount?.length === 0
                  ? "0"
                  : undefined),
              customTaxAmount:
                values.clearCustomTaxAmount ||
                values.customTaxAmount?.length === 0
                  ? undefined
                  : values.customTaxAmount,
              paymentTerm: values.paymentTerm,
              quoteDocumentId: quoteDocument?.id ?? "",
              mergeDuplicates: false,
              instructions: values.instructions,
              vendorContactIds: values.vendorContactIds,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: UomsDocument,
            },
            {
              query: BuyoutsDocument,
              variables: {
                ...paginationArgs,
              },
            },
          ],
        });
        setError(errors);
        if (!errors) {
          resetPreviousData();
        }
        return data?.createBuyout?.id ?? "";
      } catch (error) {
        setError(error);
        return false;
      }
    }

    return true;
  };

  const { updateContractorBuyout, loading: updating } = useBuyoutMutations();
  const updateBuyoutItems = async (input: UpdateContractorBuyoutInput) => {
    try {
      return await updateContractorBuyout(input);
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const syncUpdateBuyoutFromQuote = async () => {
    const values = getValues();
    if (spreadsheetData.every((row) => rowIsEmpty(row))) {
      setWarningAlert(
        <FormattedMessage id={`VALIDATION_ERROR_SHEETS_EMPTY_LIST`} />,
      );
      return false;
    }

    if (
      !validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.PositiveQuantity,
        COLUMN_TYPE.UOM,
      ]) ||
      !validateRowValues([
        COLUMN_TYPE.PositiveQuantity,
        COLUMN_TYPE.UnitPrice,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.CostCode,
      ])
    ) {
      gotoInvalidRow();
      return false;
    }

    const newItems: AddToBuyoutExtendedItemInput[] = [];
    const itemsToUpdate: UpdateContractorBuyoutItemInput[] = [];
    const itemsToRemove = getRemovedRowIds(buyout?.items ?? []);

    setSaving(true);
    const newMaterials = (await addMissingMaterials()) || [];
    const newTags = (await addMissingTags(projectId)) || [];
    setSaving(false);

    spreadsheetData.forEach((row, index) => {
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.Material,
      );
      const material = findMaterialByName(rowMaterialText, [
        ...materials,
        ...newMaterials,
      ]);

      if (!material || rowIsEmpty(row)) {
        if (row.id) {
          itemsToRemove.push(row.id);
        }
        return false;
      }

      const uom = getRowUomCreatableValue(row);
      const quantityDecimal = getCellValue(row, COLUMN_TYPE.PositiveQuantity);
      const unitPrice = Number(getCellValue(row, COLUMN_TYPE.UnitPrice));
      const notes = getCellValue(row, COLUMN_TYPE.Notes) || "";
      const tag = [...(tags || []), ...newTags].find(
        (t) => t?.name === getCellValue(row, COLUMN_TYPE.Tag),
      );

      const existingItem = buyout?.items.find((item) => item.id === row.id);
      const matchingMaterials =
        existingItem && existingItem.projectItem.material.id === material.id;
      if (matchingMaterials) {
        if (rowHasChanges(row) || existingItem.position !== index) {
          itemsToUpdate.push({
            buyoutItemId: row.id,
            quantityDecimal,
            instructions: {
              text: notes,
            },
            uom,
            requestedUnitPrice: unitPrice ? String(unitPrice) : undefined,
            position: index,
            costCodeId: getCostCodeId(row),
            clearCostCode: !getCostCodeId(row),
            tags: tag ? [tag.id] : [],
          });
        }
      } else {
        newItems.push({
          description: getNameParts(rowMaterialText).namePart,
          projectItem: {
            estimateUom: uom,
            ...(isOrgCatalogSku(material.material) && {
              orgCatalogSkuId: material.material.id,
            }),
            ...(isProductSku(material.material) && {
              masterProductId: material.material.id,
            }),
            ...(isMasterSku(material.material) && {
              masterSkuId: material.material.id,
            }),
          },
          instructions: {
            text: notes,
          },
          quantityDecimal,
          requestedUnitPrice: unitPrice ? String(unitPrice) : undefined,
          position: index,
          costCodeId: getCostCodeId(row),
          tags: tag ? [tag.id] : [],
        });
        if (row.id) {
          itemsToRemove.push(row.id);
        }
      }
    });

    if (buyout) {
      const result = await updateBuyoutItems({
        buyoutId: buyout?.id || "",
        version: buyout?.version || 0,
        taxRate: values.taxRate || undefined,
        customTaxAmount: values.customTaxAmount || undefined,
        description: values.description,
        clearCustomTaxAmount: !values.customTaxAmount,
        poNumber: values.poNumber || undefined,
        vendorLocationId: values.vendorId,
        updates: itemsToUpdate,
        addedItems: newItems,
        removedItems: itemsToRemove,
        instructions: values.instructions,
      });

      if (result) {
        resetPreviousData();
        setSuccessAlert(intl.$t({ id: "BUYOUT_ITEMS_SAVED_SUCCESS" }));
      }
      return result;
    }
    return true;
  };

  return (
    <ProviderContext.Provider
      value={{
        syncCreateBuyoutFromQuote,
        syncUpdateBuyoutFromQuote,
        creating: saving || creatingBuyout,
        updating,
        vendors,
        loadingVendors,
        predictedPoNumber: predictedPoNumberData?.project?.predictedPoNumber,
        loadingPredictedPoNumber,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useBuyoutFromQuote = (): ProviderContextType =>
  useContext(ProviderContext);
