import { BaseButton } from "@/common/components/button/BaseButton";
import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { Select } from "@/common/components/select/components/single/Select";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useInvoicesUpdate } from "@/contractor/pages/home/invoices/pages/invoice-verification/hooks/useInvoicesUpdate";
import { useInvoiceVerification } from "@/contractor/pages/home/invoices/pages/invoice-verification/providers/InvoiceVerificationProvider";
import Close from "@mui/icons-material/Close";
import { Menu } from "@mui/material";
import { useMemo, useState } from "react";
import { MdOutlineAddToPhotos } from "react-icons/md";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`
  relative grid grid-flow-col gap-1 justify-self-start h-fit self-center
`;
const Tag = tw.span`
  grid relative place-items-center bg-blue-400 text-white h-6 text-2xs px-3 rounded-3xl group/tag min-w-12
`;
const More = tw.span`
  grid place-items-center bg-blue-500 text-white text-2xs p-1 w-6 h-6 rounded-3xl group-hover:hidden
`;
const MoreTagsContainer = tw.div`
  grid grid-flow-col gap-1 h-fit
`;
const MdOutlineAddToPhotosStyled = tw(MdOutlineAddToPhotos)`text-white text-sm`;
const ActionContainer = tw.div`
  absolute grid grid-flow-col place-items-center right-0 top-0 pr-0.5 rounded-r-3xl cursor-pointer 
  opacity-0 group-hover/tag:opacity-100 bg-blue-400 h-6
`;
const Dots = tw.span`
  text-2xs py-1 font-medium pl-0.5
`;
const CloseIcon = tw(Close)`
  text-sm pb-0.5 text-blue-800 hover:text-black
`;
const CloseButton = tw(IconButtonBorderless)`
  w-5 h-5 min-h-5 place-items-center
`;
const IconButton = tw(BaseButton)`
  text-gray-600 border-none w-6 h-6 px-1 py-1 min-h-6 bg-blue-400 hover:bg-blue-500
`;

const MORE_TAG_COUNT = 3;

export const InvoiceGroups = () => {
  const { invoiceFolders } = useOrgSettings();
  const { invoice, refetch } = useInvoiceVerification();
  const { updateInvoices, loading } = useInvoicesUpdate();
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onRemove = async (group: { id: string }) => {
    if (!invoice) {
      return;
    }
    await updateInvoices({
      invoiceIds: [invoice.id],
      unassignedFolderIds: [group.id],
    });
    await refetch();
  };

  const onAdd = async (selectedFolderId: string | null) => {
    if (!invoice || !selectedFolderId) {
      return;
    }
    await updateInvoices({
      invoiceIds: [invoice.id],
      assignedFolderIds: [selectedFolderId],
    });
    await refetch();
  };

  const remainingFolders = useMemo(() => {
    return (
      invoiceFolders?.filter(
        (folder) => !invoice?.folders.some((group) => group.id === folder.id),
      ) ?? []
    );
  }, [invoiceFolders, invoice?.folders]);

  if (!invoice) {
    return null;
  }

  return (
    <Container>
      {invoice?.folders.slice(0, MORE_TAG_COUNT).map((group) => (
        <Tag key={group.id}>
          {group.name}
          <ActionContainer>
            <Dots>...</Dots>
            <CloseButton onClick={() => onRemove(group)}>
              <CloseIcon />
            </CloseButton>
          </ActionContainer>
        </Tag>
      ))}
      {invoice?.folders && invoice?.folders.length > MORE_TAG_COUNT && (
        <Tooltip
          id="invoice-groups-tooltip"
          element={
            <More>+{(invoice?.folders?.length || 0) - MORE_TAG_COUNT}</More>
          }
          position="bottom"
        >
          <MoreTagsContainer>
            {invoice?.folders.slice(MORE_TAG_COUNT).map((group) => (
              <Tag key={group.id}>
                {group.name}
                <ActionContainer>
                  <Dots>...</Dots>
                  <CloseButton onClick={() => onRemove(group)}>
                    <CloseIcon />
                  </CloseButton>
                </ActionContainer>
              </Tag>
            ))}
          </MoreTagsContainer>
        </Tooltip>
      )}
      <If isTrue={remainingFolders.length > 0}>
        <IconButton
          onClick={(event) => {
            setOpen(true);
            setAnchorEl(event.currentTarget);
          }}
        >
          <MdOutlineAddToPhotosStyled />
        </IconButton>
      </If>
      <If isTrue={open}>
        <Menu open={open} anchorEl={anchorEl} onClose={() => setOpen(false)}>
          <Select
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            placeholder={intl.formatMessage({ id: "ADD_TO_INVOICE_GROUP" })}
            options={remainingFolders}
            getLabel={(option) => option.name}
            getValue={(option) => option.id}
            onChange={(value) => onAdd(value)}
            className="px-1"
          />
        </Menu>
      </If>
      <Loader loading={loading} small />
    </Container>
  );
};
