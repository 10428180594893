import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { PDF_FONT } from "@/common/const";
import { useDownloadAsset } from "@/common/hooks/useDowloadAsset";
import { releaseHeader } from "@/common/utils/pdf-print/releaseHeader/releaseHeader";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useManufacturersSettings } from "@/contractor/pages/admin/organization/pages/org-settings/hooks/manufacturers-settings/useManufacturersSettings";
import { useProjectTags } from "@/contractor/pages/home/project/providers/ProjectTagsProvider";
import jsPDF from "jspdf";
import { useIntl } from "react-intl";
import { useReleaseItemsZoneProvider } from "../../../providers/ReleaseItemsZonesProvider";
import { ExpandedRelease } from "../../../providers/ReleaseProvider";
import { backorderMaterials } from "./print/backorderMaterials";
import { generalInstructions } from "./print/generalInstructions";
import { materials } from "./print/materials";
import { total } from "./print/total";

export const usePrintView = (
  release: ExpandedRelease | null | undefined,
  groupedByCostCode: boolean,
  fullyReceived: boolean | null | undefined,
  fullyInvoiced: boolean | null | undefined,
  filteredTags: string[],
) => {
  const intl = useIntl();
  const { zones, usePhaseCodes } = useReleaseItemsZoneProvider();
  const { hasPhaseCodes } = useOrgSettings();
  const { hasManufacturersSetting } = useManufacturersSettings();
  const { formatCostCode } = useCostCodes();
  const { tags } = useProjectTags();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { hasPermissions: canViewPrices } = usePermissions([
    Permission.canViewPrices,
  ]);
  const { downloadAsset } = useDownloadAsset();

  const savePDF = async () => {
    const contractorLogo = await downloadAsset(
      release?.orgLocation?.org.photoUrl,
    );
    let topSpacing = 15;
    if (release) {
      const doc = new jsPDF("landscape")
        .setFont(PDF_FONT)
        .setLineHeightFactor(1.5);

      topSpacing = releaseHeader(
        doc,
        release,
        intl,
        topSpacing,
        contractorLogo.base64Data,
        contractorLogo.proportion,
      );

      topSpacing = generalInstructions(doc, release, intl, topSpacing + 10);

      materials(
        doc,
        release,
        intl,
        zones,
        topSpacing,
        hasPhaseCodes && !!usePhaseCodes,
        hasManufacturersSetting,
        groupedByCostCode,
        fullyReceived,
        fullyInvoiced,
        filteredTags,
        formatCostCode,
        formatCurrency,
        tags,
        canViewPrices,
      );
      if (canViewPrices) {
        total(doc, release, intl, formatCurrency);
      }
      if (
        release.items.find(
          (i) =>
            i.alternativeFulfillmentRelease || i.alternativeFulfillmentTime,
        )
      ) {
        doc.addPage();
        backorderMaterials(
          doc,
          release,
          intl,
          zones,
          hasPhaseCodes,
          hasManufacturersSetting,
          groupedByCostCode,
          formatCostCode,
          formatCurrency,
          canViewPrices,
        );
      }
      doc.save(
        `Order ${
          release.sequenceNumber
            ? `#${release.sequenceNumber}`
            : `${intl.$t({ id: "RELEASE_STATUS_DRAFT" })}`
        }.pdf`,
      );
    }
  };

  return {
    savePDF,
  };
};
