import { EventKeysConstants } from "@/config/constants/eventKeysContants";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  FilterOptionsState,
  createFilterOptions,
} from "@mui/material";
import React, {
  ReactNode,
  Ref,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../../../if/If";
import { Loader } from "../../../loader/Loader";
import { Tooltip } from "../../../tooltip/Tooltip";
import { InputRenderer } from "../common/InputRenderer";
import { PopupIcon } from "../common/PopupIcon";
import { SelectCommonProps } from "../common/SelectCommonProps";
import { CustomPopper } from "./components/CustomPoper";

const UNDEFINED_DEFAULT_KEY = "0";

export type SelectProps<T> = SelectCommonProps<T> & {
  disabledTooltip?: string;
  handleInputChange?: (value: string) => void;
  onChange?: (value: string | null) => void;
  openOnFocus?: boolean;
  serverSideFiltering?: boolean;
  customFilter?: (options: T[], params: FilterOptionsState<T>) => T[];
  value?: string | null;
  selectedOptionCustomView?: ReactNode;
  shrink?: boolean;
};

const InputContainer = tw.div`relative w-full`;

const SelectWithoutRef = <T,>(
  {
    options,
    getLabel,
    getOptionLabel,
    getOptionDisabled,
    getValue,
    customRender,
    value,
    onChange,
    className,
    handleInputChange = () => undefined,
    disableClearable = true,
    disabled,
    serverSideFiltering = false,
    staticText = false,
    creatable = false,
    creatableTextKey,
    testId,
    creatableFn,
    creatableFirstOption = false,
    creatableAlwaysVisible = false,
    popup,
    loading = false,
    disabledTooltip,
    error,
    inputProps,
    InputLabelProps,
    inputValue,
    customFilter,
    InputProps,
    selectedOptionCustomView,
    onBlur,
    onFocus,
    shrink,
    ...props
  }: SelectProps<T>,
  ref: Ref<HTMLDivElement>,
) => {
  const intl = useIntl();
  const [isFocused, setIsFocused] = useState(false);

  const filter = useMemo(
    () => creatableFn && createFilterOptions<T>(),
    [creatableFn],
  );

  const selectedOption = useMemo(() => {
    const option = options.find((o) => getValue(o) === value);
    return option || null;
  }, [options, getValue, value]);

  const getValueWithDefaultValue = useCallback(
    (option: T | null) => (option ? getValue(option) || "" : ""),
    [getValue],
  );

  const onInputChange = useCallback(
    (event: SyntheticEvent, newInputValue: string) => {
      if (event) {
        if (
          (event as unknown as KeyboardEvent).key ===
            EventKeysConstants.Enter ||
          event.type === "blur"
        ) {
          setIsFocused(false);
          event.preventDefault();
          event.stopPropagation();
          return;
        }
        handleInputChange(newInputValue);
      }
    },
    [handleInputChange],
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <InputContainer className="relative w-full">
        {selectedOptionCustomView && !isFocused ? (
          <>{selectedOptionCustomView}</>
        ) : null}
        <InputRenderer
          props={{
            ...props,
            InputLabelProps,
            inputProps:
              selectedOptionCustomView && isFocused
                ? inputProps
                : {
                    ...(inputProps || {}),
                    className: `${inputProps?.className ?? ""} py-[2.5px] ${selectedOptionCustomView && !isFocused ? " text-transparent" : ""}`,
                  },
            className: `${className} ${selectedOptionCustomView && !isFocused ? "bg-transparent" : "bg-white"}`,
            staticText,
            error,
            InputProps,
            shrink,
          }}
          params={params}
        />
      </InputContainer>
    ),
    [
      selectedOptionCustomView,
      isFocused,
      props,
      InputLabelProps,
      inputProps,
      className,
      staticText,
      error,
      InputProps,
      shrink,
    ],
  );

  const renderOption = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, option: T) => {
      const value = getLabel(option);
      const key = getValue(option);
      if (disabledTooltip && getOptionDisabled && getOptionDisabled(option)) {
        return (
          <Tooltip
            id="disabled-tooltip"
            element={
              <li
                {...props}
                key={key || UNDEFINED_DEFAULT_KEY}
                className={`${props.className} ${
                  customRender ? "m-0 py-0" : ""
                }`}
              >
                <If isTrue={customRender}>
                  {customRender && customRender(option)}
                </If>
                <If isTrue={!customRender}>{value}</If>
              </li>
            }
            key={key}
          >
            {disabledTooltip}
          </Tooltip>
        );
      }

      return (
        <li
          {...props}
          key={key || UNDEFINED_DEFAULT_KEY}
          className={`${props.className} ${customRender ? "m-0 py-0" : ""}`}
        >
          <If isTrue={customRender}>{customRender && customRender(option)}</If>
          <If isTrue={!customRender}>{value}</If>
        </li>
      );
    },
    [customRender, disabledTooltip, getLabel, getOptionDisabled, getValue],
  );

  const fullClassName = useMemo(
    () =>
      `${className ?? ""} ${
        selectedOptionCustomView && !isFocused ? "bg-transparent" : "bg-white"
      }`,
    [className, selectedOptionCustomView, isFocused],
  );

  const filterOptions = useMemo(
    () =>
      serverSideFiltering
        ? (option: T[]) => option
        : creatable && creatableFn
          ? (options: T[], params: FilterOptionsState<T>) => {
              if (creatable) {
                const filtered = customFilter
                  ? customFilter(options, params)
                  : filter
                    ? filter(options, params)
                    : options;
                const { inputValue } = params;
                if (
                  filtered &&
                  !filtered.find(
                    (o) =>
                      getLabel(o).toLocaleLowerCase() ===
                      inputValue.toLocaleLowerCase(),
                  ) &&
                  (inputValue !== "" || creatableAlwaysVisible) &&
                  !inputValue.includes("Add")
                ) {
                  const creatableResults = creatableFn(
                    intl.$t(
                      { id: creatableTextKey || "CREATABLE_ADD_NEW" },
                      { inputValue },
                    ),
                    inputValue,
                  );
                  if (creatableResults) {
                    const mappedCreatableResults = (
                      (creatableResults as Array<T>)?.length > 0
                        ? creatableResults
                        : [creatableResults]
                    ) as Array<T>;
                    return creatableFirstOption
                      ? mappedCreatableResults.concat(filtered)
                      : filtered.concat(mappedCreatableResults);
                  }
                  return filtered;
                }
                return filtered;
              }

              return options;
            }
          : undefined,

    [
      creatable,
      creatableAlwaysVisible,
      creatableFirstOption,
      creatableFn,
      creatableTextKey,
      customFilter,
      filter,
      getLabel,
      intl,
      serverSideFiltering,
    ],
  );

  return (
    <Autocomplete
      ref={ref}
      {...props}
      options={options}
      PopperComponent={CustomPopper}
      className={fullClassName}
      data-testid={testId}
      onInputChange={onInputChange}
      onChange={(event, value) => {
        onChange?.(getValueWithDefaultValue(value as T));
      }}
      isOptionEqualToValue={(option, value) =>
        getValue(option) === getValue(value)
      }
      value={selectedOption}
      getOptionLabel={(option) =>
        getOptionLabel
          ? getOptionLabel(option as T) || ""
          : getLabel(option as T) || ""
      }
      freeSolo={creatable}
      disableClearable={disableClearable}
      inputValue={inputValue}
      autoHighlight
      filterOptions={filterOptions}
      disabled={disabled}
      readOnly={staticText || disabled}
      popupIcon={
        <PopupIcon
          disabled={disabled || staticText}
          loading={loading}
          popup={popup}
        />
      }
      loading={loading}
      loadingText={<Loader loading small />}
      renderInput={renderInput}
      getOptionDisabled={getOptionDisabled}
      renderOption={renderOption}
      onBlur={() => {
        setIsFocused(false);
        onBlur?.();
      }}
      onFocus={() => {
        setIsFocused(true);
        onFocus?.();
      }}
    />
  );
};

export const Select = React.forwardRef(SelectWithoutRef) as <T>(
  props: SelectProps<T> & { ref?: React.ForwardedRef<HTMLUListElement> },
) => ReturnType<typeof SelectWithoutRef>;
