import { DateView } from "@/common/utils/dates/DateView";
import { ReleasePartialFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`col-span-2`;
const DateItem = tw.div`text-sm font-medium`;
const Type = tw.div`text-xs text-gray-600 flex items-center justify-center`;

type Props = {
  release: Pick<ReleasePartialFieldsFragment, "time">;
  isDelivery: boolean;
};

export const ReleaseTimeAndType: FC<Props> = ({ release, isDelivery }) => {
  return (
    <Container>
      <DateItem>
        <DateView date={release.time} />
      </DateItem>
      <FormattedMessage
        id={isDelivery ? "BY_VENDOR" : "CLIENT_DELIVERY"}
        tagName={Type}
      />
    </Container>
  );
};
