import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { SelectCustomItemView } from "@/common/components/select/components/single/components/SelectCustomItemView";
import { Select } from "@/common/components/select/components/single/Select";
import { TextField } from "@/common/components/textfield/TextField";
import { CreateInvoiceFolderInput } from "@/generated/graphql";
import Close from "@mui/icons-material/Close";
import { Popover } from "@mui/material";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { EditFolderDialog } from "./EditFolderDialog";
import { useInvoiceFolders } from "./hooks/useInvoiceFolders";
import { InvoiceFolderPickerCustomRender } from "./InvoiceFolderPickerCustomRender";
import { useFolderEditStore } from "./store/useFolderEditStore";

const NewFolder = tw.div`z-50 flex flex-row justify-between items-center pl-4 bg-blue-100 border border-gray-300 rounded-t-lg border-b-0 text-sm`;
const NewFolderItem = tw.div`truncate 2xl:whitespace-normal`;
const FolderForm = tw.div`flex flex-row gap-2 p-3 bg-blue-100`;
const Container = tw.div``;
const TextFieldStyled = tw(TextField)`bg-white min-w-52`;

export const ADD_NEW_INVOICE_FOLDER_ID = "ADD_NEW_INVOICE_FOLDER";
export const ALL_INVOICE_FOLDER_ID = "ALL_INVOICE_FOLDER";
export const UNASSIGNED_INVOICE_FOLDER_ID = "UNASSIGNED_INVOICE_FOLDER";

type Option = {
  id: string;
  name: string;
};

type Props = {
  value: string[] | null | undefined;
  onChange: (folderId: string[] | undefined) => void;
  label?: string;
  withCustomView?: boolean;
};

export const InvoiceFolderPicker: FC<Props> = ({
  value,
  onChange,
  label,
  withCustomView = false,
}: Props) => {
  const intl = useIntl();
  const { folders, createFolder } = useInvoiceFolders();
  const [newFolder, setNewFolder] = useState<CreateInvoiceFolderInput>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [loading, setLoading] = useState(false);
  const { reset } = useFolderEditStore();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    reset();
  }, [reset]);

  const emptyFolder: Option = useMemo(
    () => ({
      id: ADD_NEW_INVOICE_FOLDER_ID,
      name: intl.$t({ id: "ADD_NEW_INVOICE_FOLDER" }),
      readOnly: true,
    }),
    [intl],
  );

  const defaultOptions = useMemo(() => {
    return [
      {
        id: ALL_INVOICE_FOLDER_ID,
        name: intl.$t({ id: "ALL_INVOICE_GROUPS" }),
      },
      {
        id: UNASSIGNED_INVOICE_FOLDER_ID,
        name: intl.$t({ id: "UNASSIGNED_INVOICE_GROUPS" }),
      },
    ];
  }, [intl]);

  const options = useMemo(() => {
    return [
      ...defaultOptions,
      ...folders.toSorted((a, b) => (a.name || "").localeCompare(b.name || "")),
    ] as Option[];
  }, [folders, defaultOptions]);

  const [text, setInputText] = useState<string>(
    options.find((v) => value?.includes(v.id))
      ? options.find((v) => value?.includes(v.id))?.name || ""
      : "",
  );

  useEffect(() => {
    const option = options.find((v) => value?.includes(v.id));
    if (option) {
      setInputText(option.name || "");
    }
  }, [options, value, intl]);

  const blurInput = useCallback(() => {
    if (ref.current) {
      const input = ref.current.querySelector("input");
      if (input) {
        input.blur();
      }
    }
  }, []);

  const handleChange = useCallback(
    (folderId: string | null) => {
      if (folderId === ADD_NEW_INVOICE_FOLDER_ID) {
        setNewFolder({
          name: text,
        });
        setAnchorEl(ref.current);
      } else if (
        folderId === ALL_INVOICE_FOLDER_ID ||
        folderId === ADD_NEW_INVOICE_FOLDER_ID
      ) {
        setInputText("");
        onChange(undefined);
        blurInput();
      } else {
        if (folderId) {
          onChange([folderId]);
        } else {
          onChange(undefined);
        }
      }
    },
    [onChange, ref, text, blurInput],
  );

  const handleCreate = useCallback(async () => {
    if (newFolder) {
      setLoading(true);
      const result = await createFolder(newFolder);
      if (result) {
        setNewFolder(undefined);
        setAnchorEl(null);
        setInputText("");
        blurInput();
      }
    }
    setLoading(false);
  }, [createFolder, newFolder, blurInput]);

  const handleInputChange = useCallback(
    (text: string) => {
      if (text === defaultOptions[0].name) {
        setInputText("");
      }
      setInputText(text);
    },
    [defaultOptions],
  );

  const onClose = useCallback(() => {
    setInputText("");
    setNewFolder(undefined);
    setAnchorEl(null);
  }, []);

  const selectedOption = useMemo(
    () => options.find((option) => option.id === value?.[0]),
    [options, value],
  );

  return (
    <Container ref={ref}>
      <If isTrue={!newFolder}>
        <Select
          testId="site-folder-picker"
          label={label || intl.$t({ id: "ALL_INVOICE_GROUPS" })}
          getLabel={(folder) => folder.name}
          getValue={(folder) => folder.id}
          creatableFn={() => emptyFolder}
          creatableTextKey="ADD_NEW_INVOICE_FOLDER"
          customFilter={(options, text) => {
            if (text) {
              return options.filter((v) =>
                v.name?.toLowerCase().includes(text.inputValue.toLowerCase()),
              );
            }
            return options;
          }}
          creatable
          creatableFirstOption
          creatableAlwaysVisible
          value={value?.[0]}
          options={options}
          onChange={handleChange}
          handleInputChange={handleInputChange}
          customRender={(item: Option) => (
            <InvoiceFolderPickerCustomRender {...item} />
          )}
          loading={loading}
          inputValue={text}
          onBlur={() => {
            setInputText(
              options.find((v) => value?.includes(v.id))?.name || "",
            );
          }}
          disableClearable={!value}
          selectedOptionCustomView={
            withCustomView && selectedOption ? (
              <SelectCustomItemView text={selectedOption.name} />
            ) : null
          }
        />
      </If>
      <If isTrue={newFolder}>
        <NewFolder>
          <FormattedMessage
            id="ADD_NEW_INVOICE_FOLDER"
            tagName={NewFolderItem}
          />
          <IconButtonBorderless onClick={() => setNewFolder(undefined)}>
            <Close />
          </IconButtonBorderless>
        </NewFolder>
      </If>
      <Popover
        anchorEl={anchorEl}
        onClose={onClose}
        open={!!newFolder}
        classes={{
          paper:
            "mt-10 xl:-ml-[61px] md:-ml-[9px] shadow-none border border-gray-300 border-t-0",
        }}
      >
        <FolderForm>
          <TextFieldStyled
            testId="invoiceGroupName"
            label={intl.$t({ id: "INVOICE_GROUP_NAME" })}
            value={newFolder?.name}
            onChange={(event) =>
              setNewFolder({
                ...newFolder,
                name: event.target.value,
              })
            }
          />
          <PrimaryButton onClick={handleCreate} testId="addBtn">
            <FormattedMessage id="ADD" />
          </PrimaryButton>
        </FolderForm>
      </Popover>
      <EditFolderDialog />
    </Container>
  );
};
