import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useServiceCodesQuery } from "@/generated/graphql";

export const useServiceCodes = () => {
  const { data, error, loading } = useServiceCodesQuery();
  useErrorEffect(error);

  return {
    serviceCodes: data?.viewer?.org.serviceCodes || [],
    loading,
  };
};
