import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { ExpandablePanel } from "@/common/components/expandable-panel/ExpandablePanel";
import { GridTable } from "@/common/components/grid-table/GridTable";
import { If } from "@/common/components/if/If";
import { ListEmptyState } from "@/common/components/list-empty-state/ListEmptyState";
import { Loader } from "@/common/components/loader/Loader";
import { OrderTypePicker } from "@/common/components/order-type-picker/OrderTypePicker";
import { Price } from "@/common/components/price/Price";
import { Select } from "@/common/components/select/components/single/Select";
import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { TagItem } from "@/common/components/tag-picker/TagItem";
import { useSourceSystemLogo } from "@/common/hooks/integrations/source-system-config/useSourceSystemLogo";
import { CategoryLike, CategoryState } from "@/common/hooks/useToggleCategory";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useImportProjectTags } from "@/contractor/pages/home/project/hooks/useImportProjectTags";
import {
  ExternalPoFieldsFragment,
  ExternalPoItemFieldsFragment,
} from "@/generated/graphql";
import { Identity } from "@/types/Identity";
import { ChevronLeft, InfoOutlined } from "@mui/icons-material";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  ExportPOItemType,
  ExternalPOItem,
  useExternalItemsPOTableConfiguration,
  useMergedExternalItemsPOTableConfiguration,
} from "./ExternalPOItemsTable.configuration";
const Container = tw.div`flex flex-1 flex-col rounded-3xl overflow-auto pt-1`;
const Row = tw.div`flex flex-row items-center`;
const Header = tw(Row)`h-20 items-center justify-between w-full bg-white`;
const Title = tw.div`text-xl font-bold mr-5`;
const SourceSystemImage = tw.img`ml-10`;
const InnerContainer = tw.div`flex flex-col flex-1`;
const ExcludedSection = tw.div`pb-4`;
const SectionTitleRow = tw(Row)`w-full items-center justify-center h-20`;
const List = tw.div``;
const UnsupportedCostTypesList = tw(List)`mt-4`;
const AggregatedItems = tw(Container)`bg-white max-h-[500px]`;
const AggregatedItem = tw.div`grid grid-cols-[1fr_1fr_100px] flex-row px-6 border border-gray-500 rounded-xl py-5 mt-2 justify-between border-dashed items-center`;
const Col = tw.div`flex flex-col`;
const Description = tw.div`text-2xs text-gray-500 mr-4`;
const Name = tw.div`text-base font-medium`;
const TotalRow = tw.div`flex flex-row items-center justify-end py-8 font-medium text-lg pr-6`;
const ProjectName = tw.div`text-sm`;
const BackButton = tw(PrimaryButton)`w-6 h-6 min-w-6 rounded-md mr-4`;
const ValidationError = tw(
  Row,
)`text-red-500 text-xs max-w-[450px] text-left mr-4`;
const InfoOutlinedStyled = tw(InfoOutlined)`w-6 h-6 mr-3`;
const TotalContainer = tw.div`flex gap-2 text-right w-full mt-4 justify-end pr-4`;
const TotalHeader = tw.div`font-bold`;

type Props = {
  close: () => void;
  externalPO: ExternalPoFieldsFragment | null | undefined;
  projectId?: string | null | undefined;
  loading?: boolean;
  readonly?: boolean;
  validationError?: string | null;
  missingCostCodesCategory: CategoryState<ExternalPOItem>[];
  missingTagsCategory: CategoryState<ExternalPOItem>[];
  unsupportedCostTypesCategory: CategoryState<ExternalPOItem>[];
  includedItemsCategory: CategoryState<ExternalPOItem>[];
  importExternalCostCodes?: () => void;
  importingExternalCostCodes?: boolean;
  selectedSellerOrgLocationId?: string | null;
  setSelectedSellerOrgLocationId?: (id: string | null) => void;
  orderTypeId?: string | null;
  setOrderTypeId?: (id: string | null) => void;
};

export const ExternalPOItems: FC<Props> = ({
  close,
  externalPO,
  projectId,
  loading = false,
  readonly = false,
  validationError,

  missingCostCodesCategory,
  missingTagsCategory,
  unsupportedCostTypesCategory,
  includedItemsCategory,

  importingExternalCostCodes,
  importExternalCostCodes,

  selectedSellerOrgLocationId,
  setSelectedSellerOrgLocationId,

  orderTypeId,
  setOrderTypeId,
}) => {
  const intl = useIntl();
  const { moveToPreviousStep } = useNestedStepper();
  const { importProjectTags, importingProjectTags } = useImportProjectTags();
  const { connectedSourceSystem, settings } = useOrgSettings();
  const { formatCostCode } = useCostCodes();
  const { sourceSystemLogo } = useSourceSystemLogo(connectedSourceSystem);

  const wbsTags = useMemo(
    () =>
      !!settings?.integrations.sourceSystems.find(
        (s) => s.system === connectedSourceSystem,
      )?.wbsTags,
    [connectedSourceSystem, settings],
  );

  return (
    <Loader loading={loading}>
      <Container>
        <Header>
          <Row>
            <BackButton
              onClick={() => (readonly ? close() : moveToPreviousStep())}
            >
              <ChevronLeft />
            </BackButton>
            <Title>{externalPO?.number}</Title>
            <If isTrue={!!externalPO}>
              <Col>
                <FormattedMessage id="PROJECT" tagName={Description} />
                <ProjectName>{externalPO?.project?.name}</ProjectName>
              </Col>
            </If>
            <If
              isTrue={
                !readonly &&
                !!setSelectedSellerOrgLocationId &&
                (externalPO?.vendor?.orgPreferredVendors ?? []).length > 0 &&
                !validationError
              }
            >
              <Col className="ml-3">
                <Select
                  options={externalPO?.vendor?.orgPreferredVendors ?? []}
                  getValue={(v) => v.sellerOrgLocation.id}
                  getLabel={(v) => v.sellerOrgLocation.name}
                  label={intl.$t({ id: "VENDOR" })}
                  value={selectedSellerOrgLocationId}
                  onChange={setSelectedSellerOrgLocationId}
                  disabled={
                    (externalPO?.vendor?.orgPreferredVendors ?? []).length === 1
                  }
                />
              </Col>
              <Col className="ml-3">
                <OrderTypePicker
                  orderTypeId={orderTypeId}
                  setOrderTypeId={setOrderTypeId}
                />
              </Col>
            </If>
          </Row>
          <Row className="gap-2">
            <If isTrue={!!validationError}>
              <ValidationError>
                <InfoOutlinedStyled />
                {validationError}
              </ValidationError>
            </If>
            <If isTrue={!readonly}>
              <PrimaryButton $small onClick={() => moveToPreviousStep()}>
                <FormattedMessage id="SELECT_DIFFERENT_PO" />
              </PrimaryButton>
            </If>
            <If isTrue={sourceSystemLogo}>
              <SourceSystemImage
                loading="lazy"
                src={sourceSystemLogo}
                alt="logo"
                width={90}
              />
            </If>
          </Row>
        </Header>
        <If
          isTrue={(externalPO?.itemGroups.aggregatedItems ?? []).length === 0}
        >
          <InnerContainer>
            <If isTrue={!readonly}>
              <If
                isTrue={
                  (externalPO?.itemGroups.missingTag ?? []).length > 0 ||
                  (externalPO?.itemGroups.missingCostCode ?? []).length > 0 ||
                  (externalPO?.itemGroups.unsupportedCostType ?? []).length > 0
                }
              >
                <ExcludedSection>
                  <SectionTitleRow>
                    <FormattedMessage
                      id="ITEMS_EXCLUDED_FROM_IMPORT"
                      tagName={Title}
                    />
                  </SectionTitleRow>
                  <If
                    isTrue={
                      (externalPO?.itemGroups.missingTag ?? []).length > 0
                    }
                  >
                    <GridTable<ExternalPOItem, CategoryLike>
                      configuration={{
                        container: List,
                        columns: useExternalItemsPOTableConfiguration({
                          type: ExportPOItemType.PHASE_CODE,
                          onImportClick:
                            connectedSourceSystem &&
                            (projectId || externalPO?.project?.project?.id)
                              ? () =>
                                  importProjectTags({
                                    sourceSystem: connectedSourceSystem,
                                    projectId:
                                      projectId ||
                                      externalPO?.project?.project?.id ||
                                      "",
                                  })
                              : undefined,
                          importing: importingProjectTags,
                          importLabelId: "IMPORT_PHASE_CODES",
                          sourceSystem: connectedSourceSystem,
                        }),
                        classNames: {
                          header: "bg-gray-100",
                          category: "bg-gray-100 border-none mb-2 mt-1",
                        },
                      }}
                      items={missingTagsCategory}
                      loading={loading}
                    />
                  </If>
                  <If
                    isTrue={
                      (externalPO?.itemGroups.missingCostCode ?? []).length > 0
                    }
                  >
                    <GridTable<ExternalPOItem, CategoryLike>
                      configuration={{
                        container: List,
                        columns: useExternalItemsPOTableConfiguration({
                          type: ExportPOItemType.DEFAULT,
                          onImportClick: importExternalCostCodes,
                          importing: importingExternalCostCodes,
                          importLabelId: "IMPORT_COST_CODES",
                          sourceSystem: connectedSourceSystem,
                        }),
                        classNames: {
                          header: "bg-gray-100",
                          category: "bg-gray-100 border-none mb-2 mt-1",
                        },
                      }}
                      items={missingCostCodesCategory}
                      loading={loading}
                    />
                  </If>
                  <If
                    isTrue={
                      (externalPO?.itemGroups.unsupportedCostType ?? [])
                        .length > 0
                    }
                  >
                    <GridTable<ExternalPOItem, CategoryLike>
                      configuration={{
                        container: UnsupportedCostTypesList,
                        columns: useExternalItemsPOTableConfiguration({
                          type: ExportPOItemType.DEFAULT,
                          sourceSystem: connectedSourceSystem,
                        }),
                        classNames: {
                          header: "bg-gray-100",
                          category: "bg-gray-100 border-none mb-2 mt-1",
                          item: "bg-gray-100",
                        },
                      }}
                      hideHeader={
                        (externalPO?.itemGroups.missingCostCode ?? []).length >
                        0
                      }
                      items={unsupportedCostTypesCategory}
                      loading={loading}
                    />
                  </If>
                </ExcludedSection>
              </If>
              <If isTrue={includedItemsCategory[0].items.length > 0}>
                <InnerContainer className="bg-white">
                  <SectionTitleRow>
                    <FormattedMessage
                      id="ITEMS_INCLUDED_IN_IMPORT"
                      tagName={Title}
                    />
                  </SectionTitleRow>
                  <GridTable<ExternalPOItem, CategoryLike>
                    configuration={{
                      container: List,
                      columns: useExternalItemsPOTableConfiguration({
                        type: wbsTags
                          ? ExportPOItemType.PHASE_CODE
                          : ExportPOItemType.DEFAULT,
                        sourceSystem: connectedSourceSystem,
                        importableItems: true,
                      }),
                      classNames: {
                        category: "hidden",
                      },
                    }}
                    items={includedItemsCategory}
                    loading={loading}
                  />
                  <If isTrue={externalPO?.amount}>
                    <TotalContainer>
                      <TotalHeader>
                        <FormattedMessage id="TOTAL" />:
                      </TotalHeader>
                      <Price price={externalPO?.amount} />
                    </TotalContainer>
                  </If>
                </InnerContainer>
              </If>
            </If>
            <If isTrue={readonly && externalPO}>
              <InnerContainer className="bg-white">
                <GridTable<ExternalPoItemFieldsFragment & Identity>
                  configuration={{
                    container: List,
                    columns: useMergedExternalItemsPOTableConfiguration({
                      type: wbsTags
                        ? ExportPOItemType.PHASE_CODE
                        : ExportPOItemType.DEFAULT,
                      sourceSystem: connectedSourceSystem,
                    }),
                    classNames: {
                      itemFn: (item) =>
                        [
                          ...(externalPO?.itemGroups.importable ?? []),
                          ...(externalPO?.itemGroups.additionalCharges ?? []),
                          ...(externalPO?.itemGroups.salesTax ?? []),
                        ].some((i) => i.externalId === item.externalId)
                          ? { className: "" }
                          : { className: "bg-gray-100" },
                    },
                  }}
                  items={[
                    ...(externalPO?.itemGroups.importable ?? []),
                    ...(externalPO?.itemGroups.additionalCharges ?? []),
                    ...(externalPO?.itemGroups.salesTax ?? []),
                    ...(externalPO?.itemGroups.missingTag ?? []),
                    ...(externalPO?.itemGroups.missingCostCode ?? []),
                    ...(externalPO?.itemGroups.unsupportedCostType ?? []),
                  ].map((i) => ({ ...i, id: i.externalId }))}
                  loading={loading}
                />
                <If isTrue={externalPO?.amount}>
                  <TotalContainer>
                    <TotalHeader>
                      <FormattedMessage id="TOTAL" />:
                    </TotalHeader>
                    <Price price={externalPO?.amount} />
                  </TotalContainer>
                </If>
              </InnerContainer>
            </If>

            <If isTrue={readonly && !externalPO}>
              <ListEmptyState
                messageKey="PO_NOT_FOUND_IN_SOURCE_SYSTEM"
                values={{
                  sourceSystem: connectedSourceSystem
                    ? intl.$t({ id: `INTEGRATION_${connectedSourceSystem}` })
                    : "",
                }}
              />
            </If>
            <If isTrue={externalPO?.description}>
              <ExpandablePanel header={intl.$t({ id: "DESCRIPTION" })}>
                {externalPO?.description}
              </ExpandablePanel>
            </If>
          </InnerContainer>
        </If>
        <If isTrue={(externalPO?.itemGroups.aggregatedItems ?? []).length > 0}>
          <AggregatedItems>
            {(externalPO?.itemGroups.aggregatedItems ?? []).map(
              (aggregatedItem) => (
                <AggregatedItem key={aggregatedItem.externalId}>
                  <If isTrue={!wbsTags}>
                    <Col>
                      <FormattedMessage id="COST_CODE" tagName={Description} />
                      <Name>
                        {formatCostCode(
                          aggregatedItem.externalCostCode?.costCode?.id ??
                            aggregatedItem.externalCostCode?.name,
                        )}
                      </Name>
                    </Col>
                  </If>
                  <If isTrue={wbsTags}>
                    <Row>
                      <FormattedMessage id="PHASE_CODE" tagName={Description} />
                      <TagItem
                        tag={
                          aggregatedItem.wbsTag ?? {
                            name: `${formatCostCode(
                              aggregatedItem.externalCostCode?.costCode?.id ??
                                aggregatedItem.externalCostCode?.name,
                            )} - ${aggregatedItem.costType?.code ?? ""}`,
                            id: aggregatedItem.externalId,
                            color: "",
                            mapping: connectedSourceSystem
                              ? { sourceSystem: connectedSourceSystem }
                              : undefined,
                            hasMapping: !!connectedSourceSystem,
                          }
                        }
                      />
                    </Row>
                  </If>
                  <Col>
                    <FormattedMessage id="COST_TYPE" tagName={Description} />
                    <Name>
                      {aggregatedItem.costType
                        ? `${aggregatedItem.costType.name}${aggregatedItem.costType.code ? ` (${aggregatedItem.costType.code})` : ""}`
                        : intl.$t({ id: "N_A" })}
                    </Name>
                  </Col>
                  <Price
                    price={new Decimal(aggregatedItem.amount)}
                    className="text-right"
                  />
                </AggregatedItem>
              ),
            )}
            <TotalRow>
              <FormattedMessage id="TOTAL" />
              <Price
                price={(externalPO?.itemGroups.aggregatedItems ?? []).reduce(
                  (acc, next) => acc.plus(new Decimal(next.amount)),
                  new Decimal(0),
                )}
                className="ml-3"
              />
            </TotalRow>
          </AggregatedItems>
        </If>
      </Container>
    </Loader>
  );
};
