import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { PageBackButton } from "@/common/components/page-back-button/PageBackButton";
import { FC, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useArchiveDeliverySlip } from "../../../../hooks/useArchiveDeliverySlip";
import { useDeliverySlipRelease } from "../../../../providers/DeliverySlipReleaseProvider";
import { useDeliverySlipSequence } from "../../../../providers/DeliverySlipSequenceProvider";
import { useDeliverySlipStats } from "../../../../providers/DeliverySlipStatsProvider";
import {
  PackingSlipMatchViewState,
  useDeliverySlipVerification,
} from "../../../../providers/DeliverySlipVerificationProvider";
import { DeliverySlipsFooterStepper } from "../../components/delivery-slip-footer-stepper/DeliverySlipsFooterStepper";
import { DeliverySlipReleaseFormValues } from "../../components/delivery-slip-form/DeliverySlipVerificationForm";

const ButtonContainer = tw.div`grid grid-flow-col gap-2 items-center justify-end`;
const FloatingFooterStyled = tw(FloatingFooter)`md:px-6`;

export const DeliverySlipMatchFooter: FC = () => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { refetch } = useDeliverySlipStats();
  const { syncCreateReleaseFromDeliverySlip } = useDeliverySlipRelease();
  const { archiveDeliverySlip } = useArchiveDeliverySlip();
  const {
    deliverySlip,
    updateDeliverySlip,
    updating,
    packingSlipMatchViewState,
    selectedRelease,
  } = useDeliverySlipVerification();
  const { handleSubmit } = useFormContext<DeliverySlipReleaseFormValues>();
  const { navigateToNextSequenceOrRedirect, setNewlyMatchedDeliverySlipIds } =
    useDeliverySlipSequence();

  const handleCreateReleaseFromDeliverySlip = useCallback(async () => {
    await handleSubmit(async (values: DeliverySlipReleaseFormValues) => {
      const sync = await syncCreateReleaseFromDeliverySlip(values);
      if (!sync) {
        return null;
      }
      await refetch();
      navigateToNextSequenceOrRedirect(deliverySlip?.id, {
        orderId: sync as string,
        navigateToOrder: true,
      });
    })();
  }, [
    handleSubmit,
    syncCreateReleaseFromDeliverySlip,
    refetch,
    navigateToNextSequenceOrRedirect,
    deliverySlip?.id,
  ]);

  const assignOrder = useCallback(async () => {
    await updateDeliverySlip({
      id: deliverySlip?.id ?? "",
      releaseId: selectedRelease?.id ?? "",
    });

    setNewlyMatchedDeliverySlipIds([deliverySlip?.id ?? ""]);
    navigateToNextSequenceOrRedirect(deliverySlip?.id, {
      navigateToReceiveOrder: true,
    });
  }, [
    deliverySlip?.id,
    navigateToNextSequenceOrRedirect,
    selectedRelease?.id,
    setNewlyMatchedDeliverySlipIds,
    updateDeliverySlip,
  ]);

  const archiveSlip = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "PROCEED" }),
      icon: <WarningIcon />,
      title: intl.$t({ id: "ARCHIVE_DELIVERY_SLIP_DETAILS" }),
      text: intl.$t({ id: "ARCHIVE_DELIVERY_SLIP" }),
      handleConfirm: async () => {
        await archiveDeliverySlip(deliverySlip?.id ?? "");
        await refetch();
        navigateToNextSequenceOrRedirect(deliverySlip?.id);
      },
    });
  }, [
    archiveDeliverySlip,
    deliverySlip?.id,
    intl,
    navigateToNextSequenceOrRedirect,
    openDialog,
    refetch,
  ]);

  return (
    <FloatingFooterStyled>
      <ButtonContainer>
        <PageBackButton />
        <DeliverySlipsFooterStepper
          steps={[
            {
              name: "MATCH_ORDER_TO_SLIP",
              active: true,
            },
            {
              name: "RECEIVE_ORDER",
            },
          ]}
        />
        <OutlinedButton onClick={archiveSlip}>
          <FormattedMessage id="ARCHIVE" />
        </OutlinedButton>
        <If
          isTrue={
            packingSlipMatchViewState === PackingSlipMatchViewState.CREATE_ORDER
          }
        >
          <PrimaryButton
            className="py-0"
            testId="save-and-receive-order"
            onClick={handleCreateReleaseFromDeliverySlip}
          >
            <FormattedMessage id="SAVE_AND_RECEIVE_ORDER" />
          </PrimaryButton>
        </If>
        <If
          isTrue={
            packingSlipMatchViewState === PackingSlipMatchViewState.MATCH_ORDER
          }
        >
          <PrimaryButton
            className="py-0"
            onClick={assignOrder}
            disabled={!selectedRelease}
            loading={updating}
          >
            <FormattedMessage id="CONFIRM" />
          </PrimaryButton>
        </If>
      </ButtonContainer>
    </FloatingFooterStyled>
  );
};
