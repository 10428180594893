import { Address } from "@/common/components/address/Address";
import { InfoHeader } from "@/common/components/info-header/InfoHeader";
import { ListDetailsHeaderItem } from "@/common/components/info-header/components/ListDetailsHeaderItem";
import { OrgDetailsHeaderItem } from "@/common/components/info-header/components/OrgDetailsHeaderItem";
import { VendorList } from "@/common/components/vendor-list/VendorList";
import { DateView } from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import { DistributorBuyoutFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

type Props = {
  buyout: DistributorBuyoutFieldsFragment;
};

export const BuyoutInfoHeader: FC<Props> = ({ buyout }) => {
  return (
    <InfoHeader>
      <OrgDetailsHeaderItem
        title={<FormattedMessage id="CONTRACTOR" />}
        name={getUserName(buyout.quote?.rfq.createdBy)}
        orgName={buyout.project.location.org.name}
        logoImageUrl={buyout.project.location.org.photoUrl}
        address={buyout.project.location.address}
      />
      <ListDetailsHeaderItem
        title={buyout.project.name}
        list={[
          {
            label: <FormattedMessage id="LOCATION_TITLE" />,
            value: <Address address={buyout.project.address} />,
          },
          {
            label: <FormattedMessage id="START_DATE_TITLE" />,
            value: <DateView date={buyout.project.startDate} />,
          },
        ]}
      />
      <OrgDetailsHeaderItem
        title={<FormattedMessage id="VENDOR" />}
        name={
          <VendorList
            contacts={buyout.preferredVendor?.contacts.filter(
              (c) => c.receivesBuyoutNotifications,
            )}
          />
        }
        orgName={buyout.sellerOrgLocation.org.name}
        logoImageUrl={buyout.sellerOrgLocation.org.photoUrl}
        address={buyout.sellerOrgLocation.address}
      />
    </InfoHeader>
  );
};
