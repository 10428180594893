import { add, startOfDay } from "date-fns";

// function converts date to 8am EST (8am EST -> 13am UTC)
export const getEst8amDate = (date: Date | null | undefined) => {
  if (!date) {
    return undefined;
  }

  return add(startOfDay(date), {
    hours: 13,
    minutes: -date.getTimezoneOffset(),
  });
};
