import { FC } from "react";
import { useIntl } from "react-intl";
import { StyledNumericalInput } from "../../../styles/SalesTaxInput.styles";

type Props = {
  value: number;
  setValue: (value: number) => void;
  onBlur: () => void;
};

export const SalesTaxAmountInput: FC<Props> = ({ value, setValue, onBlur }) => {
  const intl = useIntl();

  return (
    <StyledNumericalInput
      testId="tax"
      value={value}
      onChange={(e) => setValue(Number(e.target.value))}
      onBlur={onBlur}
      decimals={2}
      label={intl.$t({ id: "TAX" })}
      includeCurrency
      className="w-full"
      xs
      inputProps={{
        className: "text-right bg-white",
      }}
    />
  );
};
