import {
  PaginationArgs,
  usePagination,
} from "@/common/components/pagination/PaginationProvider";
import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useFiltersQueryParams } from "@/common/hooks/useFiltersQueryParams";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useLocalStorage } from "@/common/hooks/useLocalStorage";
import { useInvoiceCreation } from "@/contractor/pages/home/invoices/pages/scanned-invoices/providers/InvoiceCreationProvider";
import {
  CreateInvoicesInput,
  InvoiceFieldsFragment,
  InvoiceSummaryFieldsFragment,
  QueryInvoicesFilter,
  UpdateInvoicesInput,
  useArchiveInvoicesMutation,
  useInvoicesQuery,
  useUpdateInvoicesMutation,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionArrayPromise,
  NoFunctionBooleanPromise,
} from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useArchiveInvoice } from "../hooks/useArchiveInvoice";

type ProviderContextType = {
  invoices: InvoiceSummaryFieldsFragment[];
  loading: boolean;
  error: boolean;
  totalCount: number;
  filter?: QueryInvoicesFilter;
  setFilter: (filter: QueryInvoicesFilter) => void;
  paginationArgs: PaginationArgs;
  page: number;
  isFiltered: boolean;
  createInvoices: (
    input: CreateInvoicesInput,
  ) => Promise<InvoiceFieldsFragment[] | undefined>;
  archiveInvoice: (invoiceId: string) => Promise<boolean>;
  archiveInvoices: (invoiceIds: string[]) => Promise<boolean>;
  updateInvoices: (input: UpdateInvoicesInput) => Promise<boolean>;
  exportEnabled: boolean;
  setExportEnabled: (enabled: boolean) => void;
  creating: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  invoices: [],
  totalCount: 0,
  loading: false,
  error: false,
  filter: undefined,
  setFilter: NoFunction,
  paginationArgs: {},
  page: 0,
  isFiltered: false,
  createInvoices: NoFunctionArrayPromise,
  archiveInvoice: NoFunctionBooleanPromise,
  archiveInvoices: NoFunctionBooleanPromise,
  updateInvoices: NoFunctionBooleanPromise,
  exportEnabled: false,
  setExportEnabled: NoFunction,
  creating: false,
});

export const InvoicesProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { getFiltersQueryParam, setFiltersQueryParams } =
    useFiltersQueryParams();
  const { paginationArgs, page, setPageInfo, previousPage } = usePagination();
  const { readValue, setValue } = useLocalStorage();
  const { setError } = useGlobalError();

  const [filter, setFilter] = useState<QueryInvoicesFilter | undefined>(
    getFiltersQueryParam(),
  );
  const [exportEnabled, setExportEnabled] = useState<boolean>(false);
  const { createInvoices: createInvoicesMutation, creating } =
    useInvoiceCreation();

  const setFilterAndUpdateQueryString = useCallback(
    (updatedFilter: QueryInvoicesFilter) => {
      setFiltersQueryParams(updatedFilter);
      setFilter(updatedFilter);
      setValue(LOCAL_STORAGE_KEYS.INVOICES_LIST_FILTER, updatedFilter);
    },
    [setFiltersQueryParams, setValue],
  );

  useEffect(() => {
    const localStorageSettings = readValue<QueryInvoicesFilter>(
      LOCAL_STORAGE_KEYS.INVOICES_LIST_FILTER,
    ) as QueryInvoicesFilter;
    setFilter({
      statuses: localStorageSettings?.statuses,
      kickbackToMe: localStorageSettings?.kickbackToMe,
      archived: localStorageSettings?.archived,
      exported: localStorageSettings?.exported,
      poExported: localStorageSettings?.poExported,
      receiptPosted: localStorageSettings?.receiptPosted,
      closedProjects: localStorageSettings?.closedProjects,
      ...getFiltersQueryParam(),
    });
  }, [getFiltersQueryParam, readValue]);

  useEffect(() => {
    if (page !== 0 && data?.invoices.edges.length === 0) {
      previousPage();
    }
  });

  const { data, loading, error, refetch } = useInvoicesQuery({
    variables: {
      filter,
      ...paginationArgs,
    },
    skip: !filter,
    fetchPolicy: "cache-and-network",
  });

  const { archiveInvoice: archiveInvoiceFn } = useArchiveInvoice({
    filter,
    page,
    paginationArgs,
    previousPage,
  });

  useEffect(() => {
    if (data?.invoices) {
      setPageInfo(data.invoices.pageInfo);
    }
  }, [data, setPageInfo]);

  const createInvoices = async (input: CreateInvoicesInput) => {
    const createdInvoices = await createInvoicesMutation(input);
    await refetch();
    return createdInvoices;
  };

  const [updateInvoicesMutation] = useUpdateInvoicesMutation();
  const updateInvoices = useCallback(
    async (input: UpdateInvoicesInput): Promise<boolean> => {
      try {
        const { data, errors } = await updateInvoicesMutation({
          variables: {
            input,
          },
        });
        setError(errors);
        await refetch();
        return Boolean(!!data?.updateInvoices && !errors);
      } catch (errors) {
        setError(errors);
        return false;
      }
    },
    [setError, updateInvoicesMutation, refetch],
  );

  const archiveInvoice = async (id: string) => {
    return await archiveInvoiceFn(id);
  };

  const [archiveInvoicesMutation] = useArchiveInvoicesMutation();
  const archiveInvoices = async (ids: string[]) => {
    const { data, errors } = await archiveInvoicesMutation({
      variables: {
        ids,
      },
    });
    setError(errors);
    await refetch();
    return Boolean(!!data?.archiveInvoices && !errors);
  };

  useErrorEffect(error);

  return (
    <ProviderContext.Provider
      value={{
        invoices: data?.invoices?.edges?.map((edge) => edge.node) || [],
        loading,
        error: !!error,
        totalCount: data?.invoices?.totalCount || 0,
        filter,
        setFilter: setFilterAndUpdateQueryString,
        paginationArgs,
        page,
        isFiltered: !!filter?.statuses?.length || !!filter?.projectIds?.length,
        createInvoices,
        archiveInvoice,
        archiveInvoices,
        updateInvoices,
        exportEnabled,
        setExportEnabled,
        creating,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useInvoices = (): ProviderContextType =>
  useContext(ProviderContext);
