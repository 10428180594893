import DateRangePicker from "@/common/components/date-range-picker/DateRangePicker";
import { ListDatesButton } from "@/common/components/list-dates-button/ListDatesButton";
import { ListHeaderFilterButton } from "@/common/components/list-header-filter-button/ListHeaderFilterButton";
import { PopoverWithTabs } from "@/common/components/popover-with-tabs/PopoverWithTabs";
import { ProjectsFilterSelector } from "@/common/components/projects-filter-selector/ProjectsFilterSelector";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { SearchTooltip } from "@/common/components/search-input/SearchTooltip";
import { StatusesFilter } from "@/common/components/status-filter/StatusesFilter";
import { InvoiceHeader } from "@/contractor/pages/home/invoices/common/components/InvoiceHeader";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import { InvoiceStatus } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoices } from "../../providers/InvoicesProvider";
import {
  InvoiceFolderPicker,
  UNASSIGNED_INVOICE_FOLDER_ID,
} from "../invoice-folder-picker/InvoiceFolderPicker";
import { InvoiceListActions } from "../invoice-list-actions/InvoiceListActions";
import { InvoicesAdditionalFilters } from "./InvoicesAdditionalFilters";

enum ScannedInvoicesDateFilters {
  IssueDate = "issueDate",
  DueDate = "dueDate",
  CreateDate = "createDate",
}

const Filters = tw.div`grid grid-flow-col gap-2 items-center`;

export const ScannedInvoicesHeader = () => {
  const intl = useIntl();
  const { filter, setFilter } = useInvoices();
  const { projects } = useProjectListOptions({
    includeClosedProjects: filter?.closedProjects,
  });

  const options = useMemo(() => {
    return [
      {
        value: InvoiceStatus.Approved,
        name: intl.$t({ id: "INVOICE_STATUS_APPROVED" }),
      },
      {
        value: InvoiceStatus.AwaitingApproval,
        name: intl.$t({ id: "INVOICE_STATUS_AWAITING_APPROVAL" }),
      },
      {
        value: InvoiceStatus.InternalKickback,
        name: intl.$t({ id: "INVOICE_STATUS_INTERNAL_KICKBACK" }),
      },
      {
        value: InvoiceStatus.IssuesReported,
        name: intl.$t({ id: "INVOICE_STATUS_ISSUES_REPORTED" }),
      },
      {
        value: InvoiceStatus.Paid,
        name: intl.$t({ id: "INVOICE_STATUS_PAID" }),
      },
    ];
  }, [intl]);

  const setFilterHandler = useCallback(
    (values: InvoiceStatus[] | undefined) => {
      setFilter({
        ...filter,
        statuses: values,
      });
    },
    [filter, setFilter],
  );

  const setFolderFilterHandler = useCallback(
    (folderIds: string[] | undefined) => {
      if (!folderIds) {
        setFilter({
          ...filter,
          folderIds: undefined,
        });
      } else if (folderIds[0] === UNASSIGNED_INVOICE_FOLDER_ID) {
        setFilter({
          ...filter,
          folderIds: [],
        });
      } else {
        setFilter({
          ...filter,
          folderIds,
        });
      }
    },
    [filter, setFilter],
  );

  const appliedFilters = useMemo(() => {
    return (
      Number(filter?.kickbackToMe != null) +
      Number(filter?.poExported != null) +
      Number(filter?.receiptPosted != null) +
      Number(filter?.archived !== false) +
      Number(filter?.exported !== false) +
      Number(filter?.sellerOrgLocationIds != null) +
      Number(filter?.downloaded != null) +
      Number(filter?.closedProjects != null) +
      Number(filter?.releaseTypeIds != null)
    );
  }, [
    filter?.archived,
    filter?.poExported,
    filter?.receiptPosted,
    filter?.exported,
    filter?.kickbackToMe,
    filter?.sellerOrgLocationIds,
    filter?.downloaded,
    filter?.closedProjects,
    filter?.releaseTypeIds,
  ]);

  const appliedDateFilters = useMemo(() => {
    return (
      Number(filter?.minIssueDate != null || filter?.maxIssueDate != null) +
      Number(filter?.minDueDate != null || filter?.maxDueDate != null) +
      Number(filter?.minCreatedAt != null || filter?.maxCreatedAt != null)
    );
  }, [
    filter?.minIssueDate,
    filter?.maxIssueDate,
    filter?.minCreatedAt,
    filter?.maxCreatedAt,
    filter?.minDueDate,
    filter?.maxDueDate,
  ]);

  return (
    <InvoiceHeader>
      <Filters>
        <SearchInput
          placeHolder={intl.$t({ id: "SEARCH" })}
          onChange={(value: string) => {
            if (filter?.search ?? value !== "") {
              setFilter({ ...filter, search: value });
            }
          }}
          value={filter?.search}
          info={<SearchTooltip text="INVOICES_SEARCH_INFO" />}
          applyHeaderAccent
        />
        <ProjectsFilterSelector
          projects={projects}
          onChange={(projectId) =>
            setFilter({
              ...filter,
              projectIds: projectId ? [projectId] : undefined,
            })
          }
          value={filter?.projectIds?.[0]}
          withCustomView
        />
        <StatusesFilter
          options={options}
          selectedStatuses={filter?.statuses}
          onChange={setFilterHandler}
          applyHeaderAccent
        />
        <InvoiceFolderPicker
          value={
            filter?.folderIds
              ? filter?.folderIds.length
                ? filter?.folderIds
                : [UNASSIGNED_INVOICE_FOLDER_ID]
              : undefined
          }
          onChange={setFolderFilterHandler}
          withCustomView
        />
        <ListHeaderFilterButton
          isDefault={appliedFilters === 0}
          filter={<InvoicesAdditionalFilters />}
          appliedFilters={appliedFilters}
        />
        <ListDatesButton
          isDefault={appliedDateFilters === 0}
          appliedFilters={appliedDateFilters}
          hideDates
          dates={[
            {
              startDate: filter?.minIssueDate,
              endDate: filter?.maxIssueDate,
            },
          ]}
          clearValues={() =>
            setFilter({
              ...filter,
              minIssueDate: undefined,
              maxIssueDate: undefined,
            })
          }
        >
          {(onClose) => (
            <PopoverWithTabs
              tabs={[
                {
                  label: intl.$t({ id: "ISSUE_DATE" }),
                  content: (
                    <DateRangePicker
                      staticView
                      onClose={onClose}
                      selectedValue={
                        filter?.maxIssueDate || filter?.minIssueDate
                          ? {
                              startDate: filter?.minIssueDate
                                ? new Date(filter.minIssueDate)
                                : new Date(),
                              endDate: filter?.maxIssueDate
                                ? new Date(filter.maxIssueDate)
                                : new Date(),
                            }
                          : undefined
                      }
                      isUTC
                      onChange={(dateRange) =>
                        setFilter({
                          ...filter,
                          minIssueDate: dateRange.startDate?.getTime(),
                          maxIssueDate: dateRange.endDate?.getTime(),
                        })
                      }
                    />
                  ),
                  viewState: ScannedInvoicesDateFilters.IssueDate,
                },
                {
                  label: intl.$t({ id: "DUE_DATE" }),
                  content: (
                    <DateRangePicker
                      staticView
                      onClose={onClose}
                      selectedValue={
                        filter?.maxDueDate || filter?.minDueDate
                          ? {
                              startDate: filter?.minDueDate
                                ? new Date(filter.minDueDate)
                                : new Date(),
                              endDate: filter?.maxDueDate
                                ? new Date(filter.maxDueDate)
                                : new Date(),
                            }
                          : undefined
                      }
                      isUTC
                      onChange={(dateRange) =>
                        setFilter({
                          ...filter,
                          minDueDate: dateRange.startDate?.getTime(),
                          maxDueDate: dateRange.endDate?.getTime(),
                        })
                      }
                    />
                  ),
                  viewState: ScannedInvoicesDateFilters.DueDate,
                },
                {
                  label: intl.$t({ id: "RECEIVED_DATE" }),
                  content: (
                    <DateRangePicker
                      staticView
                      onClose={onClose}
                      selectedValue={
                        filter?.maxCreatedAt || filter?.minCreatedAt
                          ? {
                              startDate: filter?.minCreatedAt
                                ? new Date(filter.minCreatedAt)
                                : new Date(),
                              endDate: filter?.maxCreatedAt
                                ? new Date(filter.maxCreatedAt)
                                : new Date(),
                            }
                          : undefined
                      }
                      isUTC
                      onChange={(dateRange) =>
                        setFilter({
                          ...filter,
                          minCreatedAt: dateRange.startDate?.getTime(),
                          maxCreatedAt: dateRange.endDate?.getTime(),
                        })
                      }
                    />
                  ),
                  viewState: ScannedInvoicesDateFilters.CreateDate,
                },
              ]}
              activeTab={ScannedInvoicesDateFilters.IssueDate}
            />
          )}
        </ListDatesButton>
      </Filters>
      <InvoiceListActions />
    </InvoiceHeader>
  );
};
