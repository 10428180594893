import { If } from "@/common/components/if/If";
import {
  InfoHeader,
  InfoHeaderType,
} from "@/common/components/info-header/InfoHeader";
import { FulfillmentHeaderItem } from "@/common/components/info-header/components/FulfillmentHeaderItem";
import { InfoHeaderItem } from "@/common/components/info-header/components/InfoHeaderItem";
import { OrgDetailsHeaderItem } from "@/common/components/info-header/components/OrgDetailsHeaderItem";
import { UserInfoHeaderItem } from "@/common/components/info-header/components/UserInfoHeaderItem";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { PhoneView } from "@/common/components/phone-input/PhoneView";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { VendorList } from "@/common/components/vendor-list/VendorList";
import { useUser } from "@/common/providers/UserProvider";
import { DateView } from "@/common/utils/dates/DateView";
import { routes } from "@/config/routes";
import { ReleasePONumber } from "@/contractor/pages/home/buyout-releases/components/releases-list/release-view/ReleasePONumber";
import { FC, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useOrderTypesConfig } from "../../../../../../common/hooks/order-types-config/useOrderTypesConfig";
import { ExternalPOModal } from "../../../common/external-po/ExternalPOModal";
import { useRelease } from "../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";
import { ReleasePageType } from "../../types/ReleasePageType";
import { DeliveryHeaderInformation } from "./DeliveryHeaderInformation";
import { ReleaseDetailsHeaderFooter } from "./ReleaseDetailsHeaderFooter";
import { ReleaseDetailsHeaderTitle } from "./ReleaseDetailsHeaderTitle";

type Props = {
  headerType?: ReleasePageType;
};

const Group = tw.div`
  grid gap-4
`;

const DeliverySiteContainer = tw.div`flex flex-col`;
const SiteContactContainer = tw.div`flex gap-5 text-xs pt-1`;
const SiteContactHeader = tw.div`font-light`;
const SiteContactName = tw.div``;
const SiteContactPhone = tw.div`font-light`;
const DeliveryInfoContainer = tw.div`flex items-center h-full`;
const DeliveryInfo = tw.div`flex flex-col`;
const SiteContact = tw.div`flex gap-1`;

export const ReleaseDetailsHeader: FC<Props> = ({
  headerType = ReleasePageType.Default,
}) => {
  const intl = useIntl();
  const { release } = useRelease();
  const { updateRelease } = useReleaseUpdate();
  const { orderTypeConfig } = useOrderTypesConfig({ release });
  const [externalPOModalOpened, setExternalPOModalOpened] = useState(false);

  const { viewer } = useUser();

  if (!release) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "RELEASE" })}</title>
      </Helmet>
      <InfoHeader
        headerType={
          headerType === ReleasePageType.Default
            ? InfoHeaderType.Default
            : InfoHeaderType.Grayed
        }
        header={
          <If isTrue={headerType === ReleasePageType.Confirm}>
            <ReleaseDetailsHeaderTitle />
          </If>
        }
        footer={
          <If isTrue={headerType === ReleasePageType.Confirm}>
            <ReleaseDetailsHeaderFooter />
          </If>
        }
      >
        <OrgDetailsHeaderItem
          title={<FormattedMessage id="VENDOR" />}
          name={<VendorList contacts={release.vendorContacts} />}
          orgName={release.sellerOrgLocation?.org.name}
          logoImageUrl={release.sellerOrgLocation?.org.photoUrl}
          address={release.sellerOrgLocation?.address}
        />
        <DeliverySiteContainer>
          <OrgDetailsHeaderItem
            title={<FormattedMessage id="DELIVERY_SITE" />}
            name={
              release.project
                ? `${release.project.name}${release.project.jobNumber ? ` (${release.project.jobNumber})` : ""}`
                : undefined
            }
            logoImageUrl={viewer?.org.photoUrl}
            address={release.project?.address}
            warehouse={release.warehouse}
            orgName={viewer?.org.name}
            navTo={generatePath(routes.projectEstimatedItems, {
              id: release.project?.id ?? "",
            })}
          />
          <If isTrue={release.siteContact}>
            <SiteContactContainer>
              <SiteContactHeader>
                <FormattedMessage id="PROJECT_SITE_CONTACT" />:
              </SiteContactHeader>
              <SiteContact>
                <SiteContactName>{release.siteContact?.name}</SiteContactName>
                <If isTrue={release.siteContact?.phone}>
                  <SiteContactPhone>
                    <PhoneView value={release.siteContact?.phone ?? ""} />
                  </SiteContactPhone>
                </If>
              </SiteContact>
            </SiteContactContainer>
          </If>
        </DeliverySiteContainer>
        <InfoHeaderItem title={<FormattedMessage id="ORDER_TYPE" />}>
          {release.type?.name}
        </InfoHeaderItem>
        <If isTrue={headerType !== ReleasePageType.Confirm}>
          <InfoHeaderItem>
            <Group>
              {release.submittedBy && (
                <UserInfoHeaderItem
                  title={<FormattedMessage id="REQUESTED_BY" />}
                  users={[release.submittedBy]}
                  displayDateFn={() => release.submittedAt}
                />
              )}
              {release.approvals.length > 0 && (
                <UserInfoHeaderItem
                  title={<FormattedMessage id="APPROVED_BY" />}
                  users={release.approvals.map((a) => a.approver)}
                  approvals={release.approvals}
                  displayDateFn={(user) =>
                    release.approvals?.find((a) => a.approver.id === user.id)
                      ?.createdAt
                  }
                />
              )}
              {!release.approvals.length && release.rejectedBy && (
                <UserInfoHeaderItem
                  title={<FormattedMessage id="REJECTED_BY" />}
                  users={[release.rejectedBy]}
                  displayDateFn={() => release.rejectedAt}
                />
              )}
            </Group>
          </InfoHeaderItem>
        </If>
        <If isTrue={headerType !== ReleasePageType.Confirm}>
          <Group className="gap-0">
            <PoNumberingSettingsCheck alternativeCondition={!!release.poNumber}>
              <InfoHeaderItem
                title={<FormattedMessage id="PO_HASH" />}
                containerClassName="pb-3"
              >
                {release.poLink?.syncedAt ? (
                  <LinkLike
                    className="text-sm"
                    onClick={() => setExternalPOModalOpened(true)}
                  >
                    {release.poNumber}
                  </LinkLike>
                ) : (
                  <ReleasePONumber
                    hideLabel
                    readonly
                    readonlyClassName="-mt-1.5 -ml-1"
                    release={release}
                    update={(release, poNumber) =>
                      release &&
                      updateRelease(
                        {
                          releaseId: release?.id,
                          version: release?.version,
                          poNumber,
                          skipConfirmation: true,
                        },
                        { batch: false },
                      )
                    }
                    sourceSystem={release.poLink?.sourceSystem}
                  />
                )}
              </InfoHeaderItem>
            </PoNumberingSettingsCheck>
            {release.poLink?.createdBy && (
              <UserInfoHeaderItem
                title={<FormattedMessage id="EXPORTED_BY" />}
                users={[release.poLink?.createdBy]}
                displayDateFn={() => release.poLink?.createdAt}
              />
            )}
          </Group>
        </If>
        <If isTrue={headerType !== ReleasePageType.Confirm}>
          <DeliveryInfoContainer>
            <DeliveryInfo>
              <DeliveryHeaderInformation release={release} />
              <FulfillmentHeaderItem
                isContractor
                includeServices={release.includeServices}
                release={release}
                hideHeader
              />
            </DeliveryInfo>
          </DeliveryInfoContainer>
        </If>
        <If isTrue={headerType === ReleasePageType.Confirm}>
          <InfoHeaderItem
            title={orderTypeConfig.labels.datePickerReadonlyLabel}
          >
            <DateView date={release.time} includeTime />
          </InfoHeaderItem>
        </If>
      </InfoHeader>
      <ExternalPOModal
        opened={externalPOModalOpened}
        goBack={() => setExternalPOModalOpened(false)}
        poNumber={release.poNumber}
        projectId={release.project?.id}
      />
    </>
  );
};
