import { ExternalPOItems } from "@/contractor/pages/home/common/external-po/ExternalPOItems";
import { FC } from "react";
import { useInvoiceImportExternalPO } from "../../../../../providers/InvoiceImportExternalPoProvider";
import { useInvoiceExternalPoItemsValidation } from "./hooks/useInvoiceExternalPoItemsValidation";

export const InvoiceExternalPOItems: FC = () => {
  const {
    closeModal,
    externalPo,
    loadingExternalPo,
    importExternalCostCodes,
    importingExternalCostCodes,
    externalPOsQueryForm,
    includedItemsCategory,
    missingTagsCategory,
    unsupportedCostTypesCategory,
    missingCostCodesCategory,
    selectedSellerOrgLocationId,
    setSelectedSellerOrgLocationId,
    orderTypeId,
    setOrderTypeId,
    hasMissingPoLink,
  } = useInvoiceImportExternalPO();

  const projectId = externalPOsQueryForm?.watch("projectId");
  const { poAlreadyImported, validationError } =
    useInvoiceExternalPoItemsValidation();

  return (
    <ExternalPOItems
      close={closeModal}
      projectId={projectId}
      externalPO={externalPo}
      loading={loadingExternalPo}
      missingCostCodesCategory={missingCostCodesCategory}
      missingTagsCategory={missingTagsCategory}
      unsupportedCostTypesCategory={unsupportedCostTypesCategory}
      includedItemsCategory={includedItemsCategory}
      readonly={poAlreadyImported || !!hasMissingPoLink}
      validationError={validationError}
      importExternalCostCodes={importExternalCostCodes}
      importingExternalCostCodes={importingExternalCostCodes}
      selectedSellerOrgLocationId={selectedSellerOrgLocationId}
      setSelectedSellerOrgLocationId={setSelectedSellerOrgLocationId}
      orderTypeId={orderTypeId}
      setOrderTypeId={setOrderTypeId}
    />
  );
};
