import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import { OrgPreferredVendorsFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { Item } from "../Vendors.styles";

type Props = {
  vendor: OrgPreferredVendorsFieldsFragment;
};

const VendorNameContainer = tw.div`font-medium ml-2`;

export const PreferredVendor: FC<Props> = ({ vendor }) => {
  return (
    <Item>
      <OrgLogo
        logoImageUrl={vendor.sellerOrgLocation.org.photoUrl}
        name={vendor.sellerOrgLocation.org.name}
        width={40}
      />
      <VendorNameContainer>
        {vendor.sellerOrgLocation.org.name}
      </VendorNameContainer>
    </Item>
  );
};
