import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useEquipmentItemsQuery } from "@/generated/graphql";

export const useEquipmentItems = () => {
  const { data, error, loading } = useEquipmentItemsQuery();
  useErrorEffect(error);

  return {
    equipmentItems: data?.viewer?.org?.equipmentItems || [],
    loading,
  };
};
