import { GridTable } from "@/common/components/grid-table/GridTable";
import { AdminHeaderContainer } from "@/common/components/header-container/HeaderContainer";
import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import {
  OrgPreferredVendorsFieldsFragment,
  OrgType,
} from "@/generated/graphql";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import tw from "tailwind-styled-components";
import { LocationsProvider } from "../locations/providers/LocationsProvider";
import { PageContainer } from "./Vendors.styles";
import {
  EditVendorPanelId,
  ToggleEditVendor,
} from "./components/ToggleEditVendor";
import { useVendorsTableConfiguration } from "./components/Vendors.configuration";
import { NewVendorButton } from "./components/vendor-form/NewVendorButton";
import { ExternalVendorMappingModal } from "./components/vendor-mapping/ExternalVendorMappingModal";
import { useVendors } from "./hooks/useVendors";
import { AgaveExternalVendorProvider } from "./providers/AgaveExternalVendorProvider";
import { ExternalVendorMappingProvider } from "./providers/ExternalVendorMappingProvider";
import { OrgsProvider } from "./providers/OrgsProvider";

const HeaderGroup = tw.div`
  grid grid-flow-col justify-between w-full
`;

export type GroupedVendor = {
  id: string;
  org: OrgPreferredVendorsFieldsFragment["sellerOrgLocation"]["org"];
  orgPreferredVendors: OrgPreferredVendorsFieldsFragment[];
};

const VendorsWithProvider = () => {
  const intl = useIntl();
  const { vendorId } = useParams();
  const { toggle } = useGlobalDrawer();
  const { vendors, loading } = useVendors();
  const vendorsTableConfiguration = useVendorsTableConfiguration();
  const [searchQuery, setSearchQuery] = useState("");

  const [initialToggle, setInitialToggle] = useState(false);

  const groupedVendors = useMemo(() => {
    const list: GroupedVendor[] = [];
    vendors
      .filter(
        (vendor) =>
          vendor.sellerOrgLocation.org.name
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          vendor.contacts.some((contact) =>
            contact.name.toLowerCase().includes(searchQuery.toLowerCase()),
          ),
      )
      .forEach((vendor) => {
        if (
          list.find((item) => item.org.id === vendor.sellerOrgLocation.org.id)
        ) {
          list
            .find((item) => item.org.id === vendor.sellerOrgLocation.org.id)
            ?.orgPreferredVendors.push(vendor);
        } else {
          list.push({
            id: vendor.id,
            org: vendor.sellerOrgLocation.org,
            orgPreferredVendors: [vendor],
          });
        }
      });
    return list;
  }, [vendors, searchQuery]);

  useEffect(() => {
    if (!initialToggle && vendorId && vendors.length > 0) {
      toggle(EditVendorPanelId, true, vendorId);
      setInitialToggle(true);
    }
  }, [initialToggle, toggle, vendorId, vendors.length]);

  return (
    <PageContainer>
      <Helmet>
        <title>{intl.$t({ id: "VENDORS" })}</title>
      </Helmet>
      <AdminHeaderContainer>
        <HeaderGroup>
          <SearchInput
            placeHolder={intl.$t({ id: "SEARCH_VENDOR_AND_CONTACTS" })}
            onChange={setSearchQuery}
            className="w-64"
          />
          <NewVendorButton />
        </HeaderGroup>
      </AdminHeaderContainer>
      <GridTable
        configuration={{
          columns: vendorsTableConfiguration,
          toggle: {
            item: (vendor) => toggle(EditVendorPanelId, true, vendor.id),
          },
          classNames: {
            header: "top-21",
          },
        }}
        expandedItems={(item) =>
          item.orgPreferredVendors
            .slice(1, item.orgPreferredVendors.length + 1)
            .map((i) => ({
              ...item,
              orgPreferredVendors: item.orgPreferredVendors.slice(
                1,
                item.orgPreferredVendors.length + 1,
              ),
              id: i.id,
            }))
        }
        loading={loading}
        items={groupedVendors}
      />
      <ToggleEditVendor />
      <ExternalVendorMappingModal />
    </PageContainer>
  );
};

export const Vendors = () => {
  return (
    <NestedStepperProvider>
      <LocationsProvider>
        <OrgsProvider type={OrgType.Distributor}>
          <AgaveExternalVendorProvider>
            <ExternalVendorMappingProvider>
              <VendorsWithProvider />
            </ExternalVendorMappingProvider>
          </AgaveExternalVendorProvider>
        </OrgsProvider>
      </LocationsProvider>
    </NestedStepperProvider>
  );
};
