import { If } from "@/common/components/if/If";
import {
  InvoiceDocumentType,
  InvoiceIssue,
  InvoiceIssueType,
} from "@/common/components/invoices/invoice-issue/common/InvoiceIssue";
import { useUser } from "@/common/providers/UserProvider";
import { getUserName } from "@/common/utils/users/getUserName";
import { InvoiceKickbackFieldsFragment } from "@/generated/graphql";
import { FC } from "react";

type Props = {
  kickbacks: InvoiceKickbackFieldsFragment[];
  invoiceId?: string;
  refetchInvoice?: () => Promise<void>;
  documentType?: InvoiceDocumentType;
};

export const InvoiceKickbacks: FC<Props> = ({
  kickbacks,
  invoiceId,
  refetchInvoice,
  documentType,
}) => {
  const { viewer } = useUser();
  return (
    <If isTrue={kickbacks.length > 0}>
      {kickbacks.map((kickback, key) => (
        <InvoiceIssue
          key={key}
          index={key + 1}
          id={kickback.id}
          invoiceId={invoiceId}
          documentType={documentType}
          type={InvoiceIssueType.KICKBACK}
          issueDescription={kickback.description}
          issueDate={kickback.createdAt}
          resolutionDescription={kickback.resolutionDescription}
          issueResponseDate={kickback.resolvedAt}
          rejectionReason={undefined}
          responseBy={getUserName(kickback.resolvedBy)}
          issueBy={getUserName(kickback.reportedBy)}
          assignees={kickback.assignees}
          canEdit={
            !kickback.resolvedAt && kickback.reportedBy.id === viewer?.id
          }
          refetchInvoice={refetchInvoice}
          assets={kickback.assets}
          resolutionAssets={kickback.resolutionAssets}
        />
      ))}
    </If>
  );
};
