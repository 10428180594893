import { CostCodesSelector } from "@/common/components/cost-codes-selector/CostCodesSelector";
import { HeaderContainerWithSecondaryNavigation } from "@/common/components/header-container/HeaderContainer";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { Select } from "@/common/components/select/components/single/Select";
import { VIEW_STATE, useTableView } from "@/common/providers/TableViewProvider";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useCostCodes } from "../../../../../cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useMaterialList } from "../../providers/MaterialListProvider";
import { ImportMaterialsButton } from "../upload/ImportMaterialsButton";
import { ImportExternalMaterialsButton } from "./components/ImportExternalMaterialsButton";

const HeaderContainer = tw(HeaderContainerWithSecondaryNavigation)`pb-4`;
const Filters = tw.div`grid grid-flow-col gap-5 items-center`;
const Row = tw.div`flex flex-row space-x-2`;
const Spacer = tw.div`h-6`;

enum MaterialFilterType {
  All = "ALL",
  Connected = "CONNECTED",
  NotConnected = "NOT_CONNECTED",
  Archived = "ARCHIVED",
}

export const MaterialHeader = () => {
  const intl = useIntl();
  const { costCodes } = useCostCodes();
  const { filter, setFilter } = useMaterialList();
  const { tableView } = useTableView();

  const options = useMemo(() => {
    return Object.entries(MaterialFilterType).map(([, value]) => {
      return {
        value,
        name: intl.$t({ id: `MATERIAL_FILTER_${value}` }),
      };
    });
  }, [intl]);

  const filterValue = useMemo(() => {
    if (filter?.connected === undefined && filter?.archived === undefined) {
      return MaterialFilterType.All;
    } else if (filter?.connected) {
      return MaterialFilterType.Connected;
    } else if (filter?.connected === false) {
      return MaterialFilterType.NotConnected;
    } else if (filter?.archived) {
      return MaterialFilterType.Archived;
    } else {
      return MaterialFilterType.All;
    }
  }, [filter]);

  const setFilterHandler = useCallback(
    (value: string | null) => {
      if (value) {
        const isArchived =
          value === MaterialFilterType.Archived ? true : undefined;
        const isConnected =
          value === MaterialFilterType.Connected ? true : undefined;
        const isNotConnected =
          value === MaterialFilterType.NotConnected ? false : undefined;
        setFilter({
          ...filter,
          archived: isArchived,
          connected: isConnected || isNotConnected,
        });
      } else {
        setFilter({
          ...filter,
          archived: undefined,
          connected: undefined,
        });
      }
    },
    [filter, setFilter],
  );

  if (tableView !== VIEW_STATE.normal) {
    return <Spacer />;
  }

  return (
    <HeaderContainer>
      <Filters>
        <SearchInput
          placeHolder={intl.$t({ id: "SEARCH_MATERIALS" })}
          onChange={(search) => setFilter({ ...filter, search })}
        />
        <Select
          options={options}
          getLabel={(o) => o.name}
          getValue={(o) => o.value}
          value={filterValue}
          onChange={setFilterHandler}
          className="min-w-45"
        />
        <CostCodesSelector
          className="max-w-80"
          options={costCodes}
          selectedCostCodes={filter?.costCodeIds || []}
          setSelectedCostCodes={(costCodeIds) =>
            setFilter({ ...filter, costCodeIds })
          }
          limitTags={2}
        />
      </Filters>
      <Row>
        <ImportExternalMaterialsButton />
        <ImportMaterialsButton />
      </Row>
    </HeaderContainer>
  );
};
