import {
  DEFAULT_AUXILIARY_ITEM_UOM_MNEMONIC,
  LUMP_SUM_UOM,
} from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { usePreviousValue } from "@/common/hooks/usePreviousValue";
import {
  ApproveInvoiceInput,
  ReceiptFieldsFragment,
  UpdateInvoiceInput,
  UpdateReceiptMutation,
  useReceiptQuery,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBooleanPromise,
  NoFunctionPromise,
  NoFunctionUndefinedPromise,
} from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useApproveReceipt } from "../../receipts/hooks/useApproveReceipt";
import { useUpdateReceipt } from "../../receipts/hooks/useUpdateReceipt";
import { ReceiptFooterState } from "../types/ReceiptFooterState";

type ProviderContextType = {
  receipt: ReceiptFieldsFragment | null;
  updateReceipt: (
    input: UpdateInvoiceInput,
    args?: { includeDocuments?: boolean },
  ) => Promise<UpdateReceiptMutation | null | undefined>;
  approveReceipt: (input: ApproveInvoiceInput) => Promise<boolean>;
  footerState: ReceiptFooterState;
  setFooterState: (state: ReceiptFooterState) => void;
  loading: boolean;
  itemized: boolean;
  setItemized: (itemized: boolean) => void;
  hasReleaseRecorded: boolean;
  isItemized: (
    receipt: ReceiptFieldsFragment | null | undefined,
  ) => boolean | null;
  refetch: () => Promise<void>;
};

const ProviderContext = createContext<ProviderContextType>({
  receipt: null,
  updateReceipt: NoFunctionUndefinedPromise,
  approveReceipt: NoFunctionBooleanPromise,
  footerState: ReceiptFooterState.DEFAULT,
  setFooterState: NoFunction,
  loading: false,
  itemized: false,
  setItemized: NoFunction,
  hasReleaseRecorded: false,
  isItemized: NoFunction,
  refetch: NoFunctionPromise,
});

export const ReceiptProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { receiptId } = useParams();
  const prevReceiptId = usePreviousValue(receiptId);
  const [footerState, setFooterState] = useState<ReceiptFooterState>(
    ReceiptFooterState.DEFAULT,
  );

  const { data, loading, error, refetch } = useReceiptQuery({
    variables: { id: receiptId || "" },
    skip: !receiptId,
  });

  const isItemized = (receipt: ReceiptFieldsFragment | null | undefined) => {
    if (!receipt) {
      return false;
    }

    const items = receipt?.releaseItemHints?.map((hint) => hint.releaseItem);

    if (!items) {
      return false;
    }

    const additionalCharges = receipt?.release?.additionalCharges;
    const firstItem = items[0];

    const isNonItemized =
      items?.length === 1 &&
      (additionalCharges || []).length === 0 &&
      firstItem.quantityDecimal === "1" &&
      [DEFAULT_AUXILIARY_ITEM_UOM_MNEMONIC, LUMP_SUM_UOM].includes(
        firstItem?.uom?.mnemonic || "",
      );

    return !isNonItemized;
  };

  const [itemized, setItemized] = useState<boolean>(
    data?.receipt?.release ? isItemized(data?.receipt) : false,
  );

  useEffect(() => {
    if (prevReceiptId && receiptId && prevReceiptId !== receiptId) {
      setItemized(false);
    }
  }, [prevReceiptId, receiptId]);

  useEffect(() => {
    if (data?.receipt?.release) {
      setItemized(isItemized(data?.receipt));
    }
  }, [data]);

  const { updateReceipt: updateReceiptMutation } = useUpdateReceipt();

  const updateReceipt = async (
    input: UpdateInvoiceInput,
    { includeDocuments }: { includeDocuments?: boolean } = {},
  ): Promise<UpdateReceiptMutation | null | undefined> => {
    if (!receiptId) {
      return null;
    }
    return await updateReceiptMutation(input, includeDocuments);
  };

  const { approveReceipt: approveInvoiceMutation } = useApproveReceipt();
  const approveReceipt = async (
    input: ApproveInvoiceInput,
  ): Promise<boolean> => {
    if (receiptId) {
      return await approveInvoiceMutation(input);
    }
    return false;
  };

  useErrorEffect(error);

  const refetchReceipt = useCallback(async () => {
    await refetch();
  }, [refetch]);

  return (
    <ProviderContext.Provider
      value={{
        receipt: data?.receipt || null,
        refetch: refetchReceipt,
        updateReceipt,
        approveReceipt,
        loading,
        itemized,
        setItemized,
        footerState,
        setFooterState,
        hasReleaseRecorded: !!data?.receipt.release,
        isItemized,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReceipt = (): ProviderContextType =>
  useContext(ProviderContext);
