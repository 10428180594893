import { useVendors } from "@/contractor/pages/admin/vendors/hooks/useVendors";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { SelectCustomItemView } from "../select/components/single/components/SelectCustomItemView";
import { Select } from "../select/components/single/Select";
import {
  VendorPickerCustomRender,
  vendorLabelFormatter,
} from "../vendor-picker/VendorPickerCustomRender";

type Props = {
  onChange: (newValue: string | null) => void;
  value?: string | null;
  withCustomView?: boolean;
};

const ALL_VENDORS = "-1";

export const VendorsFilterSelector: FC<Props> = ({
  onChange,
  value,
  withCustomView = false,
}) => {
  const intl = useIntl();
  const { vendors } = useVendors();

  const vendorOptions = useMemo(() => {
    const opts = [
      {
        value: ALL_VENDORS,
        name: intl.$t({ id: "ALL_VENDORS" }),
      },
    ] as {
      value: string;
      name: string;
    }[];

    [...vendors]
      .sort((vendorA, vendorB) =>
        vendorA.sellerOrgLocation.name.localeCompare(
          vendorB.sellerOrgLocation.name,
        ),
      )
      .forEach((vendor) => {
        opts.push({
          ...vendor,
          value: vendor.sellerOrgLocation.id,
          name: vendorLabelFormatter(vendor.sellerOrgLocation),
        });
      });
    return opts;
  }, [intl, vendors]);

  const selectedOption = useMemo(
    () =>
      value === ALL_VENDORS
        ? undefined
        : vendorOptions.find((vendor) => vendor.value === value),
    [vendorOptions, value],
  );

  return (
    <Select
      options={vendorOptions || []}
      value={value || ALL_VENDORS}
      onChange={(value) => onChange(value === ALL_VENDORS ? null : value)}
      customRender={VendorPickerCustomRender}
      getLabel={(option) => option.name}
      getValue={(option) => option.value}
      inputProps={{
        className: `${!value && "text-gray-600"} px-2 py-[2.5px]`,
      }}
      selectedOptionCustomView={
        withCustomView && selectedOption ? (
          <SelectCustomItemView text={selectedOption.name} />
        ) : null
      }
    />
  );
};
