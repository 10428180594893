import { MAX_ITEMS_PER_PAGE } from "@/common/const";
import { ReleaseStatus, useReleasesSelectorQuery } from "@/generated/graphql";
import { useMemo } from "react";
import { useInvoiceVerification } from "../providers/InvoiceVerificationProvider";

export const useInvoiceSuggestedOrders = () => {
  const { invoice } = useInvoiceVerification();

  const { data, loading } = useReleasesSelectorQuery({
    variables: {
      filter: {
        poNumber: invoice?.poNumber,
        statuses: [
          ReleaseStatus.Scheduled,
          ReleaseStatus.Received,
          ReleaseStatus.PartiallyReceived,
          ReleaseStatus.Requested,
          ReleaseStatus.Reserved,
        ],
        closedProjects: false,
        deleted: false,
      },
      first: MAX_ITEMS_PER_PAGE,
    },
    skip: !invoice?.poNumber,
  });

  const releases = useMemo(
    () => data?.releases.edges.map((edge) => edge.node) || [],
    [data],
  );

  return {
    releases: data?.releases.edges.map((edge) => edge.node) || [],
    loading,
    called:
      !!data &&
      (releases.length === 0 ||
        releases.some((release) => release.poNumber === invoice?.poNumber)),
  };
};
