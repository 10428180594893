import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import {
  COLUMN_TYPE,
  SpreadSheetConfig,
} from "@/common/providers/ColumnMapperProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { ReceiptCreateReleaseFormValues } from "../../../ReceiptVerificationForm";

export const useReceiptCreateReleaseSpreadsheetConfig =
  (): SpreadSheetConfig[] => {
    const intl = useIntl();
    const { requiredValidator } = useTableValidators();
    const { hasPhaseCodes } = useOrgSettings();
    const { watch } = useFormContext<ReceiptCreateReleaseFormValues>();
    const projectId = watch("projectId");
    const { costCodes } = useProjectCostCodes(projectId);

    return [
      {
        header: intl.$t({ id: "ITEM_NAME" }),
        columnId: "description",
        width: 150,
        columnType: COLUMN_TYPE.Material,
      },
      {
        header: intl.$t({ id: "UOM" }),
        columnId: "UOM",
        columnType: COLUMN_TYPE.UOM,
      },
      {
        header: intl.$t({ id: "QUANTITY" }),
        columnId: "quantityDecimal",
        columnType: COLUMN_TYPE.Quantity,
      },
      {
        header: intl.$t({ id: "PRICE" }),
        columnId: "unitPrice",
        columnType: COLUMN_TYPE.UnitPrice,
        validator: requiredValidator,
        disabledForLumpSum: true,
      },
      {
        header: intl.$t({ id: "EXT_PRICE" }),
        columnId: "extPrice",
        width: 55,
        columnType: COLUMN_TYPE.ExtPrice,
      },
      ...(hasPhaseCodes
        ? [
            {
              header: intl.$t({ id: "PHASE_CODE" }),
              columnId: "phaseCode",
              columnType: COLUMN_TYPE.PhaseCode,
            },
          ]
        : [
            {
              header: intl.$t({ id: "COST_CODE" }),
              columnId: "costCode",
              columnType: COLUMN_TYPE.CostCode,
              options: costCodes.map((c) => c.formatted),
              metadata: { projectId },
            },
          ]),
      {
        header: intl.$t({ id: "ZONE" }),
        columnId: "zone.name",
        width: 100,
        columnType: COLUMN_TYPE.Zone,
      },
    ];
  };
