import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import {
  CostCodeFieldsFragment,
  ExternalLedgerAccount,
  ViewerCostCodesDocument,
  useAddCostCodeMutation,
  useRemoveCostCodeMutation,
  useUpdateCostCodeMutation,
  useViewerCostCodesQuery,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";

export type CostCode = CostCodeFieldsFragment & {
  isSubmitted: boolean;
} & { ledgerAccount?: ExternalLedgerAccount };

type ProviderContextType = {
  initialCodes: CostCodeFieldsFragment[];
  costCodes: CostCode[];
  setCostCodes: (costCodes: CostCode[]) => void;
  loading: boolean;
  addEmptyCostCode: () => void;
  updateCodeEditState: (id: string, inEditMode: boolean) => void;
  saveCostCode: (id: string) => void;
  deleteCostCode: (id: string) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  initialCodes: [],
  costCodes: [],
  setCostCodes: NoFunction,
  loading: false,
  addEmptyCostCode: NoFunction,
  updateCodeEditState: NoFunction,
  saveCostCode: NoFunction,
  deleteCostCode: NoFunction,
});

export const CostCodesListProvider: FC<{
  children: React.ReactNode;
  includeUnassigned?: boolean;
}> = ({ children, includeUnassigned = false }) => {
  const [codes, setCodes] = useState<CostCode[]>([]);
  const { data, loading } = useViewerCostCodesQuery();

  const { setError } = useGlobalError();

  const [addCostCode] = useAddCostCodeMutation();
  const [updateCostCode] = useUpdateCostCodeMutation();
  const [removeCostCode] = useRemoveCostCodeMutation();
  const { unassignedCostCode } = useUnspecifiedCostCode();

  useEffect(() => {
    setCodes(
      [
        ...(data?.viewer?.org.costCodes || []),
        ...(includeUnassigned ? [unassignedCostCode] : []),
      ].map((code) => ({
        ...code,
        description: code.description.trim(),
        code: code.code.trim(),
        isSubmitted: false,
      })),
    );
  }, [includeUnassigned, unassignedCostCode, data]);

  const addEmptyCostCode = () => {
    if (codes.find((c) => c.id === "")) {
      return;
    }
    setCodes([
      {
        id: "",
        code: "",
        description: "",
        isSubmitted: false,
        inUse: false,
        mappings: [],
      },
      ...codes,
    ]);
  };

  const updateCodeEditState = (id: string, inEditMode: boolean) => {
    const code = codes.find((c) => c.id === id);
    if (code) {
      if (!inEditMode && !code.code && !code.description) {
        setCodes(codes.filter((c) => c.id !== id));
      } else {
        setCodes(
          codes.map((c) => (c.id === code.id ? { ...c, inEditMode } : c)),
        );
      }
    }
  };

  const saveCostCode = async (id: string) => {
    const code = codes.find((c) => c.id === id);
    if (code) {
      if (code.id) {
        await updateCostCode({
          variables: {
            input: {
              id: code.id,
              code: code.code,
              description: code.description,
            },
          },
          refetchQueries: [{ query: ViewerCostCodesDocument }],
        });
      } else {
        try {
          const { errors } = await addCostCode({
            variables: {
              input: {
                code: code.code,
                description: code.description,
              },
            },
            refetchQueries: [{ query: ViewerCostCodesDocument }],
          });

          setError(errors);
        } catch (e) {
          setError(e);
        }
      }
    }
    setCodes(codes.map((c) => (c.id === id ? { ...c, inEditMode: false } : c)));
  };

  const deleteCostCode = async (id: string) => {
    await removeCostCode({
      variables: {
        costCodeID: id,
      },
    });
    setCodes(codes.filter((c) => c.id !== id));
  };

  return (
    <ProviderContext.Provider
      value={{
        initialCodes: data?.viewer?.org.costCodes || [],
        costCodes: codes,
        setCostCodes: setCodes,
        loading,
        addEmptyCostCode,
        updateCodeEditState,
        saveCostCode,
        deleteCostCode,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useCostCodesList = (): ProviderContextType =>
  useContext(ProviderContext);
