import { UpdateReleaseWithAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxesTypes";
import { useUser } from "@/common/providers/UserProvider";
import Decimal from "decimal.js";
import { FC, useCallback } from "react";
import { SalesTaxInput } from "../../types/SalesTaxInputType";
import { SalesTaxAmountInput } from "./input/SalesTaxAmountInput";
import { SalesTaxPercentageInput } from "./input/SalesTaxPercentageInput";
import { SalesTaxType } from "./toggle/AmountPercentageSwitch";

type Props = {
  salesTax: string | number | null;
  setSalesTax: (value: number | null) => void;
  salesAmount: number;
  setSalesAmount: (value: number) => void;
  formattedTaxAmount: string;
  type: SalesTaxType;
  updateRelease?: UpdateReleaseWithAdditionalChargesAndTaxes;
  salesTaxInput: SalesTaxInput;
};

export const UseSalesTaxInput: FC<Props> = ({
  salesTax,
  setSalesTax,
  salesAmount,
  setSalesAmount,
  formattedTaxAmount,
  updateRelease,
  salesTaxInput,
  type,
}) => {
  const { isContractor } = useUser();

  const onTaxPercentageBlur = useCallback(async () => {
    const input = {
      releaseId: salesTaxInput.id || "",
      version: salesTaxInput.version,
      taxRate: salesTax ? new Decimal(salesTax).dividedBy(100).toString() : "0",
      clearCustomTaxAmount: true,
      customTaxAmount: null,
      ...(isContractor && { clearTaxCode: true }),
    };

    await updateRelease?.(input);
  }, [
    salesTaxInput.id,
    salesTaxInput.version,
    salesTax,
    isContractor,
    updateRelease,
  ]);

  const onTaxAmountBlur = useCallback(async () => {
    const input = {
      releaseId: salesTaxInput.id || "",
      version: salesTaxInput.version,
      customTaxAmount: salesAmount?.toString() || "0",
      clearCustomTaxAmount: false,
      ...(isContractor && { clearTaxCode: true }),
    };

    await updateRelease?.(input);
  }, [
    salesTaxInput.id,
    salesTaxInput.version,
    salesAmount,
    isContractor,
    updateRelease,
  ]);

  return type === SalesTaxType.Percent ? (
    <SalesTaxPercentageInput
      value={salesTax}
      setValue={setSalesTax}
      formattedTaxAmount={formattedTaxAmount}
      onBlur={onTaxPercentageBlur}
    />
  ) : (
    <SalesTaxAmountInput
      value={salesAmount}
      setValue={setSalesAmount}
      onBlur={onTaxAmountBlur}
    />
  );
};
