import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { NoResults } from "@/common/components/no-results/NoResults";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import tw from "tailwind-styled-components";
import { useInvoiceOrderEdit } from "../../hooks/useInvoiceOrderEdit";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../providers/InvoiceVerificationProvider";
import { InvoiceCreateRelease } from "./components/invoice-create-release/InvoiceCreateRelease";
import { InvoiceOrderSelectorView } from "./components/invoice-match-header/invoice-order-selector/InvoiceOrderSelectorView";
import { InvoiceMatchedHeader } from "./components/invoice-match-header/InvoiceMatchedHeader";
import { InvoiceReleaseItems } from "./components/invoice-release-items/InvoiceReleaseItems";
import { InvoiceUpdateRelease } from "./components/invoice-update-release/InvoiceUpdateRelease";
import { NoReleaseForInvoiceInfo } from "./components/no-release-warning/NoReleaseForInvoiceInfo";

const Container = tw.div`bg-gray-100 rounded-3xl pt-4 h-full overflow-y-scroll`;
const InnerContainer = tw.div`flex flex-col`;
const NoResultsContainer = tw.div`px-5 text-sm`;

export const InvoiceMatchedOrder = () => {
  const { invoice, loading } = useInvoiceVerification();
  const { matchedOrderViewState, autoMatching } = useInvoiceMatchedOrder();
  const { loading: loadingOrgSettings } = useOrgSettings();
  useInvoiceOrderEdit();

  if (
    (loading && !invoice) ||
    (!invoice?.poExists && loadingOrgSettings) ||
    autoMatching
  ) {
    return <Loader loading />;
  }

  return (
    <Container>
      <If
        isTrue={
          matchedOrderViewState === MatchedOrderViewState.DEFAULT ||
          matchedOrderViewState ===
            MatchedOrderViewState.EDIT_INVOICE_COVERAGES ||
          matchedOrderViewState === MatchedOrderViewState.FULFILLED
        }
      >
        {invoice ? (
          <InnerContainer className="-mt-6">
            <InvoiceMatchedHeader />
            {invoice?.release ? (
              <InvoiceReleaseItems />
            ) : !invoice.hasRelease ? (
              <NoReleaseForInvoiceInfo />
            ) : (
              <NoResultsContainer>
                <NoResults
                  translationKey="INVOICE_HAS_BEEN_ASSIGNED_TO_UNAUTHORIZED_PROJECT"
                  includeWarningIcon
                  rounded
                />
              </NoResultsContainer>
            )}
          </InnerContainer>
        ) : (
          <Loader loading />
        )}
      </If>
      <If isTrue={matchedOrderViewState === MatchedOrderViewState.MATCH_ORDER}>
        <InnerContainer>
          <InvoiceOrderSelectorView />
        </InnerContainer>
      </If>
      <InnerContainer>
        <If
          isTrue={
            matchedOrderViewState === MatchedOrderViewState.CREATE_ORDER ||
            matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
          }
        >
          <InvoiceCreateRelease />
        </If>
        <If isTrue={matchedOrderViewState === MatchedOrderViewState.EDIT_ORDER}>
          <InvoiceUpdateRelease />
        </If>
      </InnerContainer>
    </Container>
  );
};
