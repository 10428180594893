import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  SHORT_DATE_OPTION,
  formattedDate,
} from "@/common/utils/dates/DateView";
import {
  IntegrationType,
  PoLink,
  SourceSystem,
  useReverseSyncPoMutation,
} from "@/generated/graphql";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ExternalPOModal } from "../../../../../common/external-po/ExternalPOModal";
import { ReleaseExportStatusIcon } from "./ReleaseExportStatusIcon";

export enum ReleaseExportStatusEnum {
  OK,
  Outdated,
  ExportIssue,
  Syncing,
  SyncIssue,
  PoExists,
}

export const RELEASE_EXPORT_STATUSES = {
  [ReleaseExportStatusEnum.OK]: {
    color: "green",
    label: "LAST_EXPORT_WITH_DATE",
  },
  [ReleaseExportStatusEnum.Outdated]: {
    color: "orange",
    label: "MODIFIED_AFTER_SYNC_WITH_DATE",
  },
  [ReleaseExportStatusEnum.ExportIssue]: {
    color: "red",
    label: "EXPORT_ISSUE_WITH_DATE",
  },
  [ReleaseExportStatusEnum.Syncing]: {
    color: "orange",
    label: "SYNCING_WITH_DATE",
  },
  [ReleaseExportStatusEnum.SyncIssue]: {
    color: "red",
    label: "SYNC_ISSUE_WITH_DATE",
  },
  [ReleaseExportStatusEnum.PoExists]: {
    color: "blue",
    label: "NOT_CONNECTED",
  },
};

const ExportedAt = tw.div`flex flex-row items-center text-2xs text-gray-600`;
const PoContainer = tw.div`flex flex-col`;
const Item = tw.div``;

type Props = {
  status: ReleaseExportStatusEnum;
  poNumber?: string | null;
  projectId?: string | null;
  sourceSystem?: SourceSystem;
  date?: number | null;
  integrationType?: IntegrationType;
  poLink?: Pick<PoLink, "immutable" | "id"> | null;
  poExists?: boolean | undefined | null;
};

export const ReleaseExportStatus: FC<Props> = ({
  status,
  poNumber,
  projectId,
  sourceSystem,
  integrationType,
  date,
  poLink,
  poExists,
}: Props) => {
  const intl = useIntl();
  const { setError } = useGlobalError();

  const [externalPOModalOpened, setExternalPOModalOpened] = useState(false);

  const [reverseSyncPoMutation, { loading: syncingPo }] =
    useReverseSyncPoMutation();
  const syncImmutablePo = async () => {
    if (!poLink?.id) {
      return;
    }
    try {
      const { data } = await reverseSyncPoMutation({
        variables: {
          poLinkId: poLink.id,
        },
      });
      return !!data?.reverseSyncPO;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  return (
    <>
      <ExportedAt>
        <ReleaseExportStatusIcon
          status={syncingPo ? ReleaseExportStatusEnum.Syncing : status}
          poLinkImmutable={!!poLink?.immutable}
          sourceSystem={sourceSystem}
        />
        <PoContainer>
          <LinkLike onClick={() => setExternalPOModalOpened(!!poNumber)}>
            <FormattedMessage
              id="PO_NUMBER_INTEGRATION"
              values={{
                number: poNumber,
                integration: intl.$t({
                  id: sourceSystem
                    ? `INTEGRATION_${sourceSystem}`
                    : `INTEGRATION_TYPE_${integrationType}`,
                }),
              }}
            />
          </LinkLike>
          {!!poLink?.immutable ? (
            <FormattedMessage
              id={"LAST_PO_UPDATE"}
              values={{
                date: formattedDate({
                  date,
                  options: SHORT_DATE_OPTION,
                }),
              }}
              tagName={Item}
            />
          ) : (
            <>
              <If isTrue={poLink}>
                <FormattedMessage
                  id={RELEASE_EXPORT_STATUSES[status].label}
                  values={{
                    date: formattedDate({
                      date,
                      options: SHORT_DATE_OPTION,
                    }),
                  }}
                  tagName={Item}
                />
              </If>
              <If isTrue={poExists && !poLink}>
                <FormattedMessage id="NOT_CONNECTED" tagName={Item} />
              </If>
            </>
          )}
        </PoContainer>
      </ExportedAt>
      <ExternalPOModal
        opened={externalPOModalOpened}
        goBack={() => setExternalPOModalOpened(false)}
        poNumber={poNumber}
        projectId={projectId}
        syncImmutablePo={poLink?.immutable ? syncImmutablePo : undefined}
        syncingImmutablePo={syncingPo}
      />
    </>
  );
};
