import { Instructions } from "@/common/components/instructions/Instructions";
import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { useTaxCalculation } from "@/common/components/sales-tax-input/hooks/useTaxCalculation";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { useQuoteDocument } from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import { useProjectZonesStore } from "@/contractor/pages/home/project/store/projectZonesStore";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  AdditionalChargesFieldsFragment,
  InstructionInput,
  ManufacturerFieldsFragment,
  OrderTypeSummaryFieldsFragment,
  OrgMaterialFieldsFragment,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { useCreateOrderFromQuoteConfig } from "./CreateOrderFromQuote.config";
import { CreateOrderFromQuoteFormValues } from "./CreateOrderFromQuoteForm";

const InnerContainer = tw.div`flex flex-col h-full`;
const Footer = tw.div`mt-10 w-full pb-7`;
const SpreadSheetView = tw.div`w-full mt-5 drop-shadow-md rounded-2xl h-fit`;

export type ItemizedQuoteTableItem = {
  id: string;
  quantityDecimal: string;
  name: string;
  material:
    | (OrgMaterialFieldsFragment & {
        manufacturer: ManufacturerFieldsFragment;
      })
    | undefined;
  costCode: string | undefined;
  UOM: string | null | undefined;
  unitPrice: string | undefined;
  extPrice: number;
};

type Props = {
  form: UseFormReturn<CreateOrderFromQuoteFormValues, unknown, undefined>;
  tableItems: ItemizedQuoteTableItem[];
  orderTypes: OrderTypeSummaryFieldsFragment[];
  total: number;
  updateSubtotal: (data: Record<string, string>[]) => void;
};

export const CreateOrderFromQuoteItemizedView: FC<Props> = ({
  form: { watch, setValue },
  tableItems,
  total,
  updateSubtotal,
}) => {
  const { release } = useRelease();
  const { loading } = useQuoteDocument();

  const spreadsheetViewColumns = useCreateOrderFromQuoteConfig();

  const projectId = watch("projectId");
  const orderTypeId = watch("orderTypeId");
  const customTaxAmount = watch("customTaxAmount");
  const subtotal = watch("subtotal");
  const taxRate = watch("taxRate");
  const taxCodeId = watch("taxCodeId");
  const taxType = watch("taxType");
  const chargesAmount = watch("additionalCharges");
  const { getTaxAmount } = useTaxCalculation();
  const taxAmount = getTaxAmount(taxRate, customTaxAmount, subtotal);
  const { calcAdditionalChargesPrice } = usePriceCalculation();

  const { setCurrentProjectId } = useProjectZonesStore(
    useShallow((state) => ({
      setCurrentProjectId: state.setCurrentProjectId,
    })),
  );

  useEffect(() => {
    if (projectId) {
      setCurrentProjectId(projectId);
    }
  }, [setCurrentProjectId, projectId]);

  const handleUpdateRelease = useCallback(
    (values: UpdateContractorReleaseInput) => {
      if (values.additionalCharges) {
        setValue("additionalCharges", values.additionalCharges);
      }

      if (values.clearCustomTaxAmount !== undefined) {
        setValue("clearCustomTaxAmount", !!values.clearCustomTaxAmount);
      }
      if (
        values.customTaxAmount ||
        (values.clearCustomTaxAmount === false && customTaxAmount === undefined)
      ) {
        setValue("customTaxAmount", values.customTaxAmount || "");
        setValue("taxRate", undefined);
      }
      if (values.taxRate || values.clearCustomTaxAmount) {
        setValue("customTaxAmount", undefined);
        setValue("taxRate", values.taxRate || "");
      }
      if (values.taxCodeId) {
        setValue("taxCodeId", values.taxCodeId);
      }
      if (values.taxType) {
        setValue("taxType", values.taxType);
      }

      return true;
    },
    [customTaxAmount, setValue],
  );

  const saveInstruction = useCallback(
    (instruction: InstructionInput) => {
      setValue("instructions", instruction);
    },
    [setValue],
  );

  const releaseInput = useMemo(
    () => ({
      customTaxAmount,
      taxRate,
      orderTypeId,
      taxAmount,
      subtotal,
      taxCodeId,
      taxType,
      chargesAmount: calcAdditionalChargesPrice(chargesAmount),
    }),
    [
      calcAdditionalChargesPrice,
      chargesAmount,
      customTaxAmount,
      orderTypeId,
      subtotal,
      taxAmount,
      taxCodeId,
      taxRate,
      taxType,
    ],
  );

  return (
    <>
      <InnerContainer>
        <SpreadSheetView>
          <SpreadSheetTable
            items={tableItems}
            columns={spreadsheetViewColumns}
            saving={loading}
            height="550px"
            rowNumber={17}
            onChanges={updateSubtotal}
          />
        </SpreadSheetView>
      </InnerContainer>
      <Footer>
        <ReleaseAdditionalChargesAndTaxes
          total={`${total}`}
          releaseInput={releaseInput}
          customPaymentTerm={`${watch("paymentTerm")}`}
          editableByContractor
          updateRelease={handleUpdateRelease}
          includePaymentTerms
          additionalCharges={
            watch("additionalCharges") as AdditionalChargesFieldsFragment[]
          }
        />
        <Instructions
          projectId={projectId}
          instruction={release?.instructions}
          saveInstruction={saveInstruction}
        />
      </Footer>
    </>
  );
};
