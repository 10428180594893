import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import { WarehouseFieldsFragment } from "@/generated/graphql";
import { Address } from "@/types/Address";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { Address as AddressView } from "../../address/Address";
import { InfoHeaderItem } from "./InfoHeaderItem";

const Details = tw.div`
  text-xs md:text-sm
`;

const ListContent = tw.div`
  grid grid-flow-row justify-start items-start
`;

const Name = tw.div`text-sm font-medium`;

const Content = tw.div`grid grid-cols-[auto_1fr] md:grid-cols-auto gap-2`;

type Props = {
  title?: string | React.JSX.Element;
  name?: string | React.JSX.Element;
  orgName?: string;
  logoImageUrl?: string | null;
  smallLogo?: boolean;
  address?: Address | null;
  navTo?: string;
  warehouse?: WarehouseFieldsFragment | null;
  hideInMobile?: boolean;
};

export const OrgDetailsHeaderItem: FC<Props> = ({
  title,
  name,
  orgName,
  logoImageUrl,
  smallLogo,
  address,
  warehouse,
  navTo,
  hideInMobile = false,
}) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const navigateTo = () => {
    if (navTo) {
      navigate(navTo);
    }
  };

  return (
    <InfoHeaderItem title={title} hideInMobile={hideInMobile}>
      <Content>
        <If isTrue={logoImageUrl}>
          <OrgLogo
            logoImageUrl={logoImageUrl}
            name={orgName as string}
            width={smallLogo ? 40 : 50}
          />
        </If>
        <ListContent>
          <If isTrue={name}>
            <If isTrue={navTo}>
              <LinkLike onClick={navigateTo}>{name}</LinkLike>
            </If>
            <If isTrue={!navTo}>
              <Name>{name}</Name>
            </If>
          </If>
          <If isTrue={orgName}>
            <Details>{orgName}</Details>
          </If>
          <Details>
            <If isTrue={warehouse}>
              {intl.$t(
                { id: "FORMATTED_WAREHOUSE_NAME" },
                { name: warehouse?.name },
              )}{" "}
              -{" "}
            </If>
            <AddressView
              address={warehouse?.address ?? address}
              customEmptyText="NOT_SPECIFIED"
            />
          </Details>
        </ListContent>
      </Content>
    </InfoHeaderItem>
  );
};
