import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { LocationSelector } from "@/common/components/location-selector/LocationSelector";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { getProjectSelectorLabel } from "@/common/components/projects-filter-selector/getProjectSelectorLabel";
import { Select } from "@/common/components/select/components/single/Select";
import { SuccessModal } from "@/common/components/success-modal/SuccessModal";
import { Switch } from "@/common/components/switch/Switch";
import { TextField } from "@/common/components/textfield/TextField";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import {
  SupportedFormats,
  UploadAsset,
} from "@/common/components/upload-asset/UploadAsset";
import {
  UploadAssetProvider,
  useUploadAssets,
} from "@/common/components/upload-asset/UploadAssetProvider";
import {
  IMAGE_EXTENSIONS,
  IMAGE_MIME_TYPE,
  PDF_EXTENSIONS,
  PDF_MIME_TYPE,
} from "@/common/components/upload/FileUploadArea";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { useUser } from "@/common/providers/UserProvider";
import { routes } from "@/config/routes";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import {
  AssetContext,
  AuthorizationStatus,
  OrgLocationRole,
} from "@/generated/graphql";
import {
  CheckCircleOutlineRounded,
  InfoOutlined,
  InfoRounded,
} from "@mui/icons-material";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useReceipts } from "../../providers/ReceiptsProvider";

type Props = {
  onClose: () => void;
};

const ANY_PROJECT = "ANY_PROJECT";

const Container = tw.div<{
  $successful: boolean;
}>`flex flex-col gap-4
${({ $successful }) => $successful && "h-full justify-between"}
`;
const SuccessContainer = tw.div`flex bg-gray-100 justify-center items-center p-10 text-center gap-2`;
const QuestionContainer = tw.div`flex flex-col items-center gap-2 p-10 text-center`;
const Row = tw.div`flex justify-between`;
const InfoIcon = tw(InfoOutlined)`text-blue-500`;

const ReceiptFormWithProvider: FC<Props> = ({ onClose }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { openDialog } = useDialog();
  const { viewer } = useUser();
  const { createReceipt } = useReceipts();
  const { assets } = useUploadAssets();
  const { projects } = useProjectListOptions();
  const { locations } = useUserLocations();

  const [notes, setNotes] = useState("");
  const [projectId, setProjectId] = useState<string | null>(ANY_PROJECT);
  const [locationId, setLocationId] = useState<string | null>(null);
  const [receiptId, setReceiptId] = useState<string | null>(null);
  const [prePaid, setPrePaid] = useState(true);
  const [successful, setSuccessful] = useState(false);

  const isForeman = useMemo(
    () =>
      viewer?.locationRoles.every(
        (s) => s.role === OrgLocationRole.LocationForeman,
      ),
    [viewer],
  );

  const save = useCallback(async () => {
    if (successful) {
      navigate(
        generatePath(routes.processReceipt, {
          receiptId,
        }),
      );
    } else if (assets && locationId) {
      const receiptId = await createReceipt({
        assetUrls: assets.map((asset) => asset.url),
        orgLocationId: locationId,
        notes,
        projectId: projectId === ANY_PROJECT ? null : projectId,
        async: false,
        prePaid,
      });

      if (receiptId) {
        if (isForeman) {
          openDialog({
            content: (
              <SuccessModal
                message={intl.$t({ id: "RECEIPT_SUCCESSFULLY_UPLOADED" })}
              />
            ),
            closingTimer: DIALOG_AUTO_CLOSE_TIMER,
          });
          onClose();
        } else {
          setSuccessful(true);
          setReceiptId(receiptId);
        }
      }
    }
  }, [
    assets,
    createReceipt,
    intl,
    isForeman,
    locationId,
    navigate,
    notes,
    onClose,
    openDialog,
    projectId,
    receiptId,
    successful,
    prePaid,
  ]);

  const projectOptions = useMemo(() => {
    return [
      {
        id: ANY_PROJECT,
        name: intl.$t({ id: "RECEIPT_ANY_PROJECT" }),
      },
      ...projects.map((project) => {
        return {
          id: project.id,
          name: getProjectSelectorLabel(project),
        };
      }),
    ];
  }, [intl, projects]);

  const selectedProject = useMemo(
    () => projects.find((project) => projectId === project.id),
    [projectId, projects],
  );

  useEffect(() => {
    if (selectedProject) {
      setLocationId(selectedProject.location.id);
      return;
    }
    setLocationId(locations.length ? locations[0].id : "");
  }, [selectedProject, locations]);

  return (
    <OverlayPanel
      title={intl.$t({ id: "ADD_RECEIPT" })}
      onSave={save}
      onCancel={onClose}
      saveLabel={intl.$t({ id: !successful ? "PROCEED" : "CREATE" })}
      cancelLabel={intl.$t({ id: !successful ? "CLOSE" : "NO" })}
      disableSave={!assets?.length || !locationId}
    >
      <Container $successful={successful}>
        <If isTrue={!successful}>
          <Select
            placeholder={intl.$t({
              id: "PROJECT_SELECT_YOUR_PROJECT",
            })}
            testId="receipt-form-projects-selector"
            options={projectOptions}
            value={projectId}
            onChange={(projectId) => setProjectId(projectId)}
            getLabel={(option) => option.name}
            getValue={(option) => option.id}
            required
            noOptionsText={intl.$t({
              id: "NO_PROJECTS_IN_LOCATION",
            })}
          />
          <If isTrue={!selectedProject}>
            <LocationSelector
              placeholder={intl.$t({ id: "INVOICE_LOCATION" })}
              locationId={locationId}
              setLocationId={setLocationId}
              disabledFn={(location) =>
                location.permissions?.createInvoice !==
                AuthorizationStatus.Authorized
              }
              disabledTooltip={intl.$t({
                id: "ORG_LOCATION_INVOICE_PERMISSION",
              })}
            />
          </If>
          <TextField
            multiline
            minRows={3}
            maxRows={10}
            label={intl.$t({ id: "NOTES_OPTIONAL" })}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            testId="receipt-form-notes"
          />
          <Row>
            <FormattedMessage id="WILL_THIS_RECEIPT_BE_PAID_VIA_INVOICE" />
            <Row>
              <Tooltip id="receipt-paid-info" element={<InfoIcon />}>
                <FormattedMessage id="WILL_THIS_RECEIPT_BE_PAID_VIA_INVOICE_INFO" />
              </Tooltip>
              <Switch
                offLabel={intl.$t({ id: "NO" })}
                onLabel={intl.$t({ id: "YES" })}
                value={!prePaid}
                onChange={(v) => setPrePaid(!v)}
                className="ml-2"
              />
            </Row>
          </Row>
          <UploadAsset
            accept={{
              [PDF_MIME_TYPE]: PDF_EXTENSIONS,
              [IMAGE_MIME_TYPE]: IMAGE_EXTENSIONS,
            }}
            testId="receipt-uploader"
          >
            <SupportedFormats>
              <FormattedMessage id="RECEIPTS_SUPPORTED_FORMATS" />
            </SupportedFormats>
          </UploadAsset>
        </If>
        <If isTrue={successful}>
          <SuccessContainer>
            <CheckCircleOutlineRounded className="text-5xl text-blue-500" />
            <FormattedMessage id="RECEIPT_SUCCESSFULLY_UPLOADED" />
          </SuccessContainer>
          <QuestionContainer>
            <InfoRounded className="text-5xl text-gray-400" />
            <FormattedMessage id="RECEIPT_REDIRECT" />
          </QuestionContainer>
        </If>
      </Container>
    </OverlayPanel>
  );
};

export const ReceiptForm: FC<Props> = ({ onClose }) => {
  return (
    <UploadAssetProvider context={AssetContext.Invoice}>
      <ReceiptFormWithProvider onClose={onClose} />
    </UploadAssetProvider>
  );
};
