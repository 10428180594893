import { Dialog } from "@/common/components/dialog/Dialog";
import { TextField } from "@/common/components/textfield/TextField";
import { FC, useCallback, useEffect, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceFolders } from "./hooks/useInvoiceFolders";
import { useFolderEditStore } from "./store/useFolderEditStore";

const DialogContent = tw.div`
  grid gap-7 place-items-center
`;

export const EditFolderDialog: FC = () => {
  const intl = useIntl();
  const { name, setName, editVisible, folder, setEditVisible } =
    useFolderEditStore();
  const { updateFolder } = useInvoiceFolders();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (editVisible) {
      setTimeout(() => {
        inputRef.current?.focus();
      });
    }
  }, [editVisible]);

  const closeDialog = () => {
    setEditVisible?.(false);
    setName("");
  };

  const handleUpdate = useCallback(async () => {
    if (folder) {
      return await updateFolder({
        id: folder.id,
        name: name,
      });
    }
  }, [updateFolder, folder, name]);

  return (
    <Dialog
      title={<FormattedMessage id="RENAME" />}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      confirmButtonText={intl.$t({ id: "CONFIRM" })}
      handleConfirm={() => {
        handleUpdate();
        closeDialog();
      }}
      handleCancel={closeDialog}
      maxWidth="sm"
      show={editVisible}
      content={
        <DialogContent>
          <TextField
            size="small"
            value={name}
            InputProps={{ style: { width: "300px" } }}
            label={intl.$t({ id: "GROUP_NAME" })}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setName(event.target.value);
            }}
            autoComplete="off"
            inputRef={inputRef}
            onKeyUpCapture={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === "Enter") {
                handleUpdate();
                closeDialog();
              }
            }}
            className="mt-3"
          />
        </DialogContent>
      }
    />
  );
};
