import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { PageBackButton } from "@/common/components/page-back-button/PageBackButton";
import { SuccessModal } from "@/common/components/success-modal/SuccessModal";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { getUserName } from "@/common/utils/users/getUserName";
import { InvoiceStatus } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { Form, FormProvider, useForm, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useReceiptKickbacks } from "../../../receipts/hooks/useReceiptKickbacks";
import { useReceiptCreateRelease } from "../../providers/ReceiptCreateReleaseProvider";
import { useReceipt } from "../../providers/ReceiptProvider";
import { useReceiptUpdateRelease } from "../../providers/ReceiptUpdateReleaseProvider";
import { ReceiptFooterState } from "../../types/ReceiptFooterState";
import { ReceiptCreateReleaseFormValues } from "../ReceiptVerificationForm";
import { ReceiptKickbackForm } from "../receipt-kickback/ReceiptKickbackForm";

const ButtonContainer = tw.div`grid grid-flow-col gap-2 items-center justify-end`;
const FloatingFooterStyled = tw(FloatingFooter)`md:px-6`;
const SuccessContainer = tw.div`flex flex-col gap-2 items-center`;
const SuccessDetails = tw.div`text-sm`;
const SuccessTitle = tw.div`font-medium text-lg`;

export const ReceiptFooter: FC = () => {
  const { handleSubmit, reset } =
    useFormContext<ReceiptCreateReleaseFormValues>();

  const intl = useIntl();
  const {
    receipt,
    loading,
    itemized,
    hasReleaseRecorded,
    refetch,
    setFooterState,
    footerState,
  } = useReceipt();
  const { syncCreateReleaseFromReceipt, createRelease } =
    useReceiptCreateRelease();
  const { syncUpdateReleaseFromReceipt, updateRelease } =
    useReceiptUpdateRelease();
  const { createReceiptKickback } = useReceiptKickbacks();
  const { setWarningAlert } = useSnackbar();
  const { openDialog } = useDialog();

  const methods = useForm({
    defaultValues: {
      description: "",
      assigneeIds: [] as string[],
      assetUrls: [] as string[],
      issue: "",
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const handleCreateReleaseFromReceipt = useCallback(async () => {
    await handleSubmit(async (values: ReceiptCreateReleaseFormValues) => {
      if (!receipt?.number) {
        setWarningAlert(intl.$t({ id: "RECEIPT_MISSING_NUMBER" }));
        return;
      }
      if (itemized) {
        const sync = await syncCreateReleaseFromReceipt(values);
        if (!sync) {
          return null;
        }
      } else {
        const release = await createRelease(values);
        if (!release) {
          return null;
        }
      }
      reset();
    })();
  }, [
    handleSubmit,
    receipt?.number,
    itemized,
    reset,
    setWarningAlert,
    intl,
    syncCreateReleaseFromReceipt,
    createRelease,
  ]);

  const handleUpdateReleaseFromReceipt = useCallback(async () => {
    await handleSubmit(async (values: ReceiptCreateReleaseFormValues) => {
      if (itemized) {
        const sync = await syncUpdateReleaseFromReceipt(values);
        if (!sync) {
          return null;
        }
      } else {
        const release = await updateRelease(values);
        if (!release) {
          return null;
        }
      }
      reset();
    })();
  }, [
    handleSubmit,
    itemized,
    reset,
    syncUpdateReleaseFromReceipt,
    updateRelease,
  ]);

  const kickback = useCallback(async () => {
    const { description, assigneeIds } = methods.getValues();

    const result = await createReceiptKickback({
      receiptId: receipt?.id ?? "",
      description,
      assigneeIds,
      assetUrls: methods.getValues().assetUrls,
    });
    if (result) {
      refetch();
      methods.reset();
      openDialog({
        content: (
          <SuccessModal
            message={
              <SuccessContainer>
                <FormattedMessage
                  id="KICKBACK_SENT_TO_TITLE"
                  tagName={SuccessTitle}
                />
                <FormattedMessage
                  id="KICKBACK_SENT_TO"
                  values={{
                    kickbackTo: result.kickbacks[
                      result.kickbacks.length - 1
                    ].assignees
                      .map(getUserName)
                      .join(", "),
                  }}
                  tagName={SuccessDetails}
                />
              </SuccessContainer>
            }
          />
        ),
        closingTimer: DIALOG_AUTO_CLOSE_TIMER,
      });
      setFooterState(ReceiptFooterState.DEFAULT);
    }
  }, [
    methods,
    createReceiptKickback,
    receipt?.id,
    refetch,
    openDialog,
    setFooterState,
  ]);

  const assigneeIds = methods.watch("assigneeIds");

  // TODO remove this when we add unarchive feature - right now we hide the footer entirely if archived
  if (receipt?.archivedAt) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <Form>
        <FloatingFooterStyled>
          <If isTrue={footerState === ReceiptFooterState.KICKBACK}>
            <ReceiptKickbackForm
              onClose={() => setFooterState(ReceiptFooterState.DEFAULT)}
              receiptId={receipt?.id}
              releaseCreatedBy={receipt?.release?.createdBy?.id}
              hideDetails
            />
          </If>
          <ButtonContainer>
            <If isTrue={footerState === ReceiptFooterState.DEFAULT}>
              <PageBackButton />
              <If isTrue={receipt?.status !== InvoiceStatus.Paid}>
                <OutlinedButton
                  className="py-0"
                  onClick={() => {
                    setFooterState(ReceiptFooterState.KICKBACK);
                  }}
                >
                  <FormattedMessage id="INVOICE_KICKBACK" />
                </OutlinedButton>
              </If>
              <If isTrue={receipt?.status !== InvoiceStatus.Approved}>
                <PrimaryButton
                  className="py-0"
                  onClick={
                    !hasReleaseRecorded
                      ? handleCreateReleaseFromReceipt
                      : handleUpdateReleaseFromReceipt
                  }
                  loading={loading}
                  testId="receipt-record-order-footer-submit"
                >
                  <FormattedMessage
                    id={
                      hasReleaseRecorded
                        ? receipt?.status === InvoiceStatus.AwaitingApproval
                          ? "UPDATE_RELEASE_AND_APPROVE"
                          : "UPDATE_RELEASE"
                        : "RECORD_ORDER"
                    }
                  />
                </PrimaryButton>
              </If>
            </If>
            <If isTrue={footerState === ReceiptFooterState.KICKBACK}>
              <OutlinedButton
                onClick={() => setFooterState(ReceiptFooterState.DEFAULT)}
              >
                <FormattedMessage id="CANCEL" />
              </OutlinedButton>
              <PrimaryButton
                className="py-0"
                onClick={kickback}
                disabled={assigneeIds.length === 0}
              >
                <FormattedMessage id="INVOICE_KICKBACK" />
              </PrimaryButton>
            </If>
          </ButtonContainer>
        </FloatingFooterStyled>
      </Form>
    </FormProvider>
  );
};
