import { LUMP_SUM_UOM, LUMP_SUM_UOM_PLURAL_DESCRIPTION } from "@/common/const";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import {
  AddToReleaseItemInput,
  OrgMaterialFieldsFragment,
} from "@/generated/graphql";
import { useIntl } from "react-intl";
import { InvoiceCreateReleaseFormValues } from "../components/matched-order/components/InvoiceVerificationForm";
import { useInvoiceVerification } from "../providers/InvoiceVerificationProvider";

export const useLumpSumInvoiceReleaseItems = () => {
  const { costCodes } = useCostCodes();
  const { updateMaterials } = useMaterials();
  const { materials } = useMaterials();
  const intl = useIntl();
  const { invoice } = useInvoiceVerification();

  const getLumpSumReleaseItem = async (
    values: InvoiceCreateReleaseFormValues,
  ): Promise<AddToReleaseItemInput[]> => {
    const costCode = costCodes.find(
      (c) => c.id === values.costCodeId,
    )?.description;
    const lumpSumMaterial = materials.find(
      (m) => m.material.name === LUMP_SUM_UOM_PLURAL_DESCRIPTION,
    );

    let newMaterials;
    if (!lumpSumMaterial) {
      newMaterials = await updateMaterials({
        addedMaterials: [
          {
            newOrgCatalogSKU: {
              defaultUom: LUMP_SUM_UOM,
              name: LUMP_SUM_UOM_PLURAL_DESCRIPTION,
            },
          },
        ],
      });
    }
    const items: AddToReleaseItemInput[] = [
      {
        projectItem: {
          estimateUom: LUMP_SUM_UOM,
          orgCatalogSkuId: (
            lumpSumMaterial || (newMaterials as OrgMaterialFieldsFragment[])[0]
          )?.material.id,
        },
        quantityDecimal: values.subtotal.toString(),
        position: 0,
        unitPrice: "1",
        tags: values.phaseCodeId ? [values.phaseCodeId] : undefined,
        costCodeId: values.costCodeId,
        name: costCode
          ? intl.$t({ id: "RECEIPT_COST_CODE_EXPENSES" }, { costCode })
          : intl.$t({ id: "RECEIPT_EXPENSES" }),
        invoiceId: invoice?.id,
      },
    ];

    return items;
  };

  return { getLumpSumReleaseItem };
};
