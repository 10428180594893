import { ProjectFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { Select } from "../select/components/single/Select";
import { SelectCustomItemView } from "../select/components/single/components/SelectCustomItemView";
import { ProjectFilterCustomRender } from "./ProjectFilterCustomRender";
import { getProjectSelectorLabel } from "./getProjectSelectorLabel";

type Props = {
  projects: Pick<ProjectFieldsFragment, "id" | "name" | "jobNumber">[];
  onChange: (newValue: string | null) => void;
  value?: string | null;
  label?: string;
  withCustomView?: boolean;
};

export const ProjectsFilterSelector: FC<Props> = ({
  projects,
  onChange,
  value,
  label,
  withCustomView = false,
}) => {
  const intl = useIntl();

  const projectOptions = useMemo(
    () => [
      {
        id: undefined,
        name: intl.$t({ id: "ALL_PROJECTS" }),
      },
      ...projects.map((project) => ({
        id: project.id,
        name: getProjectSelectorLabel(project),
      })),
    ],
    [projects, intl],
  );

  const selectedOption = useMemo(
    () =>
      value === undefined
        ? undefined
        : projectOptions.find((project) => project.id === value),
    [projectOptions, value],
  );

  return (
    <Select
      testId="projectFilter"
      options={projectOptions}
      getLabel={(o) => o.name}
      getValue={(o) => o.id as string}
      value={value}
      label={label}
      customRender={ProjectFilterCustomRender}
      inputProps={{
        className: `${!value && "text-gray-600"} px-2 py-[2.5px]`,
      }}
      onChange={onChange}
      selectedOptionCustomView={
        withCustomView && selectedOption ? (
          <SelectCustomItemView text={selectedOption.name} />
        ) : null
      }
    />
  );
};
