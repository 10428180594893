import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useLocalStorage } from "@/common/hooks/useLocalStorage";
import { useUnspecifiedPhaseCode } from "@/common/hooks/useUnspecifiedPhaseCode";
import { getCostCodesByBuyoutItems } from "@/common/utils/cost-codes-and-zones/getCostCodesByBuyoutItems";
import { useBuyoutItemsGrouping } from "@/common/utils/hooks/useBuyoutItemsGrouping";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import {
  AddToBuyoutInput,
  AddToBuyoutItemInput,
  BuyoutFieldsFragment,
  BuyoutItemFieldsFragment,
  BuyoutStatus,
  UpdateContractorBuyoutInput,
  useCreateOrgCatalogSkuMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import Decimal from "decimal.js";
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  CategoryState,
  useToggleCategory,
} from "../../../../../../../common/hooks/useToggleCategory";
import { useUnspecifiedCostCode } from "../../../../../../../common/hooks/useUnspecifiedCostCode";
import { BuyoutStandaloneReleaseItemFieldsFragmentWithId } from "../../common/buyout-standalone-release-items-list/BuyoutStandaloneReleaseItemsList";
import { useBuyoutItemsFilters } from "./hooks/useBuyoutItemsFilters";
import { useBuyoutMutations } from "./useBuyoutMutations";

type CostCodeCategory = CategoryState<BuyoutItemFieldsFragment>;

type AddToBuyoutExtendedItemInput = AddToBuyoutItemInput & {
  orgCatalogSkuName?: string;
  isAddMode?: boolean;
  notes?: string;
  uomId?: string;
};

type ContextType = {
  costCodes: CostCodeCategory[];
  toggleCategory: (name: string) => void;
  newBuyoutItem: AddToBuyoutExtendedItemInput;
  setNewBuyoutItem: (input: AddToBuyoutExtendedItemInput) => void;
  addToBuyout: () => Promise<boolean>;
  addItemsToBuyout: (
    input: AddToBuyoutInput,
  ) => Promise<BuyoutItemFieldsFragment[] | boolean>;
  updateBuyoutItems: (input: UpdateContractorBuyoutInput) => Promise<boolean>;
  removeBuyoutItems: (buyoutItemIds: string[]) => Promise<boolean>;
  loading: boolean;
  removeFromBuyout: (id: string) => Promise<boolean>;
  isEditable: boolean;
  setIsEditable: (isEditable: boolean) => void;
  isNewBuyoutWithErrors: boolean;
  setIsNewBuyoutWithErrors: (isNewBuyoutWithErrors: boolean) => void;
  groupedByCostCode: boolean;
  setGroupedByCostCode: (grouped: boolean) => void;

  groupedStandaloneItemsByCostCode: boolean;
  setGroupedStandaloneItemsByCostCode: (grouped: boolean) => void;

  orderedSubtotal: Decimal;
  orderedSalesTax: Decimal;
  orderedTotal: Decimal;
  receivedSubtotal: Decimal;
  receivedSalesTax: Decimal;
  receivedTotal: Decimal;
  subtotal: Decimal;
  salesTax: Decimal;
  total: Decimal;
  receivedStandaloneSubtotal: Decimal;
  receivedStandaloneSalesTax: Decimal;
  receivedStandaloneTotal: Decimal;
  orderedStandaloneSubtotal: Decimal;
  orderedStandaloneSalesTax: Decimal;
  orderedStandaloneTotal: Decimal;
  standaloneItems: CategoryState<BuyoutStandaloneReleaseItemFieldsFragmentWithId>[];
  toggleStandaloneCategory: (name: string) => void;
  setEditSalesTax: (value: boolean) => void;
  setEditAllowance: (value: boolean) => void;
  editSalesTax: boolean;
  editAllowance: boolean;
  isSaleTaxAmount: boolean;
  setIsSaleTaxAmount: (value: boolean) => void;
};

const Context = createContext<ContextType>({
  newBuyoutItem: {
    description: "",
    quantityDecimal: "0",
    projectItem: {
      estimateUom: "",
    },
    requestedUnitPrice: "0",
  },
  costCodes: [],
  toggleCategory: NoFunction,
  setNewBuyoutItem: NoFunction,
  addToBuyout: NoFunctionBooleanPromise,
  addItemsToBuyout: NoFunctionBooleanPromise,
  updateBuyoutItems: NoFunctionBooleanPromise,
  removeBuyoutItems: NoFunctionBooleanPromise,
  removeFromBuyout: NoFunctionBooleanPromise,
  loading: false,
  isEditable: false,
  setIsEditable: NoFunction,
  isNewBuyoutWithErrors: false,
  setIsNewBuyoutWithErrors: NoFunction,
  groupedByCostCode: false,
  setGroupedByCostCode: NoFunction,
  groupedStandaloneItemsByCostCode: false,
  setGroupedStandaloneItemsByCostCode: NoFunction,
  orderedSubtotal: new Decimal(0),
  orderedSalesTax: new Decimal(0),
  orderedTotal: new Decimal(0),
  receivedSubtotal: new Decimal(0),
  receivedSalesTax: new Decimal(0),
  receivedTotal: new Decimal(0),
  subtotal: new Decimal(0),
  salesTax: new Decimal(0),
  total: new Decimal(0),
  receivedStandaloneSubtotal: new Decimal(0),
  receivedStandaloneSalesTax: new Decimal(0),
  receivedStandaloneTotal: new Decimal(0),
  orderedStandaloneSubtotal: new Decimal(0),
  orderedStandaloneSalesTax: new Decimal(0),
  orderedStandaloneTotal: new Decimal(0),
  standaloneItems: [],
  toggleStandaloneCategory: NoFunction,
  setEditSalesTax: NoFunction,
  setEditAllowance: NoFunction,
  setIsSaleTaxAmount: NoFunction,
  editSalesTax: false,
  editAllowance: false,
  isSaleTaxAmount: false,
});

export const BuyoutGroupedProvider: FC<
  PropsWithChildren<{ buyout: BuyoutFieldsFragment }>
> = ({ children, buyout }) => {
  const [newBuyoutItem, setNewBuyoutItem] =
    useState<AddToBuyoutExtendedItemInput>({
      description: "",
      quantityDecimal: "0",
      projectItem: {
        estimateUom: "",
      },
      requestedUnitPrice: "0",
    });
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const [costCodes, setCostCodes] = useState<CostCodeCategory[]>([]);
  const [standaloneItems, setStandaloneItems] = useState<
    CategoryState<BuyoutStandaloneReleaseItemFieldsFragmentWithId>[]
  >([]);
  const { setValue, readValue } = useLocalStorage();
  const initialValue = readValue<boolean>(
    LOCAL_STORAGE_KEYS.GROUPED_BY_COST_CODE,
    !buyout.quoteDocument,
  );
  const [groupedByCostCode, setGroupedByCostCode] = useState(
    Boolean(initialValue),
  );
  const [
    groupedStandaloneItemsByCostCode,
    setGroupedStandaloneItemsByCostCode,
  ] = useState(Boolean(initialValue));
  const { calcExtPrice } = usePriceCalculation();
  const { toggleCategory } = useToggleCategory(costCodes, setCostCodes);
  const { toggleCategory: toggleStandaloneCategory } = useToggleCategory(
    standaloneItems,
    setStandaloneItems,
  );
  const {
    addToBuyout: addToBuyoutMutation,
    updateContractorBuyout: updateBuyoutMutation,
    removeFromBuyout: removeFromBuyoutMutation,
    loading,
  } = useBuyoutMutations();
  const [createOrgCatalogSkuMutation] = useCreateOrgCatalogSkuMutation();
  const { setError } = useGlobalError();
  const [isEditable, setIsEditable] = useState(
    buyout.status === BuyoutStatus.Draft,
  );
  const [isNewBuyoutWithErrors, setIsNewBuyoutWithErrors] = useState(false);
  const [editSalesTax, setEditSalesTax] = useState(false);
  const [editAllowance, setEditAllowance] = useState(false);
  const [isSaleTaxAmount, setIsSaleTaxAmount] = useState(
    !!buyout.customTaxAmount,
  );
  const { unassignedPhaseCode } = useUnspecifiedPhaseCode();
  const { hasPhaseCodes } = useOrgSettings();
  const {
    filterFullyReceived,
    filterExcludedFullyOrdered,
    filterSelectedCostCodes,
    filterSelectedTags,
  } = useBuyoutItemsFilters();

  const items = useMemo(
    () =>
      buyout.items
        .filter(filterFullyReceived)
        .filter(filterExcludedFullyOrdered)
        .filter(filterSelectedCostCodes)
        .filter(filterSelectedTags),
    [
      buyout.items,
      filterExcludedFullyOrdered,
      filterFullyReceived,
      filterSelectedCostCodes,
      filterSelectedTags,
    ],
  );
  const { filterBasedOnCostCodeAndPhaseCode, getProjectCodes } =
    useBuyoutItemsGrouping({
      items,
    });

  useEffect(() => {
    const projectCostCodes = getProjectCodes();

    if (
      newBuyoutItem.isAddMode &&
      !projectCostCodes.find(
        (costCode) => costCode.id === unassignedCostCode.id,
      )
    ) {
      projectCostCodes.push(unassignedCostCode);
    }

    const mappedCost: CostCodeCategory[] = projectCostCodes.map((costCode) => {
      const newCostCode = {
        id: costCode.id,
        name: costCode.description,
        isOpened: true,
      };
      return {
        ...newCostCode,
        items: [
          ...buyout.items
            .filter(filterFullyReceived)
            .filter(filterExcludedFullyOrdered)
            .filter((i) => filterBasedOnCostCodeAndPhaseCode(i, costCode)),
          ...(newBuyoutItem.isAddMode && costCode.id === unassignedCostCode.id
            ? [
                {
                  id: "",
                  unitPrice: newBuyoutItem.requestedUnitPrice,
                  quantityDecimal: newBuyoutItem.quantityDecimal,
                  projectItem: {
                    material: {
                      material: {
                        uom: {
                          id: newBuyoutItem.uomId || "",
                          pluralDescription:
                            newBuyoutItem.projectItem?.estimateUom,
                        },
                      },
                    },
                  },
                } as BuyoutItemFieldsFragment,
              ]
            : []),
        ],
      };
    });

    setCostCodes(mappedCost);

    const standaloneCostCodes = hasPhaseCodes
      ? [
          {
            id: unassignedPhaseCode.id,
            description: unassignedPhaseCode.description,
          },
        ]
      : getCostCodesByBuyoutItems(
          buyout?.standaloneReleaseItems || [],
          unassignedCostCode,
        );

    const mappedStandaloneCostCodes = standaloneCostCodes.map((costCode) => {
      const newCostCode = {
        id: costCode.id,
        name: costCode.description,
        isOpened: true,
      };
      return {
        ...newCostCode,
        items:
          buyout?.standaloneReleaseItems
            .filter(
              (i) =>
                hasPhaseCodes ||
                i.costCode?.id === costCode.id ||
                (!i.costCode && costCode.id === unassignedCostCode.id),
            )
            .map((i, key) => ({
              ...i,
              id: key.toString(),
            })) || [],
      };
    });

    setStandaloneItems(mappedStandaloneCostCodes);
  }, [
    newBuyoutItem,
    buyout.items,
    unassignedCostCode.id,
    unassignedCostCode.description,
    unassignedCostCode,
    filterFullyReceived,
    buyout?.standaloneReleaseItems,
    filterExcludedFullyOrdered,
    filterSelectedCostCodes,
    getProjectCodes,
    filterBasedOnCostCodeAndPhaseCode,
    hasPhaseCodes,
    unassignedPhaseCode.id,
    unassignedPhaseCode.description,
  ]);

  const addToBuyout = async () => {
    try {
      let orgCatalogSkuId = newBuyoutItem.projectItem?.orgCatalogSkuId;
      if (newBuyoutItem.orgCatalogSkuName) {
        const { data, errors: orgCatalogErrors } =
          await createOrgCatalogSkuMutation({
            variables: {
              input: {
                name: newBuyoutItem.orgCatalogSkuName,
                defaultUom: newBuyoutItem.projectItem?.estimateUom || "",
              },
            },
          });

        orgCatalogSkuId = data?.createOrgCatalogSku.id;

        setError(orgCatalogErrors);
      }

      const result = await addToBuyoutMutation({
        buyoutId: buyout.id,
        items: [
          {
            description: newBuyoutItem.description,
            quantityDecimal: newBuyoutItem.quantityDecimal || "1",
            manufacturerId: newBuyoutItem.manufacturerId,
            projectItem: {
              estimateUom: newBuyoutItem.projectItem?.estimateUom || "",
              orgCatalogSkuId,
              masterProductId: newBuyoutItem.projectItem?.masterProductId,
              masterSkuId: newBuyoutItem.projectItem?.masterSkuId,
            },
            requestedUnitPrice: newBuyoutItem.requestedUnitPrice,
            instructions: {
              text: newBuyoutItem.instructions?.text || "",
              assetUrls: newBuyoutItem.instructions?.assetUrls || [],
            },
            tags: newBuyoutItem.tags,
          },
        ],
      });

      return !!result;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const addItemsToBuyout = async (input: AddToBuyoutInput) => {
    try {
      return await addToBuyoutMutation(input);
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const updateBuyoutItems = async (input: UpdateContractorBuyoutInput) => {
    try {
      return await updateBuyoutMutation(input);
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const removeBuyoutItems = async (buyoutItemIds: string[]) => {
    try {
      return await removeFromBuyoutMutation({
        buyoutId: buyout.id,
        buyoutItemIds,
      });
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const removeFromBuyout = async (buyoutItemId: string) => {
    return await removeFromBuyoutMutation({
      buyoutId: buyout.id,
      buyoutItemIds: [buyoutItemId],
    });
  };

  const setGroupedByCostCodeAndUpdateLocalStorage = useCallback(
    (grouped: boolean) => {
      setGroupedByCostCode(grouped);
      setValue(LOCAL_STORAGE_KEYS.GROUPED_BY_COST_CODE, grouped);
    },
    [setValue],
  );

  const customTaxAmount = useMemo(
    () =>
      new Decimal(buyout?.customTaxAmount || 0).div(
        new Decimal(buyout.subtotal).add(
          buyout.additionalChargesAllowance || 0,
        ),
      ),
    [
      buyout.customTaxAmount,
      buyout.subtotal,
      buyout.additionalChargesAllowance,
    ],
  );

  const filteredItems = useMemo(
    () => buyout.items.filter(filterFullyReceived),
    [buyout.items, filterFullyReceived],
  );

  const subtotal = useMemo(
    () =>
      filteredItems.reduce(
        (acc, i) => acc.add(calcExtPrice(i.quantityDecimal, i.unitPrice)),
        new Decimal(buyout.additionalChargesAllowance || 0).toDP(2),
      ),
    [filteredItems, buyout.additionalChargesAllowance, calcExtPrice],
  );

  const salesTax = useMemo(
    () =>
      buyout.customTaxAmount
        ? customTaxAmount.mul(subtotal).toDP(2)
        : buyout.taxRate
          ? subtotal.mul(buyout.taxRate).toDP(2)
          : new Decimal(buyout.taxAmount || 0).toDP(2),
    [
      buyout.customTaxAmount,
      buyout.taxRate,
      buyout.taxAmount,
      customTaxAmount,
      subtotal,
    ],
  );

  const total = useMemo(
    () => subtotal.add(salesTax || 0),
    [subtotal, salesTax],
  );

  const allowance = useMemo(
    () =>
      filteredItems.some((item) =>
        new Decimal(item.releasedAmount || 0).toNumber(),
      )
        ? buyout.additionalChargesAllowance || 0
        : 0,
    [buyout.additionalChargesAllowance, filteredItems],
  );

  const orderedSubtotal = useMemo(
    () =>
      filteredItems.reduce(
        (acc, i) => acc.add(i.releasedAmount || 0),
        new Decimal(allowance).toDP(2),
      ),
    [filteredItems, allowance],
  );

  const orderedSalesTax = useMemo(
    () =>
      buyout.customTaxAmount
        ? customTaxAmount.mul(orderedSubtotal).toDP(2)
        : buyout.taxRate
          ? orderedSubtotal.mul(buyout.taxRate).toDP(2)
          : new Decimal(buyout.taxAmount || 0).toDP(2),
    [
      buyout.customTaxAmount,
      buyout.taxRate,
      buyout.taxAmount,
      customTaxAmount,
      orderedSubtotal,
    ],
  );

  const orderedTotal = useMemo(
    () => orderedSubtotal.add(orderedSalesTax || 0),
    [orderedSubtotal, orderedSalesTax],
  );

  const receivedSubtotal = useMemo(
    () =>
      filteredItems.reduce(
        (acc, i) => acc.add(new Decimal(i.completedAmount || 0)),
        new Decimal(allowance).toDP(2),
      ),
    [allowance, filteredItems],
  );

  const receivedSalesTax = useMemo(
    () =>
      buyout.customTaxAmount
        ? customTaxAmount.mul(receivedSubtotal).toDP(2)
        : buyout.taxRate
          ? receivedSubtotal.mul(buyout.taxRate).toDP(2)
          : new Decimal(buyout.taxAmount || 0).toDP(2),
    [
      buyout.customTaxAmount,
      buyout.taxRate,
      buyout.taxAmount,
      customTaxAmount,
      receivedSubtotal,
    ],
  );

  const receivedTotal = useMemo(
    () => receivedSubtotal.add(receivedSalesTax || 0),
    [receivedSubtotal, receivedSalesTax],
  );

  const receivedStandaloneSubtotal = useMemo(
    () =>
      buyout.standaloneReleaseItems.reduce(
        (acc, i) => acc.add(new Decimal(i.receivedSoFar || 0)),
        new Decimal(0),
      ),
    [buyout.standaloneReleaseItems],
  );

  const receivedStandaloneSalesTax = useMemo(
    () =>
      buyout.taxRate
        ? receivedStandaloneSubtotal.mul(buyout.taxRate).toDP(2)
        : new Decimal(buyout.taxAmount || 0).toDP(2),
    [buyout.taxRate, buyout.taxAmount, receivedStandaloneSubtotal],
  );

  const receivedStandaloneTotal = useMemo(
    () => receivedStandaloneSubtotal.add(receivedStandaloneSalesTax || 0),
    [receivedStandaloneSubtotal, receivedStandaloneSalesTax],
  );

  const orderedStandaloneSubtotal = useMemo(
    () =>
      buyout.standaloneReleaseItems.reduce(
        (acc, i) => acc.add(new Decimal(i.orderedSoFar || 0)),
        new Decimal(0),
      ),
    [buyout.standaloneReleaseItems],
  );

  const orderedStandaloneSalesTax = useMemo(
    () =>
      buyout.taxRate
        ? orderedStandaloneSubtotal.mul(buyout.taxRate).toDP(2)
        : new Decimal(buyout.taxAmount || 0).toDP(2),
    [buyout.taxRate, buyout.taxAmount, orderedStandaloneSubtotal],
  );

  const orderedStandaloneTotal = useMemo(
    () => orderedStandaloneSubtotal.add(orderedStandaloneSalesTax || 0),
    [orderedStandaloneSubtotal, orderedStandaloneSalesTax],
  );

  return (
    <Context.Provider
      value={{
        costCodes,
        newBuyoutItem,
        setNewBuyoutItem,
        toggleCategory,
        addToBuyout,
        addItemsToBuyout,
        updateBuyoutItems,
        removeBuyoutItems,
        removeFromBuyout,
        isEditable,
        setIsEditable,
        isNewBuyoutWithErrors,
        setIsNewBuyoutWithErrors,
        loading,
        groupedByCostCode,
        setGroupedByCostCode: setGroupedByCostCodeAndUpdateLocalStorage,
        groupedStandaloneItemsByCostCode,
        setGroupedStandaloneItemsByCostCode,
        orderedSubtotal,
        orderedSalesTax,
        orderedTotal,
        receivedSubtotal,
        receivedSalesTax,
        receivedTotal,
        subtotal,
        salesTax,
        total,
        receivedStandaloneSubtotal,
        receivedStandaloneSalesTax,
        receivedStandaloneTotal,
        orderedStandaloneSubtotal,
        orderedStandaloneSalesTax,
        orderedStandaloneTotal,
        standaloneItems,
        toggleStandaloneCategory,
        setEditSalesTax,
        setEditAllowance,
        setIsSaleTaxAmount,
        editSalesTax,
        editAllowance,
        isSaleTaxAmount,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useBuyoutGrouped = () => useContext(Context);
