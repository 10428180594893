import { Multiselect } from "@/common/components/select/components/multiple/Multiselect";
import { getUserName } from "@/common/utils/users/getUserName";
import { useUsers } from "@/contractor/pages/admin/users/components/providers/UsersProvider";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

type Props = {
  values: string[] | null | undefined;
  setFilter: (createdByIds: string[] | undefined) => void;
  applyHeaderAccent?: boolean;
};

export const SubmittedByFilter = ({
  values,
  setFilter,
  applyHeaderAccent = false,
}: Props) => {
  const intl = useIntl();
  const { users } = useUsers();

  const submittedByOptions = useMemo<{ id: string; name: string }[]>(
    () => users.map((user) => ({ id: user.id, name: getUserName(user) })),
    [users],
  );
  const setFilterHandler = useCallback(
    (values: string[] | null) => {
      setFilter(values?.length ? values : undefined);
    },
    [setFilter],
  );

  return (
    <Multiselect
      options={submittedByOptions}
      getLabel={(o) => o.name}
      getValue={(o) => o.id?.toString()}
      values={values}
      chipSize="small"
      includeCheckbox
      limitTags={1}
      disableCloseOnSelect
      className="min-w-45"
      placeholder={values?.length ? "" : intl.$t({ id: "ALL_USERS" })}
      onMultipleChange={setFilterHandler}
      applyHeaderAccent={applyHeaderAccent}
    />
  );
};
