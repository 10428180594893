import { RendererFunctionType } from "@/common/providers/ColumnMapperProvider";

import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { textRenderer } from "handsontable/renderers";
import { useCallback } from "react";
import { useRenderHelpers } from "./useRenderHelpers";

export const useCostCodeRenderer = () => {
  const { setProjectId, costCodeIsNotSupported } = useProjectCostCodes();
  const { checkReadOnly, applyTooltip, applyClasses } = useRenderHelpers();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      const metadata = instance.getCellMeta(row, col).metadata;
      if (metadata?.projectId) {
        setProjectId(metadata?.projectId as string);
      }

      if (value && costCodeIsNotSupported(value)) {
        applyTooltip(td, costCodeIsNotSupported(value) || "");
        applyClasses(
          td,
          "htCenter htMiddle text-red-500 bg-red-100 border-gray-200",
        );
      } else {
        applyClasses(td, "");
        applyTooltip(td, "");
      }
      textRenderer(instance, td, row, col, prop, value, cellProperties);
      checkReadOnly(td, row, instance, cellProperties);
    },
    [
      checkReadOnly,
      setProjectId,
      applyTooltip,
      applyClasses,
      costCodeIsNotSupported,
    ],
  );

  return renderer;
};
