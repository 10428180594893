import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { Multiselect } from "@/common/components/select/components/multiple/Multiselect";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { Check, Close } from "@mui/icons-material";
import { createRef, FC, useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceSequence } from "../../providers/InvoiceSequenceProvider";
import { useInvoices } from "../../providers/InvoicesProvider";
import { useInvoiceFolders } from "../invoice-folder-picker/hooks/useInvoiceFolders";

const Container = tw.div``;

type Props = {
  removing?: boolean;
  onClose: () => void;
};

export const InvoiceFolderSelector: FC<Props> = ({ removing, onClose }) => {
  const { invoiceFolders } = useOrgSettings();
  const { sequenceIds } = useInvoiceSequence();
  const { createFolder } = useInvoiceFolders();
  const { updateInvoices } = useInvoices();
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const ref = createRef<HTMLDivElement>();
  const [inputValue, setInputValue] = useState<string>();
  const [selectedFoldersId, setSelectedFoldersId] = useState<string[]>([]);

  const updateFolders = useCallback((folderIds: string[] | null) => {
    setSelectedFoldersId(folderIds ?? []);
  }, []);

  const options = useMemo(() => {
    return (
      invoiceFolders?.toSorted((a, b) =>
        (a.name || "").localeCompare(b.name || ""),
      ) ?? []
    );
  }, [invoiceFolders]);

  const saveChanges = useCallback(async () => {
    if (!selectedFoldersId.length) {
      if (inputValue) {
        const folder = await createFolder({ name: inputValue });
        if (folder) {
          updateInvoices({
            invoiceIds: sequenceIds,
            assignedFolderIds: [folder],
          });
        }
      }

      onClose();
      return;
    }

    if (removing) {
      updateInvoices({
        invoiceIds: sequenceIds,
        unassignedFolderIds: selectedFoldersId,
      });
    } else {
      updateInvoices({
        invoiceIds: sequenceIds,
        assignedFolderIds: selectedFoldersId,
      });
    }
    onClose();
  }, [
    sequenceIds,
    selectedFoldersId,
    updateInvoices,
    removing,
    onClose,
    createFolder,
    inputValue,
  ]);

  return (
    <>
      <Container ref={ref}>
        <Multiselect
          testId="selectGroup"
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          placeholder={
            open || selectedFoldersId.length
              ? ""
              : intl.$t({ id: "SELECT_GROUP" })
          }
          inputProps={{
            placeholder: removing
              ? intl.$t({ id: "SEARCH_FOR_GROUP" })
              : intl.$t({ id: "SEARCH_OR_ADD_NEW" }),
          }}
          options={options}
          getLabel={(option) => option.name}
          getValue={(option) => option.id}
          values={selectedFoldersId}
          onMultipleChange={updateFolders}
          onInputChange={(value) => setInputValue(value)}
          includeCheckbox
          disableCloseOnSelect
          creatable={!removing}
        />
      </Container>
      <IconButtonBorderless
        className="h-6 w-6 text-white"
        onClick={saveChanges}
      >
        <Check />
      </IconButtonBorderless>
      <IconButtonBorderless
        className="mr-2 h-6 w-6 text-white"
        onClick={onClose}
      >
        <Close />
      </IconButtonBorderless>
    </>
  );
};
