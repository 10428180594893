import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
  selectedAction: number;
  setSelectedAction: (action: number) => void;
  reset: () => void;
};

export const useSideActionsStore = create<State>()(
  devtools((set) => ({
    selectedAction: 0,
    setSelectedAction: (action: number) =>
      set(() => ({
        selectedAction: action,
      })),
    reset: () => set(() => ({ selectedAction: -1 })),
  })),
);
