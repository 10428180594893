import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { PageBackButton } from "@/common/components/page-back-button/PageBackButton";
import { useOrderTypesConfig } from "@/common/hooks/order-types-config/useOrderTypesConfig";
import { routes } from "@/config/routes";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  AuthorizationStatus,
  CancelReleaseInput,
  ReleaseStatus,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { ReceiveReleaseDialog } from "../../../releases/pages/deliveries/components/receive-release-dialog/ReceiveReleaseDialog";
import { useReleaseSequence } from "../../../releases/pages/deliveries/providers/ReleaseSequenceProvider";
import { useAddDeliverySlipStore } from "../../../releases/pages/delivery-slips/store/useAddDeliverySlipStore";
import { useHasPartialSelection } from "../../hooks/useHasPartialSelection";
import { useReleaseActions } from "../../providers/ReleaseActionsProvider";
import { ReceiptPosted } from "./ReceiptPosted";

const GroupContainer = tw.div`
  flex justify-end gap-2
`;

const Container = tw.div`
  grid justify-end mt-20 mb-5 gap-4
`;

const ReceiptPostedStyled = tw(ReceiptPosted)`mr-2`;

export const ReleaseDetailsFooter: FC = () => {
  const { release } = useRelease();
  const navigate = useNavigate();
  const { openDialog } = useDialog();
  const { cancelRelease, archiveRelease } = useReleaseActions();
  const intl = useIntl();
  const { hasPartialSelection } = useHasPartialSelection(release);
  const { navigateToNextSequence } = useReleaseSequence();
  const { orderTypeConfig } = useOrderTypesConfig({ release });
  const { setAddSlipVisible, setSlipRelease } = useAddDeliverySlipStore();

  const makeChanges = useCallback(() => {
    navigate(
      generatePath(routes.specifyDeliveryDetails, { deliveryId: release?.id }),
    );
  }, [navigate, release?.id]);

  const cancel = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "PROCEED" }),
      icon: <WarningIcon />,
      title: intl.$t({ id: "DELIVERY_CANCELED_TITLE" }),
      handleConfirm: async () => {
        await cancelRelease({
          releaseId: release?.id ?? "",
          version: release?.version ?? 0,
        } as CancelReleaseInput);
        navigateToNextSequence();
      },
    });
  }, [
    cancelRelease,
    intl,
    navigateToNextSequence,
    openDialog,
    release?.id,
    release?.version,
  ]);

  const archive = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "PROCEED" }),
      icon: <WarningIcon />,
      title: intl.$t({ id: "ARCHIVE_ORDER_DETAILS" }),
      handleConfirm: async () => {
        const result = await archiveRelease(release?.id || "");
        if (result) {
          navigateToNextSequence({ navigateToReleases: true });
        }
      },
    });
  }, [archiveRelease, navigateToNextSequence, intl, openDialog, release?.id]);

  const goBack = useCallback(() => {
    navigate(routes.deliveries);
  }, [navigate]);

  if (!release) {
    return null;
  }

  return (
    <>
      <Container>
        <FloatingFooter>
          <GroupContainer>
            <ReceiptPostedStyled release={release} />
            <PageBackButton />
            <If
              isTrue={
                release?.status !== ReleaseStatus.Canceled &&
                release?.status !== ReleaseStatus.Received &&
                release?.status !== ReleaseStatus.PartiallyReceived &&
                release?.status !== ReleaseStatus.Draft
              }
            >
              <OutlinedButton onClick={cancel}>
                <FormattedMessage id="CANCEL_RELEASE" />
              </OutlinedButton>
            </If>

            <If
              isTrue={
                (release?.status === ReleaseStatus.Draft ||
                  release?.status === ReleaseStatus.Canceled) &&
                !release.deletedAt &&
                release.permissions.archive === AuthorizationStatus.Authorized
              }
            >
              <OutlinedButton onClick={archive}>
                <FormattedMessage id="ARCHIVE_ORDER" />
              </OutlinedButton>
            </If>

            <If
              isTrue={
                release?.status === ReleaseStatus.Canceled ||
                !!release?.poLink?.immutable
              }
            >
              <OutlinedButton onClick={goBack}>
                <FormattedMessage id="GO_BACK_TO_DELIVERIES" />
              </OutlinedButton>
            </If>

            <If
              isTrue={
                ((release?.status !== ReleaseStatus.Canceled &&
                  !release?.deletedAt) ||
                  release?.status === ReleaseStatus.AwaitingApproval ||
                  release?.status === ReleaseStatus.Rejected) &&
                !release?.poLink?.immutable
              }
            >
              <OutlinedButton onClick={makeChanges}>
                <FormattedMessage
                  id={
                    release.status !== ReleaseStatus.AwaitingApproval
                      ? "MAKE_CHANGES"
                      : "REVIEW"
                  }
                />
              </OutlinedButton>
            </If>

            <If
              isTrue={
                release?.status === ReleaseStatus.Scheduled ||
                release.status === ReleaseStatus.PartiallyReceived
              }
            >
              <OutlinedButton
                onClick={() => {
                  setSlipRelease(release);
                  setAddSlipVisible(true);
                }}
                testId="receive-release"
              >
                {hasPartialSelection ? (
                  <FormattedMessage id="CONFIRM_PARTIAL_RELEASE" />
                ) : (
                  orderTypeConfig.labels.buttons.complete
                )}
              </OutlinedButton>
            </If>
          </GroupContainer>
        </FloatingFooter>
      </Container>
      <ReceiveReleaseDialog />
    </>
  );
};
