import tw from "tailwind-styled-components";
import { InvoiceFooterState } from "./types/InvoiceFooterState";

export const MatchGroup = tw.div<{
  $hasFooter: boolean;
  $editState?: InvoiceFooterState;
}>`
    grid grid-cols-[2.5fr_2fr] gap-4
    ${({ $hasFooter, $editState }) =>
      !$hasFooter
        ? "h-[calc(100vh-200px)]"
        : !$editState ||
            [
              InvoiceFooterState.DEFAULT,
              InvoiceFooterState.EDIT_INVOICE_COVERAGES,
              InvoiceFooterState.CREATE_ORDER,
              InvoiceFooterState.MATCH_ORDER,
              InvoiceFooterState.EDIT_ORDER,
              InvoiceFooterState.IMPORT_ORDER,
            ].includes($editState)
          ? "h-[calc(100vh-240px)]"
          : "h-[calc(100vh-400px)]"}
  `;
