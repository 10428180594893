import { useExportToCsv } from "@/common/components/csv-export/hooks/useExportToCsv";
import { useExportToCsvInvoiceData } from "@/common/components/csv-export/hooks/useExportToCsvInvoiceData";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { SideActionBar } from "@/common/components/side-action-bar/SideActionBar";
import { useSideActionsStore } from "@/common/components/side-action-bar/useSideActionsStore";
import { SystemAlertType } from "@/common/components/system-alert/SystemAlert";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { goToExternalUrl } from "@/common/utils/browserUtils";
import { DeleteOutline, Download } from "@mui/icons-material";
import { useCallback, useMemo, useState } from "react";
import { HiOutlineFolderAdd, HiOutlineFolderRemove } from "react-icons/hi";
import { PiFileCsv } from "react-icons/pi";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoicesDownload } from "../../../invoice-verification/hooks/useInvoicesDownload";
import { useInvoiceSequence } from "../../providers/InvoiceSequenceProvider";
import { useInvoices } from "../../providers/InvoicesProvider";
import { AnimatedLoading } from "../splitting-invoices/steps/AnimatedLoading";
import { InvoiceFolderSelector } from "./InvoiceFolderSelector";

const AnimationContainer = tw.div`w-96 h-56`;
const AnimationTextView = tw.div`flex flex-col items-center justify-center`;
const AnimationTitle = tw.div`font-medium text-xl`;
const AnimationText = tw.div``;

export const InvoicesSideActions = () => {
  const intl = useIntl();
  const { setSystemAlert } = useSnackbar();
  const { openDialog, cancelDialog } = useDialog();
  const { sequenceIds, selectedInvoices, setSelectedInvoices } =
    useInvoiceSequence();
  const { setSelectedAction } = useSideActionsStore();
  const { archiveInvoices } = useInvoices();
  const { downloadInvoices } = useInvoicesDownload();
  const { exportToCsv } = useExportToCsv();
  const { getInvoiceExportToCsvData } = useExportToCsvInvoiceData();

  const [loading, setLoading] = useState(false);

  const downloadItems = useCallback(async () => {
    let cancelled = false;
    if (selectedInvoices.length) {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        content: (
          <AnimationContainer>
            <AnimatedLoading
              loading
              text={
                <AnimationTextView>
                  <FormattedMessage id="DOWNLOADING" tagName={AnimationTitle} />
                  <FormattedMessage
                    id="INVOICES_COUNT"
                    values={{ count: selectedInvoices.length }}
                    tagName={AnimationText}
                  />
                </AnimationTextView>
              }
              className="mt-25 h-5/6"
            />
          </AnimationContainer>
        ),
        handleCancel: () => {
          cancelled = true;
          cancelDialog?.();
        },
      });
      const invoicesDownloadLink = await downloadInvoices(
        selectedInvoices.map((invoice) => ({
          invoiceId: invoice.id,
          version: invoice.version,
        })),
      );
      if (!cancelled && invoicesDownloadLink) {
        goToExternalUrl(invoicesDownloadLink);
        setSystemAlert(
          intl.$t(
            { id: "INVOICES_DOWNLOAD_SUCCESS" },
            { count: selectedInvoices.length },
          ),
          { type: SystemAlertType.MANUAL },
        );
      }
      cancelDialog?.();
    }
  }, [
    selectedInvoices,
    intl,
    downloadInvoices,
    cancelDialog,
    openDialog,
    setSystemAlert,
  ]);

  const exportItemsToCsv = useCallback(() => {
    const { data, fileName, headers } =
      getInvoiceExportToCsvData(selectedInvoices);

    exportToCsv({
      data,
      fileName,
      headers,
    });

    setSystemAlert(
      intl.$t(
        { id: "INVOICES_EXPORT_CSV_SUCCESS" },
        { count: selectedInvoices.length },
      ),
      { type: SystemAlertType.MANUAL },
    );
  }, [
    getInvoiceExportToCsvData,
    selectedInvoices,
    exportToCsv,
    setSystemAlert,
    intl,
  ]);

  const deleteItems = useCallback(() => {
    if (sequenceIds) {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "PROCEED" }),
        icon: <WarningIcon />,
        title: intl.$t({ id: "DELETE" }),
        text: intl.$t(
          { id: "DELETE_INVOICES_MESSAGE_WITH_NUMBER" },
          { number: sequenceIds.length },
        ),
        handleConfirm: async () => {
          setLoading(true);
          await archiveInvoices(sequenceIds);
          setLoading(false);
          setSelectedInvoices([]);
        },
      });
    }
  }, [
    sequenceIds,
    setLoading,
    setSelectedInvoices,
    archiveInvoices,
    intl,
    openDialog,
  ]);

  const actions = useMemo(() => {
    return [
      {
        icon: <HiOutlineFolderAdd className="h-6 w-6" />,
        tooltipKey: "ADD_TO_GROUP",
        component: (
          <InvoiceFolderSelector
            onClose={() => {
              setSelectedAction(-1);
            }}
          />
        ),
        preventClose: true,
      },
      {
        icon: <HiOutlineFolderRemove className="h-6 w-6" />,
        tooltipKey: "REMOVE_FROM_GROUP",
        component: (
          <InvoiceFolderSelector
            removing
            onClose={() => {
              setSelectedAction(-1);
            }}
          />
        ),
        preventClose: true,
      },
      {
        icon: <Download />,
        tooltipKey: "DOWNLOAD_INVOICES",
        callback: downloadItems,
      },
      {
        icon: <PiFileCsv className="h-6 w-6" />,
        tooltipKey: "EXPORT_TO_CSV",
        callback: exportItemsToCsv,
      },
      {
        icon: <DeleteOutline />,
        tooltipKey: "DELETE_ITEMS",
        callback: deleteItems,
      },
    ];
  }, [deleteItems, downloadItems, exportItemsToCsv, setSelectedAction]);

  return (
    <SideActionBar
      loading={loading}
      visible={sequenceIds.length > 0}
      actions={actions}
    />
  );
};
