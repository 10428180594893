import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { If } from "@/common/components/if/If";
import { InvoiceDocumentType } from "@/common/components/invoices/invoice-issue/common/InvoiceIssue";
import { InvoiceIssues } from "@/common/components/invoices/invoice-issue/InvoiceIssues";
import {
  ItemizedNonItemizedToggle,
  ViewType,
} from "@/common/components/itemized-non-itemized-toggle/ItemizedNonItemizedToggle";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { useAddReceiptItems } from "@/common/hooks/add-missing-items-to-order/useAddReceiptItems";
import { routes } from "@/config/routes";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useReceipt } from "../../providers/ReceiptProvider";
import { ReceiptCreateReleaseHeader } from "./ReceiptCreateReleaseHeader";

const Header = tw.div`flex flex-col`;
const OrderSelectorGroup = tw.div`grid content-start gap-3 pt-2`;
const OrderNumberHeader = tw.div`flex gap-1 text-base font-medium`;
const Group = tw.div`flex flex-row items-center justify-between first:px-4 mb-3`;
const ButtonContainer = tw.div`flex flex-row items-center gap-2`;

export const ReceiptOrderHeader: FC = () => {
  const { itemized, setItemized, hasReleaseRecorded, receipt, refetch } =
    useReceipt();
  const intl = useIntl();
  const navigate = useNavigate();
  const { addReceiptItems } = useAddReceiptItems();

  return (
    <Header>
      <Group>
        <OrderSelectorGroup>
          <OrderNumberHeader>
            <If isTrue={hasReleaseRecorded}>
              <LinkLike
                onClick={() =>
                  navigate(
                    generatePath(routes.delivery, {
                      deliveryId: receipt?.release?.id,
                    }),
                  )
                }
                className="ml-1 text-base font-semibold"
              >
                {intl.$t(
                  { id: "ORDER_WITH_NUMBER" },
                  {
                    orderNumber: receipt?.release?.sequenceNumber,
                  },
                )}
              </LinkLike>
            </If>
            <FormattedMessage
              id={
                hasReleaseRecorded
                  ? "CREATED_FROM_RECEIPT"
                  : "RECORD_ORDER_FROM_RECEIPT"
              }
              values={{
                number: receipt?.release?.sequenceNumber,
              }}
            />
          </OrderNumberHeader>
        </OrderSelectorGroup>
        <ButtonContainer>
          <If isTrue={itemized}>
            <OutlinedButton $small onClick={() => addReceiptItems()}>
              <FormattedMessage id="ADD_ITEMS_FROM_RECEIPT" />
            </OutlinedButton>
          </If>
          <ItemizedNonItemizedToggle
            handleChange={(view) => setItemized(view === ViewType.Itemized)}
            isItemized={itemized}
            size="large"
          />
        </ButtonContainer>
      </Group>
      <Group>
        <ReceiptCreateReleaseHeader />
      </Group>
      <InvoiceIssues
        invoice={receipt}
        refetchInvoice={refetch}
        documentType={InvoiceDocumentType.RECEIPT}
      />
    </Header>
  );
};
