import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useUser } from "@/common/providers/UserProvider";
import { routes } from "@/config/routes";
import { useReleaseSequence } from "@/contractor/pages/home/releases/pages/deliveries/providers/ReleaseSequenceProvider";
import { useAddDeliverySlipStore } from "@/contractor/pages/home/releases/pages/delivery-slips/store/useAddDeliverySlipStore";
import {
  AuthorizationStatus,
  ReleaseFieldsFragment,
  ReleaseReassignmentFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import {
  ReleaseErrorType,
  useReleaseActions,
} from "../../../providers/ReleaseActionsProvider";
import { ExpandedReleaseItem } from "../../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../../providers/ReleaseUpdateProvider";
import { useReleaseStore } from "../../../store/useReleaseStore";
import { useSyncReleaseItems } from "../hooks/useSyncReleaseItems";

type CallbackProps = {
  skip?: boolean;
  receive?: boolean;
  addDeliveryRecord?: boolean;
  version?: number;
  callback?: (
    result: boolean,
    vendorReassignmentReleases?: ReleaseReassignmentFieldsFragment[],
  ) => void | boolean;
};

type ButtonType = {
  label: string;
  canBypassVendorConfirmation: boolean;
  onClick: (
    opt?: { skipConfirmation: boolean } | undefined,
  ) => Promise<void> | void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback?: (opt: CallbackProps) => Promise<any> | void | boolean;
};

type Props = {
  release: ReleaseFieldsFragment;
  items: ExpandedReleaseItem[];
  setSubmitDialogVisible?: (visible: boolean) => void;
  setUpdateDialogVisible?: (visible: boolean) => void;
  overrideNavigationFn?: () => void;
};

export const useSubmitReleaseButton = ({
  release,
  items,
  setSubmitDialogVisible,
  setUpdateDialogVisible,
  overrideNavigationFn,
}: Props) => {
  const {
    submitDraftRelease,
    approveRelease,
    requestedDate,
    vendorId,
    setInputError,
    buyoutItemIds,
  } = useReleaseActions();
  const { setSuccessAlert, setWarningAlert } = useSnackbar();
  const { submitUpdate, changes } = useReleaseUpdate();

  const { hasChanges } = useReleaseStore();
  const intl = useIntl();
  const navigate = useNavigate();
  const { setAddSlipVisible, setSlipRelease } = useAddDeliverySlipStore();

  const { viewer } = useUser();
  const { getReleaseUpdateInput, validateItems } = useSyncReleaseItems();
  const itemsRef = useRef(items);
  const { navigateToNextSequence } = useReleaseSequence();
  const { spreadsheetData } = useColumnMapper();
  const { rowIsEmpty } = useTableHelpers();

  useEffect(() => {
    itemsRef.current = items;
  }, [items]);

  const checkBeforeSubmit = useCallback(() => {
    if (!validateItems()) {
      return false;
    }

    if (
      spreadsheetData.every((data) => rowIsEmpty(data)) &&
      itemsRef.current.length === 0
    ) {
      setWarningAlert(intl.$t({ id: "NO_RELEASE_ITEMS" }));
      return false;
    }
    if (
      itemsRef.current.find((item) =>
        buyoutItemIds.includes(item.buyoutItem?.id ?? ""),
      )
    ) {
      setInputError(ReleaseErrorType.INVALID_QUANTITIES);
      setWarningAlert(intl.$t({ id: "INVALID_QUANTITIES" }));
      return false;
    }

    if (
      release?.status === ReleaseStatus.Draft
        ? false
        : !!changes?.vendorContactIds &&
          changes.vendorContactIds.length === 0 &&
          (
            release?.preferredVendor?.contacts?.filter(
              (contact) => contact.receivesOrderNotifications,
            ) ?? []
          ).length > 0
    ) {
      setInputError(ReleaseErrorType.VENDOR_CONTACT);
      setWarningAlert(intl.$t({ id: "INVALID_VENDOR_CONTACTS" }));
      return false;
    }

    if (
      itemsRef.current.length &&
      !itemsRef.current.find(
        (item) =>
          !item.sellerOrgLocation ||
          item.sellerOrgLocation?.id === release.sellerOrgLocation?.id,
      )
    ) {
      setInputError(ReleaseErrorType.ALL_ITEMS_ASSIGNED_TO_OTHER_VENDORS);
      setWarningAlert(intl.$t({ id: "ALL_ITEMS_ASSIGNED_TO_OTHER_VENDORS" }));
      return false;
    }

    if (itemsRef.current.find((item) => !item.uom)) {
      setInputError(ReleaseErrorType.REQUESTED_UOM);
      setWarningAlert(
        intl.$t({ id: "DELIVERY_SUBMIT_WARNING_NO_REQUESTED_UOM" }),
      );
      return false;
    }

    if (
      !vendorId &&
      !release?.sellerOrgLocation?.id &&
      (release.permissions.submitDirectly === AuthorizationStatus.Authorized ||
        release.status === ReleaseStatus.AwaitingApproval)
    ) {
      setInputError(ReleaseErrorType.VENDOR);
      setWarningAlert(intl.$t({ id: "DELIVERY_SUBMIT_WARNING_NO_VENDOR" }));
      return false;
    }
    return true;
  }, [
    validateItems,
    spreadsheetData,
    release?.preferredVendor?.contacts,
    changes?.vendorContactIds,
    release.sellerOrgLocation?.id,
    release.permissions.submitDirectly,
    release.status,
    vendorId,
    rowIsEmpty,
    setWarningAlert,
    intl,
    buyoutItemIds,
    setInputError,
  ]);

  const retroactiveRelease = useMemo(() => {
    return !!release.time && new Date(release.time || 0) < new Date();
  }, [release.time]);

  const navigateAfterSubmit = useCallback(
    (
      result: boolean | ReleaseReassignmentFieldsFragment[],
      vendorReassignmentReleases: ReleaseReassignmentFieldsFragment[] = [],
    ) => {
      if (overrideNavigationFn) {
        overrideNavigationFn();
        return;
      }

      if (result) {
        if (typeof result === "boolean" || vendorReassignmentReleases.length) {
          const currentIndex = vendorReassignmentReleases.findIndex(
            (r) => r.id === release?.id,
          );
          if (
            currentIndex !== -1 &&
            currentIndex < vendorReassignmentReleases.length - 1
          ) {
            navigate(
              generatePath(routes.specifyDeliveryDetails, {
                deliveryId: vendorReassignmentReleases[currentIndex + 1].id,
              }),
            );
          } else {
            navigateToNextSequence({
              navigateToReleases: false,
              navigateToReleaseId: release?.id,
            });
          }
        } else {
          navigate(
            generatePath(routes.specifyDeliveryDetails, {
              deliveryId: result[0].id,
            }),
          );
        }
      }
    },
    [navigate, navigateToNextSequence, overrideNavigationFn, release?.id],
  );

  const updateRelease = useCallback(
    async (skipConfirmation: boolean = false) => {
      const input = await getReleaseUpdateInput();
      submitUpdate(release?.version, {
        skipConfirmation,
        forceVersion: true,
        input,
      });
    },
    [getReleaseUpdateInput, release?.version, submitUpdate],
  );

  return useMemo<ButtonType | undefined>(() => {
    switch (release.status) {
      case ReleaseStatus.Draft:
      case ReleaseStatus.Reserved:
        if (retroactiveRelease) {
          return {
            label: "SAVE_ORDER",
            canBypassVendorConfirmation: true,
            callback: async ({
              receive,
              version,
              addDeliveryRecord,
              callback,
            }: CallbackProps) => {
              if (
                (requestedDate || release.time) &&
                receive &&
                (release?.status === ReleaseStatus.Draft ||
                  release?.status === ReleaseStatus.Reserved)
              ) {
                if (checkBeforeSubmit()) {
                  await updateRelease(true);
                }
                submitDraftRelease(
                  {
                    skipConfirmation: true,
                    retroactive: true,
                    version,
                  },
                  undefined,
                  (result) => {
                    if (result?.data?.submitRelease) {
                      callback?.(true);
                      setTimeout(() => {
                        if (addDeliveryRecord) {
                          setSlipRelease(release);
                          setAddSlipVisible(true);
                        } else {
                          setSuccessAlert(
                            intl.$t({ id: "SUBMITTED_NEW_RELEASE" }),
                          );
                          navigateAfterSubmit(true);
                        }
                      }, DIALOG_AUTO_CLOSE_TIMER);
                    } else {
                      callback?.(false);
                    }
                  },
                );
              } else {
                setSubmitDialogVisible?.(true);
              }
            },
            onClick: () => {
              if (checkBeforeSubmit() && setSubmitDialogVisible) {
                setSubmitDialogVisible(true);
              }
            },
          };
        } else if (
          release.permissions.submitDirectly === AuthorizationStatus.Authorized
        ) {
          return {
            label: "SUBMIT",
            canBypassVendorConfirmation: true,
            onClick: () => {
              if (checkBeforeSubmit() && setSubmitDialogVisible) {
                setSubmitDialogVisible(true);
              }
            },
            callback: ({ skip, version, callback }: CallbackProps) => {
              submitDraftRelease(
                {
                  skipConfirmation: skip || false,
                  version,
                },
                undefined,
                (result, vendorReassignmentReleases) => {
                  if (result?.data) {
                    callback?.(true, vendorReassignmentReleases);
                  } else {
                    callback?.(false);
                  }
                },
              );
            },
          };
        } else {
          return {
            label: "SUBMIT_FOR_APPROVAL",
            canBypassVendorConfirmation: false,
            onClick: async (
              { skipConfirmation } = { skipConfirmation: false },
            ) => {
              if (!checkBeforeSubmit()) {
                return;
              }
              await updateRelease(skipConfirmation);
              if (!requestedDate) {
                setInputError(ReleaseErrorType.REQUESTED_FULFILLMENT_DATE);
                setWarningAlert(
                  intl.$t({ id: "DELIVERY_SUBMIT_WARNING_NO_RELEASE_DATE" }),
                );
                return;
              }

              submitDraftRelease(
                {
                  skipConfirmation,
                  version: release?.version,
                },
                undefined,
                (result, vendorReassignmentReleases) => {
                  if (result) {
                    setSuccessAlert(intl.$t({ id: "SUBMITTED_FOR_APPROVAL" }));
                    navigateAfterSubmit(
                      result.data?.submitRelease.vendorReassignmentReleases
                        .length
                        ? result.data.submitRelease.vendorReassignmentReleases
                        : true,
                      vendorReassignmentReleases,
                    );
                  }
                },
              );
            },
          };
        }
      case ReleaseStatus.AwaitingApproval:
        if (release.approvalProgress.canApprove) {
          if (release.submittedBy?.id === viewer?.id) {
            return {
              label: "UPDATE_RELEASE",
              canBypassVendorConfirmation: false,
              onClick: async () => {
                const input = await getReleaseUpdateInput();
                submitUpdate(release?.version, {
                  skipConfirmation: true,
                  forceVersion: true,
                  callback: (result) => {
                    if (result) {
                      setSuccessAlert(intl.$t({ id: "UPDATED_RELEASE" }));
                      navigate(
                        generatePath(routes.delivery, {
                          deliveryId: release.id,
                        }),
                      );
                    }
                  },
                  input,
                });
              },
            };
          } else if (
            !release.approvals.find((a) => a.approver.id === viewer?.id)
          ) {
            return {
              label: release.approvalProgress.canApproveImmediately
                ? "APPROVE_AND_SUBMIT"
                : "APPROVE_ORDER",
              canBypassVendorConfirmation: true,
              callback: ({ skip, callback, version }: CallbackProps) => {
                if (!version) {
                  return false;
                }
                return approveRelease(
                  {
                    releaseId: release.id,
                    version: version ?? undefined,
                    skipConfirmation: skip,
                  },
                  (result, vendorReassignmentReleases) => {
                    callback?.(!result.errors, vendorReassignmentReleases);
                  },
                );
              },
              onClick: async (opt) => {
                if (checkBeforeSubmit()) {
                  await updateRelease(opt?.skipConfirmation);
                  if (
                    setSubmitDialogVisible &&
                    release.approvalProgress.canApproveImmediately
                  ) {
                    setSubmitDialogVisible(true);
                  } else {
                    approveRelease(
                      {
                        releaseId: release.id,
                        version: release?.version,
                        skipConfirmation: true,
                      },
                      (result, vendorReassignmentReleases) => {
                        if (result) {
                          navigateAfterSubmit(
                            result.data?.approveRelease
                              .vendorReassignmentReleases.length
                              ? result.data.approveRelease
                                  .vendorReassignmentReleases
                              : true,
                            vendorReassignmentReleases,
                          );
                        }
                      },
                    );
                  }
                }
              },
            };
          }
        }
        break;
      case ReleaseStatus.Rejected:
        if (release.permissions.submit === AuthorizationStatus.Authorized) {
          return {
            label: "RESUBMIT_FOR_APPROVAL",
            canBypassVendorConfirmation: false,
            onClick: async () => {
              if (checkBeforeSubmit()) {
                await updateRelease();
                submitDraftRelease(
                  {
                    version: release?.version,
                    skipConfirmation: false,
                  },
                  undefined,
                  (result, vendorReassignmentReleases) => {
                    if (result) {
                      setSuccessAlert(intl.$t({ id: "SUBMITTED_NEW_RELEASE" }));
                      navigateAfterSubmit(
                        result.data?.submitRelease.vendorReassignmentReleases
                          .length
                          ? result.data?.submitRelease
                              .vendorReassignmentReleases
                          : true,
                        vendorReassignmentReleases,
                      );
                    }
                  },
                );
              }
            },
          };
        }
        if (
          release.permissions.submitDirectly === AuthorizationStatus.Authorized
        ) {
          return {
            label: "SUBMIT",
            canBypassVendorConfirmation: false,
            onClick: () => {
              if (checkBeforeSubmit() && setSubmitDialogVisible) {
                setSubmitDialogVisible(true);
              }
            },
            callback: ({ skip, version, callback }: CallbackProps) => {
              submitDraftRelease(
                {
                  skipConfirmation: skip || false,
                  version,
                },
                undefined,
                (result, vendorAssignments) => {
                  if (result?.data) {
                    callback?.(true, vendorAssignments);
                  } else {
                    callback?.(false);
                  }
                },
              );
            },
          };
        }
        break;
      case ReleaseStatus.Scheduled:
      case ReleaseStatus.Requested:
        if (release.permissions.edit) {
          return {
            label: "UPDATE_RELEASE",
            canBypassVendorConfirmation:
              release.status !== ReleaseStatus.Requested,
            onClick: () => {
              if (checkBeforeSubmit() && setUpdateDialogVisible && hasChanges) {
                setUpdateDialogVisible(true);
              }
            },
            callback: ({ skip, version, callback } = { skip: true }) => {
              return submitUpdate(version, {
                skipConfirmation: skip,
                forceVersion: true,
                callback: (result) => {
                  if (result) {
                    callback?.(result);
                    navigateAfterSubmit(true);
                  }
                },
              });
            },
          };
        }
        break;
      case ReleaseStatus.Received:
      case ReleaseStatus.PartiallyReceived:
        if (release.permissions.edit) {
          return {
            label: "UPDATE_RELEASE",
            canBypassVendorConfirmation: false,
            onClick: () => {
              if (checkBeforeSubmit() && setUpdateDialogVisible && hasChanges) {
                setUpdateDialogVisible(true);
              }
            },
            callback: ({ version } = { skip: true }) => {
              return submitUpdate(version, {
                skipConfirmation: true,
                forceVersion: true,
                callback: (result) => {
                  if (result) {
                    setSuccessAlert(intl.$t({ id: "UPDATED_RELEASE" }));
                    navigateAfterSubmit(true);
                  }
                },
              });
            },
          };
        }
        break;
    }
  }, [
    release,
    retroactiveRelease,
    requestedDate,
    getReleaseUpdateInput,
    checkBeforeSubmit,
    submitDraftRelease,
    submitUpdate,
    setSuccessAlert,
    intl,
    navigateAfterSubmit,
    setSlipRelease,
    setAddSlipVisible,
    setSubmitDialogVisible,
    updateRelease,
    setInputError,
    setWarningAlert,
    viewer?.id,
    navigate,
    approveRelease,
    setUpdateDialogVisible,
    hasChanges,
  ]);
};
