import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { useTaxCalculation } from "@/common/components/sales-tax-input/hooks/useTaxCalculation";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { useProjectZonesStore } from "@/contractor/pages/home/project/store/projectZonesStore";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { UpdateContractorReleaseInput } from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { useReceiptCreateRelease } from "../../providers/ReceiptCreateReleaseProvider";
import { useReceipt } from "../../providers/ReceiptProvider";
import { ReceiptSpreadSheetItem } from "../../types/ReceiptSpreadSheetItem";
import { ReceiptCreateReleaseFormValues } from "../ReceiptVerificationForm";
import { DashedDivider } from "./itemized/DashedDivider";
import { useReceiptCreateReleaseSpreadsheetConfig } from "./itemized/create/ReceiptCreateRelease.config";

const Container = tw.div`w-full flex flex-col flex-1 items-start px-2.5`;
const SpreadSheetView = tw.div`w-full mt-5 drop-shadow-md rounded-2xl h-full`;
const Footer = tw.div`mr-10 mt-10 w-full pb-5 flex justify-end`;

type Props = {
  itemized: boolean;
};

export const ReceiptCreateRelease: FC<Props> = ({ itemized }) => {
  const { setValue, watch } = useFormContext<ReceiptCreateReleaseFormValues>();
  const { release } = useRelease();
  const { receipt, hasReleaseRecorded, isItemized } = useReceipt();
  const { loading } = useReceiptCreateRelease();
  const { calcTableTotal, findMaterialByName } = useTableHelpers();
  const { spreadsheetData } = useColumnMapper();
  const customTaxAmount = watch("customTaxAmount");
  const subtotal = watch("subtotal");
  const orderTypeId = watch("orderTypeId");
  const taxRate = watch("taxRate");
  const spreadsheetViewColumns = useReceiptCreateReleaseSpreadsheetConfig();
  const { calcExtPrice } = usePriceCalculation();
  const { getTaxAmount } = useTaxCalculation();
  const taxAmount = getTaxAmount(taxRate, customTaxAmount, subtotal);
  const taxCodeId = watch("taxCodeId");
  const taxType = watch("taxType");

  const { setCurrentProjectId } = useProjectZonesStore(
    useShallow((state) => ({
      setCurrentProjectId: state.setCurrentProjectId,
    })),
  );

  const receiptItems: ReceiptSpreadSheetItem[] = useMemo(() => {
    const shouldRenderReceiptItems = hasReleaseRecorded && !isItemized(receipt);

    if (release && !shouldRenderReceiptItems) {
      return release.items.map((item) => {
        const matchingOrgMaterial = findMaterialByName(
          item.projectItem?.material.material.name || "",
        );

        const extPrice = new Decimal(item?.unitPrice || 0)
          .mul(item?.quantityDecimal)
          .toNumber();

        return {
          id: item.id,
          description: item.name,
          material: matchingOrgMaterial,
          costCode: matchingOrgMaterial?.costCode?.code ?? undefined,
          manufacturer: item.manufacturer?.name,
          UOM: item.uom?.pluralDescription ?? item.uom?.mnemonic ?? undefined,
          quantityDecimal: item.quantityDecimal,
          unitPrice: Number(item.unitPrice),
          extPrice,
        };
      });
    }
    if (!receipt?.items || (receipt.release?.id && !shouldRenderReceiptItems)) {
      return [];
    }

    return receipt.items.map((item) => {
      const matchingOrgMaterial = findMaterialByName(item.description);
      const extPrice = calcExtPrice(item?.quantityDecimal, item?.unitPrice);
      const isLumpSum = isLumpSumUomText(item.UOM);

      return {
        ...item,
        material: matchingOrgMaterial,
        costCode: matchingOrgMaterial?.costCode?.code ?? undefined,
        manufacturer:
          item.manufacturer ?? matchingOrgMaterial?.manufacturer?.name,
        UOM:
          item.UOM ??
          matchingOrgMaterial?.defaultEstimateUom?.pluralDescription ??
          matchingOrgMaterial?.defaultEstimateUom?.mnemonic,
        quantityDecimal:
          (isLumpSum ? item.unitPrice : item.quantityDecimal) ?? "",
        unitPrice: isLumpSum ? 1 : (Number(item.unitPrice) ?? undefined),
        extPrice,
      };
    });
  }, [
    release,
    receipt,
    findMaterialByName,
    isItemized,
    hasReleaseRecorded,
    calcExtPrice,
  ]);

  const updateSubtotal = useCallback(
    (data: Record<string, string>[]) => {
      const newTotal = calcTableTotal(data);
      if (newTotal !== watch("subtotal")) {
        setValue("subtotal", newTotal);
      }
    },
    [calcTableTotal, setValue, watch],
  );

  useEffect(() => {
    updateSubtotal(spreadsheetData);
  }, [spreadsheetData, updateSubtotal, itemized]);

  const handleUpdateRelease = useCallback(
    (values: UpdateContractorReleaseInput) => {
      if (
        values.customTaxAmount ||
        (values.clearCustomTaxAmount === false && customTaxAmount === undefined)
      ) {
        setValue(
          "customTaxAmount",
          values.customTaxAmount || receipt?.taxAmount || "0",
        );
        setValue("taxRate", undefined);
      }
      if (values.taxRate || values.clearCustomTaxAmount) {
        setValue("customTaxAmount", undefined);
        setValue("taxRate", values.taxRate || "0");
      }
      if (values.taxCodeId) {
        setValue("taxCodeId", values.taxCodeId);
      }
      if (values.taxType) {
        setValue("taxType", values.taxType);
      }

      return true;
    },
    [customTaxAmount, receipt?.taxAmount, setValue],
  );

  const { calcTotalPrice } = usePriceCalculation();

  const total = useMemo(
    () =>
      calcTotalPrice({
        subtotal,
        customTaxAmount: customTaxAmount || receipt?.taxAmount || "0",
        taxCodeId,
        taxRate,
        orderTypeId,
      }),
    [
      calcTotalPrice,
      subtotal,
      customTaxAmount,
      receipt?.taxAmount,
      taxCodeId,
      taxRate,
      orderTypeId,
    ],
  );

  const projectId = watch("projectId");

  useEffect(() => {
    if (projectId) {
      setCurrentProjectId(projectId);
    }
  }, [setCurrentProjectId, projectId]);

  const releaseInput = useMemo(
    () => ({
      customTaxAmount,
      taxRate,
      orderTypeId,
      taxAmount,
      subtotal,
      taxCodeId,
      taxType,
    }),
    [
      customTaxAmount,
      orderTypeId,
      subtotal,
      taxAmount,
      taxCodeId,
      taxRate,
      taxType,
    ],
  );

  return (
    <Container>
      <SpreadSheetView>
        <SpreadSheetTable
          items={receiptItems}
          columns={spreadsheetViewColumns}
          saving={loading}
          height="380px"
          rowNumber={10}
          onChanges={updateSubtotal}
        />
      </SpreadSheetView>
      <Footer>
        <ReleaseAdditionalChargesAndTaxes
          total={`${total}`}
          releaseInput={releaseInput}
          editableByContractor
          updateRelease={handleUpdateRelease}
          includeAdditionalCharges={hasReleaseRecorded}
          includeNotesPanel={false}
          editableAdditionalCharges={false}
          hideTotalBorder
          totalDivider={<DashedDivider className="mr-10" />}
          release={release}
        />
      </Footer>
    </Container>
  );
};
