import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useValidateRequiredParams } from "@/common/hooks/useValidateRequiredParams";
import { cleanQuery } from "@/common/utils/cacheUtils";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import {
  ApproveReleaseInput,
  ApproveReleaseMutation,
  CancelReleaseInput,
  RejectReleaseInput,
  ReleaseDocument,
  ReleaseQuery,
  ReleaseReassignmentFieldsFragment,
  ScheduleReleaseInput,
  SubmitReleaseMutation,
  UpdateContractorReleaseInput,
  UpdateContractorReleaseItemInput,
  UpdateContractorReleaseMutation,
  namedOperations,
  useArchiveReleaseMutation,
  useCancelReleaseMutation,
  useRejectReleaseMutation,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBooleanPromise,
  NoFunctionPromise,
} from "@/types/NoFunction";
import { ApolloError, FetchResult, useApolloClient } from "@apollo/client";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useScheduleRelease } from "../hooks/useScheduleRelease";
import {
  ReleaseChainInput,
  useReleaseMutations,
} from "../pages/specify-details/hooks/useReleaseMutations";
import { useReleaseStore } from "../store/useReleaseStore";
import { ExpandedReleaseItem, useRelease } from "./ReleaseProvider";

export enum ReleaseErrorType {
  "REQUESTED_FULFILLMENT_DATE",
  "REQUESTED_UOM",
  "INVALID_QUANTITIES",
  "ALL_ITEMS_ASSIGNED_TO_OTHER_VENDORS",
  "VENDOR",
  "VENDOR_CONTACT",
  "SPREADSHEET_ERROR",
}

type SubmitExtraOptions = {
  skipConfirmation: boolean;
  retroactive?: boolean;
  deliverySlipIds?: string[];
  version?: number;
  reserve?: boolean;
};

type ProviderContextType = {
  submitDraftRelease: (
    opt?: SubmitExtraOptions,
    releaseId?: string,
    callback?: (
      result?: FetchResult<SubmitReleaseMutation>,
      vendorReassignmentReleases?: ReleaseReassignmentFieldsFragment[],
    ) => void,
  ) => void;
  submitting: boolean;
  vendorId: string;
  setVendorId: (id: string) => void;
  po: string;
  setPo: (po: string) => void;
  requestedDate: Date | null;
  setRequestedDate: (date: Date | null) => void;
  siteContactName: string;
  setSiteContactName: (name: string) => void;
  contactCellPhone: string;
  setContactCellPhone: (phone: string) => void;
  scheduleRelease: (input: ScheduleReleaseInput) => Promise<boolean>;
  archiveRelease: (id: string) => Promise<boolean>;
  archiving: boolean;
  cancelRelease: (input: CancelReleaseInput) => Promise<boolean>;
  canceling: boolean;
  approveRelease: (
    input: ApproveReleaseInput,
    callback?: (
      result: FetchResult<ApproveReleaseMutation>,
      vendorReassignmentReleases: ReleaseReassignmentFieldsFragment[],
    ) => void,
  ) => void;
  approving: boolean;
  rejectRelease: (input: RejectReleaseInput) => Promise<boolean>;
  rejecting: boolean;
  inputErrors: ReleaseErrorType[];
  setInputError: (fieldName: ReleaseErrorType) => void;
  updateCostCode: () => void;
  newCostCodes: UpdateContractorReleaseItemInput[];
  setNewCostCodes: (input: UpdateContractorReleaseItemInput[]) => void;
  assignCostCodeOptions: {
    isEditable: boolean;
    categoryItems: ExpandedReleaseItem[];
  };
  setAssignCostCodeOptions: (options: {
    isEditable: boolean;
    categoryItems: ExpandedReleaseItem[];
  }) => void;
  expandedItems: string[];
  setExpandedItems: (items: string[]) => void;
  buyoutItemIds: string[];
  vendorReassignmentReleases: ReleaseReassignmentFieldsFragment[];
};

const ProviderContext = createContext<ProviderContextType>({
  submitDraftRelease: NoFunctionBooleanPromise,
  submitting: false,
  vendorId: "",
  setVendorId: NoFunction,
  po: "",
  setPo: NoFunction,
  requestedDate: null,
  setRequestedDate: NoFunction,
  siteContactName: "",
  setSiteContactName: NoFunction,
  contactCellPhone: "",
  setContactCellPhone: NoFunction,
  scheduleRelease: NoFunctionBooleanPromise,
  archiveRelease: NoFunctionBooleanPromise,
  archiving: false,
  cancelRelease: NoFunctionBooleanPromise,
  canceling: false,
  approveRelease: NoFunctionBooleanPromise,
  approving: false,
  rejectRelease: NoFunctionBooleanPromise,
  rejecting: false,
  inputErrors: [],
  setInputError: NoFunction,
  updateCostCode: NoFunctionPromise,
  newCostCodes: [],
  setNewCostCodes: NoFunction,
  assignCostCodeOptions: { isEditable: false, categoryItems: [] },
  setAssignCostCodeOptions: NoFunction,
  expandedItems: [],
  setExpandedItems: NoFunction,
  buyoutItemIds: [],
  vendorReassignmentReleases: [],
});

export const ReleaseActionsProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const client = useApolloClient();
  const { setError } = useGlobalError();
  const { release, setRelease } = useRelease();
  const [po, setPo] = useState<string>(release?.poNumber ?? "");
  const [vendorId, setVendorId] = useState<string>("");
  const [vendorReassignmentReleases, setVendorReassignmentReleases] = useState<
    ReleaseReassignmentFieldsFragment[]
  >([]);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const [requestedDate, setRequestedDate] = useState<Date | null>(
    release?.time ? new Date(release.time) : null,
  );
  const [siteContactName, setSiteContactName] = useState<string>(
    release?.siteContact?.name ?? "",
  );
  const [contactCellPhone, setContactCellPhone] = useState<string>(
    release?.siteContact?.phone ?? "",
  );
  const { updateStoreReleaseVersion, release: storeRelease } =
    useReleaseStore();
  const {
    chainUpdateContractorReleaseMutation,
    chainSubmitReleaseMutation,
    chainApproveReleaseMutation,
    submitting,
    approving,
  } = useReleaseMutations();

  useEffect(() => {
    if (release) {
      updateStoreReleaseVersion(release);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [release?.version]);

  useEffect(() => {
    setPo(release?.poNumber ?? "");
  }, [release?.poNumber]);

  const [inputErrors, setInputErrors] = useState<ReleaseErrorType[]>([]);
  const [newCostCodes, setNewCostCodes] = useState<
    UpdateContractorReleaseItemInput[]
  >([]);
  const [assignCostCodeOptions, setAssignCostCodeOptions] = useState<{
    isEditable: boolean;
    categoryItems: ExpandedReleaseItem[];
  }>({ isEditable: false, categoryItems: [] });
  const [buyoutItemIds, setBuyoutItemIds] = useState<string[]>([]);
  const { costCodes } = useCostCodes();
  const { validateParam } = useValidateRequiredParams("ReleaseActionsProvider");

  const updateCostCode = useCallback(() => {
    if (!newCostCodes?.length) {
      return;
    }
    const currentReleaseVersion = storeRelease?.version ?? release?.version;

    if (
      !validateParam({
        value: currentReleaseVersion,
        methodName: "updateCostCode",
        paramName: "version",
      })
    ) {
      return;
    }

    if (!release) {
      return;
    }
    chainUpdateContractorReleaseMutation({
      queueItem: {
        id: release?.id,
        params: {
          releaseId: release?.id || "",
          version: currentReleaseVersion,
          updates: newCostCodes,
        },
        callback: (
          queueItems: UpdateContractorReleaseInput[],
          result?: FetchResult<UpdateContractorReleaseMutation>,
        ) => {
          if (result?.data?.updateContractorRelease) {
            updateStoreReleaseVersion(result.data?.updateContractorRelease);
            client.cache.updateQuery(
              {
                query: ReleaseDocument,
                variables: {
                  id: release?.id,
                },
              },
              (data: ReleaseQuery | null) => {
                if (data?.release) {
                  return {
                    ...data,
                    release: {
                      ...data?.release,
                      items: data?.release?.items.map((item) => {
                        const updatedItem = newCostCodes?.find(
                          (updatedItem) =>
                            updatedItem.releaseItemId === item.id,
                        );

                        if (updatedItem && item.projectItem) {
                          return {
                            ...item,
                            projectItem: {
                              ...item.projectItem,
                              material: {
                                ...item.projectItem?.material,
                                costCode: costCodes.find(
                                  (costCode) =>
                                    costCode.id === updatedItem.costCodeId,
                                ),
                              },
                            },
                          };
                        } else {
                          return item;
                        }
                      }),
                    },
                  };
                }
                return data;
              },
            );
          }
          return queueItems;
        },
      },
    });
  }, [
    newCostCodes,
    storeRelease?.version,
    release,
    validateParam,
    chainUpdateContractorReleaseMutation,
    updateStoreReleaseVersion,
    client.cache,
    costCodes,
  ]);

  useEffect(() => {
    setPo(release?.poNumber ?? "");
    setRequestedDate(release?.time ? new Date(release.time) : null);
    setSiteContactName(release?.siteContact?.name ?? "");
    setContactCellPhone(release?.siteContact?.phone ?? "");
    setVendorId(release?.sellerOrgLocation?.id ?? "");
  }, [release]);

  useEffect(() => {
    setInputErrors([]);
  }, [requestedDate]);

  const submitDraftRelease = useCallback(
    (
      options?: SubmitExtraOptions,
      releaseId?: string,
      callback?: (
        result?: FetchResult<SubmitReleaseMutation>,
        vendorReassignmentReleases?: ReleaseReassignmentFieldsFragment[],
      ) => void,
    ) => {
      const id = releaseId || release?.id;
      if (!id) {
        return;
      }
      chainSubmitReleaseMutation({
        queueItem: {
          id,
          params: {
            releaseId: id,
            version: options?.version || release?.version,
            skipConfirmation: options?.skipConfirmation,
            retroactive: options?.retroactive,
            deliverySlipIds: options?.deliverySlipIds,
            reserve: options?.reserve || false,
          },
          callback: (
            queueItems: ReleaseChainInput[],
            result?: FetchResult<SubmitReleaseMutation>,
          ) => {
            if (result?.data?.submitRelease) {
              let newAssignments = [];
              setRelease(result.data.submitRelease);
              if (
                result.data.submitRelease.vendorReassignmentReleases?.length
              ) {
                newAssignments = [
                  {
                    id: result.data.submitRelease.id,
                    status: result.data.submitRelease.status,
                  },
                  ...result.data.submitRelease.vendorReassignmentReleases,
                ];
              } else {
                newAssignments = vendorReassignmentReleases.map((rel) => ({
                  ...rel,
                  status:
                    rel.id === result.data?.submitRelease?.id
                      ? result.data.submitRelease.status
                      : rel.status,
                }));
              }
              setVendorReassignmentReleases(newAssignments);
              callback?.(result, newAssignments);
            } else {
              callback?.();
            }
            return queueItems;
          },
          errorCallback: (error: unknown) => {
            if (
              (error as ApolloError)?.graphQLErrors?.[0]?.extensions
                ?.buyoutItemIDs
            ) {
              setBuyoutItemIds(
                (error as ApolloError)?.graphQLErrors[0]?.extensions
                  ?.buyoutItemIDs as string[],
              );
            }
          },
        },
      });
    },
    [
      chainSubmitReleaseMutation,
      release?.id,
      release?.version,
      setRelease,
      vendorReassignmentReleases,
    ],
  );

  const { scheduleReleaseMutation } = useScheduleRelease();
  const scheduleRelease = async (input: ScheduleReleaseInput) => {
    return await scheduleReleaseMutation(input);
  };

  const [cancelReleaseMutation, { loading: canceling }] =
    useCancelReleaseMutation({
      update: (cache) => cleanQuery(cache, namedOperations.Query.Releases),
    });
  const cancelRelease = async (input: CancelReleaseInput) => {
    try {
      const { errors } = await cancelReleaseMutation({
        variables: {
          input,
        },
      });

      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const [archiveReleaseMutation, { loading: archiving }] =
    useArchiveReleaseMutation({
      update: (cache) => cleanQuery(cache, namedOperations.Query.Releases),
    });
  const archiveRelease = async (id: string) => {
    try {
      const { errors } = await archiveReleaseMutation({
        variables: {
          id,
        },
      });

      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const approveRelease = (
    input: ApproveReleaseInput,
    callback?: (
      result: FetchResult<ApproveReleaseMutation>,
      vendorAssignments: ReleaseReassignmentFieldsFragment[],
    ) => void,
  ) => {
    if (!release?.id) {
      return;
    }
    chainApproveReleaseMutation({
      queueItem: {
        id: release?.id,
        params: input,
        callback: (
          queueItems: ApproveReleaseInput[],
          result?: FetchResult<ApproveReleaseMutation>,
        ) => {
          if (result?.data && result?.data.approveRelease) {
            let newAssignments = [];

            setRelease(result?.data.approveRelease);
            if (result.data.approveRelease.vendorReassignmentReleases?.length) {
              newAssignments = [
                {
                  id: result.data.approveRelease.id,
                  status: result.data.approveRelease.status,
                },
                ...result.data.approveRelease.vendorReassignmentReleases,
              ];
            } else {
              newAssignments = vendorReassignmentReleases.map((rel) => ({
                ...rel,
                status:
                  rel.id === result.data?.approveRelease?.id
                    ? result.data.approveRelease.status
                    : rel.status,
              }));
            }
            setVendorReassignmentReleases(newAssignments);
            if (result.data) {
              callback?.(result, newAssignments);
            }
          }

          setError(result?.errors);
          return queueItems;
        },
      },
    });
  };

  const [rejectReleaseMutation, { loading: rejecting }] =
    useRejectReleaseMutation({
      update: (cache) => cleanQuery(cache, namedOperations.Query.Releases),
    });
  const rejectRelease = async (input: RejectReleaseInput) => {
    try {
      const { errors } = await rejectReleaseMutation({
        variables: {
          input,
        },
      });
      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const setInputError = (fieldName: ReleaseErrorType) => {
    setInputErrors((errors) =>
      errors.includes(fieldName) ? errors : [fieldName, ...errors],
    );
  };

  return (
    <ProviderContext.Provider
      value={{
        submitDraftRelease,
        submitting,
        vendorId,
        setVendorId,
        po,
        setPo,
        requestedDate,
        setRequestedDate,
        siteContactName,
        setSiteContactName,
        contactCellPhone,
        setContactCellPhone,
        scheduleRelease,
        archiveRelease,
        archiving,
        cancelRelease,
        canceling,
        approveRelease,
        approving,
        rejectRelease,
        rejecting,
        inputErrors,
        setInputError,
        updateCostCode,
        newCostCodes,
        setNewCostCodes,
        assignCostCodeOptions,
        setAssignCostCodeOptions,
        expandedItems,
        setExpandedItems,
        buyoutItemIds,
        vendorReassignmentReleases,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReleaseActions = (): ProviderContextType =>
  useContext(ProviderContext);
