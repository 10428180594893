import { useGlobalError } from "@/common/hooks/useGlobalError";
import { PROJECT } from "@/contractor/pages/home/project/queries/project";
import {
  AddZoneInput,
  AddZonesInput,
  ProjectDocument,
  ProjectQuery,
  UpdateZoneInput,
  useAddZoneMutation,
  useAddZonesMutation,
  useRemoveZoneMutation,
  useUpdateZoneMutation,
} from "@/generated/graphql";
import { useEffect } from "react";
import { useShallow } from "zustand/react/shallow";
import { useProject } from "../providers/ProjectProvider";
import { useProjectZonesStore } from "../store/projectZonesStore";

export const useProjectZones = () => {
  const { project } = useProject();
  const { zones, setCurrentProjectId, loading, error } = useProjectZonesStore(
    useShallow((state) => ({
      zones: state.zones,
      setCurrentProjectId: state.setCurrentProjectId,
      loading: state.loading,
      error: state.error,
    })),
  );
  const [addZoneMutation] = useAddZoneMutation();
  const [addZonesMutation] = useAddZonesMutation();
  const [updateZoneMutation] = useUpdateZoneMutation();
  const [removeZoneMutation] = useRemoveZoneMutation();
  const { setError } = useGlobalError();

  useEffect(() => {
    if (project?.id) {
      setCurrentProjectId(project.id);
    }
  }, [project?.id, setCurrentProjectId]);

  const updateZone = async (zone: UpdateZoneInput) => {
    try {
      const { data, errors } = await updateZoneMutation({
        variables: {
          input: zone,
        },
        update: (cache, { data }) => {
          const queryProject = cache.readQuery<ProjectQuery>({
            query: ProjectDocument,
            variables: { id: project?.id, excludePhantoms: true },
          });
          if (queryProject && data?.updateZone) {
            cache.writeQuery({
              query: ProjectDocument,
              variables: { id: project?.id, excludePhantoms: true },
              data: {
                project: {
                  ...queryProject?.project,
                  zones: queryProject?.project?.zones.map((z) => {
                    if (z.id === data.updateZone.id) {
                      return { ...z, ...data.updateZone };
                    }
                    return z;
                  }),
                },
              },
            });
          }
        },
      });
      setError(errors);
      if (data?.updateZone) {
        return !!data?.updateZone;
      }
    } catch (errors) {
      setError(errors);
    }
  };

  const addZone = async (zone: AddZoneInput) => {
    try {
      const { data, errors } = await addZoneMutation({
        variables: {
          input: zone,
        },
        update: (cache, { data }) => {
          const queryProject = cache.readQuery<ProjectQuery>({
            query: ProjectDocument,
            variables: { id: project?.id, excludePhantoms: true },
          });
          if (queryProject && data?.addZone) {
            cache.writeQuery({
              query: ProjectDocument,
              variables: { id: project?.id, excludePhantoms: true },
              data: {
                project: {
                  ...queryProject?.project,
                  zones: [...data.addZone],
                },
              },
            });
          }
        },
      });
      setError(errors);
      if (data?.addZone) {
        return data?.addZone;
      }
    } catch (errors) {
      setError(errors);
    }
  };

  const addZones = async (input: AddZonesInput) => {
    try {
      const { data, errors } = await addZonesMutation({
        variables: {
          input,
        },
        refetchQueries: [
          {
            query: PROJECT,
            variables: { id: project?.id, excludePhantoms: true },
          },
        ],
      });
      setError(errors);
      if (data?.addZones) {
        return data?.addZones;
      }
    } catch (errors) {
      setError(errors);
    }
  };

  const deleteZone = async (zoneId: string) => {
    try {
      const { data, errors } = await removeZoneMutation({
        variables: {
          zoneId,
        },
        update: (cache, { data }) => {
          const queryProject = cache.readQuery<ProjectQuery>({
            query: ProjectDocument,
            variables: { id: project?.id, excludePhantoms: true },
          });
          if (queryProject && data?.removeZone) {
            cache.writeQuery({
              query: ProjectDocument,
              variables: { id: project?.id, excludePhantoms: true },
              data: {
                project: {
                  ...queryProject?.project,
                  zones: [...data.removeZone],
                },
              },
            });
          }
        },
      });
      setError(errors);
      if (data?.removeZone) {
        return !!data?.removeZone;
      }
    } catch (errors) {
      setError(errors);
    }
  };

  return {
    addZone,
    addZones,
    updateZone,
    deleteZone,
    zones,
    loading,
    error,
  };
};
