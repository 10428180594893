import {
  COLUMN_TYPE,
  SpreadSheetConfig,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useReleaseItemsDecorator } from "@/contractor/pages/home/release/pages/specify-details/hooks/useReleaseItemsDecorator";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useDeliverySlipImportExternalPO } from "../../../../providers/DeliverySlipImportExternalPOProvider";
export const useDeliverySlipEditReleaseSpreadsheetConfig =
  (): SpreadSheetConfig[] => {
    const intl = useIntl();
    const { hasPhaseCodes: hasOrgSettingsPhaseCodes } = useOrgSettings();
    const { importedItems } = useDeliverySlipImportExternalPO();
    const { phaseCodeOptions } = useColumnMapper();
    const { release } = useRelease();
    const extraOptions = useReleaseItemsDecorator();
    const { watch } = useFormContext();
    const projectId = watch("projectId");
    const { costCodes } = useProjectCostCodes(projectId);

    const hasPhaseCodes = useMemo(
      () => hasOrgSettingsPhaseCodes && phaseCodeOptions?.length > 0,
      [hasOrgSettingsPhaseCodes, phaseCodeOptions?.length],
    );

    const itemIsPartOfPoOrAssignedToInvoice = useCallback(
      (id: string, columnType: COLUMN_TYPE) => {
        const importedItem = importedItems?.find((item) => item.id === id);
        if (importedItem) {
          switch (columnType) {
            case COLUMN_TYPE.Material:
              return importedItem.description
                ? intl.$t({ id: "DISABLED_ITEM_PART_OF_PO" })
                : "";
            case COLUMN_TYPE.UOM:
              return importedItem.UOM
                ? intl.$t({ id: "DISABLED_ITEM_PART_OF_PO" })
                : "";
            default:
              return "";
          }
        }

        const assignedToInvoiceItem = release?.items.find(
          (item) =>
            item.id === id &&
            !!item.poItemLink &&
            !!release?.poLink?.retroactive,
        );
        if (assignedToInvoiceItem) {
          switch (columnType) {
            case COLUMN_TYPE.Material:
              return assignedToInvoiceItem.projectItem?.material
                ? intl.$t({ id: "DISABLED_ITEM_ASSIGNED_TO_INVOICE" })
                : "";
            case COLUMN_TYPE.UOM:
              return assignedToInvoiceItem.uom?.pluralDescription
                ? intl.$t({ id: "DISABLED_ITEM_ASSIGNED_TO_INVOICE" })
                : "";
            default:
              return "";
          }
        }

        return "";
      },
      [importedItems, intl, release?.items, release?.poLink?.retroactive],
    );

    return [
      {
        header: intl.$t({ id: "ITEM_NAME" }),
        columnId: (id) =>
          isLumpSumItem(release?.items.find((itm) => itm.id === id))
            ? "name"
            : "projectItem.material",
        width: 100,
        columnType: COLUMN_TYPE.Material,
        readOnlyFn: (id) =>
          itemIsPartOfPoOrAssignedToInvoice(id, COLUMN_TYPE.Material),
        extraOptions,
      },
      {
        header: intl.$t({ id: "UOM" }),
        columnId: "uom.pluralDescription",
        readOnlyFn: (id) =>
          itemIsPartOfPoOrAssignedToInvoice(id, COLUMN_TYPE.UOM),
        columnType: COLUMN_TYPE.UOM,
      },
      {
        header: intl.$t({ id: "ORDER_QUANT" }),
        columnId: "quantityDecimal",
        columnType: COLUMN_TYPE.Quantity,
      },
      {
        header: intl.$t({ id: "RECEIVED_QUANT" }),
        columnId: "receivedQuantityDecimal",
        columnType: COLUMN_TYPE.ReceivedQuantity,
        readOnlyFn: () => intl.$t({ id: "ITEM_COVERED_EDIT_NOT_ALLOWED" }),
      },
      {
        header: intl.$t({ id: "UNIT_PRICE" }),
        columnId: "unitPrice",
        columnType: COLUMN_TYPE.PrefilledPrice,
      },
      ...(hasPhaseCodes
        ? [
            {
              header: intl.$t({ id: "PHASE_CODE" }),
              columnId: "phaseCode",
              columnType: COLUMN_TYPE.PhaseCode,
            },
          ]
        : [
            {
              header: intl.$t({ id: "COST_CODE" }),
              columnId: "costCode.code",
              columnType: COLUMN_TYPE.CostCode,
              options: costCodes.map((c) => c.formatted),
              metadata: { projectId },
            },
          ]),
      {
        header: intl.$t({ id: "ZONE" }),
        columnId: "zone.name",
        width: 100,
        columnType: COLUMN_TYPE.Zone,
      },
    ];
  };
