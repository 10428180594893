import {
  ExternalPoBaseFieldsFragment,
  ExternalPoFieldsFragment,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useInvoiceImportExternalPO } from "../../../../../../providers/InvoiceImportExternalPoProvider";
import { useInvoiceVerification } from "../../../../../../providers/InvoiceVerificationProvider";

export const useInvoiceExternalPoItemsValidation = () => {
  const intl = useIntl();

  const { invoice } = useInvoiceVerification();
  const {
    externalPo,
    selectedBaseExternalPO,
    hasMissingPoLink,
    isNonItemizedPO,
  } = useInvoiceImportExternalPO();

  const poAlreadyImported = useMemo(
    () =>
      selectedBaseExternalPO?.poLinks.some(
        (poLink) => poLink.release?.id === invoice?.release?.id,
      ) ||
      externalPo?.poLinks.some(
        (poLink) => poLink.release?.id === invoice?.release?.id,
      ),
    [
      selectedBaseExternalPO?.poLinks,
      externalPo?.poLinks,
      invoice?.release?.id,
    ],
  );

  const validate = useCallback(
    (
      selectedBaseExternalPO: ExternalPoBaseFieldsFragment | undefined,
      externalPo: ExternalPoFieldsFragment | null | undefined,
      isNonItemizedPO: boolean,
    ) => {
      if (poAlreadyImported || hasMissingPoLink) {
        return null;
      }
      if (
        !selectedBaseExternalPO?.project?.project &&
        !externalPo?.project?.project
      ) {
        return intl.$t(
          { id: "PO_IMPORT_FM_PROJECT_NOT_IMPORTED" },
          {
            projectName:
              selectedBaseExternalPO?.project?.name ??
              externalPo?.project?.name ??
              "",
          },
        );
      }
      if (
        (selectedBaseExternalPO?.vendor?.orgPreferredVendors ?? []).length ===
          0 &&
        (externalPo?.vendor?.orgPreferredVendors ?? []).length === 0
      ) {
        return intl.$t(
          { id: "PO_IMPORT_FM_VENDOR_NOT_IMPORTED" },
          {
            vendorName:
              selectedBaseExternalPO?.vendor?.name ??
              externalPo?.vendor?.name ??
              "",
          },
        );
      }
      if (
        ((selectedBaseExternalPO?.poLinks ?? []).length > 0 ||
          (externalPo?.poLinks ?? []).length > 0) &&
        !isNonItemizedPO
      ) {
        return intl.$t({ id: "EXTERNAL_PO_ORDER_ALREADY_ASSOCIATED" });
      }
      return null;
    },
    [poAlreadyImported, hasMissingPoLink, intl],
  );

  const validationError = useMemo(() => {
    return validate(selectedBaseExternalPO, externalPo, isNonItemizedPO);
  }, [validate, selectedBaseExternalPO, externalPo, isNonItemizedPO]);

  return {
    validationError,
    validate,
    poAlreadyImported,
  };
};
