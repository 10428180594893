import { DeleteButton } from "@/common/components/button/DeleteButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  AuthorizationStatus,
  ReleasePartialFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ExportReleaseIcon } from "../../../../release/components/exports/ExportReleaseIcon";
import { useDeliveries } from "../providers/DeliveriesProvider";
import { ReleaseWarnings } from "./ReleaseWarnings";

const Container = tw.div`flex flex-row gap-1 items-center flex-1 ml-1`;
const DeleteButtonContainer = tw.div`w-10 h-10 items-center flex justify-center`;
const DeleteButtonStyled = tw(DeleteButton)`h-auto p-0`;

const RELEASE_ARCHIVE_STATUSES = [
  ReleaseStatus.Draft,
  ReleaseStatus.Canceled,
  ReleaseStatus.Rejected,
  ReleaseStatus.AwaitingApproval,
  ReleaseStatus.Received,
  ReleaseStatus.PartiallyReceived,
  ReleaseStatus.Reserved,
];

export const ReleaseActions: FC<{
  id: string;
  release: ReleasePartialFieldsFragment;
}> = ({ id, release }) => {
  const { openDialog } = useDialog();
  const { archiveRelease, exportEnabled } = useDeliveries();
  const { setSuccessAlert } = useSnackbar();
  const intl = useIntl();

  const isArchiveButtonVisible = useMemo(
    () =>
      RELEASE_ARCHIVE_STATUSES.includes(release.status) &&
      !release.deletedAt &&
      release.permissions.archive === AuthorizationStatus.Authorized,
    [release.status, release.deletedAt, release.permissions.archive],
  );

  const handleArchiveRelease = useCallback(async () => {
    if (await archiveRelease(id)) {
      setSuccessAlert(intl.$t({ id: "DELIVERY_ARCHIVED" }));
    }
  }, [archiveRelease, id, setSuccessAlert, intl]);

  return (
    <Container>
      <If isTrue={exportEnabled}>
        <ExportReleaseIcon release={release} />
      </If>
      <ReleaseWarnings release={release} />
      <DeleteButtonContainer>
        <If isTrue={isArchiveButtonVisible && !exportEnabled}>
          <DeleteButtonStyled
            onClick={() =>
              openDialog({
                cancelButtonText: intl.$t({ id: "CANCEL" }),
                confirmButtonText: intl.$t({ id: "PROCEED" }),
                icon: <WarningIcon />,
                title: intl.$t({ id: "ARCHIVE_ORDER_DETAILS" }),
                handleConfirm: handleArchiveRelease,
              })
            }
          />
        </If>
      </DeleteButtonContainer>
    </Container>
  );
};
