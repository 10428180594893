import { useExportToCsv } from "@/common/components/csv-export/hooks/useExportToCsv";
import { useExportToCsvReceiptData } from "@/common/components/csv-export/hooks/useExportToCsvReceiptData";
import { SideActionBar } from "@/common/components/side-action-bar/SideActionBar";
import { SystemAlertType } from "@/common/components/system-alert/SystemAlert";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useCallback, useMemo } from "react";
import { PiFileCsv } from "react-icons/pi";
import { useIntl } from "react-intl";
import { useReceiptSequence } from "../../providers/ReceiptsSequenceProvider";

export const ReceiptSideActions = () => {
  const { setSystemAlert } = useSnackbar();
  const { sequenceIds, selectedReceipts } = useReceiptSequence();
  const { exportToCsv } = useExportToCsv();
  const { getReceiptExportToCsvData } = useExportToCsvReceiptData();
  const intl = useIntl();

  const exportItemsToCsv = useCallback(() => {
    const { data, fileName, headers } =
      getReceiptExportToCsvData(selectedReceipts);

    exportToCsv({
      data,
      fileName,
      headers,
    });

    setSystemAlert(
      intl.$t(
        { id: "RECEIPTS_EXPORT_CSV_SUCCESS" },
        { count: selectedReceipts.length },
      ),
      { type: SystemAlertType.MANUAL },
    );
  }, [
    getReceiptExportToCsvData,
    selectedReceipts,
    exportToCsv,
    setSystemAlert,
    intl,
  ]);

  const actions = useMemo(() => {
    return [
      {
        icon: <PiFileCsv className="h-6 w-6" />,
        tooltipKey: "EXPORT_TO_CSV",
        callback: exportItemsToCsv,
      },
    ];
  }, [exportItemsToCsv]);

  return <SideActionBar visible={sequenceIds.length > 0} actions={actions} />;
};
