import { useApolloClientStore } from "@/common/stores/useApolloClientStore";
import { ProjectZonesDocument, ZoneFieldsFragment } from "@/generated/graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
  currentProjectId: string | null | undefined;
  zonesMap: Map<string, ZoneFieldsFragment[]>;
  zones: ZoneFieldsFragment[];
  loading: boolean;
  error: Error | null;
  setCurrentProjectId: (projectId: string) => void;
};

export const useProjectZonesStore = create<State>()(
  devtools((set, get) => ({
    currentProjectId: undefined,
    zonesMap: new Map(),
    zones: [],
    loading: false,
    error: null,
    setCurrentProjectId: async (projectId: string | null | undefined) => {
      if (!projectId) {
        return;
      }
      const { client } = useApolloClientStore.getState();
      if (!client) {
        return;
      }

      set({ loading: true, currentProjectId: projectId });
      try {
        const result = await client.query({
          query: ProjectZonesDocument,
          variables: { id: projectId },
        });
        set({
          zonesMap: get().zonesMap.set(projectId, result.data.project.zones),
          zones: result.data.project.zones,
          loading: false,
        });
      } catch (error) {
        set({ error: error as Error, loading: false });
      }
    },
  })),
);
