import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { HeaderContainer } from "@/common/components/header-container/HeaderContainer";
import { ListHeaderFilterButton } from "@/common/components/list-header-filter-button/ListHeaderFilterButton";
import { ListStatusesFilter } from "@/common/components/list-statuses-filter/ListStatusesFilter";
import { ProjectsFilterSelector } from "@/common/components/projects-filter-selector/ProjectsFilterSelector";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { SearchTooltip } from "@/common/components/search-input/SearchTooltip";
import { ToggleRfqForm } from "@/contractor/pages/home/rfq-master-catalog-import/components/ToggleRfqForm";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useProjectListOptions } from "../../projects/hooks/useProjectListOptions";
import { FILTER_OPTIONS, useRfqs } from "../providers/RfqsProvider";
import { RfqsAdditionalFilters } from "./RfqsAdditionalFilters";

const HeaderContainerStyled = tw(HeaderContainer)`
  justify-between
`;

const Filters = tw.div`
  grid grid-flow-col gap-2 items-center
`;

export const RfqsHeader = () => {
  const intl = useIntl();
  const { filter, setFilter } = useRfqs();
  const { projects } = useProjectListOptions({
    includeClosedProjects: filter?.closedProjects,
  });

  const options = useMemo(() => {
    return FILTER_OPTIONS.map((opt) => {
      return {
        id: opt.id,
        name: intl.$t({ id: `RFQ_FILTER_OPTION_${opt.id}` }),
      };
    });
  }, [intl]);

  const appliedFilters = useMemo(() => {
    return (
      Number(filter?.deleted !== false) +
      Number(filter?.quotesReceived != null) +
      Number(filter?.closedProjects != null)
    );
  }, [filter?.deleted, filter?.quotesReceived, filter?.closedProjects]);

  return (
    <HeaderContainerStyled>
      <Filters>
        <SearchInput
          placeHolder={intl.$t({ id: "SEARCH" })}
          onChange={(value: string) => setFilter({ ...filter, search: value })}
          value={filter?.search}
          info={<SearchTooltip text="RFQS_SEARCH_INFO" />}
          applyHeaderAccent
        />
        <ProjectsFilterSelector
          projects={projects}
          onChange={(projectId) =>
            setFilter({
              ...filter,
              projectIds: projectId ? [projectId] : undefined,
            })
          }
          value={filter?.projectIds?.[0]}
          withCustomView
        />
        <ListStatusesFilter
          options={options}
          filter={filter}
          setFilter={setFilter}
          values={filter?.statuses || null}
          applyHeaderAccent
        />
        <ListHeaderFilterButton
          isDefault={appliedFilters === 0}
          filter={<RfqsAdditionalFilters />}
          appliedFilters={appliedFilters}
        />
      </Filters>
      <ToggleRfqForm
        label={intl.$t({ id: "CREATE_RFQ_DIALOG_REQUEST_FOR_QUOTE" })}
      >
        <PrimaryButton testId="new-rfq-button-quotes">
          <FormattedMessage id="NEW_RFQ" />
        </PrimaryButton>
      </ToggleRfqForm>
    </HeaderContainerStyled>
  );
};
