import { TaxType } from "@/generated/graphql";
import { FC } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { useReceiptCreation } from "../../receipts/providers/ReceiptCreationProvider";

export type ReceiptCreateReleaseFormValues = {
  businessLocationId: string;
  projectId: string;
  vendorId: string;
  issueDate: Date | undefined;
  costCodeId: string;
  phaseCodeId: string;
  subtotal: number;
  taxRate: string | undefined;
  customTaxAmount: string | undefined;
  orderTypeId: string;
  taxType: TaxType | undefined;
  taxCodeId: string | undefined;
};

type ReceiptCreateReleaseForm = UseFormReturn<
  ReceiptCreateReleaseFormValues,
  unknown,
  ReceiptCreateReleaseFormValues
>;

export const ReceiptVerificationForm: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { locationId } = useReceiptCreation();
  const receiptCreateReleaseForm: ReceiptCreateReleaseForm =
    useForm<ReceiptCreateReleaseFormValues>({
      defaultValues: {
        businessLocationId: locationId ?? "",
        projectId: "",
        vendorId: "",
        issueDate: new Date(),
        phaseCodeId: "",
        costCodeId: "",
        customTaxAmount: undefined,
        subtotal: undefined,
        orderTypeId: "",
      },
      mode: "onChange",
      reValidateMode: "onChange",
    });
  return <FormProvider {...receiptCreateReleaseForm}>{children}</FormProvider>;
};
