import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  CreateInvoiceFolderInput,
  InvoicesDocument,
  UpdateInvoiceFolderInput,
  useCreateInvoiceFolderMutation,
  useDeleteInvoiceFolderMutation,
  useOrgFoldersLazyQuery,
  useUpdateInvoiceFolderMutation,
} from "@/generated/graphql";
import { useInvoices } from "../../../providers/InvoicesProvider";

export const useInvoiceFolders = () => {
  const [deleteFolderMutation, { loading: deleteFolderLoading }] =
    useDeleteInvoiceFolderMutation();
  const [createFolderMutation, { loading: createFolderLoading }] =
    useCreateInvoiceFolderMutation();
  const [updateFolderMutation, { loading: updateFolderLoading }] =
    useUpdateInvoiceFolderMutation();
  const { setError } = useGlobalError();
  const { invoiceFolders, loading } = useOrgSettings();
  const [getFolders, { loading: foldersLoading }] = useOrgFoldersLazyQuery();
  const { paginationArgs, page, filter } = useInvoices();

  const archiveFolder = async (id: string) => {
    try {
      const { errors } = await deleteFolderMutation({
        variables: { id },
        refetchQueries: [
          {
            query: InvoicesDocument,
            variables: {
              ...paginationArgs,
              page,
              filter,
            },
          },
        ],
        awaitRefetchQueries: true,
      });

      setError(errors);
      await getFolders({
        fetchPolicy: "network-only",
      });
      return !errors;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  const createFolder = async (input: CreateInvoiceFolderInput) => {
    try {
      const { data: createdData, errors } = await createFolderMutation({
        variables: { input },
      });

      setError(errors);
      await getFolders({
        fetchPolicy: "network-only",
      });
      if (createdData) {
        return createdData?.createInvoiceFolder.id;
      }
    } catch (errors) {
      setError(errors);
      return undefined;
    }
  };

  const updateFolder = async (input: UpdateInvoiceFolderInput) => {
    try {
      const { data: updatedData, errors } = await updateFolderMutation({
        variables: { input },
      });
      setError(errors);
      await getFolders({
        fetchPolicy: "network-only",
      });
      if (updatedData) {
        return updatedData?.updateInvoiceFolder.id;
      }
    } catch (errors) {
      setError(errors);
      return undefined;
    }
  };

  return {
    folders: invoiceFolders || [],
    loading:
      loading ||
      foldersLoading ||
      deleteFolderLoading ||
      createFolderLoading ||
      updateFolderLoading,
    archiveFolder,
    createFolder,
    updateFolder,
  };
};
