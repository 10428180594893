import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import {
  AddToReleaseItemInput,
  OrgPreferredVendorsFieldsFragment,
  ReleaseFieldsFragment,
  ReleaseStatus,
  ServiceType,
  UomsDocument,
  UpdateContractorReleaseItemInput,
  useCreateStandaloneReleaseMutation,
  useProjectPredictedPoNumberQuery,
  useUpdateContractorReleaseMutation,
} from "@/generated/graphql";
import { FC, createContext, useContext, useEffect, useState } from "react";

import { LUMP_SUM_UOM_PLURAL_DESCRIPTION, QUERYSTRING } from "@/common/const";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { RELEASE } from "@/common/queries/release";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useVendors } from "@/contractor/pages/admin/vendors/hooks/useVendors";
import { useProjectZonesStore } from "@/contractor/pages/home/project/store/projectZonesStore";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { NoFunction, NoFunctionUndefined } from "@/types/NoFunction";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { CreateOrderFromNoteFormValues } from "../components/order-from-note/create-order-from-note/components/CreateOrderFromNoteForm";
import { useNoteDocument } from "../components/order-from-note/note-document/providers/NoteDocumentProvider";

type ProviderContextType = {
  syncCreateReleaseFromNote: (
    skipConfirmation?: boolean,
    retroactive?: boolean,
    status?: ReleaseStatus,
  ) => Promise<ReleaseFieldsFragment | undefined | null>;
  syncUpdateReleaseFromNote: () => Promise<
    ReleaseFieldsFragment | undefined | null
  >;
  validateSpreadsheet: () => boolean;
  loading: boolean;
  vendors: OrgPreferredVendorsFieldsFragment[];
  loadingVendors: boolean;
  findOrderTypeByLocationId: (locationId: string) => string | undefined;
  loadingPredictedPoNumber: boolean;
  setWasDocumentReplaced: (wasDocumentReplaced: boolean) => void;
  wasDocumentReplaced: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  syncCreateReleaseFromNote: () => Promise.resolve(null),
  syncUpdateReleaseFromNote: () => Promise.resolve(null),
  validateSpreadsheet: () => false,
  loading: false,
  vendors: [],
  loadingPredictedPoNumber: false,
  loadingVendors: false,
  findOrderTypeByLocationId: NoFunctionUndefined,
  setWasDocumentReplaced: NoFunction,
  wasDocumentReplaced: false,
});

export const OrderFromNoteProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { queryParams } = useQueryParams();

  const { noteDocument } = useNoteDocument();
  const { setError } = useGlobalError();
  const { materials } = useMaterials();
  const { release } = useRelease();
  const [wasDocumentReplaced, setWasDocumentReplaced] = useState(
    queryParams.get(QUERYSTRING.REPLACE_DOCUMENT) === "true",
  );
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const {
    spreadsheetData,
    resetPreviousData,
    gotoInvalidRow,
    getRemovedRowIds,
  } = useColumnMapper();
  const {
    getCellValue,
    getRowUomCreatableValue,
    getCostCodeId,
    rowIsEmpty,
    addMissingMaterials,
    addMissingZones,
    getCellWithAdditionalData,
    findMaterialByName,
  } = useTableHelpers();
  const {
    vendors,
    loading: loadingVendors,
    findOrderTypeByLocationId,
  } = useVendors();
  const { setWarningAlert } = useSnackbar();

  const [saving, setSaving] = useState(false);

  const { setValue, watch, getValues } =
    useFormContext<CreateOrderFromNoteFormValues>();

  const { zones } = useProjectZonesStore(
    useShallow((state) => ({
      zones: state.zones,
    })),
  );

  const projectId = watch("projectId");
  const poNumber = watch("poNumber");
  const orderDate = watch("orderDate");

  const {
    data: projectData,
    error: predictedPoNumberError,
    loading: loadingPredictedPoNumber,
  } = useProjectPredictedPoNumberQuery({
    variables: {
      id: projectId,
    },
    skip: !projectId || !!poNumber,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    const retroactiveRelease =
      orderDate && orderDate?.getTime() < new Date().getTime();
    const predictedPoNumber = projectData?.project?.predictedPoNumber;

    if (!retroactiveRelease && !predictedPoNumberError && predictedPoNumber) {
      return setValue("predictedPoNumber", predictedPoNumber);
    }

    return setValue("predictedPoNumber", "");
  }, [
    orderDate,
    projectData?.project?.predictedPoNumber,
    predictedPoNumberError,
    setValue,
  ]);

  const [createStandaloneRelease, { loading: creating }] =
    useCreateStandaloneReleaseMutation();
  const [updateRelease, { loading: updating }] =
    useUpdateContractorReleaseMutation();

  const validateSpreadsheet = () => {
    if (spreadsheetData.every((row) => rowIsEmpty(row))) {
      setWarningAlert(
        <FormattedMessage id={`VALIDATION_ERROR_SHEETS_EMPTY_LIST`} />,
      );
      return false;
    }

    if (
      !validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.Quantity,
      ]) ||
      !validateRowValues([
        COLUMN_TYPE.Quantity,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.CostCode,
      ])
    ) {
      gotoInvalidRow();
      return false;
    }

    return true;
  };
  const syncCreateReleaseFromNote = async (
    skipConfirmation?: boolean,
    retroactive?: boolean,
    status?: ReleaseStatus,
  ) => {
    const isValid = validateSpreadsheet();
    const values = getValues();

    if (!isValid) {
      return null;
    }

    const newItems: AddToReleaseItemInput[] = [];
    setSaving(true);
    const newMaterials = (await addMissingMaterials()) || [];
    const newZones = (await addMissingZones(values.projectId)) || [];
    setSaving(false);

    spreadsheetData.forEach((row, index) => {
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.Material,
      );
      let material = findMaterialByName(rowMaterialText, [
        ...materials,
        ...newMaterials,
      ]);

      const uom = getRowUomCreatableValue(row);
      const isLumpSum = isLumpSumUomText(uom);
      if (isLumpSum) {
        material = findMaterialByName(LUMP_SUM_UOM_PLURAL_DESCRIPTION, [
          ...materials,
          ...newMaterials,
        ]);
      }

      if (!material || rowIsEmpty(row)) {
        return null;
      }

      const zone = [...(zones || []), ...newZones].find(
        (z) => z?.name === getCellValue(row, COLUMN_TYPE.Zone),
      );

      const quantityDecimal = getCellValue(row, COLUMN_TYPE.Quantity);
      const unitPrice = getCellValue(row, COLUMN_TYPE.PrefilledPrice);
      const notes = getCellValue(row, COLUMN_TYPE.Notes);

      newItems.push({
        projectItem: {
          estimateUom: uom,
          ...(isOrgCatalogSku(material.material) && {
            orgCatalogSkuId: material.material.id,
          }),
          ...(isProductSku(material.material) && {
            masterProductId: material.material.id,
          }),
          ...(isMasterSku(material.material) && {
            masterSkuId: material.material.id,
          }),
        },
        quantityDecimal,
        unitPrice: unitPrice ? String(unitPrice) : undefined,
        ...(isLumpSum && { name: rowMaterialText }),
        position: index,
        costCodeId: getCostCodeId(row),
        instructions: { text: notes },
        zoneId: zone?.id,
      } as AddToReleaseItemInput);
    });

    if (newItems.length > 0) {
      try {
        const includeServices = [];
        if (!values.willCall) {
          includeServices.push({ type: ServiceType.Delivery });
        }
        if (!values.willCall && values.vendorStocking) {
          includeServices.push({ type: ServiceType.Stocking });
        }

        const relStatus =
          values.orderDate && values.orderDate?.getTime() < Date.now()
            ? retroactive
              ? ReleaseStatus.Received
              : ReleaseStatus.Scheduled
            : skipConfirmation
              ? ReleaseStatus.Scheduled
              : skipConfirmation === false
                ? ReleaseStatus.Requested
                : ReleaseStatus.Draft;

        const { data, errors } = await createStandaloneRelease({
          variables: {
            input: {
              projectId: values.projectId ?? "",
              sellerOrgLocationId: values.vendorId || undefined,
              poNumber: values.poNumber || undefined,
              time: values.orderDate?.getTime() ?? undefined,
              items: newItems,
              requiresInventoryReceipt: values.requiresInventoryReceipt,
              noteDocumentId: noteDocument?.id ?? "",
              includeServices,
              taxRate:
                values.taxRate ||
                (values.clearCustomTaxAmount ||
                values.customTaxAmount?.length === 0
                  ? "0"
                  : undefined),
              customTaxAmount:
                values.clearCustomTaxAmount ||
                values.customTaxAmount?.length === 0
                  ? undefined
                  : values.customTaxAmount,
              additionalCharges: values.additionalCharges,
              instructions: values.instructions,
              paymentTerm: values.paymentTerm,
              status: status || relStatus,
              typeId: values.orderTypeId || undefined,
              vendorContactIds: values.vendorContactIds,
              taxCodeId: values.taxCodeId,
              taxType: values.taxType,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: UomsDocument,
            },
          ],
        });
        setError(errors);
        if (!errors) {
          resetPreviousData();
        }
        return data?.createStandaloneRelease ?? null;
      } catch (error) {
        setError(error);
        return null;
      }
    }

    return release;
  };

  const syncUpdateReleaseFromNote = async () => {
    const values = getValues();
    if (
      !validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.Quantity,
      ]) ||
      !validateRowValues(
        [COLUMN_TYPE.Quantity, COLUMN_TYPE.UOM, COLUMN_TYPE.CostCode],
        undefined,
        { minPrice: undefined },
      )
    ) {
      gotoInvalidRow();
      return null;
    }

    const newItems: AddToReleaseItemInput[] = [];
    const itemsToUpdate: UpdateContractorReleaseItemInput[] = [];
    const itemsToRemove = getRemovedRowIds(release?.items ?? []);

    setSaving(true);
    const newMaterials = (await addMissingMaterials()) || [];
    const newZones = (await addMissingZones(release?.project?.id || "")) || [];
    setSaving(false);

    spreadsheetData.forEach((row, index) => {
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.Material,
      );
      let material = findMaterialByName(rowMaterialText, [
        ...materials,
        ...newMaterials,
      ]);

      const uom = getRowUomCreatableValue(row);
      const isLumpSum = isLumpSumUomText(uom);
      if (isLumpSum) {
        material = findMaterialByName(LUMP_SUM_UOM_PLURAL_DESCRIPTION, [
          ...materials,
          ...newMaterials,
        ]);
      }

      if (!material || rowIsEmpty(row)) {
        return null;
      }

      const zone = [...(zones || []), ...newZones].find(
        (z) => z?.name === getCellValue(row, COLUMN_TYPE.Zone),
      );

      const quantityDecimal = getCellValue(row, COLUMN_TYPE.Quantity);
      const unitPrice = getCellValue(row, COLUMN_TYPE.PrefilledPrice);

      const hasUnitPrice =
        unitPrice !== "" && unitPrice !== null && unitPrice !== undefined;
      const notes = getCellValue(row, COLUMN_TYPE.Notes);

      const existingItem = release?.items.find((item) => item.id === row.id);
      const matchingMaterials =
        existingItem && existingItem.projectItem?.material.id === material.id;

      if (matchingMaterials) {
        itemsToUpdate.push({
          releaseItemId: row.id,
          uom,
          quantityDecimal: quantityDecimal,
          unitPrice: hasUnitPrice ? String(unitPrice) : undefined,
          ...(isLumpSum && { name: rowMaterialText }),
          clearUnitPrice: !hasUnitPrice,
          position: index,
          costCodeId: getCostCodeId(row),
          zoneId: zone?.id,
          instructions: { text: notes },
        });
      } else {
        newItems.push({
          projectItem: {
            estimateUom: uom ?? "",
            ...(isOrgCatalogSku(material.material) && {
              orgCatalogSkuId: material.material.id,
            }),
            ...(isProductSku(material.material) && {
              masterProductId: material.material.id,
            }),
            ...(isMasterSku(material.material) && {
              masterSkuId: material.material.id,
            }),
          },
          quantityDecimal,
          unitPrice: unitPrice ? String(unitPrice) : undefined,
          ...(isLumpSum && { name: rowMaterialText }),
          position: index,
          costCodeId: getCostCodeId(row),
          zoneId: zone?.id,
          instructions: { text: notes },
        });
        if (row.id) {
          itemsToRemove.push(row.id);
        }
      }
    });

    const includeServices = [];
    if (!values.willCall) {
      includeServices.push({ type: ServiceType.Delivery });
    }
    if (!values.willCall && values.vendorStocking) {
      includeServices.push({ type: ServiceType.Stocking });
    }

    if (release) {
      try {
        const { data, errors } = await updateRelease({
          variables: {
            input: {
              releaseId: release.id,
              version: release.version,
              addedItems: newItems,
              updates: itemsToUpdate,
              removedItems: itemsToRemove,
              taxRate: values.taxRate || undefined,
              customTaxAmount: values.customTaxAmount || undefined,
              clearCustomTaxAmount: !values.customTaxAmount,
              additionalCharges: values.additionalCharges.filter(
                (charge) => charge.description && Number(charge.amount) > 0,
              ),
              poNumber: values.poNumber,
              requestedTime: values.orderDate?.getTime() ?? undefined,
              requiresInventoryReceipt: values.requiresInventoryReceipt,
              includeServices,
              instructions: values.instructions,
              typeId: values.orderTypeId || undefined,
              assignDefaultCostCodes: false,
              prefillPrices: false,
              sellerOrgLocationId: values.vendorId || undefined,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: RELEASE,
              variables: { id: release?.id ?? "" },
            },
            {
              query: UomsDocument,
            },
          ],
        });
        setError(errors);
        if (!errors) {
          resetPreviousData();
        }
        return data?.updateContractorRelease ?? null;
      } catch (error) {
        setError(error);
        return null;
      }
    }

    return release;
  };

  return (
    <ProviderContext.Provider
      value={{
        syncCreateReleaseFromNote,
        syncUpdateReleaseFromNote,
        validateSpreadsheet,
        loading: saving || creating || updating,
        vendors,
        loadingVendors,
        findOrderTypeByLocationId,
        loadingPredictedPoNumber,
        setWasDocumentReplaced,
        wasDocumentReplaced,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useOrderFromNote = (): ProviderContextType =>
  useContext(ProviderContext);
