import { useGlobalError } from "@/common/hooks/useGlobalError";
import { mergeChanges } from "@/common/utils/mergeChanges";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import {
  AddToReleaseItemInput,
  ProjectFieldsFragment,
  ReleaseDocument,
  ReleaseFieldsFragment,
  ReleaseItemFieldsFragment,
  ReleaseQuery,
  ReleaseStatus,
  UpdateContractorReleaseInput,
  UpdateContractorReleaseItemInput,
  UpdateContractorReleaseMutation,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBoolean,
  NoFunctionBooleanPromise,
} from "@/types/NoFunction";
import { FetchResult, useApolloClient } from "@apollo/client";
import Decimal from "decimal.js";
import { FC, createContext, useContext, useRef, useState } from "react";

import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { useTaxCodeSummaries } from "@/common/components/sales-tax-input/hooks/useTaxCodeSummaries";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useIntl } from "react-intl";
import { extPriceCalculation } from "../hooks/usePriceCalculation";
import { useReleaseMutations } from "../pages/specify-details/hooks/useReleaseMutations";
import { useReleaseStore } from "../store/useReleaseStore";
import { ReleaseUpdate } from "../types/ReleaseUpdate";
import { useRelease } from "./ReleaseProvider";
import { useReleaseCacheUpdate } from "./useReleaseCacheUpdate";

type Props = {
  children: React.ReactNode;
  items: ReleaseItemFieldsFragment[];
  release: ReleaseUpdate;
  project?: Pick<ProjectFieldsFragment, "tags" | "id"> | undefined | null;
};

type SubmitUpdateInputCallbackParams = {
  version?: number;
};

type SubmitUpdateInput = {
  skipConfirmation?: boolean;
  receive?: boolean;
  forceVersion?: boolean;
  callback?: (result: boolean, params: SubmitUpdateInputCallbackParams) => void;
  input?: UpdateContractorReleaseInput | null | undefined;
};

type ProviderContextType = {
  updateRelease: (
    input: UpdateContractorReleaseInput,
    options?: { batch?: boolean; setLoading?: boolean } | undefined,
  ) => Promise<boolean>;
  removeReleaseItem: (ids: string) => void;
  hasChanges: () => boolean;
  submitUpdate: (
    releaseNumber?: number,
    input?: SubmitUpdateInput,
  ) => boolean | Promise<boolean>;
  updating: boolean;
  release: Pick<
    ReleaseFieldsFragment,
    "id" | "version" | "status" | "sellerOrgLocation" | "poLink"
  >;
  project?: Pick<ProjectFieldsFragment, "tags" | "id"> | undefined | null;
  removing: boolean;
  addItems: (itemsToAdd: AddToReleaseItemInput[]) => Promise<boolean>;
  selectedReleaseItemIds: string[];
  setSelectedReleaseItemIds: (id: string[]) => void;
  updates: UpdateContractorReleaseItemInput[] | undefined | null;
  updatingChanges: boolean;
  changes?: UpdateContractorReleaseInput;
  requireDeliverySlip: boolean;
  setRequireDeliverySlip: (val: boolean) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  updateRelease: NoFunctionBooleanPromise,
  submitUpdate: NoFunctionBoolean,
  hasChanges: () => false,
  updating: false,
  release: {
    id: "",
    version: 0,
    status: ReleaseStatus.Draft,
  },
  project: {
    id: "",
    tags: [],
  },
  removeReleaseItem: NoFunctionBooleanPromise,
  removing: false,
  addItems: NoFunctionBooleanPromise,
  selectedReleaseItemIds: [],
  setSelectedReleaseItemIds: NoFunction,
  updates: [],
  updatingChanges: false,
  requireDeliverySlip: false,
  setRequireDeliverySlip: NoFunction,
  changes: undefined,
});

export const ReleaseUpdateProvider: FC<Props> = ({
  children,
  release,
  project,
  items,
}) => {
  const client = useApolloClient();
  const { setError } = useGlobalError();
  const [itemsToRemove, setItemsToRemove] = useState<string[]>([]);
  const [removing, setRemoving] = useState(false);
  const changes = useRef<UpdateContractorReleaseInput>();
  const updateReleaseCache = useReleaseCacheUpdate();
  const { chainUpdateContractorReleaseMutation, executionCount, updating } =
    useReleaseMutations();
  const { addItemsToRelease, invoiceStatuses } = useRelease();
  const { updateStoreRelease } = useReleaseStore();
  const { manufacturers } = useManufacturers();
  const { taxCodes } = useTaxCodeSummaries();
  const { orderTypes } = useOrderTypeOptions();

  const [selectedReleaseItemIds, setSelectedReleaseItemIds] = useState<
    string[]
  >([]);
  const [requireDeliverySlip, setRequireDeliverySlip] = useState(
    release.type.requireDeliverySlip,
  );
  const { setSuccessAlert } = useSnackbar();
  const intl = useIntl();

  const [updatingChanges, setUpdatingChanges] = useState(false);
  const {
    setHasChanges,
    updateStoreReleaseVersion,
    release: releaseStore,
  } = useReleaseStore();

  const removeReleaseItem = (id: string) => {
    if (
      !checkReleaseStatus(release, [
        ReleaseStatus.Draft,
        ReleaseStatus.AwaitingApproval,
        ReleaseStatus.Rejected,
        ReleaseStatus.Received,
        ReleaseStatus.PartiallyReceived,
      ])
    ) {
      setItemsToRemove((prev) => [...prev, id]);
      setHasChanges(true);
      client.cache.updateQuery(
        {
          query: ReleaseDocument,
          variables: {
            id: release?.id || "",
          },
        },
        (data: ReleaseQuery | null) => {
          if (data?.release) {
            const result = {
              ...data,
              release: {
                ...data?.release,
                items: data.release.items.filter((item) => item.id !== id),
              },
            };
            const subtotal = result.release.items.reduce(
              (acc: number, item) =>
                Number(
                  new Decimal(acc).add(
                    extPriceCalculation(item?.quantityDecimal, item.unitPrice),
                  ),
                ),
              0,
            );
            const charges = result.release.additionalCharges.reduce(
              (acc, charge) =>
                Number(new Decimal(acc).add(new Decimal(charge.amount || 0))),
              0,
            );
            const rate = result.release.taxCode?.rate || data?.release?.taxRate;
            const taxAmount = new Decimal(subtotal).add(charges).mul(rate ?? 0);
            return {
              ...result,
              release: {
                ...result.release,
                taxAmount: taxAmount?.toString(),
                subtotal: subtotal.toString(),
                total: new Decimal(subtotal)
                  .add(charges)
                  .add(data?.release?.taxAmount || 0)
                  .toString(),
              },
            };
          }
          if (!changes.current) {
            changes.current = {
              releaseId: release.id,
              version: release.version,
            };
          }
          return data;
        },
      );
      return false;
    }
    chainUpdateContractorReleaseMutation({
      queueItem: {
        id: release.id,
        params: {
          releaseId: release.id,
          removedItems: [id],
          version: release.version,
        },
        updateApolloCache: (cache, result) => {
          const { data } =
            result as FetchResult<UpdateContractorReleaseMutation>;
          if (!data?.updateContractorRelease) {
            return;
          }
          cache.writeQuery({
            query: ReleaseDocument,
            variables: {
              id: release.id || "",
            },
            data: {
              release: {
                ...data.updateContractorRelease,
                items: data.updateContractorRelease.items.filter(
                  (item) => item.id !== id,
                ),
              },
            },
          });
        },
        callback: (
          queueItems: UpdateContractorReleaseInput[],
          result?: FetchResult<UpdateContractorReleaseMutation>,
        ) => {
          if (result?.data?.updateContractorRelease) {
            updateStoreReleaseVersion(result?.data?.updateContractorRelease);
          }
          setRemoving(false);
          const errors = result?.errors;
          if (!errors) {
            setSuccessAlert(intl.$t({ id: "REMOVED_ITEM_FROM_RELEASE" }));
          } else {
          }
          setError(errors);
          return queueItems;
        },
      },
    });
  };

  const updateRelease = async (
    input: UpdateContractorReleaseInput,
    options?: { batch?: boolean; setLoading?: boolean } | undefined,
  ) => {
    updateReleaseCache(input, changes, { invoiceStatuses });
    updateStoreRelease(input, { taxCodes, orderTypes });
    if (options?.setLoading) {
      setUpdatingChanges(true);
    }
    const result = await update(input, options?.batch);
    if (options?.setLoading) {
      setTimeout(() => {
        setUpdatingChanges(false);
      }, 500);
    }
    return result;
  };

  const addItems = async (
    itemsToAdd: AddToReleaseItemInput[],
  ): Promise<boolean> => {
    if (
      release &&
      itemsToAdd.length > 0 &&
      (await addItemsToRelease(itemsToAdd, itemsToRemove))
    ) {
      if (!changes.current) {
        // We want to ensure that changes are non-empty so that the update
        // mutation can be triggered following item addition/removal, even if
        // its input is a no-op (to send a notification).
        changes.current = {
          releaseId: release.id,
          version: release.version,
        };
      }
      return true;
    }
    return false;
  };

  const update = async (
    input: UpdateContractorReleaseInput,
    batch = true,
    forceVersion = false,
  ) => {
    accumulateChanges(input);
    if (
      release.status !== ReleaseStatus.Draft &&
      release.status !== ReleaseStatus.AwaitingApproval &&
      release.status !== ReleaseStatus.Rejected &&
      batch
    ) {
      return false;
    }

    if (input.version) {
      const result = await submitUpdate(input.version, {
        skipConfirmation: input.skipConfirmation || false,
        forceVersion,
      });
      if (changes.current && result) {
        await update(
          {
            ...changes.current,
            releaseId: release.id,
            warehouseId: input.warehouseId,
            clearWarehouse: !input.warehouseId,
            clearSiteContact: !input.siteContactId,
            skipConfirmation: input.skipConfirmation,
            assignDefaultCostCodes: false,
          },
          batch,
          true,
        );
      }
    }
    return true;
  };

  const accumulateChanges = (input: UpdateContractorReleaseInput) => {
    changes.current = {
      ...changes.current,
      ...input,
      updates: mergeChanges(
        changes.current?.updates,
        input.updates,
        "releaseItemId",
      ),
    };
    changes.current.updates = changes.current?.updates?.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.releaseItemId === item.releaseItemId),
    );
    setHasChanges(true);
  };

  const hasChanges = () => Boolean(changes.current) || itemsToRemove.length > 0;

  const submitUpdate = (
    releaseVersion?: number,
    {
      skipConfirmation,
      receive,
      forceVersion,
      callback: customCallback,
      input,
    }: SubmitUpdateInput = {
      skipConfirmation: false,
      forceVersion: false,
    },
  ) => {
    if (input) {
      accumulateChanges(input);
    }

    const version = forceVersion
      ? (releaseVersion ?? releaseStore?.version)
      : (releaseStore?.version ?? releaseVersion);
    if (changes.current || itemsToRemove.length) {
      const allChanges = {
        ...changes.current,
        updates: changes.current?.updates?.filter(
          (u) => !itemsToRemove.includes(u.releaseItemId),
        ),
        removedItems: itemsToRemove,
      } as UpdateContractorReleaseInput;

      chainUpdateContractorReleaseMutation({
        queueItem: {
          id: release.id,
          params: {
            ...allChanges,
            updates: receive
              ? items
                  .filter((item) =>
                    selectedReleaseItemIds.find((id) => id === item.id),
                  )
                  .filter(
                    (item) =>
                      !allChanges?.updates?.find(
                        (u) =>
                          u.releaseItemId === item.id &&
                          u.receivedQuantityDecimal === "0",
                      ),
                  )
                  .map((item) => {
                    const existingItem = items.find(
                      (releaseItem) => releaseItem.id === item.id,
                    );
                    const update = allChanges?.updates?.find(
                      (u) => u.releaseItemId === item.id,
                    );
                    return update
                      ? {
                          ...update,
                          receivedQuantityDecimal: new Decimal(
                            update.receivedQuantityDecimal || 0,
                          )
                            .plus(existingItem?.receivedQuantityDecimal || 0)
                            .toString(),
                        }
                      : {
                          releaseItemId: item.id,
                          receivedQuantityDecimal:
                            existingItem?.quantityDecimal,
                        };
                  })
              : allChanges.updates,
            releaseId: release.id,
            version,
            skipConfirmation,
            receive,
            issues: allChanges.issues,
          },
          updateApolloCache: (cache, result) => {
            const { data } =
              result as FetchResult<UpdateContractorReleaseMutation>;
            const releaseQuery = cache.readQuery<ReleaseQuery>({
              query: ReleaseDocument,
              variables: {
                id: release.id || "",
              },
            });
            if (releaseQuery?.release && data?.updateContractorRelease) {
              cache.writeQuery({
                query: ReleaseDocument,
                variables: {
                  id: release.id || "",
                },
                data: {
                  release: {
                    ...releaseQuery.release,
                    instructions: allChanges.instructions
                      ? data?.updateContractorRelease?.instructions
                      : releaseQuery.release?.instructions,
                    version: data?.updateContractorRelease.version,
                    items: releaseQuery.release.items.map((item) => {
                      const updatedItem = (
                        changes.current?.updates ?? allChanges.updates
                      )?.find((u) => u.releaseItemId === item.id);
                      if (updatedItem) {
                        return {
                          ...item,
                          quantityDecimal:
                            updatedItem?.quantityDecimal ||
                            item.quantityDecimal,
                          unitPrice: updatedItem?.unitPrice || item.unitPrice,
                          manufacturer: updatedItem?.clearManufacturer
                            ? null
                            : updatedItem?.manufacturerId
                              ? manufacturers.find(
                                  (manufacturer) =>
                                    manufacturer.id ===
                                    updatedItem?.manufacturerId,
                                )
                              : item.manufacturer,
                          tags: changes.current?.updates?.find(
                            (u) => u.releaseItemId === item.id,
                          )?.tags
                            ? (project?.tags || []).filter((t) =>
                                changes.current?.updates
                                  ?.find((u) => u.releaseItemId === item.id)
                                  ?.tags?.includes(t.id),
                              )
                            : item.tags,
                        };
                      }
                      return item;
                    }),
                  },
                },
              });
              changes.current = undefined;
            }
          },
          callback: (
            queueItems: UpdateContractorReleaseInput[],
            result?: FetchResult<UpdateContractorReleaseMutation>,
          ) => {
            if (result?.data?.updateContractorRelease) {
              updateStoreReleaseVersion(result?.data?.updateContractorRelease);
            }
            setHasChanges(false);
            setRemoving(false);
            const errors = result?.errors;
            setError(errors);
            customCallback?.(!!result?.data, {
              version: result?.data?.updateContractorRelease.version,
            });
            return queueItems;
          },
        },
      });
    } else {
      customCallback?.(true, { version });
      return true;
    }
    setItemsToRemove([]);
    setHasChanges(false);
    return false;
  };

  return (
    <ProviderContext.Provider
      value={{
        updateRelease,
        hasChanges,
        submitUpdate,
        updating: executionCount.current > 0 || updating,
        release,
        project,
        removeReleaseItem,
        removing,
        addItems,
        selectedReleaseItemIds,
        setSelectedReleaseItemIds,
        requireDeliverySlip,
        setRequireDeliverySlip,
        updates: changes.current?.updates,
        updatingChanges,
        changes: changes.current,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReleaseUpdate = (): ProviderContextType =>
  useContext(ProviderContext);
