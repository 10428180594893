import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  DownloadInvoiceDocumentInput,
  InvoicesDocument,
  useDownloadInvoiceDocumentsMutation,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useInvoices } from "../../scanned-invoices/providers/InvoicesProvider";

export const useInvoicesDownload = () => {
  const { setError } = useGlobalError();
  const { paginationArgs, page, filter } = useInvoices();
  const [downloadInvoiceDocuments, { loading }] =
    useDownloadInvoiceDocumentsMutation();

  const downloadInvoices = useCallback(
    async (invoices: DownloadInvoiceDocumentInput[]) => {
      try {
        if (!invoices.length) {
          return;
        }

        const { data, errors } = await downloadInvoiceDocuments({
          variables: {
            input: {
              invoices,
            },
          },
          refetchQueries: [
            {
              query: InvoicesDocument,
              variables: {
                ...paginationArgs,
                page,
                filter,
              },
            },
          ],
        });

        if (errors) {
          setError(errors);
        }

        return data?.downloadInvoiceDocuments;
      } catch (error) {
        setError(error);
        return;
      }
    },
    [paginationArgs, page, filter, downloadInvoiceDocuments, setError],
  );

  return {
    downloadInvoices,
    loading,
  };
};
