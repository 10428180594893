import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { Tabs } from "@/common/components/tabs/Tabs";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { routes } from "@/config/routes";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useOrgSettings } from "../../org-settings/hooks/useOrgSettings";

export const AdminCostTabNavigation = () => {
  const intl = useIntl();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const { connectedSourceSystem } = useOrgSettings();

  const tabs = useMemo(
    () => [
      {
        label: intl.$t({ id: "COST_CODES" }),
        path: routes.costStructure,
      },
      ...(!connectedSourceSystem ||
      hasFeatureInConnectedSourceSystem(IntegrationFeature.IncludeCostTypes)
        ? [
            {
              label: intl.$t({ id: "COST_TYPES" }),
              path: routes.costTypes,
            },
          ]
        : []),
      ...(!connectedSourceSystem ||
      hasFeatureInConnectedSourceSystem(IntegrationFeature.IncludeEquipment)
        ? [
            {
              label: intl.$t({ id: "EQUIPMENT" }),
              path: routes.equipment,
            },
          ]
        : []),
      ...(!connectedSourceSystem ||
      hasFeatureInConnectedSourceSystem(IntegrationFeature.IncludeServiceCodes)
        ? [
            {
              label: intl.$t({ id: "SERVICE_CODES" }),
              path: routes.serviceCodes,
            },
          ]
        : []),
      ...(!!connectedSourceSystem &&
      hasFeatureInConnectedSourceSystem(
        IntegrationFeature.IncludeTaxAuthorities,
      )
        ? [
            {
              label: intl.$t({ id: "TAX_CODES" }),
              path: routes.taxCodes,
            },
          ]
        : []),
    ],
    [connectedSourceSystem, hasFeatureInConnectedSourceSystem, intl],
  );

  return <Tabs tabs={tabs} type="secondary" className="pt-4" />;
};
