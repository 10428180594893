import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { getIsNativeSalesTax } from "@/common/components/sales-tax-input/utils/salesTaxUtils";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import {
  LUMP_SUM_UOM_PLURAL_DESCRIPTION,
  RELEASE_DRAFT_STATUSES,
} from "@/common/const";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { RELEASE } from "@/common/queries/release";
import { cleanQuery } from "@/common/utils/cacheUtils";
import { getEst8amDate } from "@/common/utils/dates/getEst8amDate";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useProjectZonesStore } from "@/contractor/pages/home/project/store/projectZonesStore";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  AddToReleaseItemInput,
  namedOperations,
  UomsDocument,
  UpdateContractorReleaseInput,
  UpdateContractorReleaseItemInput,
  useSubmitReleaseMutation,
  useUpdateContractorReleaseMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import { createContext, FC, useContext, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { InvoiceCreateReleaseFormValues } from "../components/matched-order/components/InvoiceVerificationForm";
import { useLumpSumInvoiceReleaseItems } from "../hooks/useLumpSumInvoiceReleaseItems";
import { useInvoiceVerification } from "./InvoiceVerificationProvider";

type ProviderContextType = {
  syncUpdateReleaseFromInvoice: (
    values: InvoiceCreateReleaseFormValues,
  ) => Promise<boolean>;
  saving: boolean;
  updateRelease: (input: UpdateContractorReleaseInput) => Promise<boolean>;
  itemized: boolean;
  setItemized: (itemized: boolean) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  syncUpdateReleaseFromInvoice: NoFunctionBooleanPromise,
  saving: false,
  updateRelease: NoFunctionBooleanPromise,
  itemized: false,
  setItemized: NoFunction,
});

export const InvoiceUpdateReleaseProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { invoice, refetch } = useInvoiceVerification();
  const [itemized, setItemized] = useState(true);
  const { getOrderType } = useOrderTypeOptions();

  const [submitReleaseMutation] = useSubmitReleaseMutation({
    update: (cache) => cleanQuery(cache, namedOperations.Query.Releases),
  });
  const {
    spreadsheetData,
    getRemovedRowIds,
    resetPreviousData,
    gotoInvalidRow,
  } = useColumnMapper();
  const {
    getCellValue,
    getRowUomCreatableValue,
    getCostCodeId,
    rowIsEmpty,
    addMissingMaterials,
    addMissingZones,
    getCellWithAdditionalData,
    findMaterialByName,
  } = useTableHelpers();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const { setError } = useGlobalError();
  const { release } = useRelease();
  const { materials } = useMaterials();
  const [saving, setSaving] = useState(false);
  const [updateReleaseMutation, { loading: updating }] =
    useUpdateContractorReleaseMutation();
  const { getLumpSumReleaseItem } = useLumpSumInvoiceReleaseItems();
  const { zones } = useProjectZonesStore(
    useShallow((state) => ({
      zones: state.zones,
    })),
  );

  const syncUpdateReleaseFromInvoice = async (
    values: InvoiceCreateReleaseFormValues,
  ) => {
    if (
      itemized &&
      (!validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.Quantity,
      ]) ||
        !validateRowValues(
          [COLUMN_TYPE.Quantity, COLUMN_TYPE.UOM, COLUMN_TYPE.CostCode],
          undefined,
          { minPrice: undefined },
        ))
    ) {
      gotoInvalidRow();
      return false;
    }

    let newItems: AddToReleaseItemInput[] = [];
    const itemsToUpdate: UpdateContractorReleaseItemInput[] = [];
    let itemsToRemove: string[];

    if (itemized) {
      setSaving(true);
      itemsToRemove = [...getRemovedRowIds(release?.items ?? [])];
      const newMaterials = (await addMissingMaterials()) || [];
      const newZones =
        (await addMissingZones(release?.project?.id || "")) || [];
      setSaving(false);

      spreadsheetData.forEach((row, index) => {
        const rowMaterialText = getCellWithAdditionalData(
          row,
          COLUMN_TYPE.Material,
        );
        let material = findMaterialByName(rowMaterialText, [
          ...materials,
          ...newMaterials,
        ]);

        const uom = getRowUomCreatableValue(row);
        const isLumpSum = isLumpSumUomText(uom);
        if (isLumpSum) {
          material = findMaterialByName(LUMP_SUM_UOM_PLURAL_DESCRIPTION, [
            ...materials,
            ...newMaterials,
          ]);
        }

        if (!material || rowIsEmpty(row)) {
          return false;
        }

        const quantityDecimal = getCellValue(row, COLUMN_TYPE.Quantity);
        const unitPrice = getCellValue(row, COLUMN_TYPE.UnitPrice);
        const notes = getCellValue(row, COLUMN_TYPE.Notes);

        const hasUnitPrice =
          unitPrice !== "" && unitPrice !== null && unitPrice !== undefined;
        const zone = [...(zones || []), ...newZones].find(
          (z) => z?.name === getCellValue(row, COLUMN_TYPE.Zone),
        );

        const existingItem = release?.items.find((item) => item.id === row.id);
        const matchingMaterials =
          existingItem && existingItem.projectItem?.material.id === material.id;

        if (matchingMaterials) {
          itemsToUpdate.push({
            releaseItemId: row.id,
            uom,
            quantityDecimal: quantityDecimal,
            unitPrice: hasUnitPrice ? String(unitPrice) : undefined,
            ...(isLumpSum && { name: rowMaterialText }),
            clearUnitPrice: !hasUnitPrice,
            position: index,
            zoneId: zone?.id,
            costCodeId: getCostCodeId(row),
            clearCostCode: !getCostCodeId(row),
            instructions: { text: notes },
          });
        } else {
          newItems.push({
            invoiceId: invoice?.id ?? "",
            projectItem: {
              estimateUom: uom ?? "",
              ...(isOrgCatalogSku(material.material) && {
                orgCatalogSkuId: material.material.id,
              }),
              ...(isProductSku(material.material) && {
                masterProductId: material.material.id,
              }),
              ...(isMasterSku(material.material) && {
                masterSkuId: material.material.id,
              }),
            },
            quantityDecimal: quantityDecimal,
            unitPrice: hasUnitPrice ? String(unitPrice) : undefined,
            ...(isLumpSum && { name: rowMaterialText }),
            position: index,
            costCodeId: getCostCodeId(row),
            instructions: { text: notes },
            zoneId: zone?.id,
          });
          if (row.id) {
            itemsToRemove.push(row.id);
          }
        }
      });
    } else {
      itemsToRemove = release?.items.map((item) => item.id) ?? [];
      newItems = [...(await getLumpSumReleaseItem(values))];
    }

    if (release) {
      try {
        const isNativeSalesTax = getIsNativeSalesTax(
          getOrderType(values.orderTypeId),
        );
        const { data, errors } = await updateReleaseMutation({
          variables: {
            input: {
              releaseId: release.id,
              version: release.version,
              addedItems: newItems,
              poNumber: values.poNumber,
              updates: itemsToUpdate,
              removedItems: itemsToRemove,
              taxRate: isNativeSalesTax
                ? undefined
                : values.taxRate || undefined,
              customTaxAmount: isNativeSalesTax
                ? undefined
                : values.customTaxAmount || undefined,
              taxCodeId: isNativeSalesTax ? values.taxCodeId : undefined,
              taxType: isNativeSalesTax ? values.taxType : undefined,
              clearCustomTaxAmount: !values.customTaxAmount,
              additionalCharges: values.additionalCharges?.filter(
                (charge) => charge.description && Number(charge.amount) > 0,
              ),
              assignDefaultCostCodes: false,
              prefillPrices: false,
              requestedTime: getEst8amDate(values.orderDate)?.getTime(),
              typeId: values.orderTypeId,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: RELEASE,
              variables: { id: release?.id ?? "" },
            },
            {
              query: UomsDocument,
            },
          ],
        });

        if (
          data?.updateContractorRelease.status &&
          RELEASE_DRAFT_STATUSES.includes(
            data?.updateContractorRelease.status,
          ) &&
          data.updateContractorRelease.items.length > 0
        ) {
          await submitReleaseMutation({
            variables: {
              input: {
                releaseId: data?.updateContractorRelease.id,
                version: data?.updateContractorRelease.version,
                retroactive: true,
              },
            },
          });
        }
        setError(errors);
        if (!errors) {
          resetPreviousData();
          refetch();
        }
        return !errors;
      } catch (error) {
        setError(error);
        return false;
      }
    }

    return true;
  };

  const updateRelease = async (input: UpdateContractorReleaseInput) => {
    setSaving(true);
    try {
      const { errors } = await updateReleaseMutation({
        variables: {
          input,
        },
      });
      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    } finally {
      setSaving(false);
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        syncUpdateReleaseFromInvoice,
        updateRelease,
        saving: saving || updating,
        itemized,
        setItemized,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useInvoiceUpdateRelease = (): ProviderContextType =>
  useContext(ProviderContext);
