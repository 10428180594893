import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { getUTCDate } from "@/common/utils/dates/getUTCDate";
import { useVendors } from "@/contractor/pages/admin/vendors/hooks/useVendors";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/providers/WarehousesProvider";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import Decimal from "decimal.js";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { dateDiffInDays } from "../../scanned-invoices/utils/dateDiff";
import {
  invoiceCreateReleaseFormDefaultValues,
  InvoiceCreateReleaseFormValues,
} from "../components/matched-order/components/InvoiceVerificationForm";
import { useInvoiceImportExternalPO } from "../providers/InvoiceImportExternalPoProvider";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../providers/InvoiceVerificationProvider";

const EDITABLE_ORDER_VIEW_STATES = [
  MatchedOrderViewState.CREATE_ORDER,
  MatchedOrderViewState.EDIT_ORDER,
  MatchedOrderViewState.IMPORT_ORDER,
];

export const useInvoiceOrderEdit = () => {
  const { invoice } = useInvoiceVerification();
  const { matchedOrderViewState } = useInvoiceMatchedOrder();
  const { reset, getValues } = useFormContext<InvoiceCreateReleaseFormValues>();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const { locations } = useUserLocations();
  const { projects } = useProjectListOptions();
  const { warehouses } = useWarehouses();
  const { vendors, findOrderTypeByLocationId } = useVendors();
  const {
    externalPo,
    orderTypeId: externalOrderTypeId,
    selectedSellerOrgLocationId: externalVendorId,
  } = useInvoiceImportExternalPO();
  const { defaultOrderType } = useOrderTypeOptions();
  const intl = useIntl();

  const isOrderInEditMode = useMemo(
    () =>
      EDITABLE_ORDER_VIEW_STATES.includes(
        matchedOrderViewState ?? MatchedOrderViewState.DEFAULT,
      ),
    [matchedOrderViewState],
  );

  useEffect(() => {
    if (isOrderInEditMode) {
      const businessLocationId =
        getValues("businessLocationId") ||
        invoice?.release?.project?.location?.id ||
        externalPo?.project?.project?.location?.id ||
        invoice?.orgLocation?.id ||
        invoice?.predictedProject?.location?.id ||
        (locations.length === 1 ? locations[0].id : undefined);

      const projectId =
        getValues("projectId") ||
        invoice?.release?.project?.id ||
        externalPo?.project?.project?.id ||
        invoice?.predictedProject?.id ||
        (projects.length === 1 ? projects[0].id : undefined);

      const vendorId =
        getValues("vendorId") ||
        invoice?.release?.sellerOrgLocation?.id ||
        (externalPo ? externalVendorId : undefined) ||
        invoice?.predictedSellerOrgLocation?.id ||
        (vendors.length === 1 ? vendors[0].sellerOrgLocation.id : undefined);

      const fulfillmentLocationId =
        getValues("fulfillmentLocationId") ||
        invoice?.release?.warehouse?.id ||
        (warehouses.length === 1 ? warehouses[0].id : undefined) ||
        projectId;

      const orderTypeId =
        getValues("orderTypeId") ||
        invoice?.release?.type.id ||
        (externalPo ? externalOrderTypeId : undefined) ||
        (vendorId ? findOrderTypeByLocationId(vendorId) : undefined) ||
        defaultOrderType?.id;

      const poNumber = includePoNumbering
        ? getValues("poNumber") ||
          invoice?.release?.poNumber ||
          externalPo?.number ||
          invoice?.poNumber ||
          ""
        : "";

      const orderDate =
        getValues("orderDate") ||
        getUTCDate(
          invoice?.release?.time || externalPo?.date || invoice?.issueDate,
        );

      const customTaxAmount =
        getValues("customTaxAmount") ??
        (invoice?.release?.customTaxAmount &&
        invoice?.release.customTaxAmount !== "0"
          ? invoice?.release.customTaxAmount
          : undefined) ??
        (externalPo?.itemGroups?.salesTax
          ? externalPo.itemGroups.salesTax
              ?.reduce(
                (acc, next) =>
                  new Decimal(acc).add(new Decimal(next.amount)).toNumber(),
                0,
              )
              .toString()
          : undefined) ??
        invoice?.taxAmount ??
        undefined;

      const additionalCharges =
        getValues("additionalCharges") ??
        (invoice?.release?.additionalCharges.length
          ? invoice?.release.additionalCharges
          : undefined) ??
        externalPo?.itemGroups.additionalCharges.map((i) => ({
          amount: i.amount,
          description: i.description,
          id: i.externalId,
        })) ??
        (invoice?.chargesAmount && Number(invoice.chargesAmount) > 0
          ? [
              {
                amount: invoice?.chargesAmount,
                description: intl.$t({ id: "ADDITIONAL_CHARGES" }),
              },
            ]
          : undefined) ??
        invoice?.charges ??
        [];

      const subtotal =
        getValues("subtotal") ||
        (invoice?.release?.subtotal
          ? Number(invoice?.release.subtotal)
          : undefined) ||
        Number(invoice?.subtotal);

      const total = invoice?.total ?? "0";

      const paymentTerm =
        invoice?.dueDate && invoice?.issueDate
          ? dateDiffInDays(invoice.dueDate, invoice.issueDate)?.toString()
          : "";

      reset({
        ...getValues(),
        businessLocationId,
        projectId,
        vendorId,
        fulfillmentLocationId,
        poNumber,
        orderTypeId,
        orderDate,
        customTaxAmount,
        taxRate: undefined,
        additionalCharges,
        subtotal,
        total,
        paymentTerm,
      });
    } else {
      reset(invoiceCreateReleaseFormDefaultValues);
    }
  }, [
    isOrderInEditMode,
    invoice,
    getValues,
    includePoNumbering,
    reset,
    locations,
    projects,
    warehouses,
    vendors,
    externalPo,
    defaultOrderType,
    findOrderTypeByLocationId,
    externalOrderTypeId,
    externalVendorId,
    intl,
  ]);
};
