import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
  folder: {
    id: string;
    name: string;
  };
  setFolder: (folder: { id: string; name: string }) => void;
  name: string;
  setName: (name: string) => void;
  editVisible: boolean;
  setEditVisible: (editVisible: boolean) => void;
  reset: () => void;
};

export const useFolderEditStore = create<State>()(
  devtools((set) => ({
    folder: {
      id: "",
      name: "",
    },
    setFolder: (folder: { id: string; name: string }) =>
      set(() => ({ folder, name: folder.name })),
    name: "",
    setName: (name: string) => set(() => ({ name })),
    editVisible: false,
    setEditVisible: (editVisible: boolean) => set(() => ({ editVisible })),
    reset: () =>
      set(() => ({ folder: { id: "", name: "" }, editVisible: false })),
  })),
);
