import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { If } from "@/common/components/if/If";
import {
  ItemizedNonItemizedToggle,
  ViewType,
} from "@/common/components/itemized-non-itemized-toggle/ItemizedNonItemizedToggle";
import { useAddInvoiceItems } from "@/common/hooks/add-missing-items-to-order/useAddInvoiceItems";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceImportExternalPO } from "../../../../providers/InvoiceImportExternalPoProvider";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../../../providers/InvoiceMatchedOrderProvider";
import { InvoiceSelectModeHeader } from "../invoice-select-mode-header/InvoiceSelectModeHeader";
import { InvoiceCreateReleaseHeader } from "./InvoiceCreateReleaseHeader";
import { InvoiceCreateReleaseItemizedView } from "./InvoiceCreateReleaseItemizedView";
import { InvoiceCreateReleaseNonItemizedView } from "./InvoiceCreateReleaseNonItemizedView";
import { InvoiceExternalPOCostCodes } from "./InvoiceExternalPOCostCodes";

const Container = tw.div`w-full flex flex-col flex-1 items-start px-2.5`;
const HeaderGroup = tw.div`flex w-full place-items-center gap-1 mb-2`;
const ButtonGroup = tw.div`flex flex-row gap-2 min-w-screen-xs justify-end pr-2 items-center`;

export const InvoiceCreateRelease: FC = () => {
  const { addInvoiceItems } = useAddInvoiceItems();
  const { importedPoItemized, setImportedPoItemized, isNonItemizedPO } =
    useInvoiceImportExternalPO();
  const { matchedOrderViewState } = useInvoiceMatchedOrder();

  return (
    <Container>
      <HeaderGroup>
        <InvoiceSelectModeHeader
          classes={{
            root: "flex flex-full justify-evenly",
            row: "justify-start",
          }}
        >
          <ButtonGroup>
            <If isTrue={importedPoItemized}>
              <OutlinedButton $small onClick={() => addInvoiceItems()}>
                <FormattedMessage id="ADD_SCANNED_ITEMS_FROM_INVOICE" />
              </OutlinedButton>
            </If>
            <If
              isTrue={
                isNonItemizedPO ||
                matchedOrderViewState !== MatchedOrderViewState.IMPORT_ORDER
              }
            >
              <ItemizedNonItemizedToggle
                handleChange={(view) =>
                  setImportedPoItemized(view === ViewType.Itemized)
                }
                isItemized={!!importedPoItemized}
              />
            </If>
          </ButtonGroup>
        </InvoiceSelectModeHeader>
      </HeaderGroup>
      <InvoiceCreateReleaseHeader />
      <InvoiceExternalPOCostCodes />
      <If
        isTrue={
          importedPoItemized ||
          matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
        }
      >
        <InvoiceCreateReleaseItemizedView />
      </If>
      <If
        isTrue={
          !importedPoItemized &&
          matchedOrderViewState !== MatchedOrderViewState.IMPORT_ORDER
        }
      >
        <InvoiceCreateReleaseNonItemizedView />
      </If>
    </Container>
  );
};
