import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import {
  NestedWizardModalPage,
  WizardModalPage,
} from "@/common/components/wizard-modal/WizardModal";
import { DEFAULT_ITEMS_PER_PAGE, LUMP_SUM_UOM } from "@/common/const";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { useUomOptions } from "@/common/hooks/useUomOptions";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { ExternalPOItem } from "@/contractor/pages/home/common/external-po/ExternalPOItemsTable.configuration";
import { useReverseSyncPO } from "@/contractor/pages/home/release/components/connections/hooks/useReverseSyncPO";
import * as Types from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import Decimal from "decimal.js";
import * as React from "react";
import { UseFormReturn, useForm, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useDebounce } from "use-debounce";
import {
  ExternalPOsPaginatedOutput,
  useExternalPOsWithPagination,
} from "../../../../../../../common/hooks/useExternalPOsWithPagination";
import { useInvoiceExternalPoItemsValidation } from "../components/matched-order/components/invoice-import-external-po/invoice-external-po-items/hooks/useInvoiceExternalPoItemsValidation";
import { InvoiceExternalPOItems } from "../components/matched-order/components/invoice-import-external-po/invoice-external-po-items/InvoiceExternalPOItems";
import { InvoiceExternalPOs } from "../components/matched-order/components/invoice-import-external-po/invoice-external-pos/InvoiceExternalPOs";
import { InvoiceCreateReleaseFormValues } from "../components/matched-order/components/InvoiceVerificationForm";
import { useExternalPOUtils } from "../hooks/useExternalPOUtils";
import { useInvoicePermissions } from "../hooks/useInvoicePermissions";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "./InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "./InvoiceVerificationProvider";
import { ExternalPOsQueryInputType } from "./types/ExternalPOsQueryInputType";
import { InvoiceImportExternalPoOpenModalType } from "./types/InvoiceImportExternalPoOpenModalType";

type ProviderContextType = {
  importModalOpened: boolean;
  openModal: (props: InvoiceImportExternalPoOpenModalType) => void;
  closeModal: () => void;
  resetImportedData: () => void;
  modalPages: WizardModalPage[];
  externalPOsQueryForm:
    | UseFormReturn<ExternalPOsQueryInputType, unknown>
    | undefined;
  externalPOsPaginatedOutput: ExternalPOsPaginatedOutput;
  selectedBaseExternalPO: Types.ExternalPoBaseFieldsFragment | undefined;
  setSelectedBaseExternalPO: (
    baseExternalPO: Types.ExternalPoBaseFieldsFragment | undefined,
  ) => void;
  externalPo: Types.ExternalPoFieldsFragment | null | undefined;
  loadingExternalPo: boolean;
  includedItemsCategory: CategoryState<ExternalPOItem>[];
  missingTagsCategory: CategoryState<ExternalPOItem>[];
  missingCostCodesCategory: CategoryState<ExternalPOItem>[];
  unsupportedCostTypesCategory: CategoryState<ExternalPOItem>[];
  aggregatedItems: Types.ExternalPoItemFieldsFragment[] | undefined;
  importExternalCostCodes: () => void;
  importingExternalCostCodes: boolean;
  selectedSellerOrgLocationId: string | null;
  setSelectedSellerOrgLocationId: (id: string | null) => void;
  importedPoExternalId: string | null;
  importedPoItemized: boolean | null;
  setImportedPoItemized: (isItemized: boolean) => void;
  importedItems: null | Types.InvoiceItemFieldsFragment[];
  setImportedItems: (items: Types.InvoiceItemFieldsFragment[] | null) => void;
  orderTypeId?: string | null;
  setOrderTypeId?: (id: string | null) => void;
  hasMissingPoLink: boolean;
  importExternalPO: (
    externalPO: Types.ExternalPoFieldsFragment | null | undefined,
  ) => void;
  isNonItemizedPO: boolean;
};

const ProviderContext = React.createContext<ProviderContextType>({
  importModalOpened: false,
  openModal: NoFunction,
  closeModal: NoFunction,
  resetImportedData: NoFunction,
  modalPages: [],
  externalPOsQueryForm: undefined,
  externalPOsPaginatedOutput: {
    externalPOs: [],
    loadingExternalPOs: false,
    page: 1,
    count: 0,
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    hasPrevious: false,
    hasNext: false,
    nextPage: NoFunction,
    previousPage: NoFunction,
    setPageFn: NoFunction,
    setPageSizeFn: NoFunction,
  },
  selectedBaseExternalPO: undefined,
  setSelectedBaseExternalPO: NoFunction,
  externalPo: null,
  loadingExternalPo: false,
  includedItemsCategory: [],
  missingTagsCategory: [],
  missingCostCodesCategory: [],
  unsupportedCostTypesCategory: [],
  aggregatedItems: [],
  importExternalCostCodes: NoFunction,
  importingExternalCostCodes: false,
  selectedSellerOrgLocationId: null,
  setSelectedSellerOrgLocationId: NoFunction,
  importedPoExternalId: null,
  importedPoItemized: null,
  setImportedPoItemized: NoFunction,
  importedItems: null,
  setImportedItems: NoFunction,
  orderTypeId: null,
  setOrderTypeId: NoFunction,
  hasMissingPoLink: false,
  importExternalPO: NoFunction,
  isNonItemizedPO: false,
});

export const InvoiceImportExternalPOProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();
  const { setError } = useGlobalError();
  const { connectedSourceSystem } = useOrgSettings();
  const { setFooterState, invoice, shouldFetchExternalPO } =
    useInvoiceVerification();
  const { watch } = useFormContext<InvoiceCreateReleaseFormValues>();
  const { moveToNextStep, setStep } = useNestedStepper();
  const { setMatchedOrderViewState, setAutoMatching } =
    useInvoiceMatchedOrder();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const [importModalOpened, setImportModalOpened] = React.useState(false);
  const [hasMissingPoLink, setHasMissingPoLink] = React.useState(false);
  const [selectedBaseExternalPO, setSelectedBaseExternalPO] = React.useState<
    Types.ExternalPoBaseFieldsFragment | undefined
  >();
  const [selectedSellerOrgLocationId, setSelectedSellerOrgLocationId] =
    React.useState<string | null>(null);
  const [importedItems, setImportedItems] = React.useState<
    Types.InvoiceItemFieldsFragment[] | null
  >(null);
  const [orderTypeId, setOrderTypeId] = React.useState<string | null>(null);
  const [importedPoExternalId, setImportedPoExternalId] = React.useState<
    string | null
  >(null);
  const [importedPoItemized, setImportedPoItemized] = React.useState<
    boolean | null
  >(true);
  const { defaultOrderType } = useOrderTypeOptions();
  const { getUomByName } = useUomOptions();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const { openDialog } = useDialog();
  const {
    getUnsupportedCostTypesCategory,
    getMissingCostCodesCategory,
    getMissingTagsCategory,
    checkIfPOIsItemized,
    getIncludedItemsCategory,
  } = useExternalPOUtils();
  const { setSystemAlert } = useSnackbar();
  const { validate } = useInvoiceExternalPoItemsValidation();
  const { reverseSyncPO } = useReverseSyncPO();

  React.useEffect(() => {
    if (defaultOrderType && !orderTypeId) {
      setOrderTypeId(defaultOrderType.id);
    }
  }, [defaultOrderType, orderTypeId]);

  const externalPOsQueryForm = useForm<ExternalPOsQueryInputType>({
    defaultValues: {
      projectId: undefined,
      sellerOrgLocationID: undefined,
      search: undefined,
      minDate: undefined,
      maxDate: undefined,
    },
    mode: "onChange",
  });
  const projectId = externalPOsQueryForm.watch("projectId");
  const sellerOrgLocationID = externalPOsQueryForm.watch("sellerOrgLocationID");
  const search = externalPOsQueryForm.watch("search");
  const minDate = externalPOsQueryForm.watch("minDate");
  const maxDate = externalPOsQueryForm.watch("maxDate");
  const { fetchInvoicePermissions } = useInvoicePermissions();
  const externalPOsPaginatedOutput = useExternalPOsWithPagination({
    sourceSystem: connectedSourceSystem,
    projectId: !!projectId && projectId !== "" ? projectId : undefined,
    sellerOrgLocationID:
      !!sellerOrgLocationID && sellerOrgLocationID !== ""
        ? sellerOrgLocationID
        : undefined,
    search: search ?? undefined,
    minDate: minDate ? Number(minDate) : undefined,
    maxDate: maxDate ? Number(maxDate) : undefined,
    skip: !shouldFetchExternalPO || !includePoNumbering,
  });
  const invoiceCreateReleaseFormPoNumber = watch("poNumber");
  const invoiceCreateReleaseFormProjectId = watch("projectId");
  const [debouncedInvoiceCreateReleaseFormPoNumber] = useDebounce(
    invoiceCreateReleaseFormPoNumber,
    800,
  );

  const externalPoQueryOptions = React.useMemo(() => {
    if (selectedBaseExternalPO) {
      return {
        variables: {
          input: {
            sourceSystem:
              connectedSourceSystem ?? Types.SourceSystem.Foundation,
            externalId: selectedBaseExternalPO.externalId,
            projectId: hasFeatureInConnectedSourceSystem(
              IntegrationFeature.ExternalPoReadsProjectSpecific,
            )
              ? selectedBaseExternalPO.project?.project?.id
              : undefined,
            releaseTypeId: orderTypeId,
          },
        },
        skip:
          !shouldFetchExternalPO ||
          !connectedSourceSystem ||
          !selectedBaseExternalPO.externalId ||
          (hasFeatureInConnectedSourceSystem(
            IntegrationFeature.ExternalPoReadsProjectSpecific,
          )
            ? !selectedBaseExternalPO.project?.project?.id
            : false),
      };
    }
    if (!!invoice?.release && shouldFetchExternalPO) {
      return {
        variables: {
          input: {
            sourceSystem:
              connectedSourceSystem ?? Types.SourceSystem.Foundation,
            number: invoice?.release?.poNumber,
            projectId:
              !!connectedSourceSystem &&
              hasFeatureInConnectedSourceSystem(
                IntegrationFeature.ExternalPoReadsProjectSpecific,
              )
                ? invoice?.release?.project?.id
                : undefined,
          },
        },
        skip:
          !connectedSourceSystem ||
          !invoice?.release?.poNumber ||
          (hasFeatureInConnectedSourceSystem(
            IntegrationFeature.ExternalPoReadsProjectSpecific,
          )
            ? !invoice?.release?.project?.id
            : false),
      };
    }
    return {
      variables: {
        input: {
          sourceSystem: connectedSourceSystem ?? Types.SourceSystem.Foundation,
          number: debouncedInvoiceCreateReleaseFormPoNumber,
          projectId:
            !!connectedSourceSystem &&
            hasFeatureInConnectedSourceSystem(
              IntegrationFeature.ExternalPoReadsProjectSpecific,
            )
              ? invoiceCreateReleaseFormProjectId
              : undefined,
        },
      },
      skip:
        !shouldFetchExternalPO ||
        !connectedSourceSystem ||
        !debouncedInvoiceCreateReleaseFormPoNumber ||
        (hasFeatureInConnectedSourceSystem(
          IntegrationFeature.ExternalPoReadsProjectSpecific,
        )
          ? !invoiceCreateReleaseFormProjectId
          : false),
    };
  }, [
    selectedBaseExternalPO,
    invoice?.release,
    shouldFetchExternalPO,
    connectedSourceSystem,
    debouncedInvoiceCreateReleaseFormPoNumber,
    invoiceCreateReleaseFormProjectId,
    hasFeatureInConnectedSourceSystem,
    orderTypeId,
  ]);

  const {
    data,
    loading: loadingExternalPo,
    error,
    refetch,
  } = Types.useExternalPoQuery({
    ...externalPoQueryOptions,
    nextFetchPolicy: "network-only",
  });

  useErrorEffect(error);

  React.useEffect(() => {
    const sellerOrgLocationId =
      data?.externalPO?.vendor?.orgPreferredVendors?.[0]?.sellerOrgLocation.id;
    if (sellerOrgLocationId) {
      setSelectedSellerOrgLocationId(sellerOrgLocationId);
    }
  }, [data]);

  const [importCostCodesMutation, { loading: importingExternalCostCodes }] =
    Types.useImportCostCodesMutation();
  const importExternalCostCodes = React.useCallback(async () => {
    try {
      if (
        (data?.externalPO?.itemGroups.missingCostCode ?? []).length > 0 &&
        connectedSourceSystem
      ) {
        const externalCostCodeIds = (
          data?.externalPO?.itemGroups.missingCostCode ?? []
        )
          .filter((c) => !!c.externalCostCode?.externalId)
          .map((c) => c.externalCostCode?.externalId);
        await importCostCodesMutation({
          variables: {
            input: {
              sourceSystem: connectedSourceSystem,
              externalCostCodeIds: externalCostCodeIds as string[],
            },
          },
          refetchQueries: [{ query: Types.ViewerCostCodesDocument }],
        });
        if (!externalPoQueryOptions.skip) {
          await refetch();
        }
      }
    } catch (error) {
      setError(error);
    }
  }, [
    data?.externalPO?.itemGroups.missingCostCode,
    connectedSourceSystem,
    importCostCodesMutation,
    externalPoQueryOptions.skip,
    refetch,
    setError,
  ]);

  const missingTagsCategory = React.useMemo(
    () => getMissingTagsCategory(data?.externalPO),
    [data?.externalPO, getMissingTagsCategory],
  );
  const missingCostCodesCategory = React.useMemo(
    () => getMissingCostCodesCategory(data?.externalPO),
    [data?.externalPO, getMissingCostCodesCategory],
  );
  const unsupportedCostTypesCategory = React.useMemo(
    () => getUnsupportedCostTypesCategory(data?.externalPO),
    [data?.externalPO, getUnsupportedCostTypesCategory],
  );
  const includedItemsCategory = React.useMemo(
    () => getIncludedItemsCategory(data?.externalPO),
    [data?.externalPO, getIncludedItemsCategory],
  );
  const aggregatedItems = React.useMemo(
    () => data?.externalPO?.itemGroups.aggregatedItems,
    [data?.externalPO?.itemGroups],
  );
  const isNonItemizedPO = React.useMemo(
    () => !checkIfPOIsItemized(data?.externalPO),
    [checkIfPOIsItemized, data?.externalPO],
  );

  const openModal = React.useCallback(
    ({
      skipPoList,
      hasMissingPoLink,
    }: InvoiceImportExternalPoOpenModalType) => {
      setStep(skipPoList ? 1 : 0);
      setImportModalOpened(true);
      setHasMissingPoLink(!!hasMissingPoLink);
    },
    [setStep],
  );

  const importExternalPO = React.useCallback(
    (externalPO: Types.ExternalPoFieldsFragment | null | undefined) => {
      if (externalPO) {
        setSelectedBaseExternalPO(externalPO);
        const isNonItemizedPO = !checkIfPOIsItemized(externalPO);
        if (!isNonItemizedPO) {
          setImportedItems(
            (externalPO.itemGroups.importable ?? []).map((i) => {
              const isLumpSum = getUomByName(i.uom)?.mnemonic === LUMP_SUM_UOM;
              return {
                id: i.externalId,
                description: i.description,
                costCode: i.costCode?.code ?? i.wbsTag?.name,
                UOM: i.uom,
                quantityDecimal: isLumpSum ? i.amount : i.quantity,
                unitPrice: isLumpSum ? "1" : i.unitCost,
                extPrice: isLumpSum
                  ? i.amount
                  : new Decimal(i?.unitCost).mul(i?.quantity).toNumber(),
              };
            }),
          );
        } else {
          setImportedItems(
            externalPO.itemGroups.aggregatedItems.map((item) => {
              return {
                id: item.externalId,
                description: item.externalCostCode?.name || "",
                UOM: LUMP_SUM_UOM,
                quantityDecimal: item.quantity,
                unitPrice: item.unitCost,
                extPrice: item.amount,
              };
            }),
          );
        }
        setImportedPoExternalId(externalPO?.externalId);
        setImportedPoItemized(!isNonItemizedPO);
        if (
          !validate(
            selectedBaseExternalPO ?? externalPO,
            externalPO,
            isNonItemizedPO,
          )
        ) {
          setFooterState(InvoiceFooterState.IMPORT_ORDER);
          setMatchedOrderViewState(MatchedOrderViewState.IMPORT_ORDER);
          setSystemAlert(
            intl.$t(
              { id: "INVOICE_MATCHED_TO_EXTERNAL_PO" },
              {
                poNumber: invoice?.poNumber,
                integration: intl.$t({
                  id: `SOURCE_SYSTEM_${connectedSourceSystem}`,
                }),
              },
            ),
          );
          setImportModalOpened(false);
        } else {
          setFooterState(InvoiceFooterState.DEFAULT);
          setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
          openModal({
            skipPoList: true,
          });
        }
        setAutoMatching(false);
      }
    },
    [
      checkIfPOIsItemized,
      validate,
      selectedBaseExternalPO,
      setAutoMatching,
      getUomByName,
      setFooterState,
      setMatchedOrderViewState,
      setSystemAlert,
      intl,
      invoice?.poNumber,
      connectedSourceSystem,
      openModal,
    ],
  );

  const importSelectedExternalPO = React.useCallback(() => {
    importExternalPO(data?.externalPO);
    setFooterState(InvoiceFooterState.IMPORT_ORDER);
    setMatchedOrderViewState(MatchedOrderViewState.IMPORT_ORDER);
  }, [
    importExternalPO,
    data?.externalPO,
    setFooterState,
    setMatchedOrderViewState,
  ]);

  const importExternalPOWithConfirmation = React.useCallback(async () => {
    if (!invoice || !selectedBaseExternalPO?.project?.project?.id) {
      return;
    }
    const { data: invoicePermissionData } = await fetchInvoicePermissions(
      invoice?.id,
      selectedBaseExternalPO?.project?.project?.id,
    );
    if (
      invoicePermissionData?.invoice?.permissions.approve ===
      Types.AuthorizationStatus.Authorized
    ) {
      importSelectedExternalPO();
    } else {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "IMPORT_ORDER" }),
        icon: <WarningIcon />,
        titleClassName: "w-96",
        title: intl.$t({
          id: "IMPORT_ORDER",
        }),
        text: intl.$t({
          id: "APPROVER_NOTIFICATION_NOTE",
        }),
        handleConfirm: importSelectedExternalPO,
      });
    }
  }, [
    invoice,
    selectedBaseExternalPO?.project?.project?.id,
    fetchInvoicePermissions,
    importSelectedExternalPO,
    openDialog,
    intl,
  ]);

  const resetImportedData = React.useCallback(() => {
    setSelectedSellerOrgLocationId(null);
    setImportedPoExternalId(null);
    setImportedPoItemized(null);
    setImportedItems(null);
    setOrderTypeId(null);
    externalPOsQueryForm.reset();
  }, [externalPOsQueryForm]);

  const closeModal = React.useCallback(() => {
    setImportModalOpened(false);
    setSelectedBaseExternalPO(undefined);
  }, []);

  const syncImmutablePo = React.useCallback(() => {
    if (invoice?.release?.poLink?.immutable) {
      reverseSyncPO(invoice.release.poLink.id, invoice.release.id);
      closeModal();
    }
  }, [
    invoice?.release?.id,
    invoice?.release?.poLink,
    reverseSyncPO,
    closeModal,
  ]);

  const pages: WizardModalPage[] = React.useMemo(
    () => [
      {
        pages: [
          {
            hideHeader: true,
            component: <InvoiceExternalPOs />,
            onNextClick: moveToNextStep,
            onNextClickDisabled: !selectedBaseExternalPO,
            onCloseClick: closeModal,
          } as NestedWizardModalPage,
        ],
      },
      {
        pages: [
          {
            hideHeader: true,
            component: <InvoiceExternalPOItems />,
            onNextClick: !!invoice?.release?.poLink?.immutable
              ? syncImmutablePo
              : loadingExternalPo ||
                  ((data?.externalPO?.poLinks ?? []).length > 0 &&
                    !isNonItemizedPO)
                ? undefined
                : importExternalPOWithConfirmation,
            onNextClickDisabled: !!invoice?.release?.poLink?.immutable
              ? false
              : (isNonItemizedPO
                  ? false
                  : includedItemsCategory[0].items.length === 0) ||
                !data?.externalPO?.project?.project ||
                (data?.externalPO?.vendor?.orgPreferredVendors ?? []).length ===
                  0 ||
                ((data?.externalPO?.poLinks ?? []).length > 0 &&
                  !isNonItemizedPO),
            onNextLabel: intl.$t(
              {
                id: invoice?.release?.poLink?.immutable
                  ? "SYNC_IMMUTABLE_PO"
                  : isNonItemizedPO
                    ? "ASSOCIATE_PO_WITH_ORDER"
                    : "IMPORT",
              },
              {
                integration: connectedSourceSystem,
              },
            ),
            onNextClassName: isNonItemizedPO ? "w-60" : "",
            onCloseClick: closeModal,
          } as NestedWizardModalPage,
        ],
      },
    ],
    [
      selectedBaseExternalPO,
      loadingExternalPo,
      data?.externalPO?.poLinks,
      data?.externalPO?.project?.project,
      data?.externalPO?.vendor?.orgPreferredVendors,
      invoice?.release?.poLink?.immutable,
      isNonItemizedPO,
      includedItemsCategory,
      connectedSourceSystem,
      intl,
      closeModal,
      moveToNextStep,
      importExternalPOWithConfirmation,
      syncImmutablePo,
    ],
  );

  return (
    <ProviderContext.Provider
      value={{
        importModalOpened,
        openModal,
        closeModal,
        resetImportedData,
        modalPages: pages,
        externalPOsQueryForm,
        externalPOsPaginatedOutput,
        selectedBaseExternalPO,
        setSelectedBaseExternalPO,
        externalPo: data?.externalPO,
        loadingExternalPo,
        includedItemsCategory,
        missingTagsCategory,
        missingCostCodesCategory,
        unsupportedCostTypesCategory,
        aggregatedItems,
        importExternalCostCodes,
        importingExternalCostCodes,
        selectedSellerOrgLocationId,
        setSelectedSellerOrgLocationId,
        importedPoExternalId,
        importedPoItemized,
        setImportedPoItemized,
        importedItems,
        setImportedItems,
        orderTypeId,
        setOrderTypeId,
        hasMissingPoLink,
        importExternalPO,
        isNonItemizedPO,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useInvoiceImportExternalPO = () =>
  React.useContext(ProviderContext);
