import {
  AdditionalFiltersOverlay,
  Option,
} from "@/common/components/additional-filters-overlay/AdditionalFiltersOverlay";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { QueryBuyoutsFilter } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useBuyouts } from "../providers/BuyoutsProvider";

enum AdditionalFilter {
  Deleted = "DELETED",
  ClosedProjects = "CLOSED_PROJECTS",
  PoExported = "PO_EXPORTED",
  PoExists = "PO_EXISTS",
}

export const BuyoutsAdditionalFilters = () => {
  const { filter, setFilter } = useBuyouts();
  const intl = useIntl();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const { connectedSourceSystem } = useOrgSettings();

  const options = useMemo<Option<QueryBuyoutsFilter>[]>(() => {
    return [
      ...(includePoNumbering
        ? [
            {
              id: AdditionalFilter.PoExported,
              title: intl.$t({ id: "PO_EXPORTED" }),
              filter: (value: boolean | null) => ({
                exported: value,
              }),
              value: (currentFilter: QueryBuyoutsFilter | undefined) =>
                currentFilter?.exported,
            },
          ]
        : []),
      ...(includePoNumbering && connectedSourceSystem
        ? [
            {
              id: AdditionalFilter.PoExists,
              title: intl.$t(
                { id: "PO_EXISTS_IN_SOURCE_SYSTEM" },
                {
                  sourceSystem: intl.$t({
                    id: `INTEGRATION_${connectedSourceSystem}`,
                  }),
                },
              ),
              filter: (value: boolean | null) => ({
                poExists: value,
              }),
              value: (currentFilter: QueryBuyoutsFilter | undefined) =>
                currentFilter?.poExists,
            },
          ]
        : []),
      {
        id: AdditionalFilter.ClosedProjects,
        title: intl.$t({ id: "BUYOUTS_FROM_CLOSED_PROJECTS" }),
        filter: (value) => ({
          closedProjects: value,
        }),
        value: (currentFilter) => currentFilter?.closedProjects,
      },
      {
        id: AdditionalFilter.Deleted,
        title: intl.$t({ id: "DELETED" }),
        filter: (value) => ({
          deleted: value,
        }),
        value: (currentFilter) => currentFilter?.deleted,
      },
    ];
  }, [connectedSourceSystem, includePoNumbering, intl]);

  return (
    <AdditionalFiltersOverlay
      filter={filter}
      defaultFilter={{
        deleted: false,
      }}
      options={options}
      setFilter={setFilter}
    />
  );
};
