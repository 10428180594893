import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import {
  COLUMN_TYPE,
  SpreadSheetConfig,
} from "@/common/providers/ColumnMapperProvider";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useProjectZonesStore } from "@/contractor/pages/home/project/store/projectZonesStore";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";

export const useCreateOrderFromNoteConfig = (): SpreadSheetConfig[] => {
  const intl = useIntl();
  const { requiredValidator } = useTableValidators();
  const { watch } = useFormContext();
  const projectId = watch("projectId");
  const { costCodes } = useProjectCostCodes(projectId);
  const { zones } = useProjectZonesStore(
    useShallow((state) => ({
      zones: state.zones,
    })),
  );

  return [
    {
      header: intl.$t({ id: "ITEM_NAME" }),
      columnId: "name",
      width: 100,
      columnType: COLUMN_TYPE.Material,
    },
    {
      header: intl.$t({ id: "UOM" }),
      columnId: "UOM",
      width: 60,
      columnType: COLUMN_TYPE.UOM,
    },
    {
      header: intl.$t({ id: "QUANTITY" }),
      columnId: "quantityDecimal",
      width: 60,
      columnType: COLUMN_TYPE.Quantity,
    },
    {
      header: intl.$t({ id: "UNIT_PRICE" }),
      columnId: "unitPrice",
      width: 60,
      columnType: COLUMN_TYPE.PrefilledPrice,
      validator: requiredValidator,
    },
    {
      header: intl.$t({ id: "COST_CODE" }),
      columnId: "costCode",
      width: 80,
      columnType: COLUMN_TYPE.CostCode,
      options: costCodes.map((c) => c.formatted),
      metadata: { projectId },
    },
    {
      header: intl.$t({ id: "ZONE" }),
      columnId: "zone.name",
      width: 100,
      columnType: COLUMN_TYPE.Zone,
    },
    {
      header: intl.$t({ id: "NOTES" }),
      columnId: "notes",
      width: 100,
      columnType: COLUMN_TYPE.Notes,
      hidden: zones?.length > 0,
    },
  ];
};
