import {
  AdditionalFiltersOverlay,
  Option,
} from "@/common/components/additional-filters-overlay/AdditionalFiltersOverlay";
import { OrderTypesFilter } from "@/common/components/order-types-filter/OrderTypesFilter";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { RequestedByFilter } from "@/common/components/requested-by-filter/RequestedByFilter";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { QueryReleasesFilter } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDeliveries } from "../providers/DeliveriesProvider";

enum DeliveryAdditionalFilter {
  PoExported = "PO_EXPORTED",
  ReceiptPosted = "RECEIPT_POSTED",
  BackOrdered = "BACK_ORDERED",
  Retroactive = "RETROACTIVE",
  MaterialOrders = "MATERIAL_ORDERS",
  RentalOrders = "RENTAL_ORDERS",
  ServiceOrders = "SERVICE_ORDERS",
  Deleted = "DELETED",
  ClosedProjects = "CLOSED_PROJECTS",
  PoExists = "PO_EXISTS",
}

export const DeliveriesAdditionalFilters = () => {
  const { filter, setFilter } = useDeliveries();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettings();

  const options = useMemo<Option<QueryReleasesFilter>[]>(() => {
    return [
      ...(includePoNumbering
        ? [
            {
              id: DeliveryAdditionalFilter.PoExported,
              title: intl.$t({ id: "PO_EXPORTED" }),
              filter: (value: boolean | null) => ({
                exported: value,
              }),
              value: (currentFilter: QueryReleasesFilter | undefined) =>
                currentFilter?.exported,
            },
          ]
        : []),
      ...(includePoNumbering && connectedSourceSystem
        ? [
            {
              id: DeliveryAdditionalFilter.PoExists,
              title: intl.$t(
                { id: "PO_EXISTS_IN_SOURCE_SYSTEM" },
                {
                  sourceSystem: intl.$t({
                    id: `INTEGRATION_${connectedSourceSystem}`,
                  }),
                },
              ),
              filter: (value: boolean | null) => ({
                poExists: value,
              }),
              value: (currentFilter: QueryReleasesFilter | undefined) =>
                currentFilter?.poExists,
            },
          ]
        : []),
      {
        id: DeliveryAdditionalFilter.ReceiptPosted,
        title: intl.$t({ id: "RECEIPT_POSTED" }),
        filter: (value) => ({
          hasReceipt: value,
        }),
        value: (currentFilter) => currentFilter?.hasReceipt,
      },
      {
        id: DeliveryAdditionalFilter.BackOrdered,
        title: intl.$t({ id: "BACK_ORDERED" }),
        filter: (value) => ({
          isBackorder: value,
        }),
        value: (currentFilter) => currentFilter?.isBackorder,
      },
      {
        id: DeliveryAdditionalFilter.Retroactive,
        title: intl.$t({ id: "CREATED_RETROACTIVELY" }),
        filter: (value) => ({
          retroactive: value,
        }),
        value: (currentFilter) => currentFilter?.retroactive,
      },
      {
        id: DeliveryAdditionalFilter.ClosedProjects,
        title: intl.$t({ id: "ORDERS_FROM_CLOSED_PROJECTS" }),
        filter: (value) => ({
          closedProjects: value,
        }),
        value: (currentFilter) => currentFilter?.closedProjects,
      },
      {
        id: DeliveryAdditionalFilter.Deleted,
        title: intl.$t({ id: "DELETED" }),
        filter: (value) => ({
          deleted: value,
        }),
        value: (currentFilter) => currentFilter?.deleted,
      },
    ];
  }, [connectedSourceSystem, includePoNumbering, intl]);

  const setFilterHandler = useCallback(
    (values: string[] | undefined) => {
      setFilter({
        ...filter,
        typeIds: values,
      });
    },
    [filter, setFilter],
  );

  return (
    <AdditionalFiltersOverlay
      filter={filter}
      options={options}
      setFilter={setFilter}
      defaultFilter={{
        exported: null,
        hasReceipt: null,
        isBackorder: null,
        retroactive: null,
        deleted: false,
        typeIds: undefined,
      }}
      customBottomFilters={
        <>
          <OrderTypesFilter
            selectedOrderTypes={filter?.typeIds}
            onChange={setFilterHandler}
          />
          <RequestedByFilter
            values={filter?.createdBy}
            setFilter={(users) => setFilter({ ...filter, createdBy: users })}
          />
        </>
      }
    />
  );
};
