import { useExportToCsv } from "@/common/components/csv-export/hooks/useExportToCsv";
import { useExportToCsvReleaseData } from "@/common/components/csv-export/hooks/useExportToCsvReleaseData";
import { SideActionBar } from "@/common/components/side-action-bar/SideActionBar";
import { SystemAlertType } from "@/common/components/system-alert/SystemAlert";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useCallback, useMemo } from "react";
import { PiFileCsv } from "react-icons/pi";
import { useIntl } from "react-intl";
import { useReleaseSequence } from "../../providers/ReleaseSequenceProvider";

export const ReleaseSideActions = () => {
  const { setSystemAlert } = useSnackbar();
  const { sequenceIds, selectedReleases } = useReleaseSequence();
  const { exportToCsv } = useExportToCsv();
  const { getReleaseExportToCsvData } = useExportToCsvReleaseData();
  const intl = useIntl();

  const exportItemsToCsv = useCallback(() => {
    const { data, fileName, headers } =
      getReleaseExportToCsvData(selectedReleases);

    exportToCsv({
      data,
      fileName,
      headers,
    });

    setSystemAlert(
      intl.$t(
        { id: "RELEASES_EXPORT_CSV_SUCCESS" },
        { count: selectedReleases.length },
      ),
      { type: SystemAlertType.MANUAL },
    );
  }, [
    getReleaseExportToCsvData,
    selectedReleases,
    exportToCsv,
    setSystemAlert,
    intl,
  ]);

  const actions = useMemo(() => {
    return [
      {
        icon: <PiFileCsv className="h-6 w-6" />,
        tooltipKey: "EXPORT_TO_CSV",
        callback: exportItemsToCsv,
      },
    ];
  }, [exportItemsToCsv]);

  return <SideActionBar visible={sequenceIds.length > 0} actions={actions} />;
};
