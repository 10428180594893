import DateRangePicker from "@/common/components/date-range-picker/DateRangePicker";
import { HeaderContainer } from "@/common/components/header-container/HeaderContainer";
import { ListDatesButton } from "@/common/components/list-dates-button/ListDatesButton";
import { ListHeaderFilterButton } from "@/common/components/list-header-filter-button/ListHeaderFilterButton";
import { ListStatusesFilter } from "@/common/components/list-statuses-filter/ListStatusesFilter";
import { PopoverWithTabs } from "@/common/components/popover-with-tabs/PopoverWithTabs";
import { ProjectsFilterSelector } from "@/common/components/projects-filter-selector/ProjectsFilterSelector";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { SearchTooltip } from "@/common/components/search-input/SearchTooltip";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import { QueryInvoicesFilter } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ReceiptType, useReceipts } from "../../providers/ReceiptsProvider";
import { SubmittedByFilter } from "../header/SubmittedByFilter";
import { ReceiptListActions } from "./ReceiptListActions";
import { ReceiptsAdditionalFilters } from "./ReceiptsAdditionalFilters";

const HeaderContainerStyled = tw(HeaderContainer)`
  grid lg:grid-flow-col justify-between gap-3 flex-wrap
`;
const Filters = tw.div`grid grid-flow-col gap-2 items-center`;

type FilterOption = {
  id: string;
  name: string;
  filter?: QueryInvoicesFilter;
};

enum DatesKeys {
  TransactionDate = "transactionDate",
  ScannedDate = "ScannedDate",
}

export const ReceiptsHeader = () => {
  const intl = useIntl();
  const { filter, setFilter } = useReceipts();
  const { projects } = useProjectListOptions({
    includeClosedProjects: filter?.closedProjects,
  });

  const options = useMemo<FilterOption[]>(() => {
    return [
      {
        id: ReceiptType.AwaitingApproval,
        name: intl.$t({ id: "RECEIPT_STATUS_AWAITING_APPROVAL" }),
      },
      {
        id: ReceiptType.Approved,
        name: intl.$t({ id: "RECEIPT_STATUS_APPROVED" }),
      },
      {
        id: ReceiptType.Paid,
        name: intl.$t({ id: "RECEIPT_STATUS_PAID" }),
      },
    ];
  }, [intl]);

  const appliedFilters = useMemo(() => {
    return (
      Number(filter?.kickbackToMe != null) +
      Number(filter?.exported != null) +
      Number(filter?.prePaid != null) +
      Number(filter?.archived !== false) +
      Number(filter?.closedProjects != null) +
      Number(filter?.releaseTypeIds != null)
    );
  }, [
    filter?.archived,
    filter?.exported,
    filter?.prePaid,
    filter?.closedProjects,
    filter?.releaseTypeIds,
    filter?.kickbackToMe,
  ]);

  const appliedDateFilters = useMemo(() => {
    return (
      Number(filter?.minIssueDate != null || filter?.maxIssueDate != null) +
      Number(filter?.minCreatedAt != null || filter?.maxCreatedAt != null)
    );
  }, [
    filter?.minIssueDate,
    filter?.maxIssueDate,
    filter?.minCreatedAt,
    filter?.maxCreatedAt,
  ]);

  return (
    <HeaderContainerStyled>
      <Filters>
        <SearchInput
          placeHolder={intl.$t({ id: "SEARCH" })}
          onChange={(value: string) => setFilter({ ...filter, search: value })}
          value={filter?.search}
          info={<SearchTooltip text="RECEIPTS_SEARCH_INFO" />}
          applyHeaderAccent
        />
        <ProjectsFilterSelector
          projects={projects}
          onChange={(projectId) =>
            setFilter({
              ...filter,
              projectIds: projectId ? [projectId] : undefined,
            })
          }
          value={filter?.projectIds?.[0]}
          withCustomView
        />
        <ListStatusesFilter
          options={options}
          filter={filter}
          setFilter={setFilter}
          values={filter?.statuses || null}
          applyHeaderAccent
        />
        <SubmittedByFilter
          setFilter={(createdByIds) => setFilter({ ...filter, createdByIds })}
          values={filter?.createdByIds || null}
          applyHeaderAccent
        />
        <ListHeaderFilterButton
          isDefault={appliedFilters === 0}
          filter={<ReceiptsAdditionalFilters />}
          appliedFilters={appliedFilters}
        />
        <ListDatesButton
          isDefault={appliedDateFilters === 0}
          appliedFilters={appliedDateFilters}
          hideDates
          dates={[
            {
              startDate: filter?.minIssueDate,
              endDate: filter?.maxIssueDate,
              key: DatesKeys.TransactionDate,
              label: intl.$t({ id: "TRANSACTION_DATE" }),
            },
            {
              startDate: filter?.minCreatedAt,
              endDate: filter?.maxCreatedAt,
              key: DatesKeys.ScannedDate,
              label: intl.$t({ id: "SCAN_DATE" }),
            },
          ]}
          clearValues={(value) => {
            if (value === DatesKeys.TransactionDate) {
              setFilter({
                ...filter,
                minIssueDate: undefined,
                maxIssueDate: undefined,
              });
            } else {
              setFilter({
                ...filter,
                minCreatedAt: undefined,
                maxCreatedAt: undefined,
              });
            }
          }}
        >
          {(onClose) => (
            <PopoverWithTabs
              tabs={[
                {
                  label: intl.$t({ id: "TRANSACTION_DATE" }),
                  content: (
                    <DateRangePicker
                      staticView
                      onClose={onClose}
                      selectedValue={
                        filter?.minIssueDate || filter?.maxIssueDate
                          ? {
                              startDate: filter?.minIssueDate
                                ? new Date(filter.minIssueDate)
                                : new Date(),
                              endDate: filter?.maxIssueDate
                                ? new Date(filter.maxIssueDate)
                                : new Date(),
                            }
                          : undefined
                      }
                      isUTC
                      onChange={(dateRange) =>
                        setFilter({
                          ...filter,
                          minIssueDate: dateRange.startDate?.getTime(),
                          maxIssueDate: dateRange.endDate?.getTime(),
                        })
                      }
                    />
                  ),
                  viewState: DatesKeys.TransactionDate,
                },
                {
                  label: intl.$t({ id: "SCAN_DATE" }),
                  content: (
                    <DateRangePicker
                      staticView
                      onClose={onClose}
                      selectedValue={
                        filter?.minCreatedAt || filter?.maxCreatedAt
                          ? {
                              startDate: filter?.minCreatedAt
                                ? new Date(filter.minCreatedAt)
                                : new Date(),
                              endDate: filter?.maxCreatedAt
                                ? new Date(filter.maxCreatedAt)
                                : new Date(),
                            }
                          : undefined
                      }
                      isUTC
                      onChange={(dateRange) =>
                        setFilter({
                          ...filter,
                          minCreatedAt: dateRange.startDate?.getTime(),
                          maxCreatedAt: dateRange.endDate?.getTime(),
                        })
                      }
                    />
                  ),
                  viewState: DatesKeys.ScannedDate,
                },
              ]}
              activeTab={DatesKeys.TransactionDate}
            />
          )}
        </ListDatesButton>
      </Filters>
      <ReceiptListActions />
    </HeaderContainerStyled>
  );
};
