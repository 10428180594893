import tw from "tailwind-styled-components";

const Container = tw.div`absolute inset-0 flex items-center bg-white pl-2 pr-8 overflow-hidden`;
const Chip = tw.div`rounded-4xl bg-blue-400 !text-white max-w-full overflow-hidden text-nowrap text-xs text-ellipsis py-1 px-2`;

interface CustomSelectedItemViewProps {
  text: string;
}

export const SelectCustomItemView = ({ text }: CustomSelectedItemViewProps) => {
  return (
    <Container>
      <Chip>{text}</Chip>
    </Container>
  );
};
