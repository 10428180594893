import pako from "pako";
import { useCallback } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import { usePagination } from "../components/pagination/PaginationProvider";
import { QUERYSTRING } from "../const";
import { usePageParamsStore } from "../stores/usePageParamsStore";

type FilterParams =
  | ({
      name?: string | null | undefined;
      search?: string | null | undefined;
    } & Record<string, unknown>)
  | undefined;

const compressQueryParam = (data: unknown): string => {
  const json = JSON.stringify(data);
  const compressed = pako.deflate(json);
  return btoa(String.fromCharCode(...new Uint8Array(compressed)));
};

const isBase64 = (str: string): boolean => {
  try {
    return btoa(atob(str)) === str;
  } catch {
    return false;
  }
};

const decompressQueryParam = (compressedData: string) => {
  if (isBase64(compressedData)) {
    const binaryString = atob(compressedData);
    const charArray = binaryString.split("").map((char) => char.charCodeAt(0));
    const byteArray = new Uint8Array(charArray);
    const decompressed = pako.inflate(byteArray, { to: "string" });
    return JSON.parse(decompressed);
  } else {
    return {};
  }
};

export const useFiltersQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setPage } = usePagination();
  const setParamsForPage = usePageParamsStore(
    useShallow((state) => state.setParamsForPage),
  );
  const pageUrl = useLocation();

  const getFiltersQueryParam = useCallback(() => {
    const compressedQueryParams = searchParams.get(QUERYSTRING.FILTERS);
    setParamsForPage(pageUrl.pathname, compressedQueryParams ?? "");
    if (!compressedQueryParams) {
      return undefined;
    }
    return decompressQueryParam(compressedQueryParams);
  }, [searchParams, pageUrl, setParamsForPage]);

  const setFiltersQueryParams = useCallback(
    (filterParams: FilterParams) => {
      if (filterParams) {
        const compressedQueryParams = compressQueryParam(filterParams);
        setPage({ page: 0 });
        setSearchParams({
          ...searchParams,
          [QUERYSTRING.FILTERS]: compressedQueryParams,
        });
      } else {
        setSearchParams({
          ...searchParams,
          [QUERYSTRING.FILTERS]: undefined,
        });
      }
    },
    [searchParams, setSearchParams, setPage],
  );

  return { getFiltersQueryParam, setFiltersQueryParams };
};
