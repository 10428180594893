import { addressToString } from "@/common/components/adress-location/AddressLocation";
import { formatPhone } from "@/common/components/phone-input/formatPhone";
import { PDF_FONT, PDF_LEFT_SPACING } from "@/common/const";
import { ExpandedRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  DistributorReleaseFieldsFragment,
  ServiceType,
} from "@/generated/graphql";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { IntlShape } from "react-intl";

const FAKE_COLUMN_WIDTH = 3;
const CELL_PADDING = 3;
const LINE_HEIGHT = 4.3;

const addDeliveryInfo = (
  doc: jsPDF,
  release: ExpandedRelease | DistributorReleaseFieldsFragment,
  intl: IntlShape,
  x: number,
  y: number,
  width: number,
  sketchMode: boolean = false,
) => {
  let topOffset = y;
  let leftOffset = 0;
  const isReleaseWithDelivery = release.includeServices.includes(
    ServiceType.Delivery,
  );
  const isReleaseWithStocking = release.includeServices.includes(
    ServiceType.Stocking,
  );
  doc.setFontSize(10).setFont(PDF_FONT, "", "bold");
  if (!sketchMode) {
    doc.text(
      isReleaseWithDelivery
        ? `${intl.$t({ id: "SHIP_TO" })}:`
        : `${intl.$t({ id: "FULFILLMENT" })}`,
      x,
      topOffset,
    );
  }
  topOffset += 6;
  doc.setFontSize(8);
  if (isReleaseWithDelivery) {
    if (release.project?.name) {
      //project name
      const projectName = doc.splitTextToSize(
        release.project.name,
        width - leftOffset,
      );
      if (!sketchMode) {
        doc.text(projectName, x, topOffset);
      }
      topOffset += 4.3 * projectName.length + 1;
    }
    //warehouse or project address
    const locationAddress =
      release.warehouse?.address ?? release.project?.address;
    if (locationAddress) {
      doc.setFont(PDF_FONT, "", "normal");

      const addressLines = doc.splitTextToSize(
        addressToString(locationAddress, {
          excludeCity: true,
          excludeCountry: true,
          excludePostalCode: true,
          excludeState: true,
        }),
        width - leftOffset,
      );
      const addressCityAndState = doc.splitTextToSize(
        addressToString(locationAddress, {
          excludeAddressLines: true,
          excludeCountry: true,
        }),
        width - leftOffset,
      );
      const address = [...addressLines, ...addressCityAndState];
      if (!sketchMode) {
        doc.text(address, x, topOffset);
      }
      topOffset += LINE_HEIGHT * address.length + 1;
    }
  } else {
    doc.setFont(PDF_FONT, "", "bold");
    if (!sketchMode) {
      doc.text(intl.$t({ id: "CLIENT_DELIVERY" }), x, topOffset);
    }
    topOffset += 5;
  }
  //delivery contact
  if (release.siteContact) {
    doc.setFont(PDF_FONT, "", "bold");
    const contactHeader = `${intl.$t({ id: "SITE_CONTACT" })}: `;
    if (!sketchMode) {
      doc.text(contactHeader, x, topOffset);
    }
    leftOffset = doc.getTextWidth(contactHeader);
    doc.setFont(PDF_FONT, "", "normal");
    const contactInfo = (
      release.siteContact.name ? `${release.siteContact.name}, ` : ""
    ).concat(`${formatPhone(release.siteContact.phone || "")}`);
    const splitContactInfo = doc.splitTextToSize(
      contactInfo,
      width - leftOffset,
    );
    if (!sketchMode) {
      doc.text(splitContactInfo, x + leftOffset, topOffset);
    }
    topOffset += LINE_HEIGHT * splitContactInfo.length + 1;
  }
  //delivery stocking
  if (isReleaseWithStocking) {
    doc.setFont(PDF_FONT, "", "bold");
    const stockingHeader = `${intl.$t({ id: "STOCKING" })}: `;
    if (!sketchMode) {
      doc.text(stockingHeader, x, topOffset);
    }
    leftOffset = doc.getTextWidth(stockingHeader);
    doc.setFont(PDF_FONT, "", "normal");
    if (!sketchMode) {
      doc.text(intl.$t({ id: "YES" }), x + leftOffset, topOffset);
    }
    leftOffset = 0;
    topOffset += 5;
  }
  return topOffset;
};

const addContractorInfo = (
  doc: jsPDF,
  release: ExpandedRelease | DistributorReleaseFieldsFragment,
  intl: IntlShape,
  x: number,
  y: number,
  width: number,
  sketchMode: boolean = false,
) => {
  let topOffset = y;
  let leftOffset = 0;
  doc.setFontSize(10).setFont(PDF_FONT, "", "bold");
  if (!sketchMode) {
    doc.text(`${intl.$t({ id: "BILL_TO" })}:`, x, topOffset);
  }
  topOffset += 6;
  doc.setFontSize(8);
  if (release.orgLocation?.org) {
    //contractor name
    const orgName = doc.splitTextToSize(
      release.orgLocation.org.name,
      width - leftOffset,
    );
    if (!sketchMode) {
      doc.text(orgName, x, topOffset);
    }
    topOffset += LINE_HEIGHT * orgName.length + 1;

    //contractor address
    doc.setFont(PDF_FONT, "", "normal");
    const addressLines = doc.splitTextToSize(
      addressToString(release.orgLocation.address, {
        excludeCity: true,
        excludeCountry: true,
        excludePostalCode: true,
        excludeState: true,
      }),
      width - leftOffset,
    );
    const addressCityAndState = doc.splitTextToSize(
      addressToString(release.orgLocation.address, {
        excludeAddressLines: true,
        excludeCountry: true,
      }),
      width - leftOffset,
    );
    const address = [...addressLines, ...addressCityAndState];
    if (!sketchMode) {
      doc.text(address, x, topOffset);
    }
    topOffset += LINE_HEIGHT * address.length + 1;

    //contractor phone
    if (release.orgLocation.phone) {
      doc.setFont(PDF_FONT, "", "bold");
      const phoneHeader = `${intl.$t({ id: "LOCATION_CELL_PHONE" })}: `;
      if (!sketchMode) {
        doc.text(phoneHeader, x, topOffset);
      }
      leftOffset = doc.getTextWidth(phoneHeader);
      doc.setFont(PDF_FONT, "", "normal");
      const orgPhone = doc.splitTextToSize(
        formatPhone(release.orgLocation.phone),
        width - leftOffset,
      );
      if (!sketchMode) {
        doc.text(orgPhone, x + leftOffset, topOffset);
      }
      topOffset += LINE_HEIGHT * orgPhone.length + 1;
      leftOffset = 0;
    }
  }
  return topOffset;
};

const addVendorInfo = (
  doc: jsPDF,
  release: ExpandedRelease | DistributorReleaseFieldsFragment,
  intl: IntlShape,
  x: number,
  y: number,
  width: number,
  sketchMode: boolean = false,
) => {
  let topOffset = y;
  let leftOffset = 0;
  doc.setFontSize(10).setFont(PDF_FONT, "", "bold");
  if (!sketchMode) {
    doc.text(`${intl.$t({ id: "VENDOR" })}:`, x, topOffset);
  }
  topOffset += 6;
  doc.setFontSize(8);
  if (release?.sellerOrgLocation) {
    //vendor name
    const orgName = doc.splitTextToSize(
      release.sellerOrgLocation.org.name,
      width - leftOffset,
    );
    if (!sketchMode) {
      doc.text(orgName, x, topOffset);
    }
    topOffset += LINE_HEIGHT * orgName.length + 1;

    //vendor address
    doc.setFont(PDF_FONT, "", "normal");
    const addressLines = doc.splitTextToSize(
      addressToString(release.sellerOrgLocation?.address, {
        excludeCity: true,
        excludeCountry: true,
        excludePostalCode: true,
        excludeState: true,
      }),
      width - leftOffset,
    );
    const addressCityAndState = doc.splitTextToSize(
      addressToString(release.sellerOrgLocation?.address, {
        excludeAddressLines: true,
        excludeCountry: true,
      }),
      width - leftOffset,
    );
    const address = [...addressLines, ...addressCityAndState];
    if (!sketchMode) {
      doc.text(address, x, topOffset);
    }
    topOffset += LINE_HEIGHT * address.length + 1;

    //vendor phone
    if (release.sellerOrgLocation.phone) {
      doc.setFont(PDF_FONT, "", "bold");
      const phoneHeader = `${intl.$t({ id: "LOCATION_CELL_PHONE" })}: `;
      if (!sketchMode) {
        doc.text(phoneHeader, x, topOffset);
      }
      leftOffset = doc.getTextWidth(phoneHeader);
      doc.setFont(PDF_FONT, "", "normal");
      const orgPhone = doc.splitTextToSize(
        formatPhone(release.sellerOrgLocation.phone),
        width - leftOffset,
      );
      if (!sketchMode) {
        doc.text(orgPhone, x + leftOffset, topOffset);
      }
      topOffset += LINE_HEIGHT * orgPhone.length + 1;
      leftOffset = 0;
    }
  }

  //vendor contacts
  const contacts =
    release.preferredVendor?.contacts.filter(
      (contact) => contact.receivesOrderNotifications,
    ) || [];
  if (contacts.length) {
    doc.setFont(PDF_FONT, "", "bold");
    const contactHeader = `${intl.$t({ id: "CONTACT" })}: `;
    if (!sketchMode) {
      doc.text(contactHeader, x, topOffset);
    }
    leftOffset = doc.getTextWidth(contactHeader);
    doc.setFont(PDF_FONT, "", "normal");
    contacts.forEach((contact) => {
      const contactInfo = contact.name
        .concat(`, ${formatPhone(contact.cellPhone)}`)
        .concat(`\n${contact.email}`);
      const splitContactInfo = doc.splitTextToSize(
        contactInfo,
        width - leftOffset,
      );
      if (!sketchMode) {
        doc.text(splitContactInfo, x + leftOffset, topOffset);
      }
      topOffset += LINE_HEIGHT * splitContactInfo.length + 1;
    });
  }
  return topOffset;
};

export const releaseHeaderLowerPart = (
  doc: jsPDF,
  release: ExpandedRelease | DistributorReleaseFieldsFragment,
  intl: IntlShape,
  topSpacing: number,
) => {
  const cellWidth =
    (doc.internal.pageSize.width -
      2 * FAKE_COLUMN_WIDTH -
      2 * PDF_LEFT_SPACING) /
      3 -
    2 * CELL_PADDING;
  const tableHeight =
    Math.max(
      addVendorInfo(doc, release, intl, 0, 0, cellWidth, true),
      addContractorInfo(doc, release, intl, 0, 0, cellWidth, true),
      addDeliveryInfo(doc, release, intl, 0, 0, cellWidth, true),
    ) + 4;
  autoTable(doc, {
    startY: topSpacing,
    theme: "plain",
    showHead: false,
    styles: {
      font: PDF_FONT,
    },
    body: [["", "", "", "", ""]],
    columnStyles: {
      1: { cellWidth: FAKE_COLUMN_WIDTH },
      3: { cellWidth: FAKE_COLUMN_WIDTH },
    },
    didDrawCell: function (data) {
      if (data.row.section === "body") {
        switch (data.column.index) {
          case 0: {
            addContractorInfo(
              doc,
              release,
              intl,
              data.cell.x + CELL_PADDING,
              data.cell.y + 5,
              data.cell.width - 2 * CELL_PADDING,
            );
            break;
          }
          case 2: {
            addVendorInfo(
              doc,
              release,
              intl,
              data.cell.x + CELL_PADDING,
              data.cell.y + 5,
              data.cell.width - 2 * CELL_PADDING,
            );
            break;
          }
          case 4: {
            addDeliveryInfo(
              doc,
              release,
              intl,
              data.cell.x + CELL_PADDING,
              data.cell.y + 5,
              data.cell.width - 2 * CELL_PADDING,
            );
            break;
          }
          default:
            break;
        }
      }
    },
    willDrawCell: (data) => {
      data.cell.height = tableHeight;
      data.cell.styles.lineWidth = {
        top: data.column.index % 2 === 0 ? 0.1 : 0,
        bottom: data.column.index % 2 === 0 ? 0.1 : 0,
        left: 0.1,
        right: 0.1,
      };
    },
  });
  return topSpacing + tableHeight;
};
