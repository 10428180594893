import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { useNoteDocument } from "@/contractor/pages/home/releases/pages/deliveries/components/order-from-note/note-document/providers/NoteDocumentProvider";
import { NoteDocumentItemFieldsFragment } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useAddItemsModal } from "./useAddItemsModal";

export const useAddNoteItems = () => {
  const { handsonInstance } = useColumnMapper();
  const { noteDocument } = useNoteDocument();
  const { findMaterialByName, appendRows, rowIsEmpty } = useTableHelpers();
  const { showModal } = useAddItemsModal();
  const { formatCostCode } = useCostCodes();
  const { calcExtPrice } = usePriceCalculation();

  const missingItems = useMemo(
    () => noteDocument?.items || [],
    [noteDocument?.items],
  );

  const addAllItems = useCallback(
    (items?: NoteDocumentItemFieldsFragment[]) => {
      const newItems =
        (items || noteDocument?.items || []).map((item) => {
          const material = findMaterialByName(item.description ?? "");
          return {
            [COLUMN_TYPE.Material]:
              material?.material.name ?? item.description ?? "",
            [COLUMN_TYPE.UOM]: String(item.uom) || "UT",
            [COLUMN_TYPE.Quantity]: item.quantity || "",
            ...(item.unitPrice !== undefined && item.unitPrice !== null
              ? { [COLUMN_TYPE.PrefilledPrice]: item.unitPrice }
              : {}),
            [COLUMN_TYPE.ExtPrice]: String(
              calcExtPrice(item.quantity, item.unitPrice),
            ),
            [COLUMN_TYPE.CostCode]: formatCostCode(material?.costCode ?? ""),
          };
        }) || [];
      appendRows(newItems, handsonInstance);
    },
    [
      noteDocument?.items,
      appendRows,
      handsonInstance,
      findMaterialByName,
      calcExtPrice,
      formatCostCode,
    ],
  );

  const addMissingItems = useCallback(() => {
    addAllItems(missingItems);
  }, [addAllItems, missingItems]);

  const addNoteItems = useCallback(() => {
    if (handsonInstance?.isDestroyed) {
      return;
    }
    const allRowsEmpty = handsonInstance?.getData().every(rowIsEmpty);
    const noMatches = noteDocument?.items.length === missingItems.length;
    if (allRowsEmpty || noMatches) {
      addAllItems();
      return;
    }
    showModal(
      {
        handleCustomButtonAction: addAllItems,
        handleConfirm: addMissingItems,
      },
      noteDocument?.items.length || 0,
      missingItems.length,
    );
  }, [
    handsonInstance,
    rowIsEmpty,
    showModal,
    addAllItems,
    addMissingItems,
    noteDocument?.items.length,
    missingItems,
  ]);

  return {
    addNoteItems,
  };
};
