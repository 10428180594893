import { DeleteButton } from "@/common/components/button/DeleteButton";
import { UnarchiveButton } from "@/common/components/button/UnarchiveButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { TaxCodeFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useArchiveTaxCodes } from "../../hooks/useArchiveTaxCodes";

const Container = tw.div`flex items-center justify-end space-x-2`;

type Props = {
  item: TaxCodeFieldsFragment;
};

export const TaxCodeActions = ({ item }: Props) => {
  const { archiveTaxCodes, unarchiveTaxCodes, loading } = useArchiveTaxCodes();
  const { openDialog } = useDialog();
  const intl = useIntl();
  const onArchiveClick = useCallback(() => {
    openDialog({
      title: intl.formatMessage({ id: "TAX_CODE_ARCHIVE" }),
      text: intl.formatMessage({ id: "TAX_CODE_ARCHIVE_DETAILS" }),
      handleConfirm: async () => {
        await archiveTaxCodes([item.id]);
      },
      icon: <WarningIcon />,
      confirmButtonText: intl.formatMessage({ id: "CONFIRM" }),
      cancelButtonText: intl.formatMessage({ id: "CANCEL" }),
    });
  }, [archiveTaxCodes, intl, item.id, openDialog]);

  const onUnarchiveClick = useCallback(() => {
    openDialog({
      title: intl.formatMessage({ id: "TAX_CODE_UNARCHIVE" }),
      text: intl.formatMessage({ id: "TAX_CODE_UNARCHIVE_DETAILS" }),
      handleConfirm: async () => {
        await unarchiveTaxCodes([item.id]);
      },
      icon: <WarningIcon />,
      confirmButtonText: intl.formatMessage({ id: "CONFIRM" }),
      cancelButtonText: intl.formatMessage({ id: "CANCEL" }),
    });
  }, [intl, item.id, openDialog, unarchiveTaxCodes]);

  return (
    <Loader small loading={loading}>
      <Container>
        <If isTrue={!item.archivedAt}>
          <Tooltip
            id="archive"
            element={<DeleteButton onClick={onArchiveClick} />}
          >
            <FormattedMessage id="TAX_CODE_ARCHIVE" />
          </Tooltip>
        </If>
        <If isTrue={item.archivedAt}>
          <Tooltip
            id="unarchive"
            element={<UnarchiveButton onClick={onUnarchiveClick} />}
          >
            <FormattedMessage id="TAX_CODE_UNARCHIVE" />
          </Tooltip>
        </If>
      </Container>
    </Loader>
  );
};
