import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import {
  AddToReleaseItemInput,
  CreateStandaloneReleaseInput,
  DeliverySlipsDocument,
  OrgMaterialFieldsFragment,
  OrgPreferredVendorsFieldsFragment,
  ReleaseFieldsFragment,
  ReleaseStatus,
  ServiceType,
  UomsDocument,
  UpdateContractorReleaseInput,
  UpdateContractorReleaseItemInput,
  useCreateStandaloneReleaseMutation,
  useProjectPredictedPoNumberQuery,
  useUpdateContractorReleaseMutation,
} from "@/generated/graphql";
import { FC, createContext, useContext, useEffect, useState } from "react";

import { LUMP_SUM_UOM, LUMP_SUM_UOM_PLURAL_DESCRIPTION } from "@/common/const";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { routes } from "@/config/routes";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useVendors } from "@/contractor/pages/admin/vendors/hooks/useVendors";
import { useQuoteDocument } from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import { useProjectZonesStore } from "@/contractor/pages/home/project/store/projectZonesStore";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { NoFunction, NoFunctionUndefined } from "@/types/NoFunction";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import { CreateOrderFromQuoteFormValues } from "../components/order-from-quote/create-order-from-quote/components/CreateOrderFromQuoteForm";

type ProviderContextType = {
  syncCreateReleaseFromQuote: (
    skipConfirmation?: boolean,
    retroactive?: boolean,
    status?: ReleaseStatus,
  ) => Promise<ReleaseFieldsFragment | undefined | null>;
  syncUpdateReleaseFromQuote: () => Promise<
    ReleaseFieldsFragment | undefined | null
  >;
  validateSpreadsheet: () => boolean;
  loading: boolean;
  vendors: OrgPreferredVendorsFieldsFragment[];
  loadingVendors: boolean;
  findOrderTypeByLocationId: (locationId: string) => string | undefined;
  loadingPredictedPoNumber: boolean;
  itemized: boolean;
  setItemized: (isItemized: boolean) => void;
  createLumpSumRelease: (
    skipConfirmation?: boolean,
    retroactive?: boolean,
    status?: ReleaseStatus,
  ) => Promise<ReleaseFieldsFragment | undefined | null>;
  updateLumpSumRelease: (
    skipConfirmation?: boolean,
    retroactive?: boolean,
    status?: ReleaseStatus,
  ) => Promise<ReleaseFieldsFragment | undefined | null>;
};

const ProviderContext = createContext<ProviderContextType>({
  syncCreateReleaseFromQuote: () => Promise.resolve(null),
  syncUpdateReleaseFromQuote: () => Promise.resolve(null),
  validateSpreadsheet: () => false,
  loading: false,
  vendors: [],
  loadingPredictedPoNumber: false,
  loadingVendors: false,
  findOrderTypeByLocationId: NoFunctionUndefined,
  itemized: true,
  setItemized: NoFunction,
  createLumpSumRelease: () => Promise.resolve(null),
  updateLumpSumRelease: () => Promise.resolve(null),
});

export const OrderFromQuoteProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { quoteDocument } = useQuoteDocument();
  const { setError } = useGlobalError();
  const { materials, updateMaterials } = useMaterials();
  const { costCodes } = useCostCodes();
  const { release } = useRelease();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const {
    spreadsheetData,
    resetPreviousData,
    gotoInvalidRow,
    getRemovedRowIds,
  } = useColumnMapper();
  const {
    getCellValue,
    getRowUomCreatableValue,
    getCostCodeId,
    rowIsEmpty,
    addMissingMaterials,
    addMissingZones,
    getCellWithAdditionalData,
    findMaterialByName,
  } = useTableHelpers();
  const {
    vendors,
    loading: loadingVendors,
    findOrderTypeByLocationId,
  } = useVendors();
  const { setWarningAlert } = useSnackbar();
  const { zones } = useProjectZonesStore(
    useShallow((state) => ({
      zones: state.zones,
    })),
  );

  const [itemized, setItemized] = useState(true);
  const [saving, setSaving] = useState(false);

  const { setValue, watch, getValues } =
    useFormContext<CreateOrderFromQuoteFormValues>();

  const projectId = watch("projectId");
  const poNumber = watch("poNumber");
  const orderDate = watch("orderDate");

  const {
    data: projectData,
    error: predictedPoNumberError,
    loading: loadingPredictedPoNumber,
  } = useProjectPredictedPoNumberQuery({
    variables: {
      id: projectId,
    },
    skip: !projectId || !!poNumber,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    const retroactiveRelease =
      orderDate && orderDate?.getTime() < new Date().getTime();
    const predictedPoNumber = projectData?.project?.predictedPoNumber;

    if (!retroactiveRelease && !predictedPoNumberError && predictedPoNumber) {
      return setValue("predictedPoNumber", predictedPoNumber);
    }

    return setValue("predictedPoNumber", "");
  }, [
    orderDate,
    projectData?.project?.predictedPoNumber,
    predictedPoNumberError,
    setValue,
  ]);

  const [createStandaloneRelease, { loading: creating }] =
    useCreateStandaloneReleaseMutation();
  const [updateReleaseMutation, { loading: updating }] =
    useUpdateContractorReleaseMutation();

  const validateSpreadsheet = () => {
    if (spreadsheetData.every((row) => rowIsEmpty(row))) {
      setWarningAlert(
        <FormattedMessage id={`VALIDATION_ERROR_SHEETS_EMPTY_LIST`} />,
      );
      return false;
    }

    if (
      !validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.Quantity,
      ]) ||
      !validateRowValues([
        COLUMN_TYPE.Quantity,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.CostCode,
      ])
    ) {
      gotoInvalidRow();
      return false;
    }

    return true;
  };

  const createRelease = async (input: CreateStandaloneReleaseInput) => {
    try {
      const { data, errors } = await createStandaloneRelease({
        variables: {
          input,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: UomsDocument,
          },
          {
            query: DeliverySlipsDocument,
          },
        ],
      });
      setError(errors);
      if (!errors) {
        resetPreviousData();
      }
      return data?.createStandaloneRelease ?? null;
    } catch (error) {
      setError(error);
      return null;
    }
  };

  const updateRelease = async (input: UpdateContractorReleaseInput) => {
    try {
      const { data, errors } = await updateReleaseMutation({
        variables: {
          input,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: UomsDocument,
          },
          {
            query: DeliverySlipsDocument,
          },
        ],
      });
      setError(errors);
      if (!errors) {
        resetPreviousData();
      }
      return data?.updateContractorRelease ?? null;
    } catch (error) {
      setError(error);
      return null;
    }
  };

  const syncCreateReleaseFromQuote = async (
    skipConfirmation?: boolean,
    retroactive?: boolean,
    status?: ReleaseStatus,
  ) => {
    const isValid = validateSpreadsheet();
    const values = getValues();

    if (!isValid) {
      return null;
    }

    const newItems: AddToReleaseItemInput[] = [];
    setSaving(true);
    const newMaterials = (await addMissingMaterials()) || [];
    const newZones = (await addMissingZones(values.projectId)) || [];
    setSaving(false);

    spreadsheetData.forEach((row, index) => {
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.Material,
      );
      let material = findMaterialByName(rowMaterialText, [
        ...materials,
        ...newMaterials,
      ]);

      const uom = getRowUomCreatableValue(row);
      const isLumpSum = isLumpSumUomText(uom);
      if (isLumpSum) {
        material = findMaterialByName(LUMP_SUM_UOM_PLURAL_DESCRIPTION, [
          ...materials,
          ...newMaterials,
        ]);
      }

      if (!material || rowIsEmpty(row)) {
        return null;
      }

      const zone = [...(zones || []), ...newZones].find(
        (z) => z?.name === getCellValue(row, COLUMN_TYPE.Zone),
      );

      const quantityDecimal = getCellValue(row, COLUMN_TYPE.Quantity);
      const unitPrice = getCellValue(row, COLUMN_TYPE.UnitPrice);
      const notes = getCellValue(row, COLUMN_TYPE.Notes);

      newItems.push({
        projectItem: {
          estimateUom: uom,
          ...(isOrgCatalogSku(material.material) && {
            orgCatalogSkuId: material.material.id,
          }),
          ...(isProductSku(material.material) && {
            masterProductId: material.material.id,
          }),
          ...(isMasterSku(material.material) && {
            masterSkuId: material.material.id,
          }),
        },
        quantityDecimal,
        unitPrice: unitPrice ? String(unitPrice) : undefined,
        ...(isLumpSum && { name: rowMaterialText }),
        position: index,
        costCodeId: getCostCodeId(row),
        instructions: { text: notes },
        zoneId: zone?.id,
      } as AddToReleaseItemInput);
    });

    if (newItems.length > 0) {
      const includeServices = [];
      if (!values.willCall) {
        includeServices.push({ type: ServiceType.Delivery });
      }
      if (!values.willCall && values.vendorStocking) {
        includeServices.push({ type: ServiceType.Stocking });
      }

      const relStatus =
        values.orderDate?.getTime() < Date.now()
          ? retroactive
            ? ReleaseStatus.Received
            : ReleaseStatus.Scheduled
          : skipConfirmation
            ? ReleaseStatus.Scheduled
            : skipConfirmation === false
              ? ReleaseStatus.Requested
              : ReleaseStatus.Draft;

      return await createRelease({
        projectId: values.projectId ?? "",
        sellerOrgLocationId: values.vendorId,
        poNumber: values.poNumber || undefined,
        time: values.orderDate?.getTime() ?? undefined,
        items: newItems,
        requiresInventoryReceipt: values.requiresInventoryReceipt,
        quoteDocumentId: quoteDocument?.id ?? "",
        includeServices,
        taxRate:
          values.taxRate ||
          (values.clearCustomTaxAmount || values.customTaxAmount?.length === 0
            ? "0"
            : undefined),
        customTaxAmount:
          values.clearCustomTaxAmount || values.customTaxAmount?.length === 0
            ? undefined
            : values.customTaxAmount,
        additionalCharges: values.additionalCharges,
        instructions: values.instructions,
        paymentTerm: values.paymentTerm,
        status: status || relStatus,
        typeId: values.orderTypeId || undefined,
        vendorContactIds: values.vendorContactIds,
        taxCodeId: values.taxCodeId,
        taxType: values.taxType,
      });
    }
  };

  const syncUpdateReleaseFromQuote = async () => {
    const values = getValues();
    if (
      !validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.Quantity,
      ]) ||
      !validateRowValues(
        [COLUMN_TYPE.Quantity, COLUMN_TYPE.UOM, COLUMN_TYPE.CostCode],
        undefined,
        { minPrice: undefined },
      )
    ) {
      gotoInvalidRow();
      return null;
    }

    const newItems: AddToReleaseItemInput[] = [];
    const itemsToUpdate: UpdateContractorReleaseItemInput[] = [];
    const itemsToRemove = getRemovedRowIds(release?.items ?? []);

    setSaving(true);
    const newMaterials = (await addMissingMaterials()) || [];
    const newZones = (await addMissingZones(release?.project?.id || "")) || [];
    setSaving(false);

    spreadsheetData.forEach((row, index) => {
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.Material,
      );
      let material = findMaterialByName(rowMaterialText, [
        ...materials,
        ...newMaterials,
      ]);

      const uom = getRowUomCreatableValue(row);
      const isLumpSum = isLumpSumUomText(uom);
      if (isLumpSum) {
        material = findMaterialByName(LUMP_SUM_UOM_PLURAL_DESCRIPTION, [
          ...materials,
          ...newMaterials,
        ]);
      }

      if (!material || rowIsEmpty(row)) {
        return null;
      }

      const zone = [...(zones || []), ...newZones].find(
        (z) => z?.name === getCellValue(row, COLUMN_TYPE.Zone),
      );

      const quantityDecimal = getCellValue(row, COLUMN_TYPE.Quantity);
      const unitPrice = getCellValue(row, COLUMN_TYPE.UnitPrice);

      const hasUnitPrice =
        unitPrice !== "" && unitPrice !== null && unitPrice !== undefined;
      const notes = getCellValue(row, COLUMN_TYPE.Notes);

      const existingItem = release?.items.find((item) => item.id === row.id);
      const matchingMaterials =
        existingItem && existingItem.projectItem?.material.id === material.id;

      if (matchingMaterials) {
        itemsToUpdate.push({
          releaseItemId: row.id,
          uom,
          quantityDecimal,
          unitPrice: hasUnitPrice ? String(unitPrice) : undefined,
          ...(isLumpSum && { name: rowMaterialText }),
          clearUnitPrice: !hasUnitPrice,
          position: index,
          costCodeId: getCostCodeId(row),
          instructions: { text: notes },
        });
      } else {
        newItems.push({
          projectItem: {
            estimateUom: uom ?? "",
            ...(isOrgCatalogSku(material.material) && {
              orgCatalogSkuId: material.material.id,
            }),
            ...(isProductSku(material.material) && {
              masterProductId: material.material.id,
            }),
            ...(isMasterSku(material.material) && {
              masterSkuId: material.material.id,
            }),
          },
          quantityDecimal,
          unitPrice: unitPrice ? String(unitPrice) : undefined,
          ...(isLumpSum && { name: rowMaterialText }),
          position: index,
          costCodeId: getCostCodeId(row),
          zoneId: zone?.id,
          instructions: { text: notes },
        });
        if (row.id) {
          itemsToRemove.push(row.id);
        }
      }
    });

    const includeServices = [];
    if (!values.willCall) {
      includeServices.push({ type: ServiceType.Delivery });
    }
    if (!values.willCall && values.vendorStocking) {
      includeServices.push({ type: ServiceType.Stocking });
    }

    if (release) {
      return await updateRelease({
        releaseId: release.id,
        version: release.version,
        sellerOrgLocationId: values.vendorId,
        addedItems: newItems,
        updates: itemsToUpdate,
        removedItems: itemsToRemove,
        taxRate: values.taxRate || undefined,
        customTaxAmount: values.customTaxAmount || undefined,
        clearCustomTaxAmount: !values.customTaxAmount,
        additionalCharges: values.additionalCharges.filter(
          (charge) => charge.description && Number(charge.amount) > 0,
        ),
        poNumber: values.poNumber,
        requestedTime: values.orderDate?.getTime() ?? undefined,
        requiresInventoryReceipt: values.requiresInventoryReceipt,
        includeServices,
        instructions: values.instructions,
        typeId: values.orderTypeId || undefined,
        assignDefaultCostCodes: false,
        prefillPrices: false,
      });
    }

    return release;
  };

  const createLumpSumRelease = async (
    skipConfirmation?: boolean,
    retroactive?: boolean,
    status?: ReleaseStatus,
  ) => {
    setSaving(true);
    const values = getValues();
    const costCode = costCodes.find(
      (c) => c.id === values.costCodeId,
    )?.description;

    const lumpSumMaterial = materials.find(
      (m) => m.material.name === LUMP_SUM_UOM_PLURAL_DESCRIPTION,
    );

    let newMaterials;
    if (!lumpSumMaterial) {
      newMaterials = await updateMaterials({
        addedMaterials: [
          {
            newOrgCatalogSKU: {
              defaultUom: LUMP_SUM_UOM,
              name: LUMP_SUM_UOM_PLURAL_DESCRIPTION,
            },
          },
        ],
      });
    }
    const items: AddToReleaseItemInput[] = [
      {
        projectItem: {
          estimateUom: LUMP_SUM_UOM,
          orgCatalogSkuId: (
            lumpSumMaterial || (newMaterials as OrgMaterialFieldsFragment[])[0]
          )?.material.id,
        },
        quantityDecimal: String(values.subtotal),
        position: 0,
        unitPrice: "1",
        tags: values.phaseCodeId ? [values.phaseCodeId] : undefined,
        costCodeId: values.costCodeId,
        name: intl.$t({ id: "NAME_LUMP_SUM" }, { name: costCode }),
      },
    ];

    const relStatus =
      values.orderDate?.getTime() < Date.now()
        ? retroactive
          ? ReleaseStatus.Received
          : ReleaseStatus.Scheduled
        : skipConfirmation
          ? ReleaseStatus.Scheduled
          : skipConfirmation === false
            ? ReleaseStatus.Requested
            : ReleaseStatus.Draft;

    const data = await createRelease({
      projectId: values.projectId ?? "",
      sellerOrgLocationId: values.vendorId,
      poNumber: values.poNumber || undefined,
      time: values.orderDate?.getTime() ?? undefined,
      items,
      requiresInventoryReceipt: values.requiresInventoryReceipt,
      quoteDocumentId: quoteDocument?.id ?? "",
      taxRate:
        values.taxRate ||
        (values.clearCustomTaxAmount || values.customTaxAmount?.length === 0
          ? "0"
          : undefined),
      customTaxAmount:
        values.clearCustomTaxAmount || values.customTaxAmount?.length === 0
          ? undefined
          : values.customTaxAmount,
      additionalCharges: values.additionalCharges,
      instructions: values.instructions,
      paymentTerm: values.paymentTerm,
      status: status || relStatus,
      typeId: values.orderTypeId || undefined,
      vendorContactIds: values.vendorContactIds,
      taxCodeId: values.taxCodeId,
      taxType: values.taxType,
    });

    if (data?.id) {
      navigate(
        generatePath(routes.delivery, {
          deliveryId: data.id,
        }),
      );
    }

    return data;
  };

  const updateLumpSumRelease = async () => {
    const values = getValues();
    const includeServices = [];
    if (!values.willCall) {
      includeServices.push({ type: ServiceType.Delivery });
    }
    if (!values.willCall && values.vendorStocking) {
      includeServices.push({ type: ServiceType.Stocking });
    }
    const costCode = costCodes.find(
      (c) => c.id === values.costCodeId,
    )?.description;
    const lumpSumMaterial = materials.find(
      (m) => m.material.name === LUMP_SUM_UOM_PLURAL_DESCRIPTION,
    );
    const newMaterials = await updateMaterials({
      addedMaterials: [
        {
          newOrgCatalogSKU: {
            defaultUom: LUMP_SUM_UOM,
            name: LUMP_SUM_UOM_PLURAL_DESCRIPTION,
          },
        },
      ],
    });
    const newItems: AddToReleaseItemInput[] = [
      {
        projectItem: {
          estimateUom: LUMP_SUM_UOM,
          orgCatalogSkuId: (
            lumpSumMaterial || (newMaterials as OrgMaterialFieldsFragment[])[0]
          )?.material.id,
        },
        quantityDecimal: String(values.subtotal),
        position: 0,
        unitPrice: "1",
        tags: values.phaseCodeId ? [values.phaseCodeId] : undefined,
        costCodeId: values.costCodeId,
        name: intl.$t({ id: "NAME_LUMP_SUM" }, { name: costCode }),
      },
    ];
    const itemsToUpdate: UpdateContractorReleaseItemInput[] = [];
    const itemsToRemove = release?.items.map((item) => item.id);
    if (release) {
      return await updateRelease({
        releaseId: release.id,
        version: release.version,
        sellerOrgLocationId: values.vendorId,
        addedItems: newItems,
        updates: itemsToUpdate,
        removedItems: itemsToRemove,
        taxRate: values.taxRate || undefined,
        customTaxAmount: values.customTaxAmount || undefined,
        clearCustomTaxAmount: !values.customTaxAmount,
        additionalCharges: values.additionalCharges.filter(
          (charge) => charge.description && Number(charge.amount) > 0,
        ),
        poNumber: values.poNumber,
        requestedTime: values.orderDate?.getTime() ?? undefined,
        requiresInventoryReceipt: values.requiresInventoryReceipt,
        includeServices,
        instructions: values.instructions,
        typeId: values.orderTypeId || undefined,
        assignDefaultCostCodes: false,
        prefillPrices: false,
      });
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        syncCreateReleaseFromQuote,
        syncUpdateReleaseFromQuote,
        validateSpreadsheet,
        loading: saving || creating || updating,
        vendors,
        loadingVendors,
        findOrderTypeByLocationId,
        loadingPredictedPoNumber,
        itemized,
        setItemized,
        createLumpSumRelease,
        updateLumpSumRelease,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useOrderFromQuote = (): ProviderContextType =>
  useContext(ProviderContext);
