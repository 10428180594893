import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import { UsersProvider } from "@/contractor/pages/admin/users/components/providers/UsersProvider";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { DeliveriesHeader } from "./components/DeliveriesHeader";
import { DeliveriesList } from "./components/DeliveriesList";
import { ReceiveReleaseDialog } from "./components/receive-release-dialog/ReceiveReleaseDialog";
import { ReleaseSideActions } from "./components/side-actions/ReleasesSideActions";
import {
  DeliveriesProvider,
  useDeliveries,
} from "./providers/DeliveriesProvider";
import { ReleaseSequenceProvider } from "./providers/ReleaseSequenceProvider";

const DeliveriesWithProvider = () => {
  const intl = useIntl();
  const { deliveries, loading, totalCount, isFiltered } = useDeliveries();

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "DELIVERIES" })}</title>
      </Helmet>
      <DeliveriesHeader />
      <DeliveriesList
        deliveries={deliveries}
        loading={loading}
        totalCount={totalCount}
        isFiltered={isFiltered}
      />
      <ReceiveReleaseDialog />
      <ReleaseSideActions />
    </>
  );
};
export const Deliveries = () => (
  <UsersProvider>
    <PaginationProvider itemsPerPage={DEFAULT_ITEMS_PER_PAGE}>
      <DeliveriesProvider>
        <ReleaseSequenceProvider>
          <DeliveriesWithProvider />
        </ReleaseSequenceProvider>
      </DeliveriesProvider>
    </PaginationProvider>
  </UsersProvider>
);
