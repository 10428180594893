import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { Dialog } from "@/common/components/dialog/Dialog";
import { DateTimePicker } from "@/common/components/picker/components/DateTimePicker";
import { defaultReleaseDate } from "@/common/utils/dates/defaultReleaseDate";
import { PickerCustomActionBar } from "@/contractor/pages/home/release/pages/specify-details/PickerCustomActionBar";
import {
  ReleaseErrorType,
  useReleaseActions,
} from "@/contractor/pages/home/release/providers/ReleaseActionsProvider";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useReleaseUpdate } from "@/contractor/pages/home/release/providers/ReleaseUpdateProvider";
import { UpdateContractorReleaseInput } from "@/generated/graphql";
import { InputLabelProps } from "@mui/material";
import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const DateTimeContainer = tw.div`flex items-center justify-center pt-2`;

interface DeliverySlipReceiveDialogProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  handleConfirm: () => Promise<void>;
  confirming: boolean;
}

export const DeliverySlipReceiveDialog = ({
  visible,
  setVisible,
  handleConfirm,
  confirming,
}: DeliverySlipReceiveDialogProps) => {
  const intl = useIntl();
  const { release } = useRelease();
  const { requestedDate, setRequestedDate, inputErrors } = useReleaseActions();
  const { updateRelease, updating } = useReleaseUpdate();

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const InputLabelPropsMemoized: InputLabelProps = useMemo(() => {
    return {
      classes: {
        shrink: "text-base",
        root: "text-sm pt-1",
      },
    };
  }, []);

  const onDateConfirm = async (date: Date | null) => {
    setRequestedDate(date);
    const currentRelease = release;

    if (currentRelease) {
      const input: UpdateContractorReleaseInput = {
        releaseId: currentRelease?.id,
        version: currentRelease?.version,
      };

      if (date) {
        input.requestedTime = date.getTime();
      }

      await updateRelease(input);
    }
  };

  const retroactiveRelease = useMemo(() => {
    return requestedDate && requestedDate?.getTime() < new Date().getTime();
  }, [requestedDate]);

  const onDateChange = (date: Date | null) => {
    setRequestedDate(!requestedDate ? defaultReleaseDate(date) : date);
  };

  return (
    <Dialog
      icon={<WarningIcon />}
      title={
        <FormattedMessage
          id={"RECEIVE_PACKING_SLIP_CONFIRMATION"}
          values={{ br: <br /> }}
        />
      }
      saving={confirming || updating}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      disabledConfirmButton={!release?.time}
      confirmButtonText={intl.$t({
        id: "PROCEED",
      })}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      loading={false}
      show={visible}
      content={
        <DateTimeContainer>
          <DateTimePicker
            label={intl.$t({ id: "REQUESTED_FULFILLMENT_DATE" })}
            date={requestedDate}
            onChange={onDateChange}
            onAccept={onDateConfirm}
            required
            error={inputErrors.includes(
              ReleaseErrorType.REQUESTED_FULFILLMENT_DATE,
            )}
            InputLabelProps={InputLabelPropsMemoized}
            actionBar={retroactiveRelease ? PickerCustomActionBar : undefined}
          />
        </DateTimeContainer>
      }
    />
  );
};
