import { BuyoutPickerCustomRender } from "@/common/components/buyout-picker/BuyoutPickerCustomRender";
import { If } from "@/common/components/if/If";
import { OrderTypePickerControlled } from "@/common/components/order-type-picker/OrderTypePickerControlled";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { SwitchControlled } from "@/common/components/switch/SwitchControlled";
import {
  VendorPickerCustomRender,
  vendorLabelFormatter,
} from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { WarehouseSelectorCustomRenderer } from "@/common/components/warehouse-selector/WarehouseSelectorCustomRenderer";
import {
  PROJECT_ADDRESS_ID,
  useWarehouseOptions,
} from "@/common/components/warehouse-selector/useWarehouseOptions";
import { useOrderTypesConfig } from "@/common/hooks/order-types-config/useOrderTypesConfig";
import { useUser } from "@/common/providers/UserProvider";
import { routes } from "@/config/routes";
import { useVendors } from "@/contractor/pages/admin/vendors/hooks/useVendors";
import { QuoteDocumentImport } from "@/contractor/pages/home/common/quote-document/components/QuoteDocumentImport";
import {
  QuoteDocumentProvider,
  useQuoteDocument,
} from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import {
  AssetFieldsFragment,
  AuthorizationStatus,
  PoNumberingMode,
  ProjectFieldsFragment,
  ReleaseStatus,
  ServiceType,
  WarehouseFieldsFragment,
  usePredictedReleaseSequenceNumberQuery,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import {
  WarehousesProvider,
  useWarehouses,
} from "../../../../../../admin/warehouse/providers/WarehousesProvider";

import { useDialog } from "@/common/components/dialog/DialogProvider";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { PoNumberInputControlled } from "@/common/components/po-number/PoNumberInputControlled";
import { useProjectPredictedPoNumber } from "@/common/components/po-number/hooks/useProjectPredictedPoNumber";
import { getProjectSelectorLabel } from "@/common/components/projects-filter-selector/getProjectSelectorLabel";
import { SuccessModal } from "@/common/components/success-modal/SuccessModal";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { useCreateRelease } from "@/contractor/pages/home/release/hooks/useCreateRelease";
import { InfoOutlined } from "@mui/icons-material";
import { useDeliveries } from "../../providers/DeliveriesProvider";
import { NoteDocumentImport } from "../order-from-note/note-document/components/NoteDocumentImport";
import {
  NoteDocumentProvider,
  useNoteDocument,
} from "../order-from-note/note-document/providers/NoteDocumentProvider";
import {
  BuyoutsByProjectProvider,
  useBuyoutsByProject,
} from "./BuyoutsByProjectProvider";
import { PoPredictedNumber } from "./PoPredictedNumber";

const FormContainer = tw.div`flex flex-col w-full gap-5`;
const SwitchGroup = tw.div`flex flex-row gap-2 items-center justify-between`;
const GrayContainer = tw.div`flex flex-col bg-gray-100 gap-3 rounded-3xl shadow-md p-5`;
const ConfirmationContainer = tw.div`max-w-38`;

type FormType = {
  locationId: string;
  projectId: string;
  buyoutId: string;
  vendorId?: string;
  warehouseId?: string;
  willCall: boolean;
  vendorStocking: boolean;
  orderTypeId: string;
  poNumber: string;
  reservePoNumber: boolean;
  reserveSequenceNumber: boolean;
};

enum NewOrderType {
  CreateNew = "createNew",
  ImportQuote = "quote",
  ImportNote = "note",
}

const tabs = [
  {
    label: <FormattedMessage id="CREATE_NEW" />,
    viewState: NewOrderType.CreateNew,
  },
  {
    label: (
      <FormattedMessage id="IMPORT_FROM_VENDOR_QUOTE" values={{ br: <br /> }} />
    ),
    viewState: NewOrderType.ImportQuote,
  },
  {
    label: <FormattedMessage id="IMPORT_FROM_NOTE" values={{ br: <br /> }} />,
    viewState: NewOrderType.ImportNote,
  },
];

type Props = {
  onClose: () => void;
  project?: ProjectFieldsFragment | null;
};

const NewDeliveryFormWithProvider: FC<Props> = ({ onClose, project }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { buyouts, setProjectIdFilter } = useBuyoutsByProject();
  const { vendors, findOrderTypeByLocationId } = useVendors();
  const { locations } = useUserLocations();
  const { projects } = useProjectListOptions();
  const { createReservedRelease, createStandaloneRelease, creating } =
    useCreateRelease();
  const { viewer, poNumbering } = useUser();
  const { warehouses } = useWarehouses();
  const [view, setView] = useState(NewOrderType.CreateNew);
  const { createQuoteDocumentWithAsset } = useQuoteDocument();
  const { createNoteDocumentWithAsset } = useNoteDocument();
  const [asset, setAsset] = useState<AssetFieldsFragment | null>(null);
  const { getOrderType, defaultOrderType } = useOrderTypeOptions();
  const { openDialog } = useDialog();
  const { refetch } = useDeliveries();
  const { data: predictedReleaseSequenceData } =
    usePredictedReleaseSequenceNumberQuery({
      fetchPolicy: "network-only",
      skip: poNumbering !== PoNumberingMode.Never,
    });

  const { handleSubmit, setValue, watch, ...formProps } = useForm({
    defaultValues: {
      locationId: "",
      projectId: project?.id || "",
      buyoutId: "",
      orderTypeId: "",
      warehouseId: "",
      vendorId: "",
      willCall: false,
      vendorStocking: false,
      poNumber: "",
      reservePoNumber: false,
      reserveSequenceNumber: false,
    },
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const locationId = watch("locationId");
  const projectId = watch("projectId");
  const orderTypeId = watch("orderTypeId");
  const vendorId = watch("vendorId");
  const buyoutId = watch("buyoutId");
  const reservePoNumber = watch("reservePoNumber");
  const reserveSequenceNumber = watch("reserveSequenceNumber");

  const selectedProject = useMemo(
    () => project ?? projects.find((project) => project.id === projectId),
    [project, projectId, projects],
  );

  useEffect(() => {
    if (selectedProject) {
      setValue("locationId", selectedProject.location.id);
    }
  }, [selectedProject, setValue]);

  const { predictedPoNumber, loading: loadingPredictedPoNumber } =
    useProjectPredictedPoNumber(projectId);

  const transactionKind = useMemo(
    () => getOrderType(orderTypeId)?.transactionKind,
    [getOrderType, orderTypeId],
  );
  const { orderTypeConfig } = useOrderTypesConfig({
    release:
      orderTypeId && transactionKind
        ? {
            type: {
              transactionKind,
            },
          }
        : undefined,
  });

  const { warehouseOptions } = useWarehouseOptions(
    warehouses,
    project?.address ||
      projects.find((project) => project.id === projectId)?.address,
  );

  useEffect(() => {
    setProjectIdFilter(projectId);
  }, [projectId, setProjectIdFilter]);

  useEffect(() => {
    if (projectId) {
      setValue("warehouseId", PROJECT_ADDRESS_ID);
    }
  }, [projectId, setValue]);

  useEffect(() => {
    if (!project && locations?.length === 1) {
      setValue("locationId", locations[0].id);
    }
  }, [locations, setValue, project]);

  useEffect(() => {
    if (!project && projects?.length === 1) {
      setValue("projectId", projects[0].id);
    }
  }, [project, projects, setValue]);

  useEffect(() => {
    if (defaultOrderType) {
      setValue("orderTypeId", defaultOrderType.id);
    }
  }, [defaultOrderType, setValue]);

  useEffect(() => {
    if (buyoutId) {
      const buyout = buyouts.find((b) => b.id === buyoutId);
      setValue("orderTypeId", buyout?.releaseType?.id || "");
    }
  }, [buyoutId, buyouts, setValue]);

  useEffect(() => {
    if (vendorId) {
      const orderTypeId = findOrderTypeByLocationId(vendorId);
      if (orderTypeId) {
        setValue("orderTypeId", orderTypeId);
      }
    }
  }, [findOrderTypeByLocationId, setValue, vendorId]);

  const hasBuyout = useMemo(
    () =>
      !!vendorId && buyouts.some((b) => b.sellerOrgLocation?.id === vendorId),
    [vendorId, buyouts],
  );

  const onSubmit = useCallback(
    async (values: FormType) => {
      const {
        projectId,
        buyoutId,
        vendorId,
        warehouseId,
        orderTypeId,
        poNumber,
        reservePoNumber,
        reserveSequenceNumber,
      } = values;
      const newPoNumber = reservePoNumber
        ? poNumbering === PoNumberingMode.Always
          ? poNumber || predictedPoNumber
          : poNumber
        : undefined;
      if (hasBuyout && buyoutId) {
        const result = await createReservedRelease({
          buyoutId,
          typeId: orderTypeId || undefined,
          poNumber: newPoNumber,
        });
        if (result) {
          navigate(
            generatePath(routes.specifyDeliveryDetails, {
              deliveryId: result.id,
            }),
          );
        }
      } else {
        const includeServices = [];
        if (!values.willCall) {
          includeServices.push({ type: ServiceType.Delivery });
        }
        if (!values.willCall && values.vendorStocking) {
          includeServices.push({ type: ServiceType.Stocking });
        }

        const result = await createStandaloneRelease({
          projectId,
          sellerOrgLocationId: vendorId || undefined,
          warehouseId:
            warehouseId === PROJECT_ADDRESS_ID ? undefined : warehouseId,
          includeServices,
          typeId: orderTypeId || undefined,
          poNumber: newPoNumber,
          status:
            reserveSequenceNumber || reservePoNumber
              ? ReleaseStatus.Reserved
              : undefined,
        });
        if (result) {
          if (reservePoNumber && result.poNumber) {
            onClose();
            refetch();
            openDialog({
              content: (
                <SuccessModal
                  message={
                    <ConfirmationContainer>
                      {intl.$t(
                        { id: "RESERVE_PO_NUMBER_CONFIRMATION" },
                        { poNumber: result.poNumber },
                      )}
                    </ConfirmationContainer>
                  }
                />
              ),
              closingTimer: DIALOG_AUTO_CLOSE_TIMER,
            });
            return;
          }
          if (reserveSequenceNumber && result.sequenceNumber) {
            onClose();
            refetch();
            openDialog({
              content: (
                <SuccessModal
                  message={
                    <ConfirmationContainer>
                      {intl.$t(
                        { id: "ORDER_NUMBER_WAS_RESERVED" },
                        { sequenceNumber: result.sequenceNumber },
                      )}
                    </ConfirmationContainer>
                  }
                />
              ),
              closingTimer: DIALOG_AUTO_CLOSE_TIMER,
            });
            return;
          }
          navigate(
            generatePath(routes.specifyDeliveryDetails, {
              deliveryId: result.id,
            }),
          );
        }
      }
    },
    [
      createReservedRelease,
      createStandaloneRelease,
      hasBuyout,
      onClose,
      refetch,
      openDialog,
      navigate,
      predictedPoNumber,
      poNumbering,
      intl,
    ],
  );

  const requiresApproval = useMemo(() => {
    const locationRoles = viewer?.locationRoles || [];
    return (
      viewer?.org?.settings?.releaseApproval.enabled &&
      !viewer?.org?.settings?.releaseApproval.exemptRoles.some((role) =>
        locationRoles.find(
          (lRole) => lRole.role === role && lRole.orgLocID === locationId,
        ),
      )
    );
  }, [viewer, locationId]);

  const onTabChange = useCallback(
    (viewState: string) => {
      setView(viewState as NewOrderType);
    },
    [setView],
  );

  const createFromQuote = useCallback(
    async (values: FormType) => {
      const result = await createQuoteDocumentWithAsset(
        values.locationId || project?.location.id || "",
      );
      if (result) {
        navigate({
          pathname: generatePath(routes.deliveryFromQuote, {
            quoteDocumentId: result?.id,
          }),
          search: `?projectId=${projectId}`,
        });
      }
    },
    [createQuoteDocumentWithAsset, navigate, project?.location.id, projectId],
  );

  const createFromNote = useCallback(
    async (values: FormType) => {
      const result = await createNoteDocumentWithAsset(
        values.locationId || project?.location.id || "",
      );

      if (result) {
        navigate({
          pathname: generatePath(routes.deliveryFromNote, {
            noteDocumentId: result?.id,
          }),
          search: `?projectId=${projectId}`,
        });
      }
    },
    [createNoteDocumentWithAsset, navigate, project?.location.id, projectId],
  );

  const saveLabel = useMemo(() => {
    const id =
      reservePoNumber || reserveSequenceNumber
        ? poNumbering !== PoNumberingMode.Never
          ? "RESERVE_PO_NUMBER"
          : "RESERVE_ORDER"
        : view === NewOrderType.CreateNew
          ? "CREATE_DELIVERY"
          : "IMPORT";
    return intl.$t({ id });
  }, [intl, poNumbering, reservePoNumber, reserveSequenceNumber, view]);

  const selectedLocation = useMemo(
    () => locations.find((location) => location.id === locationId),
    [locationId, locations],
  );

  return (
    <OverlayPanel
      title={<FormattedMessage id="NEW_RELEASE" />}
      onSave={
        view === NewOrderType.CreateNew
          ? handleSubmit(onSubmit)
          : view === NewOrderType.ImportQuote
            ? handleSubmit(createFromQuote)
            : handleSubmit(createFromNote)
      }
      disableSave={
        (view === NewOrderType.ImportQuote ||
          view === NewOrderType.ImportNote) &&
        ((!locationId && !project) || !asset)
      }
      onCancel={onClose}
      saveLabel={saveLabel}
      flexDirectionRow
      isLoading={creating}
      tabs={tabs}
      activeTab={view}
      onTabChange={onTabChange}
    >
      <FormProvider
        {...formProps}
        handleSubmit={handleSubmit}
        setValue={setValue}
        watch={watch}
      >
        <If isTrue={view === NewOrderType.CreateNew}>
          <FormContainer>
            <SelectControlled
              name="projectId"
              placeholder={intl.$t({ id: "PROJECT_NAME" })}
              options={project ? [project] : projects}
              rules={{ required: true }}
              getLabel={(option) => getProjectSelectorLabel(option)}
              getValue={(option) => option.id}
              noOptionsText={intl.$t({ id: "NO_PROJECTS_FOUND" })}
              staticText={!!project?.id}
            />
            <SelectControlled
              name="vendorId"
              testId="form-vendors-selector"
              placeholder={intl.$t({
                id:
                  requiresApproval && !reservePoNumber && !reserveSequenceNumber
                    ? "VENDOR_OPTIONAL"
                    : "VENDOR",
              })}
              options={vendors || []}
              customRender={(item) =>
                VendorPickerCustomRender(
                  item,
                  (c) => c.receivesOrderNotifications,
                )
              }
              rules={{
                required:
                  reservePoNumber || reserveSequenceNumber || !requiresApproval,
              }}
              getLabel={(o) =>
                vendorLabelFormatter(
                  o.sellerOrgLocation,
                  o.contacts.filter((c) => c.receivesOrderNotifications),
                )
              }
              getValue={(option) => option.sellerOrgLocation.id}
              noOptionsText={intl.$t({ id: "NO_VENDORS_FOUND" })}
            />
            <OrderTypePickerControlled
              name="orderTypeId"
              required
              disabled={!!buyoutId}
            />
            <If isTrue={hasBuyout}>
              <SelectControlled
                name="buyoutId"
                testId="form-buyouts-selector"
                placeholder={intl.$t({ id: "BUYOUT_TITLE_OPTIONAL" })}
                options={buyouts || []}
                customRender={(item) =>
                  BuyoutPickerCustomRender(
                    item,
                    (c) => c.receivesQuoteNotifications,
                  )
                }
                getLabel={(option) =>
                  `#${option.clientIdentifier} - ${
                    option.sellerOrgLocation.org.name
                  } - ${option.preferredVendor?.contacts
                    .filter((c) => c.receivesQuoteNotifications)
                    .map((c) => c.name)
                    .join(", ")}`
                }
                getValue={(option) => option.id}
                noOptionsText={intl.$t({ id: "NO_BUYOUTS_FOUND" })}
                disableClearable={false}
              />
            </If>
            <If
              isTrue={
                orderTypeConfig?.features.includeVendorStocking ||
                orderTypeConfig?.features.includeWillCall
              }
            >
              <GrayContainer>
                <If isTrue={orderTypeConfig?.features.includeWillCall}>
                  <SwitchGroup>
                    <FormattedMessage id="REQUEST_VENDOR_RELEASE" />
                    <SwitchControlled
                      name="willCall"
                      onLabel={intl.$t({ id: "YES" })}
                      offLabel={intl.$t({ id: "NO" })}
                      testId="will-call-switch"
                    />
                  </SwitchGroup>
                </If>
                <If
                  isTrue={
                    !watch("willCall") &&
                    orderTypeConfig?.features.includeWillCall
                  }
                >
                  <SelectControlled
                    name="warehouseId"
                    options={warehouseOptions}
                    placeholder={intl.$t({ id: "DELIVER_TO" })}
                    getLabel={(option: WarehouseFieldsFragment) => option.name}
                    getValue={(option: WarehouseFieldsFragment) => option.id}
                    rules={{ required: true }}
                    customRender={WarehouseSelectorCustomRenderer}
                  />
                </If>
                <If
                  isTrue={
                    !watch("willCall") &&
                    orderTypeConfig?.features.includeVendorStocking
                  }
                >
                  <SwitchGroup>
                    <FormattedMessage id="REQUEST_VENDOR_STOCKING" />
                    <SwitchControlled
                      name="vendorStocking"
                      onLabel={intl.$t({ id: "YES" })}
                      offLabel={intl.$t({ id: "NO" })}
                      testId="vendor-stocking-switch"
                    />
                  </SwitchGroup>
                </If>
              </GrayContainer>
              <If
                isTrue={
                  selectedLocation?.permissions.submitReleaseDirectly ===
                  AuthorizationStatus.Authorized
                }
              >
                <SwitchGroup>
                  <FormattedMessage
                    id={
                      poNumbering === PoNumberingMode.Never
                        ? "RESERVE_PO_BLANK_ORDER_NUMBER"
                        : "RESERVE_PO_NUMBER_FOR_ORDER"
                    }
                  />
                  <If isTrue={poNumbering === PoNumberingMode.Never}>
                    <Tooltip
                      id="subtotal-price"
                      element={
                        <LinkLike onClick={() => null} forwardEvent={false}>
                          <InfoOutlined />
                        </LinkLike>
                      }
                      className="ml-auto"
                    >
                      <FormattedMessage id="RESERVE_ORDER_TOOLTIP" />
                    </Tooltip>
                  </If>
                  {poNumbering === PoNumberingMode.Never ? (
                    <SwitchControlled
                      name="reserveSequenceNumber"
                      onLabel={intl.$t({ id: "YES" })}
                      offLabel={intl.$t({ id: "NO" })}
                      testId="reserver-sequence-number-switch"
                    />
                  ) : (
                    <SwitchControlled
                      name="reservePoNumber"
                      onLabel={intl.$t({ id: "YES" })}
                      offLabel={intl.$t({ id: "NO" })}
                      testId="reserver-po-number-switch"
                    />
                  )}
                </SwitchGroup>
              </If>
              <If isTrue={reservePoNumber}>
                <PoNumberInputControlled
                  setValue={(poNumber) => setValue("poNumber", poNumber)}
                  trigger={() => formProps.trigger("poNumber")}
                  predictedPoNumber={predictedPoNumber}
                  loading={loadingPredictedPoNumber}
                  required={
                    reservePoNumber &&
                    (poNumbering !== PoNumberingMode.Always ||
                      !predictedPoNumber)
                  }
                />
              </If>
              <If isTrue={reserveSequenceNumber}>
                <PoPredictedNumber
                  predictedNumber={
                    predictedReleaseSequenceData?.viewer?.org
                      .predictedReleaseSequenceNumber
                  }
                />
              </If>
            </If>
          </FormContainer>
        </If>
        <If isTrue={view === NewOrderType.ImportQuote}>
          <FormContainer>
            <If isTrue={locations && locations.length !== 1 && !project?.id}>
              <SelectControlled
                name="locationId"
                placeholder={intl.$t({ id: "PROJECT_LOCATION" })}
                options={locations || []}
                rules={{ required: true }}
                getLabel={(option) => option.name}
                getValue={(option) => option.id}
                noOptionsText={intl.$t({ id: "NO_LOCATIONS_FOUND" })}
              />
            </If>
            <QuoteDocumentImport onChange={setAsset} />
          </FormContainer>
        </If>
        <If isTrue={view === NewOrderType.ImportNote}>
          <FormContainer>
            <If isTrue={locations && locations.length !== 1 && !project?.id}>
              <SelectControlled
                name="locationId"
                placeholder={intl.$t({ id: "PROJECT_LOCATION" })}
                options={locations || []}
                rules={{ required: true }}
                getLabel={(option) => option.name}
                getValue={(option) => option.id}
                noOptionsText={intl.$t({ id: "NO_LOCATIONS_FOUND" })}
              />
            </If>
            <NoteDocumentImport onChange={setAsset} />
          </FormContainer>
        </If>
      </FormProvider>
    </OverlayPanel>
  );
};

export const NewDeliveryForm: FC<Props> = (props) => {
  return (
    <BuyoutsByProjectProvider>
      <WarehousesProvider>
        <QuoteDocumentProvider>
          <NoteDocumentProvider>
            <NewDeliveryFormWithProvider {...props} />
          </NoteDocumentProvider>
        </QuoteDocumentProvider>
      </WarehousesProvider>
    </BuyoutsByProjectProvider>
  );
};
