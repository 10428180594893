import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  UpdateOrgSettingsInput,
  useUpdateOrgSettingsMutation,
  ViewerDocument,
} from "@/generated/graphql";

export const useUpdateOrgSettings = () => {
  const { setError } = useGlobalError();

  const [updateOrgSettingsMutation, { loading: updating }] =
    useUpdateOrgSettingsMutation();
  const updateOrgSettings = async (input: UpdateOrgSettingsInput) => {
    try {
      const { errors } = await updateOrgSettingsMutation({
        variables: { input },
        refetchQueries: [{ query: ViewerDocument }],
      });
      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  return {
    updateOrgSettings,
    updating,
  };
};
