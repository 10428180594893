import { If } from "@/common/components/if/If";
import { Select } from "@/common/components/select/components/single/Select";
import { useEquipmentItems } from "@/contractor/pages/admin/cost-structure/pages/equipment/hooks/useEquipmentItems";
import { useServiceCodes } from "@/contractor/pages/admin/cost-structure/pages/service-codes/hooks/useServiceCodes";
import {
  InvoiceFieldsFragment,
  ReceiptFieldsFragment,
  UpdateInvoiceInput,
  UpdateInvoiceMutation,
  UpdateReceiptMutation,
} from "@/generated/graphql";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { EquipmentItemServiceCodeSelectorItem } from "./EquipmentItemServiceCodeSelectorItem";

const Row = tw.div`gap-y-4 grid grid-cols-2 py-3 text-sm gap-x-2 items-center`;

type Props = {
  invoice: InvoiceFieldsFragment | ReceiptFieldsFragment | null;
  readonly: boolean;
  setUpdatesCount: (count: SetStateAction<number>) => void;
  updateInvoice: (
    input: UpdateInvoiceInput,
  ) => Promise<
    UpdateInvoiceMutation | UpdateReceiptMutation | undefined | null
  >;
};

export const EquipmentItemServiceCodeSelectors = ({
  invoice,
  readonly,
  setUpdatesCount,
  updateInvoice,
}: Props) => {
  const { equipmentItems, loading: loadingEquipmentItems } =
    useEquipmentItems();
  const { serviceCodes, loading: loadingServiceCodes } = useServiceCodes();

  const [serviceCodeId, setServiceCodeId] = useState(
    invoice?.serviceCode?.id ?? null,
  );
  const [equipmentItemId, setEquipmentItemId] = useState(
    invoice?.equipmentItem?.id ?? null,
  );

  useEffect(() => {
    setEquipmentItemId(invoice?.equipmentItem?.id ?? null);
  }, [invoice?.equipmentItem?.id]);

  useEffect(() => {
    setServiceCodeId(invoice?.serviceCode?.id ?? null);
  }, [invoice?.serviceCode?.id]);

  const handleEquipmentItemChange = useCallback(
    async (value: string | null) => {
      setEquipmentItemId(value);
      let success;
      if (invoice) {
        if (value) {
          success = await updateInvoice?.({
            id: invoice.id,
            equipmentItemId: value,
          });
        } else {
          success = await updateInvoice?.({
            id: invoice.id,
            clearEquipmentItem: true,
          });
        }
      }
      if (success) {
        setUpdatesCount((count) => count + 1);
      }
    },
    [invoice, updateInvoice, setUpdatesCount],
  );

  const handleServiceCodeChange = useCallback(
    (value: string | null) => {
      setServiceCodeId(value);
      let success;
      if (invoice) {
        if (value) {
          success = updateInvoice?.({
            id: invoice.id,
            serviceCodeId: value,
          });
        } else {
          success = updateInvoice?.({
            id: invoice.id,
            clearServiceCode: true,
          });
        }
      }
      if (success) {
        setUpdatesCount((count) => count + 1);
      }
    },
    [invoice, updateInvoice, setUpdatesCount],
  );

  return (
    <If
      isTrue={
        (equipmentItems.length && !(!invoice?.equipmentItem?.id && readonly)) ||
        (serviceCodes.length && !(!invoice?.serviceCode?.id && readonly))
      }
    >
      <Row>
        <If
          isTrue={
            equipmentItems.length && !(!invoice?.equipmentItem?.id && readonly)
          }
        >
          <FormattedMessage id="RELATED_EQUIPMENT_ITEM" />
          <Select
            options={equipmentItems.toSorted((i1, i2) =>
              i1.description.localeCompare(i2.description),
            )}
            getLabel={(item) => `(${item.code}) ${item.description}`}
            getValue={(item) => item.id}
            onChange={handleEquipmentItemChange}
            value={equipmentItemId}
            loading={loadingEquipmentItems}
            disableClearable={false}
            disabled={readonly}
            className="max-w-[226px] overflow-hidden rounded bg-white"
            customRender={EquipmentItemServiceCodeSelectorItem}
          />
        </If>
        <If
          isTrue={
            serviceCodes.length && !(!invoice?.serviceCode?.id && readonly)
          }
        >
          <FormattedMessage id="SERVICE_CODE" />
          <Select
            options={serviceCodes.toSorted((i1, i2) =>
              i1.description.localeCompare(i2.description),
            )}
            getLabel={(item) => `(${item.code}) ${item.description}`}
            getValue={(item) => item.id}
            onChange={handleServiceCodeChange}
            value={serviceCodeId}
            loading={loadingServiceCodes}
            disableClearable={false}
            disabled={readonly}
            className="max-w-[226px] overflow-hidden rounded bg-white"
            customRender={EquipmentItemServiceCodeSelectorItem}
          />
        </If>
      </Row>
    </If>
  );
};
