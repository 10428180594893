import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useDeliverySlipVerification } from "@/contractor/pages/home/releases/pages/delivery-slips/providers/DeliverySlipVerificationProvider";
import { DeliverySlipItemFieldsFragment } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useAddItemsModal } from "./useAddItemsModal";

export const useAddSlipItems = () => {
  const { handsonInstance } = useColumnMapper();
  const { deliverySlip } = useDeliverySlipVerification();
  const { findMaterialByName, appendRows, rowIsEmpty } = useTableHelpers();
  const { formatCostCode } = useCostCodes();
  const { showModal } = useAddItemsModal();

  const missingItems = useMemo(
    () =>
      deliverySlip?.items.filter(
        (item) =>
          !deliverySlip?.releaseItemHints.some(
            (hint) => hint.deliverySlipItem?.id === item.id,
          ),
      ) || [],
    [deliverySlip?.items, deliverySlip?.releaseItemHints],
  );

  const addAllItems = useCallback(
    (items?: DeliverySlipItemFieldsFragment[]) => {
      const newItems =
        (items || deliverySlip?.items || []).map((item) => {
          const material = findMaterialByName(item.description ?? "");
          return {
            [COLUMN_TYPE.Material]:
              material?.material.name ?? item.description ?? "",
            [COLUMN_TYPE.UOM]: item.uom || "UT",
            [COLUMN_TYPE.Quantity]: item.quantityDecimal || "",
            [COLUMN_TYPE.ReceivedQuantity]: "0",
            [COLUMN_TYPE.CostCode]: formatCostCode(material?.costCode ?? ""),
          };
        }) || [];
      appendRows(newItems, handsonInstance);
    },
    [
      appendRows,
      deliverySlip?.items,
      findMaterialByName,
      formatCostCode,
      handsonInstance,
    ],
  );

  const addMissingItems = useCallback(() => {
    addAllItems(missingItems);
  }, [addAllItems, missingItems]);

  const addSlipItems = useCallback(() => {
    if (handsonInstance?.isDestroyed) {
      return;
    }
    const allRowsEmpty = handsonInstance?.getData().every(rowIsEmpty);
    const noMatches = deliverySlip?.items.length === missingItems.length;
    if (allRowsEmpty || noMatches) {
      addAllItems();
      return;
    }
    showModal(
      {
        handleCustomButtonAction: addAllItems,
        handleConfirm: addMissingItems,
      },
      deliverySlip?.items.length || 0,
      missingItems.length,
    );
  }, [
    handsonInstance,
    rowIsEmpty,
    showModal,
    addAllItems,
    addMissingItems,
    deliverySlip?.items.length,
    missingItems,
  ]);

  return {
    addSlipItems,
  };
};
