import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { ExternalPOItems } from "@/contractor/pages/home/common/external-po/ExternalPOItems";
import { ExternalPOTableCategoryId } from "@/contractor/pages/home/common/external-po/ExternalPOItemsTable.configuration";
import { SourceSystem, useExternalPoQuery } from "@/generated/graphql";
import { Modal } from "@mui/material";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
const ModalStyled = tw(Modal)`flex flex-1 px-20 py-5`;
const ModalContainer = tw.div`flex flex-1 flex-col bg-white rounded-3xl px-8 pt-4 pb-2`;
const Footer = tw.div`h-20 flex justify-end gap-4`;

type Props = {
  opened: boolean;
  poNumber: string | null | undefined;
  projectId: string | null | undefined;
  syncImmutablePo?: () => void;
  syncingImmutablePo?: boolean;
  goBack: () => void;
};

export const ExternalPOModal: FC<Props> = ({
  opened,
  poNumber,
  projectId,
  syncImmutablePo,
  syncingImmutablePo,
  goBack,
}) => {
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettings();

  const { data, loading, error } = useExternalPoQuery({
    variables: {
      input: {
        sourceSystem: connectedSourceSystem ?? SourceSystem.Foundation,
        number: poNumber,
        projectId,
      },
    },
    skip: !connectedSourceSystem || !poNumber || !opened,
    fetchPolicy: "no-cache",
  });

  useErrorEffect(error);

  const missingTagsCategory = useMemo(
    () =>
      (data?.externalPO?.itemGroups.missingTag ?? []).length > 0
        ? [
            {
              id: ExternalPOTableCategoryId.MISSING_TAG,
              isOpened: true,
              name: intl.$t({ id: "MISSING_PHASE_CODES" }),
              description: intl.$t({ id: "MISSING_PHASE_CODES_TOOLTIP" }),
              items: (data?.externalPO?.itemGroups.missingTag ?? []).map(
                (i) => ({
                  ...i,
                  id: i.externalId,
                }),
              ),
            },
          ]
        : [],
    [data?.externalPO?.itemGroups.missingTag, intl],
  );

  const missingCostCodesCategory = useMemo(
    () =>
      (data?.externalPO?.itemGroups.missingCostCode ?? []).length > 0
        ? [
            {
              id: ExternalPOTableCategoryId.MISSING_COST_CODES,
              isOpened: true,
              name: intl.$t({ id: "MISSING_COST_CODES" }),
              description: intl.$t({ id: "MISSING_COST_CODES_TOOLTIP" }),
              items: (data?.externalPO?.itemGroups.missingCostCode ?? []).map(
                (i) => ({ ...i, id: i.externalId }),
              ),
            },
          ]
        : [],
    [data?.externalPO?.itemGroups.missingCostCode, intl],
  );

  const unsupportedCostTypesCategory = useMemo(
    () =>
      (data?.externalPO?.itemGroups.unsupportedCostType ?? []).length > 0
        ? [
            {
              id: ExternalPOTableCategoryId.UNSUPPORTED_COST_TYPES,
              isOpened: true,
              name: intl.$t({ id: "UNSUPPORTED_COST_TYPES" }),
              description: intl.$t({ id: "UNSUPPORTED_COST_TYPES_TOOLTIP" }),
              items: (
                data?.externalPO?.itemGroups.unsupportedCostType ?? []
              ).map((i) => ({ ...i, id: i.externalId })),
            },
          ]
        : [],
    [data?.externalPO?.itemGroups.unsupportedCostType, intl],
  );

  const includedItemsCategory = useMemo(
    () => [
      {
        id: ExternalPOTableCategoryId.INCLUDED,
        isOpened: true,
        name: "",
        items: [
          ...(data?.externalPO?.itemGroups.importable ?? []),
          ...(data?.externalPO?.itemGroups.additionalCharges ?? []),
          ...(data?.externalPO?.itemGroups.salesTax ?? []),
        ].map((i) => ({ ...i, id: i.externalId })),
      },
    ],
    [data?.externalPO?.itemGroups],
  );

  return (
    <ModalStyled open={opened} onClose={goBack}>
      <ModalContainer>
        <ExternalPOItems
          close={goBack}
          loading={loading}
          externalPO={data?.externalPO}
          missingCostCodesCategory={missingCostCodesCategory}
          missingTagsCategory={missingTagsCategory}
          unsupportedCostTypesCategory={unsupportedCostTypesCategory}
          includedItemsCategory={includedItemsCategory}
          readonly
        />
        <Footer>
          <OutlinedButton onClick={goBack}>
            <FormattedMessage id="CLOSE" />
          </OutlinedButton>
          <If isTrue={syncImmutablePo}>
            <PrimaryButton
              onClick={() => {
                syncImmutablePo?.();
                goBack();
              }}
              disabled={syncingImmutablePo}
            >
              <FormattedMessage
                id="SYNC_IMMUTABLE_PO"
                values={{ integration: connectedSourceSystem }}
              />
            </PrimaryButton>
          </If>
        </Footer>
      </ModalContainer>
    </ModalStyled>
  );
};
