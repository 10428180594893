import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { Instructions } from "@/common/components/instructions/Instructions";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { useAddNoteItems } from "@/common/hooks/add-missing-items-to-order/useAddNoteItems";
import { usePredictedTax } from "@/common/hooks/usePredictedTax";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { defaultReleaseDate } from "@/common/utils/dates/defaultReleaseDate";
import { getUTCDate } from "@/common/utils/dates/getUTCDate";
import { routes } from "@/config/routes";
import { useVendors } from "@/contractor/pages/admin/vendors/hooks/useVendors";
import { useProjectZonesStore } from "@/contractor/pages/home/project/store/projectZonesStore";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useReleaseStore } from "@/contractor/pages/home/release/store/useReleaseStore";
import {
  AdditionalChargesFieldsFragment,
  InstructionInput,
  ServiceType,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { ChevronLeft } from "@mui/icons-material";
import Decimal from "decimal.js";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { useOrderFromNote } from "../../../../providers/OrderFromNoteProvider";
import { useNoteDocumentItems } from "../../hooks/useNoteDocumentItems";
import { useNoteDocument } from "../../note-document/providers/NoteDocumentProvider";
import { useCreateOrderFromNoteConfig } from "./CreateOrderFromNote.config";
import { CreateOrderFromNoteFormValues } from "./CreateOrderFromNoteForm";
import { CreateOrderFromNoteHeader } from "./CreateOrderFromNoteHeader";

const Container = tw.div`bg-gray-100 rounded-3xl pt-4 px-4 h-fit overflow-y-scroll`;
const Header = tw.div`grid pl-1 text-base font-medium items-center mb-1`;
const InnerContainer = tw.div`flex flex-col h-full`;
const Footer = tw.div`mt-10 w-full pb-5`;
const SpreadSheetView = tw.div`w-full mt-5 drop-shadow-md rounded-2xl h-fit`;
const HeaderGroup = tw.div`grid grid-cols-[auto_auto_1fr] w-full place-content-center place-items-center gap-1 mb-4`;
const BackButton = tw.button`grid place-items-center bg-blue-800 rounded-md text-white`;
const ButtonGroup = tw.div`grid grid-flow-col w-fit gap-2 justify-self-end pr-2`;
const NewOrderHeaderGroup = tw(HeaderGroup)`grid-cols-[auto_1fr]`;

export const CreateOrderFromNoteDetails: FC = () => {
  const { noteDocument, loading } = useNoteDocument();
  const { findMaterialByName, calcTableTotal } = useTableHelpers();
  const { predictedTaxRate, setTaxInput } = usePredictedTax();
  const { spreadsheetData } = useColumnMapper();
  const spreadsheetViewColumns = useCreateOrderFromNoteConfig();
  const { release, loading: loadingRelease } = useRelease();
  const { calcExtPrice, calcAdditionalChargesPrice } = usePriceCalculation();
  const { watch, setValue, getValues } =
    useFormContext<CreateOrderFromNoteFormValues>();
  const [searchParams] = useSearchParams();
  const { defaultOrderType } = useOrderTypeOptions();
  const { findOrderTypeByLocationId } = useVendors();
  const intl = useIntl();
  const { setHasChanges } = useReleaseStore();
  const navigate = useNavigate();
  const { wasDocumentReplaced } = useOrderFromNote();
  const { addNoteItems } = useAddNoteItems();

  const projectId = watch("projectId");
  const sellerOrgLocationId = watch("vendorId");
  const willCall = watch("willCall");
  const warehouseId = watch("fulfillmentLocationId");
  const customTaxAmount = watch("customTaxAmount");
  const subtotal = watch("subtotal");
  const additionalCharges = watch("additionalCharges");
  const orderTypeId = watch("orderTypeId");
  const taxRate = watch("taxRate");
  const taxCodeId = watch("taxCodeId");
  const taxType = watch("taxType");
  const chargesAmount = watch("additionalCharges");

  const { setCurrentProjectId } = useProjectZonesStore(
    useShallow((state) => ({
      setCurrentProjectId: state.setCurrentProjectId,
    })),
  );

  useEffect(() => {
    if (projectId) {
      setCurrentProjectId(projectId);
    }
  }, [setCurrentProjectId, projectId]);

  const updateSubtotal = useCallback(
    (data: Record<string, string>[]) => {
      setHasChanges(true);
      const newTotal = `${calcTableTotal(data)}`;
      if (newTotal !== watch("subtotal")) {
        setValue("subtotal", newTotal);
      }
    },
    [calcTableTotal, setHasChanges, setValue, watch],
  );

  useEffect(() => {
    setValue("instructions", {
      text: release?.instructions?.text ?? "",
      assetUrls: release?.instructions?.assets.map((asset) => asset.url) ?? [],
    });
    setValue(
      "willCall",
      release?.id
        ? !release?.includeServices.includes(ServiceType.Delivery)
        : false,
    );
    setValue(
      "vendorStocking",
      release?.id
        ? release?.includeServices.includes(ServiceType.Stocking)
        : false,
    );
  }, [
    release?.id,
    release?.includeServices,
    release?.instructions?.assets,
    release?.instructions?.text,
    setValue,
  ]);

  useEffect(() => {
    setValue("businessLocationId", noteDocument?.orgLocation?.id ?? "");
    setValue(
      "projectId",
      projectId || release?.project?.id || searchParams.get("projectId") || "",
    );
    setValue(
      "vendorId",
      sellerOrgLocationId || release?.sellerOrgLocation?.id || "",
    );
    setValue("paymentTerm", release?.paymentTerm || undefined);
    setValue(
      "customTaxAmount",
      getValues("customTaxAmount") || release?.taxAmount || "",
    );
    setValue("poNumber", (getValues("poNumber") || release?.poNumber) ?? "");
    setValue(
      "vendorContactIds",
      getValues("vendorContactIds") ||
        (release?.vendorContacts?.length === 1
          ? release?.vendorContacts?.map((vc) => vc.id)
          : []) ||
        [],
    );
    if (release?.time) {
      setValue("orderDate", defaultReleaseDate(getUTCDate(release.time)));
    }
    if (release?.additionalCharges) {
      setValue("additionalCharges", release.additionalCharges);
    }
    updateSubtotal(spreadsheetData);
  }, [
    updateSubtotal,
    noteDocument,
    setValue,
    searchParams,
    release,
    spreadsheetData,
    projectId,
    sellerOrgLocationId,
    loadingRelease,
    loading,
    getValues,
  ]);
  const { openDialog } = useDialog();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { noteDocumentItems } = useNoteDocumentItems(noteDocument);

  useEffect(() => {
    if (defaultOrderType) {
      setValue("orderTypeId", defaultOrderType.id);
    }
  }, [defaultOrderType, setValue]);

  useEffect(() => {
    if (sellerOrgLocationId) {
      const orderTypeId = findOrderTypeByLocationId(sellerOrgLocationId);
      if (orderTypeId) {
        setValue("orderTypeId", orderTypeId);
      }
    }
  }, [findOrderTypeByLocationId, sellerOrgLocationId, setValue]);

  useEffect(() => {
    setTaxInput({
      projectId,
      sellerOrgLocationId,
      willCall,
      warehouseId: willCall
        ? undefined
        : projectId !== warehouseId
          ? warehouseId
          : undefined,
    });
  }, [projectId, sellerOrgLocationId, willCall, warehouseId, setTaxInput]);

  const { calcTotalPrice } = usePriceCalculation();
  const total = useMemo(
    () =>
      calcTotalPrice({
        subtotal,
        customTaxAmount,
        taxCodeId,
        taxRate,
        orderTypeId,
        additionalCharges,
      }),
    [
      calcTotalPrice,
      subtotal,
      customTaxAmount,
      taxCodeId,
      taxRate,
      orderTypeId,
      additionalCharges,
    ],
  );

  useEffect(() => {
    if (predictedTaxRate && taxRate === "" && !release) {
      openDialog({
        cancelButtonText: intl.$t({ id: "NO" }),
        confirmButtonText: intl.$t({ id: "YES" }),
        icon: <WarningIcon />,
        title: intl.$t({ id: "OVERWRITE_EXISTING_SALES_TAX_QUESTION" }),
        text: intl.$t(
          {
            id: "OVERWRITE_EXISTING_SALES_TAX",
          },
          {
            value: formatCurrency(new Decimal(predictedTaxRate).mul(subtotal), {
              minimumFractionDigits: 2,
            }),
          },
        ),
        handleConfirm: () => {
          setValue("taxRate", predictedTaxRate);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatCurrency, intl, predictedTaxRate, setValue, release]);

  const tableItems = useMemo(() => {
    if (release?.items && release.items.length && !wasDocumentReplaced) {
      return release.items
        .toSorted((a, b) => (a.position || 0) - (b.position || 0))
        .map((item) => {
          const matchingOrgMaterial = findMaterialByName(
            item.projectItem?.material.material.name ?? item.name ?? "",
          );

          return {
            id: item.id,
            name: item.name ?? "",
            material: matchingOrgMaterial,
            costCode:
              item?.costCode?.code ??
              matchingOrgMaterial?.costCode?.code ??
              undefined,
            notes: item.instructions?.text ?? "",
            UOM:
              item?.uom?.pluralDescription ??
              item?.uom?.mnemonic ??
              item.projectItem?.estimateUom.mnemonic,
            unitPrice: item.unitPrice ?? undefined,
            quantityDecimal: item.quantityDecimal ?? "",
            zone: item?.zone ?? "",
            extPrice: calcExtPrice(item.quantityDecimal, item.unitPrice),
          };
        });
    }

    return noteDocumentItems;
  }, [
    release?.items,
    wasDocumentReplaced,
    noteDocumentItems,
    findMaterialByName,
    calcExtPrice,
  ]);

  const handleUpdateRelease = useCallback(
    (values: UpdateContractorReleaseInput) => {
      if (values.additionalCharges) {
        setValue("additionalCharges", values.additionalCharges);
      }

      if (values.clearCustomTaxAmount !== undefined) {
        setValue("clearCustomTaxAmount", !!values.clearCustomTaxAmount);
      }
      if (
        values.customTaxAmount ||
        (values.clearCustomTaxAmount === false && customTaxAmount === undefined)
      ) {
        setValue("customTaxAmount", values.customTaxAmount || "");
        setValue("taxRate", undefined);
      }
      if (values.taxRate || values.clearCustomTaxAmount) {
        setValue("customTaxAmount", undefined);
        setValue("taxRate", values.taxRate || "");
      }
      if (values.taxCodeId) {
        setValue("taxCodeId", values.taxCodeId);
      }
      if (values.taxType) {
        setValue("taxType", values.taxType);
      }

      return true;
    },
    [customTaxAmount, setValue],
  );

  const saveInstruction = useCallback(
    (instruction: InstructionInput) => {
      setValue("instructions", instruction);
    },
    [setValue],
  );

  const handleClick = useCallback(() => {
    if (release) {
      navigate(
        generatePath(routes.specifyDeliveryDetails, { deliveryId: release.id }),
      );
    }
  }, [navigate, release]);

  const releaseInput = useMemo(
    () => ({
      customTaxAmount,
      taxRate,
      orderTypeId,
      subtotal,
      taxCodeId,
      taxType,
      chargesAmount: calcAdditionalChargesPrice(chargesAmount),
    }),
    [
      calcAdditionalChargesPrice,
      chargesAmount,
      customTaxAmount,
      orderTypeId,
      subtotal,
      taxCodeId,
      taxRate,
      taxType,
    ],
  );

  return (
    <Container>
      <If isTrue={release}>
        <HeaderGroup>
          <BackButton onClick={handleClick}>
            <ChevronLeft />
          </BackButton>
          <FormattedMessage id="EDIT_ORDER" tagName={Header} />
          <ButtonGroup>
            <OutlinedButton $small onClick={() => addNoteItems()}>
              <FormattedMessage id="ADD_SCANNED_ITEMS_FROM_NOTE" />
            </OutlinedButton>
          </ButtonGroup>
        </HeaderGroup>
      </If>
      <If isTrue={!release}>
        <NewOrderHeaderGroup>
          <FormattedMessage id="NEW_RELEASE" tagName={Header} />
          <ButtonGroup>
            <OutlinedButton $small onClick={() => addNoteItems()}>
              <FormattedMessage id="ADD_SCANNED_ITEMS_FROM_NOTE" />
            </OutlinedButton>
          </ButtonGroup>
        </NewOrderHeaderGroup>
      </If>
      <CreateOrderFromNoteHeader />
      <InnerContainer>
        <SpreadSheetView>
          <SpreadSheetTable
            items={tableItems}
            columns={spreadsheetViewColumns}
            saving={loading}
            height="550px"
            rowNumber={17}
            onChanges={updateSubtotal}
          />
        </SpreadSheetView>
        <If isTrue={projectId}>
          <Instructions
            instruction={release?.instructions}
            projectId={projectId}
            saveInstruction={saveInstruction}
          />
        </If>
      </InnerContainer>
      <Footer>
        <ReleaseAdditionalChargesAndTaxes
          total={`${total}`}
          releaseInput={releaseInput}
          customPaymentTerm={`${watch("paymentTerm")}`}
          editableByContractor
          updateRelease={handleUpdateRelease}
          includePaymentTerms
          additionalCharges={
            watch("additionalCharges") as AdditionalChargesFieldsFragment[]
          }
        />
      </Footer>
    </Container>
  );
};
